import React from "react";

import InformationalAlert from "./InformationalAlert";

const MailcheckInformationalAlert: React.VFC = () => (
  <InformationalAlert>
    It looked like there was a typo in your email. Please make sure it&apos;s
    correct now.
  </InformationalAlert>
);

export default MailcheckInformationalAlert;
