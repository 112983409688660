import React from "react";

const SVG: React.VFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    aria-hidden="true"
  >
    <path
      d="M1.9987 13.3333H4.4987V8.33333H9.4987V13.3333H11.9987V5.83333L6.9987 2.08333L1.9987 5.83333V13.3333ZM0.332031 15V5L6.9987 0L13.6654 5V15H7.83203V10H6.16536V15H0.332031Z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;
