import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import LabeledSection from "~src/components/layout/LabeledSection";
import { selectIsMobileApp } from "~src/store";

import AccountSettingsToggle from "./AccountSettingsToggle";

const CommunicationSection: React.VFC = () => {
  const container = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();
  const isMobileApp = useSelector(selectIsMobileApp);

  useEffect(() => {
    const root = document.getElementById("root");

    if (hash === "#communication" && root && container.current) {
      root.scrollTo({
        top: container.current.offsetTop,
        left: 0,
        behavior: "instant",
      });
    }
  }, [hash]);

  return (
    <Box id="communication" ref={container}>
      <LabeledSection heading="Communication">
        <Stack spacing={6}>
          {isMobileApp && (
            <Stack spacing={2}>
              <Typography component="legend" variant="h3">
                Push notifications
              </Typography>

              <AccountSettingsToggle
                label="Expiration reminders"
                name="push_credit_reminders_opt_in"
              />
              <AccountSettingsToggle
                label="Credit drops & Promos"
                name="push_highlights_opt_in"
              />
            </Stack>
          )}

          <Stack spacing={2}>
            <AccountSettingsToggle
              label="Text reminders"
              trackingLabel="SMS Credit Reminders"
              name="sms_credit_reminders_opt_in"
              sx={{ label: { fontWeight: 700 } }}
            />

            <Typography paragraph variant="bodyRegular" pr={11.5}>
              Catch will send text reminders if your credits are about to
              expire. Message and data rates may apply. Unsubscribe at any time.
            </Typography>
          </Stack>
        </Stack>
      </LabeledSection>
    </Box>
  );
};

export default CommunicationSection;
