import { UserResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectCurrentUser = makeFetchSelectors<
  UserResponseData | null,
  RootState
>("currentUser");

export { selectCurrentUser };
