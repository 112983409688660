import React from "react";

import { Typography, TypographyProps } from "@mui/material";

const DialogHeader: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography variant="h2" sx={{ textAlign: "center" }} {...props}>
    {children}
  </Typography>
);

DialogHeader.displayName = "DialogHeader";

export default DialogHeader;
