import React from "react";

import { Box } from "@mui/material";

// This component is intended to abstract the heavy styling away
// from the layout/logic of the NavBar.

const NavBarContainer: React.FC = ({ children }) => (
  <Box
    sx={({ palette }) => ({
      position: "relative",
      borderBottom: `1px solid ${palette.divider}`,
      background: palette.common.white,
    })}
  >
    <Box
      sx={{
        display: "flex",
        position: "relative",
        gap: 4,
        alignItems: "center",
        justifyContent: "space-between",
        height: "var(--navbar-height)",
        width: "100%",
        maxWidth: "var(--navbar-content-max-width)",
        margin: "0 auto",
        px: 6,
        ".logo": {
          display: "flex",
          alignItems: "center",
          background: "none",
          border: "none",
          cursor: "pointer",
          height: "100%",
          px: 4,
          mx: -4,
        },
      }}
    >
      {children}
    </Box>
  </Box>
);

export default NavBarContainer;
