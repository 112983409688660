import React from "react";

import { Box } from "@mui/material";

import { SwipeableDrawerAnchor } from "../../utils/modal-utils";

type SwipeableDrawerBarProps = {
  anchor: SwipeableDrawerAnchor;
  fullBleed?: boolean;
};

const SwipeableDrawerBar: React.VFC<SwipeableDrawerBarProps> = ({
  anchor,
  fullBleed,
}) => (
  <Box
    sx={({ spacing }) => ({
      ...(fullBleed && {
        width: "100%",
        height: spacing(2),
        position: "absolute",
        bottom: anchor === "top" ? 0 : "auto",
        top: anchor === "bottom" ? 0 : "auto",
      }),
    })}
  >
    <Box
      component="div"
      sx={({ palette, shape, spacing }) => ({
        bgcolor: "grey.500",
        height: spacing(0.75),
        width: spacing(16),
        m: "auto",
        mb: anchor === "top" ? 2 : "auto",
        mt: anchor === "bottom" ? 2 : "auto",
        borderRadius: `${shape.borderRadius / 2}px`,
        position: "relative",
        zIndex: 1,
        ...(fullBleed && {
          opacity: 0.4,
          boxShadow: `0 0 2px ${palette.background.default}`,
        }),
      })}
    />
  </Box>
);

export default SwipeableDrawerBar;
