import React from "react";

import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const BankHeaderPushToDebit: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => (
  <ConnectPaymentInstrumentAlertHeader
    headingId={headingId}
    title={<>Sorry, you can&apos;t complete this order with a bank account</>}
    subtitle={<>To continue, please link a debit card instead.</>}
  />
);

export default BankHeaderPushToDebit;
