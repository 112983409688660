import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Box } from "@mui/material";

import { CatchLogo } from "~common/components/logos";
import { useTracking } from "~common/tracking";
import useAppLogout from "~src/hooks/services/useAppLogout";
import { selectIsAuthedVerified } from "~src/store";

const NavLogo: React.VFC = () => {
  const { trackEvent } = useTracking();
  const isAuthedVerified = useSelector(selectIsAuthedVerified);

  const logout = useAppLogout();

  // Log out if not fully authenticated and verified.
  // This turns the navbar logo into an escape hatch to reset the
  // app and go back to the phone sign in if you are not verified
  // but want to log out of the authenticated account.
  const handleLogoClick = () => {
    trackEvent("Catch logo clicked", {
      userLoggedIn: isAuthedVerified,
    });

    if (isAuthedVerified) {
      return;
    }

    void logout({ cause: "Clicked unverified NavLogo" });
  };

  if (!isAuthedVerified) {
    return (
      <Box component="button" className="logo" onClick={handleLogoClick}>
        <CatchLogo size="large" />
      </Box>
    );
  }

  return (
    <NavLink to="/home" className="logo" onClick={handleLogoClick}>
      <CatchLogo size="large" />
    </NavLink>
  );
};

export default NavLogo;
