import React, { useEffect } from "react";

import { alpha, Box, Stack, Typography } from "@mui/material";

import { useTracking, useTrackPageView } from "~common/tracking";

import EarnRedeemFAQ from "./EarnRedeemFAQ";
import WhatIsCatchFAQ from "./WhatIsCatchFAQ";

const GetStartedSection: React.VFC = () => {
  useTrackPageView("Get Started Section");
  const { patchTrackingContext } = useTracking();

  useEffect(() => {
    patchTrackingContext({ hasSeenGetStartedSectionHomePage: true });
  }, [patchTrackingContext]);

  return (
    <Box
      sx={({ palette }) => ({
        background: palette.primary.light,
        borderBottom: "1px solid",
        borderColor: alpha(palette.primary.main, 0.1),
        color: palette.primary.main,
      })}
    >
      <Stack
        sx={{
          maxWidth: "var(--medium-page-panel-max-width)",
          margin: "0 auto",
          gap: 4,
          p: 6,
        }}
      >
        <Typography variant="h1">Get started 🥳</Typography>

        <Box
          sx={({ breakpoints, palette }) => ({
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 4,
            [breakpoints.up("md")]: {
              gridTemplateColumns: "repeat(2,1fr)",
              gap: 6,
            },
            ".MuiButton-root.MuiButton-outlined.MuiButton-colorInherit": {
              p: 6,
              border: `1px solid ${alpha(palette.primary.main, 0.3)}`,
              borderRadius: 2,
              backgroundColor: palette.common.white,
              ":hover": {
                backgroundColor: palette.grey[200],
              },
              ".label": { width: "100%" },
              ".MuiSvgIcon-root": {
                color: palette.primary.main,
                height: 24,
                width: 24,
              },
            },
          })}
        >
          <EarnRedeemFAQ />
          <WhatIsCatchFAQ />
        </Box>
      </Stack>
    </Box>
  );
};

export default GetStartedSection;
