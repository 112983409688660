import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { useEnv } from "~common/contexts/env-context";
import { useMerchantAssetsUrl } from "~common/hooks/asset-hooks";
import { MerchantCardInfo } from "~common/services";
import { useTracking } from "~common/tracking";
import { navigateWithUserParams } from "~common/utils/navigation";
import { getUtmParams } from "~src/utils/getUtmsParams";

type MerchantCardProps = {
  merchant: MerchantCardInfo;
  utmCampaign: string;
  categoryName?: string;
  tabIndex?: number;
};

const MerchantCard: React.VFC<MerchantCardProps> = ({
  merchant,
  utmCampaign,
  categoryName,
  tabIndex = 0,
}) => {
  const { APP_ENVIRONMENT, APP_STAGE } = useEnv();
  const { trackEvent } = useTracking();

  const titleCopy = merchant.name;

  const subtitleCopy = merchant.rewards_rate
    ? `Earn ${Math.round(merchant.rewards_rate * 100)}% minimum`
    : null;

  const href = `https://${merchant.url}/?${getUtmParams(utmCampaign)}`;
  const merchantAssetsUrl = useMerchantAssetsUrl(merchant.id);
  const bgImageUrl = `${merchantAssetsUrl}/shop_background_normal.png`;
  const badgeUrl = `${merchantAssetsUrl}/shop_badge.png`;

  const handleClick = () => {
    trackEvent("Shop MerchantCard clicked", {
      merchant: merchant.name,
      category: categoryName,
    });
  };

  return (
    <Stack
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e: React.SyntheticEvent) =>
        navigateWithUserParams(e, APP_ENVIRONMENT, APP_STAGE, handleClick)
      }
      tabIndex={tabIndex}
      sx={({ palette }) => ({
        gap: 2,
        "& > .MuiBox-root": {
          backgroundColor: palette.grey[300],
          borderRadius: 2,
          overflow: "hidden",
          position: "relative",
          width: "100%",
          height: "240px",
          ".photo": {
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: "scale(1)",
            transition: "transform 0.5s",
          },
          ".wrapper": {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            color: palette.common.white,
            ".content": {
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              p: 4,
            },
          },
          ".logo": {
            height: 48,
            width: 48,
            borderRadius: "100%",
            border: `1px solid ${palette.common.white}`,
            overflow: "hidden",
            img: {
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
            },
          },
        },
        "&:hover .photo": {
          transform: "scale(1.05)",
        },
      })}
    >
      <Box>
        <img src={bgImageUrl} className="photo" alt="Merchant background" />
        <Box className="wrapper">
          <Stack className="content">
            <Box className="logo">
              <img src={badgeUrl} alt="Merchant logo" />
            </Box>
          </Stack>
        </Box>
      </Box>
      <Stack>
        <Typography
          variant="h3"
          color="grey.700"
          sx={{
            // TODO: We're hardcoding heights and preventing text overflow to ensure the iframe
            // version for the public page doesn't break due to scroll issues. We should remove these
            // restrictions once we have a better solution for the public page.
            whiteSpace: "nowrap",
          }}
        >
          {titleCopy}
        </Typography>
        <Typography
          variant="bodyRegular"
          color="grey.600"
          data-testid={`${merchant.id}-card-subtitle`}
          sx={{
            // TODO: We're hardcoding heights and preventing text overflow to ensure the iframe
            // version for the public page doesn't break due to scroll issues. We should remove these
            // restrictions once we have a better solution for the public page.
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {subtitleCopy}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MerchantCard;
