import { useGetCurrentUser } from "~common/services/users";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectCurrentUser } from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";

const useGet = () =>
  useGetCurrentUser({
    lazy: true,
    queryParams: {
      include_billing_address: true,
    },
  });

const useFetchCurrentUser = () => {
  useLazyFetch(
    "CurrentUser",
    currentUserActions,
    selectCurrentUser,
    useGet,
    (data) => data
  );
};

export default useFetchCurrentUser;
