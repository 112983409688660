import React from "react";

import { CardGiftcard as GiftIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { WrappedTooltip } from "~common/components/tooltips";

import MerchantCardButton from "./MerchantCardButton";

type ShareAsGiftButtonProps = {
  isRewardGiftable: boolean;
  onClick?: () => void;
};

const ShareAsGiftButton: React.VFC<ShareAsGiftButtonProps> = ({
  onClick,
  isRewardGiftable,
}) => {
  if (isRewardGiftable) {
    return (
      <MerchantCardButton onClick={onClick} endIcon={<GiftIcon fontSize="small" />}>
        Share as gift
      </MerchantCardButton>
    );
  }

  return (
    <WrappedTooltip
      placement="bottom"
      title={
        <Typography variant="bodyRegular" component="p">
          Sorry, some credits earned through promotions can&apos;t be gifted.
        </Typography>
      }
    >
      <MerchantCardButton endIcon={<GiftIcon />} sx={{ opacity: 0.5 }}>
        Share as gift
      </MerchantCardButton>
    </WrappedTooltip>
  );
};

export default ShareAsGiftButton;
