import React from "react";

import { Box, Typography } from "@mui/material";

type SkipnavProps = {
  targetId: string;
};

const Skipnav: React.VFC<SkipnavProps> = ({ targetId }) => (
  <Box
    sx={{
      "&:not(:focus-within)": {
        // Visually hide without using display: none;
        position: "absolute",
        overflow: "hidden",
        width: "1px",
        height: "1px",
        margin: "-1px",
        padding: 0,
        whiteSpace: "nowrap",
        border: 0,
        clip: "rect(0 0 0 0)",
      },

      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1002,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Typography
      component="a"
      variant="bodyRegular"
      href={`#${targetId}`}
      sx={({ shape, shadows }) => ({
        bgcolor: "info.main",
        color: "background.default",
        p: 3,
        borderRadius: `0 0 ${shape.borderRadius}px ${shape.borderRadius}px`,
        boxShadow: shadows[3],
      })}
    >
      Skip to content
    </Typography>
  </Box>
);

export default Skipnav;
