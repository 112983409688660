import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Stack, Typography } from "@mui/material";

import AdyenCardConnect from "~common/components/bank-linking/AdyenCardConnect";
import {
  setBankLinkingStep,
  setDisableAch,
} from "~common/store/slices/bankLinking-slice";
import WrappedBankLinkManager from "~src/components/bank/WrappedBankLinkManager";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";

const LinkCatchPassMobile: React.VFC = () => {
  const dispatch = useDispatch();
  usePaymentInstruments();

  useEffect(() => {
    // Enable inline debit
    dispatch(setBankLinkingStep("ConnectBank"));

    dispatch(setDisableAch(true));

    return () => {
      dispatch(setDisableAch(false));
    };
  }, [dispatch]);

  return (
    <WrappedBankLinkManager>
      <Stack direction="column" alignItems="center" gap={4} mt={8}>
        <Typography
          variant="bodyLarge"
          color="black"
          sx={{ width: `calc(100vw - 48px)` }}
        >
          The Catch card comes with a free digital debit card linked to your existing
          debit card, so you can earn everywhere.
        </Typography>
        <AdyenCardConnect inline />
      </Stack>
    </WrappedBankLinkManager>
  );
};

export default LinkCatchPassMobile;
