import React from "react";
import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { selectDisableAch } from "../../../../../store/selectors";
import { BankLockIcon } from "../../../../icons/vector";
import PaymentInstrumentProviders from "./PaymentInstrumentProviders";

type PaymentInstrumentHeaderDefaultProps = {
  headingId?: string;
  isModal?: boolean;
};
const PaymentInstrumentHeaderDefault: React.VFC<
  PaymentInstrumentHeaderDefaultProps
> = ({ headingId, isModal }) => {
  const disableAch = useSelector(selectDisableAch);
  return (
    <>
      <Stack spacing={isModal ? 2 : 4} className="BankLinking-header">
        <BankLockIcon size="small" />
        <Typography variant="h1" id={headingId}>
          {disableAch ? "Link your debit card" : "Select a payment method"}
        </Typography>
        <Typography variant="h3" display="inline">
          Your private payment information is never visible to us.
          <PaymentInstrumentProviders />{" "}
          <Typography variant="bodyRegular" display="inline" color="grey.600">
            to encrypt it and keep it safe.
          </Typography>
        </Typography>
        <Typography variant="h3">No cost to you. Just rewards.</Typography>
      </Stack>
    </>
  );
};
export default PaymentInstrumentHeaderDefault;
