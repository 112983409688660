const removePhoneNumberFormatting = (value: string): string =>
  value.replace(/\D/g, "");

const formatUSPhoneNumber = (value: string): string => {
  value = value.trim();
  if (!value) {
    return "";
  }
  // Strip the US country code.
  if (value.startsWith("+1")) {
    value = value.slice(2);
  }
  const digits = removePhoneNumberFormatting(value);
  const { length } = digits;
  if (!length) {
    return "";
  }
  // Account for full US numbers that include a leading "1".
  if (length === 11 && digits[0] === "1") {
    return formatUSPhoneNumber(digits.slice(1));
  }
  let out = `(${digits.slice(0, 3)}`;
  if (length > 3) {
    out = `${out}) ${digits.slice(3, 6)}`;
  }
  if (length > 6) {
    out = `${out}-${digits.slice(6, 10)}`;
  }
  return out;
};

const isValidUSPhoneNumber = (value: string): boolean => {
  const cleaned = removePhoneNumberFormatting(value);
  const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  if (cleaned.length !== 10 || !regex.test(cleaned)) {
    return false;
  }
  return true;
};

export {
  formatUSPhoneNumber,
  isValidUSPhoneNumber,
  removePhoneNumberFormatting,
};
