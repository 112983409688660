import mailcheck from "mailcheck";

const INVALID_EMAIL_MESSAGE = "Please enter a valid email address";
const EMAIL_IN_USE_MESSAGE = "Looks like that email is already in use";

const isValidEmailAddress = (email: string): boolean => {
  const regEx =
    /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
  return email.length !== 0 && regEx.test(email);
};

const runMailcheck = (email: string): string | undefined => {
  const mailcheckResult = mailcheck.run({ email });
  return mailcheckResult?.full;
};

export {
  EMAIL_IN_USE_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  isValidEmailAddress,
  runMailcheck,
};
