import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { ErrorAlert } from "~common/components/alerts";
import RewardCard from "~common/components/cards/RewardCard";
import ExternalTextLink from "~common/components/links/ExternalTextLink";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import { useTracking } from "~common/tracking";
import { formatPercent } from "~common/utils/numbers";
import LandingPagePanel from "~src/components/landing/LandingPagePanel";
import LandingPageRewardCard from "~src/components/landing/LandingPageRewardCard";
import YouCanStillEarnCredit from "~src/components/landing/YouCanStillEarnCredit";
import useRewardCampaign from "~src/hooks/services/useRewardCampaign";
import usePublicMerchantById from "~src/hooks/usePublicMerchantById";
import { setShowAppAdoptionNux } from "~src/store/slices/nuxs-slice";
import {
  setClaimedCampaign,
  setClaimedCampaignId,
  setClaimedCampaignSource,
} from "~src/store/slices/user-slice";
import { rewardCampaignInfo } from "~src/utils/reward-campaigns";

const LandingCampaign: React.VFC = () => {
  const { rewardCampaignId } = useParams();

  const { rewardCampaign, loading, error } = useRewardCampaign(
    rewardCampaignId || ""
  );
  const { merchant } = usePublicMerchantById(rewardCampaign?.merchant_id);
  const merchantRewardRate = useMemo(() => merchant?.rewards_rate, [merchant]);
  const dispatch = useDispatch();
  const { trackPage } = useTracking();

  const {
    isInvalidCampaign,
    isExpiredCampaign,
    isOnlyForNewUsers,
    isFlexCredit,
    isSecondChanceBonus,
  } = rewardCampaignInfo(rewardCampaign, error);

  const fullyLoaded =
    (!loading && (merchantRewardRate || isFlexCredit)) || error;

  useEffect(() => {
    dispatch(setShowAppAdoptionNux(false));
  }, [dispatch]);

  useEffect(() => {
    if (rewardCampaign !== null && !error) {
      // Save reward campaign info to store for claiming after authentication.
      dispatch(setClaimedCampaignId(rewardCampaignId));
      dispatch(
        setClaimedCampaignSource("user_portal_reward_campaign_landing_page")
      );
      dispatch(setClaimedCampaign(rewardCampaign));
    }

    // Don't fire tracking until attributes have correctly updated
    if (
      isSecondChanceBonus === null ||
      isInvalidCampaign === null ||
      (!rewardCampaign && !error)
    ) {
      return;
    }

    trackPage("Claim Reward Landing Page", {
      rewardCampaignId,
      isSecondChanceBonus,
      isInvalidCampaign,
    });
  }, [
    dispatch,
    rewardCampaign,
    error,
    rewardCampaignId,
    trackPage,
    isSecondChanceBonus,
    isInvalidCampaign,
  ]);

  let contents;
  let ctaCopy = "Continue with Catch";
  let containerProps = {
    bgcolor: "tertiary.main",
    color: "common.white",
    pb: 2,
  };

  if (isInvalidCampaign) {
    contents = (
      <>
        <Typography variant="h2" sx={{ mb: 4 }}>
          Looks like that link isn&apos;t valid, but hey...
        </Typography>
        <YouCanStillEarnCredit />
      </>
    );
  } else if (isExpiredCampaign) {
    contents = (
      <>
        <Typography variant="h2" sx={{ mb: 4 }}>
          Sorry, this credit is no longer available, but hey...
        </Typography>
        <YouCanStillEarnCredit />
      </>
    );
  } else if (error) {
    contents = (
      <>
        <ErrorAlert>Could not load data. Please try again later.</ErrorAlert>
      </>
    );
  } else if (rewardCampaign) {
    ctaCopy = "Claim your credit";
    contents = (
      <>
        <LandingPageRewardCard>
          <RewardCard reward={rewardCampaign} sx={{ width: 224, mb: 8 }} />
        </LandingPageRewardCard>

        <Typography variant="h1" color="grey.700">
          Pay with Catch.
        </Typography>
        <Typography variant="h1" color="primary.main">
          {isFlexCredit
            ? "Earn 5-10% every time"
            : merchantRewardRate &&
              `Earn ${formatPercent(merchantRewardRate)} every time`}
        </Typography>
        {isFlexCredit && (
          <Typography variant="bodyLarge" sx={{ mt: 4 }}>
            Catch Cash can be redeemed at{" "}
            <ExternalTextLink
              size="large"
              href="https://www.getcatch.com/where-to-shop"
              eventName="Where To Shop Clicked"
              sx={{ fontWeight: 500 }}
            >
              any brand
            </ExternalTextLink>{" "}
            where you shop with Catch.
          </Typography>
        )}
        {isOnlyForNewUsers && (
          <Typography variant="bodyLarge" sx={{ mt: 4 }}>
            You can claim this credit if you&apos;ve never made a purchase or
            claimed a credit using Catch.
          </Typography>
        )}
      </>
    );
    containerProps = {
      bgcolor: "common.white",
      color: "grey.700",
      pb: 0,
    };
  }

  return (
    <Box {...containerProps}>
      <LandingPagePanel ctaCopy={ctaCopy}>
        <LoadingGuard {...{ loading: !fullyLoaded }}>{contents}</LoadingGuard>
      </LandingPagePanel>
    </Box>
  );
};

export default LandingCampaign;
