import { RewardPoolsResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectRewardPools = makeFetchSelectors<
  RewardPoolsResponseData | null,
  RootState
>("rewardPools");

export { selectRewardPools };
