import React from "react";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";

import { WrappedAccordion } from "~common/components/accordions";
import { ConsumerActivity } from "~common/services";
import { formatUSDate } from "~common/utils/dates";
import { formatMoney } from "~common/utils/money";
import {
  getAmountColor,
  getAmountOperator,
  getDescription,
} from "~src/utils/activities";

import ActivityFeedIcon from "./ActivityFeedIcon";
import ActivityFeedRewardRow from "./ActivityFeedRewardRow";

type ActivityFeedActivityrops = {
  activity: ConsumerActivity;
};

const ActivityFeedActivity: React.VFC<ActivityFeedActivityrops> = ({
  activity,
}) => {
  const amountOperator = getAmountOperator(activity);
  const amountColor = getAmountColor(activity, amountOperator);

  return (
    <WrappedAccordion
      color="grey.700"
      sx={({ palette }) => ({
        pb: 4,
        "& + .MuiAccordion-root": {
          borderTop: `1px solid ${palette.grey[300]}`,
          pt: 4,
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        sx={{ mr: 2.5 }}
        id={activity.id}
        aria-controls={`${activity.id}-details`}
      >
        <Stack
          width="100%"
          spacing={4}
          direction="row"
          alignItems="center"
          pr={2.5}
        >
          <ActivityFeedIcon type={activity.activity_type} />

          <Stack
            width="100%"
            spacing={4}
            direction="row"
            justifyContent="space-between"
          >
            <Stack>
              <Typography variant="h3" component="p">
                {activity.activity_type === "referral"
                  ? `Referral credit${
                      activity.details.user_name
                        ? ` for ${activity.details.user_name}`
                        : ""
                    }`
                  : `${activity.details.merchant_name}`}
              </Typography>

              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="bodyXSmall" color="grey.500">
                  {formatUSDate(activity.event_datetime, {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })}
                </Typography>

                {activity.activity_type === "purchase" &&
                  activity.details.is_pending && (
                    <Typography
                      variant="bodyXSmall"
                      color="grey.600"
                      fontWeight="700"
                      sx={({ palette, shape }) => ({
                        background: palette.grey[200],
                        borderRadius: `${shape.borderRadius}px`,
                        py: 0.5,
                        px: 1,
                      })}
                    >
                      Pending
                    </Typography>
                  )}
              </Stack>
            </Stack>

            <Stack textAlign="right">
              <Typography variant="h3" component="p" color={amountColor}>
                {amountOperator}
                {formatMoney(Math.abs(activity.details.reward_change_amount))}
              </Typography>

              {(activity.activity_type === "purchase" ||
                activity.activity_type === "reward_applied") &&
                !!activity.details.purchase_total && (
                  <Typography variant="bodyXSmall" color="grey.500">
                    {formatMoney(activity.details.purchase_total)}
                  </Typography>
                )}

              {activity.activity_type === "refund" && (
                <Typography variant="bodyXSmall" color="grey.500">
                  {formatMoney(activity.details.refund_total)}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          pt: 2,
          pb: 0,
        }}
      >
        <Stack
          spacing={2}
          bgcolor="grey.200"
          p={1}
          sx={({ shape }) => ({
            borderRadius: `${shape.borderRadius}px`,
            mx: {
              zero: 0,
              sm: 10,
            },
          })}
        >
          {(activity.activity_type === "purchase" &&
            !!activity.details.earned_rewards?.length) ||
          (activity.activity_type === "reward_applied" &&
            !!activity.details.redeemed_rewards?.length) ? (
            <>
              {activity.activity_type === "purchase" &&
                activity.details.earned_rewards?.map((reward) => (
                  <ActivityFeedRewardRow
                    key={`${reward.title}-${reward.subtitle}-${reward.amount}`}
                    title={reward.title}
                    subtitle={reward.subtitle}
                    amount={reward.amount}
                    amountOperator="+"
                  />
                ))}

              {activity.activity_type === "reward_applied" &&
                activity.details.redeemed_rewards?.map((reward) => (
                  <ActivityFeedRewardRow
                    key={`${reward.title}-${reward.subtitle}-${reward.amount}`}
                    title={reward.title}
                    subtitle={reward.subtitle}
                    amount={reward.amount}
                    amountOperator="-"
                  />
                ))}
            </>
          ) : (
            <ActivityFeedRewardRow
              title={getDescription(activity)}
              amount={activity.details.reward_change_amount}
              amountOperator={amountOperator}
            />
          )}
        </Stack>
      </AccordionDetails>
    </WrappedAccordion>
  );
};

export default ActivityFeedActivity;
