import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import PinForm from "~src/components/shared/onboarding/PinForm";
import { selectClaims } from "~src/store";

type PinSetupProps = {
  onNext: () => void;
};

const PinSetup: React.VFC<PinSetupProps> = ({ onNext }) => {
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onNext();
      }, 5000);
    }
  }, [success, onNext]);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <SmallPagePanel
      title="Set Up Your PIN"
      subtitle="Stores might ask for it when you use your Catch card in person with Apple Pay. Make sure you can memorize it or write it down."
      icon={
        newUserOnboardingRewardCampaign ? (
          <CatchCardReward
            reward={newUserOnboardingRewardCampaign}
            percentComplete={(6 / 7) * 100}
          />
        ) : (
          <></>
        )
      }
    >
      <PinForm onSuccess={handleSuccess} />
    </SmallPagePanel>
  );
};

export default PinSetup;
