import React, { useCallback, useMemo, useState } from "react";

import {
  CardGiftcard as GiftIcon,
  DeleteForeverOutlined as TrashIcon,
} from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";

import DefaultButton from "~common/components/controls/buttons/DefaultButton";
import { RewardPoolEntry, useRemoveRewardPoolEntry } from "~common/services";
import { formatMoney } from "~common/utils/money";
import usePublicMerchantById from "~src/hooks/usePublicMerchantById";
import useRefreshCreditExchange from "~src/hooks/useRefreshCreditExchange";
import { merchantNameFromId } from "~src/utils/merchants";

import ClaimRewardPoolEntryModal from "./ClaimRewardPoolEntryModal";

type AvailableCreditRowProps = {
  rewardPoolEntry: RewardPoolEntry;
  senderIsCurrentUser: boolean;
  divider: boolean;
};

const AvailableCreditRow: React.VFC<AvailableCreditRowProps> = ({
  rewardPoolEntry,
  senderIsCurrentUser,
  divider,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { mutate: removeRewardPoolEntry, loading: loadingRemoval } =
    useRemoveRewardPoolEntry();
  const refreshCreditExchange = useRefreshCreditExchange();
  const { merchant } = usePublicMerchantById(rewardPoolEntry.merchant_id);
  const merchantName = useMemo(
    () => merchant?.name || merchantNameFromId(rewardPoolEntry.merchant_id),
    [merchant, rewardPoolEntry]
  );

  const handleClick = useCallback(async () => {
    if (loadingRemoval) return;
    if (senderIsCurrentUser) {
      await removeRewardPoolEntry(undefined, {
        pathParams: {
          rewardPoolId: rewardPoolEntry.pool_id,
          hashedGiftSecret: rewardPoolEntry.hashed_gift_secret,
        },
      });
      refreshCreditExchange();
    } else {
      setShowModal(true);
    }
  }, [
    loadingRemoval,
    senderIsCurrentUser,
    removeRewardPoolEntry,
    refreshCreditExchange,
    rewardPoolEntry,
    setShowModal,
  ]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
      >
        <Stack direction="row" alignItems="center" gap={4}>
          <GiftIcon
            sx={{ width: 24, height: 24, minWidth: 24, minHeight: 24 }}
          />
          <Stack direction="column">
            <Typography variant="h3">
              <strong>
                {formatMoney(rewardPoolEntry.amount)} to {merchantName}
              </strong>{" "}
              <Typography variant="bodyRegular">
                from {rewardPoolEntry.sender_name}
              </Typography>
            </Typography>
            <Typography variant="bodySmall">
              In {rewardPoolEntry.pool_name}
            </Typography>
          </Stack>
        </Stack>
        <DefaultButton
          startIcon={senderIsCurrentUser && <TrashIcon />}
          onClick={handleClick}
          loading={loadingRemoval}
        >
          {senderIsCurrentUser ? "Remove" : "Claim"}
        </DefaultButton>
      </Stack>
      {divider && <Divider sx={{ my: 4 }} />}
      <ClaimRewardPoolEntryModal
        open={showModal}
        onClose={() => setShowModal(false)}
        rewardPoolEntry={rewardPoolEntry}
      />
    </>
  );
};

export default AvailableCreditRow;
