// This hook wraps all the hooks we want to run at the top of `App.tsx`

import useAuthEffects from "./useAuthEffects";
import useLazyServices from "./useLazyServices";
import usePatchInitialAppContext from "./usePatchInitialAppContext";

const useAppEffects = () => {
  useAuthEffects();
  usePatchInitialAppContext();
  useLazyServices();
};

export default useAppEffects;
