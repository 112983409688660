import { useGet } from "restful-react";

import { sortEarnedRewardsBreakdown } from "../utils/configurable-rewards";
import {
  DiscoverRewardsResponseData,
  GetCalculatedEarnedRewardsPublicPathParams,
  GetCalculatedEarnedRewardsPublicQueryParams,
  GetCalculatedEarnedRewardsPublicResponse,
  GetDiscoverRewardsPathParams,
  GetDiscoverRewardsQueryParams,
} from "./types/configurable-rewards-types";
import { ErrorResponseData } from "./types/error-handling-types";
import {
  GetUserRewardsPathParams,
  UserRewardsResponseData,
} from "./types/rewards-types";

const useGetUserRewards = (
  options: { pathParams?: GetUserRewardsPathParams; lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<
    UserRewardsResponseData,
    ErrorResponseData,
    undefined,
    GetUserRewardsPathParams
  >({
    path: ({ userId }) => `transactions-svc/users/${userId}/rewards`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

const useGetCalculatedEarnedRewardsPublic = (
  options: {
    pathParams?: GetCalculatedEarnedRewardsPublicPathParams;
    queryParams?: GetCalculatedEarnedRewardsPublicQueryParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    GetCalculatedEarnedRewardsPublicResponse,
    ErrorResponseData,
    GetCalculatedEarnedRewardsPublicQueryParams,
    GetCalculatedEarnedRewardsPublicPathParams
  >({
    path: ({ merchantIdentifier }) =>
      `transactions-svc/merchants/${merchantIdentifier}/calculate_earned_rewards/public`,
    pathParams: options.pathParams,
    queryParams: options.queryParams,
    lazy: options.lazy,
  });

const useGetDiscoverRewards = (
  options: {
    pathParams?: GetDiscoverRewardsPathParams;
    queryParams?: GetDiscoverRewardsQueryParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    DiscoverRewardsResponseData,
    ErrorResponseData,
    GetDiscoverRewardsQueryParams,
    GetDiscoverRewardsPathParams
  >({
    path: ({ merchantId }) =>
      `transactions-svc/merchants/${merchantId}/discover_rewards`,
    pathParams: options.pathParams,
    queryParams: options.queryParams,
    lazy: options.lazy,
    resolve: (response: DiscoverRewardsResponseData) => ({
      reward_rules: sortEarnedRewardsBreakdown(response.reward_rules),
    }),
  });

export {
  useGetCalculatedEarnedRewardsPublic,
  useGetDiscoverRewards,
  useGetUserRewards,
};
