import React from "react";

import { Skeleton, SkeletonProps } from "@mui/material";

type WrappedSkeletonProps = Omit<SkeletonProps, "animation"> & {
  variant: Required<SkeletonProps["variant"]>;
};

// Our own version of Skeleton for e.g. future consistent style overrides,
// but more importantly, to enforce pulse animation and enforce variant
// selection for accuracy.

const WrappedSkeleton: React.VFC<WrappedSkeletonProps> = ({ ...props }) => (
  <Skeleton animation="pulse" {...props} />
);

export default WrappedSkeleton;
