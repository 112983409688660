import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";

import AnimatedPhoneSendingFallback from "./AnimatedPhoneSendingFallback";

const AnimatedPhoneSendingLottie = lazy(
  () => import("./AnimatedPhoneSendingLottie")
);

const AnimatedPhoneSending: React.VFC = () => (
  <Suspense
    fallback={
      <Box style={{ width: 80, height: 80, margin: -0 }}>
        <AnimatedPhoneSendingFallback />
      </Box>
    }
  >
    <AnimatedPhoneSendingLottie />
  </Suspense>
);

export default AnimatedPhoneSending;
