import React, { useEffect } from "react";

import { useTracking } from "../../tracking";
import { ErrorAlert } from "../alerts";
import GenericLoadingScreen from "./GenericLoadingScreen";

type LoadingGuardProps = {
  loading?: boolean;
  error?: unknown;
  isFullscreen?: boolean;
  children: React.ReactNode;
};

const LoadingGuard: React.FC<LoadingGuardProps> = ({
  loading,
  error = null,
  isFullscreen = false,
  children,
}) => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!loading && error) {
      trackEvent("Generic error box rendered", {
        error,
        component: "LoadingGuard",
      });
    }
  }, [trackEvent, loading, error]);

  if (loading) {
    return <GenericLoadingScreen {...{ isFullscreen }} />;
  }
  if (error) {
    return (
      <ErrorAlert>Could not load data. Please try again later.</ErrorAlert>
    );
  }
  return <>{children}</>;
};

LoadingGuard.defaultProps = {
  loading: undefined,
  error: null,
  isFullscreen: false,
};

export default LoadingGuard;
