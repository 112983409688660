// GENERATED FILE. DO NOT EDIT.

import React from "react";

import IconSVG from "../vector-source/Donate.icon.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const DonateIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon iconSvg={IconSVG} {...props} />
);

export default DonateIcon;
