import React from "react";

import { alpha, Stack, Typography } from "@mui/material";

const MyCreditsEmpty: React.VFC = () => (
  <Stack
    justifyContent="space-between"
    sx={[
      ({ palette }) => ({
        background: palette.grey[200],
        width: "100%",
        maxWidth: 300,
        p: 4,
        pb: 2,
        borderRadius: "6% / 9.71%",
        boxShadow: `0 1px 2px ${alpha(palette.grey[700], 0.3)}`,
        aspectRatio: "1.618 / 1",
        overflow: "hidden",
        color: palette.grey[300],
      }),
    ]}
  >
    <Typography variant="bodySmall" color="grey.400">
      Your rewards will live here
    </Typography>

    <Stack>
      <Typography variant="h1" component="p">
        $0.00
      </Typography>

      <Typography
        sx={{
          color: "inherit",
          fontSize: 13,
          lineHeight: 1.54,
        }}
      >
        Exp. 00/00/00
      </Typography>
    </Stack>
  </Stack>
);

export default MyCreditsEmpty;
