import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.5547 57.7498L15.0547 49.1248L13.5547 56.6248L56.0547 65.2498L57.5547 57.7498Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5547 27.2495L44.3047 14.6245L63.6797 36.6245L17.5547 27.2495Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1797 29.1245L20.1797 48.7495"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.0547 35.7502L53.0547 55.3753"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.4297 34.375L46.4297 54"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6797 30.3752L26.6797 50.0002"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3047 31.7498L33.3047 51.3748"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.8047 33.125L39.8047 52.75"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5543 27.7506C43.3043 28.1256 45.1793 27.0006 45.5543 25.1256C45.9293 23.3756 44.8043 21.5006 42.9293 21.1256C41.1793 20.7506 39.3043 21.8756 38.9293 23.7506C38.5543 25.6256 39.6793 27.3756 41.5543 27.7506Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.4297 50.5003C66.4297 50.5003 80.9297 47.0002 75.3047 40.7502"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.1797 43.9997C58.6797 43.8747 63.3047 41.6247 67.8047 38.6247C73.0547 35.1247 76.6797 32.3747 77.9297 34.7497C79.0547 36.9997 75.9297 42.2497 66.1797 46.2497"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.0547 52.749C55.9297 52.874 59.3047 54.499 62.6797 54.499C65.8047 54.624 72.5547 54.124 72.0547 49.999"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.30446 38.8743C9.30446 38.8743 -2.57055 29.9993 5.05446 26.3743"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3043 36.8747C19.0543 35.7497 15.8043 31.8747 12.8043 27.3747C9.3043 22.1247 7.0543 18.1247 5.0543 19.8747C3.0543 21.3747 3.9293 27.4997 11.3043 34.9997"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6794 45.875C17.9294 45.25 14.1794 45.375 11.0544 44.125C8.17938 43 2.05437 39.875 4.17938 36.25"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
