import { useSelector } from "react-redux";

import { useGetUserRewards } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectUserId, selectUserRewards } from "~src/store";
import { userRewardsActions } from "~src/store/slices/services/userRewards-slice";

const useFetchUserRewards = () => {
  const userId = useSelector(selectUserId);

  const useGet = () =>
    useGetUserRewards({
      pathParams: {
        userId: userId ?? "",
      },
      lazy: true,
    });

  useLazyFetch(
    "UserRewards",
    userRewardsActions,
    selectUserRewards,
    useGet,
    (data) => data.rewards
  );
};

export default useFetchUserRewards;
