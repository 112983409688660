import React from "react";

import useMedia from "../../hooks/useMedia";
import DraggableWallet from "./DraggableWallet";
import GridWallet from "./GridWallet";

const Wallet: React.FC = ({ children }) => {
  const media = useMedia();

  if (media.above.md) return <GridWallet {...{ children }} />;

  return <DraggableWallet {...{ children }} />;
};

export default Wallet;
