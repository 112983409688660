import React from "react";

import { RestfulProvider } from "restful-react";

import store from "~src/store";
import { getDevice } from "~src/utils/devices";

type CatchClientApp =
  | "ios_app"
  | "android_app"
  | "mobile_web_ios"
  | "mobile_web_android"
  | "desktop_web";

const getAuthHeader = () => {
  const { authData } = store.getState().user;
  return authData ? authData.id_token : "";
};

const getClientAppHeader = (): CatchClientApp => {
  /*
   * Normally, we use the react-router useSearchParams hook to get search
   * params. However, using a hook can cause slow or intially incorrect results
   * and in this case we need to know if a user is in an app webview immediately.
   * Since "hideHeader" (the param set in the apps) won't change, it's safe to
   * use URLSearchParams here instead.
   */
  const urlParams = new URLSearchParams(window.location.search);
  const device = getDevice();

  if (urlParams.get("hideHeader") === "true") {
    return device === "android" ? "android_app" : "ios_app";
  }

  if (device === "android") {
    return "mobile_web_android";
  }

  if (device === "iphone") {
    return "mobile_web_ios";
  }

  return "desktop_web";
};

const ServiceProvider: React.FC = ({ children }) => (
  <RestfulProvider
    base="/api"
    requestOptions={() => ({
      headers: {
        Authorization: getAuthHeader(),
        "x-catch-client-app": getClientAppHeader(),
      },
    })}
  >
    {children}
  </RestfulProvider>
);

export default ServiceProvider;
