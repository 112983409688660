import { useMediaQuery, useTheme } from "@mui/material";

const useMedia = () => {
  const { breakpoints } = useTheme();

  return {
    below: {
      xs: useMediaQuery(breakpoints.down("xs")),
      sm: useMediaQuery(breakpoints.down("sm")),
      md: useMediaQuery(breakpoints.down("md")),
      lg: useMediaQuery(breakpoints.down("lg")),
      xl: useMediaQuery(breakpoints.down("xl")),
    },

    above: {
      xs: useMediaQuery(breakpoints.up("xs")),
      sm: useMediaQuery(breakpoints.up("sm")),
      md: useMediaQuery(breakpoints.up("md")),
      lg: useMediaQuery(breakpoints.up("lg")),
      xl: useMediaQuery(breakpoints.up("xl")),
    },
  };
};

export default useMedia;
