import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Box } from "@mui/material";

import { useTracking } from "~common/tracking";
import { selectMenuOpen } from "~src/store";
import { setMenuOpen } from "~src/store/slices/core-slice";

type NavItemProps = {
  name: string;
  href: string;
  external?: boolean;
};

const NavItem: React.FC<NavItemProps> = ({
  name,
  href,
  external,
  children,
}) => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const menuOpen = useSelector(selectMenuOpen);

  const handleClick = () => {
    trackEvent(`${name} NavItem clicked`, {
      menuOpen,
      href,
    });

    dispatch(setMenuOpen(false));
  };

  return (
    <Box
      sx={[
        ({ palette, breakpoints }) => ({
          height: "100%",
          "a[target=_blank] .MuiBox-root": {
            color: palette.grey[500],
            borderBottom: "3px solid transparent",
            fontWeight: 500,
          },
          ".MuiBox-root": {
            cursor: "pointer",
            display: "flex",
            height: "100%",
            alignItems: "center",
            borderTop: "3px solid transparent",
            fontSize: "16px",
          },
          [breakpoints.down("md")]: {
            width: "100%",
            ".MuiBox-root": {
              width: "100%",
              justifyContent: "center",
              py: 5,
              border: "none",
            },
          },
        }),
      ]}
    >
      {external ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <Box>{children}</Box>
        </a>
      ) : (
        <NavLink to={href} onClick={handleClick}>
          {({ isActive }) => (
            <Box
              sx={({ palette, breakpoints }) =>
                isActive
                  ? {
                      borderBottom: "3px solid transparent",
                      color: palette.grey[700],
                      fontWeight: 700,
                      [breakpoints.up("md")]: {
                        borderBottomColor: palette.primary.main,
                      },
                    }
                  : {
                      borderBottom: `3px solid transparent`,
                      color: palette.grey[500],
                      fontWeight: 500,
                    }
              }
            >
              {children}
            </Box>
          )}
        </NavLink>
      )}
    </Box>
  );
};

NavItem.defaultProps = {
  external: false,
};

export default NavItem;
