import { useEffect, useRef } from "react";

import { initialize, WithJWT } from "@iterable/web-sdk";

import { ITERABLE_KEY } from "~src/config";

const useIterable = (idToken?: string) => {
  const iterable = useRef<WithJWT>();

  useEffect(() => {
    if (idToken) {
      iterable.current = initialize(ITERABLE_KEY, () =>
        Promise.resolve(idToken)
      );
    }
  }, [idToken]);

  return iterable.current;
};

export default useIterable;
