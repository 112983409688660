import { selectCurrentUser } from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";

import useLazyService from "./useLazyService";

const useCurrentUser = () => {
  const {
    data: currentUser,
    loading,
    error,
  } = useLazyService(selectCurrentUser, currentUserActions);

  return { currentUser, loading, error };
};

export default useCurrentUser;
