import type { AppEnvironment, AppStage } from "./config-utils";

const getLocalDeviceTokenKey = (env: AppEnvironment, stage: AppStage) => {
  let key = "device_token";

  if (stage === "sandbox") {
    key = `sandbox_${key}`;
  }

  if (env === "development") {
    key = `dev_${key}`;
  }

  if (env === "staging") {
    key = `staging_${key}`;
  }

  return key;
};

export { getLocalDeviceTokenKey };
