import { useEffect, useState } from "react";

import { ReferralResponseData, useGetReferral } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { ErrorResponseData } from "~common/services/types/error-handling-types";
import { useTracking } from "~common/tracking";

const useReferral = (referralCode: string) => {
  const { captureException } = useTracking();
  const [referral, setReferral] = useState<ReferralResponseData | null>(null);
  const [error, setError] = useState<ErrorResponseData | null>(null);

  const {
    refetch,
    loading,
    error: serviceError,
  } = useGetReferral({
    pathParams: {
      referralCode,
    },
    lazy: true,
  });

  useEffect(() => {
    const refresh = async (): Promise<void> => {
      try {
        const responseData = await refetch();

        if (responseData) {
          setReferral(responseData);
        }
      } catch (_error) {
        // Restful-react can't properly handle catching
        // errors on lazy GET requests yet. See the useEffect
        // below for handling.
      }
    };

    if (referral === null) {
      void refresh();
    }
  }, [referral, refetch]);

  // Handle service errors and update error state.
  useEffect(() => {
    let newError;

    if (serviceError !== null) {
      newError = convertRawServiceError(serviceError);
      captureException({
        component: `useReferral`,
        exceptionMessage: `Error fetching Referral`,
        rawError: serviceError,
      });
    } else {
      newError = null;
    }

    setError(newError);
  }, [serviceError, captureException]);

  return { referral, loading, error };
};

export default useReferral;
