import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";
import { scrollToTop } from "~common/utils/scrollToTop";
import { selectMenuOpen } from "~src/store";
import { setMenuOpen } from "~src/store/slices/core-slice";

const NavMenuButton: React.VFC = () => {
  const { trackEvent } = useTracking();
  const menuOpen = useSelector(selectMenuOpen);
  const dispatch = useDispatch();

  const handleToggleMenu = () => {
    trackEvent("Menu toggled");
    dispatch(setMenuOpen(!menuOpen));

    if (!menuOpen) {
      // Scroll to top if user clicked Menu button from slightly lower position.
      scrollToTop();
    }
  };

  return (
    <Stack direction="row" spacing={3}>
      <DefaultButton
        onClick={handleToggleMenu}
        sx={{
          "&.MuiButton-sizeMedium": {
            px: 6,
          },
        }}
      >
        Menu
      </DefaultButton>
    </Stack>
  );
};

export default NavMenuButton;
