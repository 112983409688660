import React, { useCallback, useState } from "react";

import { Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import SimpleMultiselect from "~common/components/inputs/SimpleMultiselect";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import { DialogBody, DialogHeader } from "~common/components/modals";
import { RewardPool } from "~common/services";
import useRewardPools from "~src/hooks/services/useRewardPools";

type ChooseRewardPoolsModalContentProps = {
  onSubmit: (selectedPools: RewardPool[]) => void;
  dialogId: string;
  dialogDescriptionId: string;
  loadingEntryCreation: boolean;
};

const ChooseRewardPoolsModalContent: React.VFC<
  ChooseRewardPoolsModalContentProps
> = ({ onSubmit, dialogId, dialogDescriptionId, loadingEntryCreation }) => {
  const { rewardPools, loading: loadingRewardPools, error } = useRewardPools();
  const [selectedPoolIndices, setSelectedIndices] = useState<number[]>([]);
  const hasRewardPools = rewardPools !== null && rewardPools.length > 0;

  const handleGiftItClick = useCallback(() => {
    if (rewardPools === null) return;
    onSubmit(selectedPoolIndices.map((idx) => rewardPools[idx]));
  }, [rewardPools, selectedPoolIndices, onSubmit]);

  return (
    <Stack spacing={4}>
      <DialogHeader id={dialogId} variant="h1">
        Select a pool
      </DialogHeader>
      <DialogBody id={dialogDescriptionId}>
        Please select which of the following pools you would like to drop your
        credits into.
      </DialogBody>
      <Stack sx={{ height: 120, overflowY: "scroll" }}>
        <LoadingGuard loading={loadingRewardPools} {...{ error }}>
          {!hasRewardPools ? (
            <Typography color="grey.500" sx={{ alignSelf: "center" }}>
              No reward pools found.
            </Typography>
          ) : (
            <SimpleMultiselect
              options={rewardPools.map((pool) => pool.name)}
              selectedIndices={selectedPoolIndices}
              onSelectionChange={setSelectedIndices}
            />
          )}
        </LoadingGuard>
      </Stack>
      <PrimaryButton
        disabled={selectedPoolIndices.length <= 0}
        loading={loadingEntryCreation}
        onClick={handleGiftItClick}
      >
        Gift it
      </PrimaryButton>
    </Stack>
  );
};

export default ChooseRewardPoolsModalContent;
