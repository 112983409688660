import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 12.5V9.99999H6.66667V8.33332H4.16667V5.83332H2.5V8.33332H0V9.99999H2.5V12.5H4.16667ZM10 11.4583C8.05 11.4583 4.16667 12.4333 4.16667 14.375V15.8333H15.8333V14.375C15.8333 12.4333 11.95 11.4583 10 11.4583ZM6.11667 14.1667C6.81667 13.6833 8.50833 13.125 10 13.125C11.4917 13.125 13.1833 13.6833 13.8833 14.1667H6.11667ZM10 9.99999C11.6083 9.99999 12.9167 8.69166 12.9167 7.08332C12.9167 5.47499 11.6083 4.16666 10 4.16666C8.39167 4.16666 7.08333 5.47499 7.08333 7.08332C7.08333 8.69166 8.39167 9.99999 10 9.99999ZM10 5.83332C10.6917 5.83332 11.25 6.39166 11.25 7.08332C11.25 7.77499 10.6917 8.33332 10 8.33332C9.30833 8.33332 8.75 7.77499 8.75 7.08332C8.75 6.39166 9.30833 5.83332 10 5.83332ZM14.1667 9.99999C15.775 9.99999 17.0833 8.69166 17.0833 7.08332C17.0833 5.47499 15.775 4.16666 14.1667 4.16666C13.9667 4.16666 13.7667 4.18332 13.575 4.22499C14.2083 5.00832 14.5833 5.99999 14.5833 7.08332C14.5833 8.16666 14.1917 9.14999 13.5583 9.93332C13.7583 9.97499 13.9583 9.99999 14.1667 9.99999ZM16.1 11.6833C16.9333 12.3583 17.5 13.2417 17.5 14.375V15.8333H20V14.375C20 12.9667 17.9667 12.075 16.1 11.6833Z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;
