import React from "react";
import { useSelector } from "react-redux";

import {
  selectIsReconnectingPaymentInstrument,
  selectPaymentInstrument,
  selectPlaidCustomLinkName,
  selectTellerInstitutionId,
} from "../../store/selectors";
import PlaidLink from "./PlaidLink";
import TellerConnect from "./TellerConnect";

const BankLinkConnector: React.VFC = () => {
  const paymentInstrument = useSelector(selectPaymentInstrument);
  const isReconnectingPaymentInstrument = useSelector(
    selectIsReconnectingPaymentInstrument
  );
  const tellerInstitutionId = useSelector(selectTellerInstitutionId);
  const plaidCustomLinkName = useSelector(selectPlaidCustomLinkName);

  // If we are reconnecting an instrument OR if we have a Plaid custom link
  // set or a Teller institution id set, open Teller or Plaid respectively

  if (
    plaidCustomLinkName !== null ||
    (paymentInstrument?.source === "plaid_instant_auth" &&
      isReconnectingPaymentInstrument)
  ) {
    return <PlaidLink />;
  }

  if (
    tellerInstitutionId !== null ||
    (paymentInstrument?.source === "teller_instant_auth" &&
      isReconnectingPaymentInstrument)
  ) {
    return <TellerConnect />;
  }

  return null;
};

export default BankLinkConnector;
