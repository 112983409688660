import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { Stack, Typography } from "@mui/material";

import { InlineTextButtonSmall } from "~common/components/controls/buttons";
import CompactButton from "~common/components/controls/buttons/CompactButton";
import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";
import type { PaymentInstrument } from "~common/services";
import {
  BankLinkingState,
  openBankModal,
  setBankLinkingStep,
  setConnectBankVariant,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import { selectIsMobileApp } from "~src/store";

import PaymentMethodConnectPrompt from "./PaymentMethodConnectPrompt";
import PaymentMethodErrorPrompt from "./PaymentMethodErrorPrompt";

type PaymentMethodRowProps = {
  loading: boolean;
  heading: string;
  paymentInstrument?: PaymentInstrument;
};

const PaymentMethodRow: React.VFC<PaymentMethodRowProps> = ({
  loading,
  heading,
  paymentInstrument,
}) => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const intercom = useIntercom();
  const isMobileApp = useSelector(selectIsMobileApp);
  const [buttonText, setButtonText] = useState("Connect");
  const [connectVariant, setConnectVariant] =
    useState<BankLinkingState["connectBankVariant"]>("Default");
  const instrumentVerified = paymentInstrument?.status === "verified";

  useEffect(() => {
    if (!paymentInstrument) {
      if (heading === "Catch card") {
        setButtonText(isMobileApp ? "Sign up" : "");
      } else {
        setButtonText("Connect");
        setConnectVariant("Default");
      }

      return;
    }

    if (!instrumentVerified) {
      setButtonText("Reconnect");
      setConnectVariant("Reconnect");
      return;
    }

    if (heading === "Catch card") {
      setButtonText("");
      return;
    }

    setButtonText("Change");
    setConnectVariant("ChangeMethod");
  }, [paymentInstrument, instrumentVerified, heading, isMobileApp]);

  const handleClick = () => {
    if (heading === "Catch card") {
      window.location.href =
        "https://app.getcatch.com/u/?open_in_app=true&tab=catchPass";
      return;
    }

    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(setConnectBankVariant(connectVariant));
    dispatch(openBankModal());

    trackEvent("Bank Linking open click", {
      component: "PaymentMethodSection",
      connectVariant,
      buttonText,
    });
  };

  return (
    <Stack>
      <Stack spacing={1}>
        <Typography variant="h3">{heading}</Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          {loading ? (
            <>
              <Stack flexGrow={1}>
                <Typography variant="bodySmall">
                  <WrappedSkeleton variant="text" width={80} />
                </Typography>

                <Typography variant="bodyLarge">
                  <WrappedSkeleton variant="text" width={240} />
                </Typography>
              </Stack>

              <CompactButton sx={{ width: 90 }} disabled>
                <WrappedSkeleton variant="text" sx={{ width: 60 }} />
              </CompactButton>
            </>
          ) : (
            <>
              <Stack>
                {paymentInstrument ? (
                  <>
                    <Typography variant="bodySmall" color="grey.500">
                      {paymentInstrument.account_type === "debit"
                        ? "Debit card"
                        : "Bank account"}
                    </Typography>

                    <Typography variant="bodyLarge" color="grey.700">
                      {paymentInstrument.display_name}
                    </Typography>

                    {!instrumentVerified && <PaymentMethodErrorPrompt />}
                  </>
                ) : (
                  <PaymentMethodConnectPrompt
                    isAnywhere={heading === "Catch card"}
                  />
                )}
              </Stack>

              {buttonText && (
                <CompactButton
                  onClick={handleClick}
                  sx={({ palette }) => ({
                    background: palette.common.white,
                    minWidth: 100,
                  })}
                >
                  {buttonText}
                </CompactButton>
              )}
            </>
          )}
        </Stack>
      </Stack>

      {!buttonText && heading === "Catch card" && (
        <Typography variant="bodySmall" component="div">
          To make changes to your Catch card payment method, please{" "}
          <InlineTextButtonSmall
            sx={{
              "&.MuiButton-sizeSmall": {
                p: 0,
                display: "inline",
                verticalAlign: "baseline",
              },
            }}
            onClick={intercom.show}
          >
            contact support
          </InlineTextButtonSmall>
          .
        </Typography>
      )}
    </Stack>
  );
};

export default PaymentMethodRow;
