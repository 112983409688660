import { FAQCard } from "~common/components/faq/faq-types";

const howDoesCatchMakeMoneyCardData: FAQCard = {
  id: "catch_is_gettin_rich",
  title: "How does Catch make money? 🤑",
  steps: [
    [
      {
        text: "We charge brands a small fee if and when you redeem your Catch-earned credit with them.",
        accented: false,
      },
    ],
    [
      {
        text: "So we only get paid if you and the brands you like see value from our product.",
        accented: false,
      },
    ],
    [
      {
        text: "This way we ensure ",
        accented: false,
      },
      {
        text: "we always keep our customers at the forefront ",
        accented: true,
      },
      { text: "of our decision-making.", accented: false },
    ],
  ],
};

const howDoRefundsWorkCardData: FAQCard = {
  id: "rewards_and_refunds",
  title: "How do refunds/returns work? 🛍",
  steps: [
    [
      {
        text: "Catch initiates a refund when we are notified by the merchant that they have received your returned goods.",
        accented: false,
      },
    ],
    [
      {
        text: "The funds should appear in your bank account within a few business days.",
        accented: false,
      },
    ],
    [
      {
        text: "Any Catch credit earned on that purchase will be adjusted in your Catch account based on the new purchase total.",
        accented: false,
      },
    ],
    [
      {
        text: "If you have already redeemed or gifted the credits from that purchase, your refund amount will be deducted accordingly. ",
        accented: false,
      },
    ],
  ],
};

const forTheCuriousCards: FAQCard[] = [
  howDoesCatchMakeMoneyCardData,
  howDoRefundsWorkCardData,
];

export { forTheCuriousCards };
