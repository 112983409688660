import { useGetPublicMerchantList } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectMerchants } from "~src/store";
import { merchantsActions } from "~src/store/slices/services/merchants-slice";

const useGet = () => useGetPublicMerchantList({ lazy: true });

const useFetchMerchants = () => {
  useLazyFetch(
    "PublicMerchantList",
    merchantsActions,
    selectMerchants,
    useGet,
    (data) => data.merchants
  );
};

export default useFetchMerchants;
