import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { selectDisableAch } from "../../../../../store/selectors";
import ExternalTextLink from "../../../../links/ExternalTextLink";

const PaymentInstrumentProviders: React.VFC = () => {
  const disableAch = useSelector(selectDisableAch);
  return (
    <Typography
      paragraph
      variant="bodyRegular"
      color="grey.600"
      display="inline"
    >
      {" We partner with "}
      {disableAch ? (
        <ExternalTextLink
          color="grey.600"
          size="small"
          href="https://www.adyen.com/"
          eventName="Adyen Outgoing Link Clicked"
        >
          Adyen
        </ExternalTextLink>
      ) : (
        <>
          <ExternalTextLink
            color="grey.600"
            size="small"
            href="https://plaid.com/how-it-works-for-consumers/"
            eventName="Plaid Outgoing Link Clicked"
          >
            Plaid
          </ExternalTextLink>
          {" and "}
          <ExternalTextLink
            color="grey.600"
            size="small"
            href="https://teller.io/legal#end-user-terms-of-service"
            eventName="Teller Outgoing Link Clicked"
          >
            Teller
          </ExternalTextLink>
        </>
      )}
    </Typography>
  );
};

export default PaymentInstrumentProviders;
