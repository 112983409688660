import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useBankLinkingOptions } from "../../contexts/BankLinkingContext";
import useInitiatePlaidLink from "../../hooks/bank-linking/useInitiatePlaidLink";
import { selectPlaidOpenAllowed } from "../../store/selectors";
import { setBankLinkingStep } from "../../store/slices/bankLinking-slice";
import { useTracking } from "../../tracking";

const PlaidLink: React.VFC = () => {
  const plaidOpenAllowed = useSelector(selectPlaidOpenAllowed);
  const { ready, open, exit } = useInitiatePlaidLink();
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const { bus } = useBankLinkingOptions();

  useEffect(() => {
    if (ready && plaidOpenAllowed) {
      trackEvent("Plaid Link Opened");
      open();
      dispatch(setBankLinkingStep("PlaidLogin"));
    }

    const handleUserIdle = () => {
      exit();
    };

    bus?.on("USER_IDLE", handleUserIdle);
    return () => {
      bus?.off("USER_IDLE", handleUserIdle);
    };
  }, [dispatch, plaidOpenAllowed, ready, open, trackEvent, exit, bus]);

  return null;
};

export default PlaidLink;
