import React from "react";

import { Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import AndroidGooglePlayIcon from "~common/components/icons/apps/AndroidGooglePlayIcon";
import IosAppStoreIcon from "~common/components/icons/apps/IosAppStoreIcon";
import ExternalTextLink from "~common/components/links/ExternalTextLink";
import { ADOPTION_URLS } from "~common/utils/adoption";
import { formatRelativeLink } from "~common/utils/urls";

const APPS = ["ios", "android"] as const;

const MyCreditsCatchCashTooltip: React.VFC = () => (
  <Stack gap={4}>
    <Typography variant="bodyRegular">
      <Typography variant="inherit" component="strong">
        A reward you can redeem at all{" "}
        <ExternalTextLink
          size="regular"
          href={formatRelativeLink("shop")}
          eventName="Catch Partners clicked"
          sx={{ fontWeight: "inherit" }}
        >
          Catch partners
        </ExternalTextLink>
        !
      </Typography>{" "}
      Earn more via Catch Cash Offers — only in the mobile app.
    </Typography>

    <Stack direction="row" gap={4}>
      {APPS.map((app) => (
        <PrimaryButton
          key={app}
          href={ADOPTION_URLS[app]}
          sx={({ palette, shape }) => ({
            "&.MuiButton-root": {
              width: "50%",
              height: 40,
              background: palette.grey[600],
              border: `1px solid ${palette.grey[400]}`,
              borderRadius: `${shape.borderRadius}px`,
              boxShadow: "none",
              "&:after": {
                borderRadius: `${shape.borderRadius}px`,
              },
              "&:hover": {
                background: palette.grey[600],
              },
            },
            ".MuiBox-root": {
              display: "flex",
              alignItems: "center",
            },
            svg: {
              width: app === "ios" ? 108 : 116,
              height: 28,
            },
          })}
        >
          {app === "ios" ? (
            <IosAppStoreIcon />
          ) : (
            <AndroidGooglePlayIcon colorIcon />
          )}
        </PrimaryButton>
      ))}
    </Stack>
  </Stack>
);

export default MyCreditsCatchCashTooltip;
