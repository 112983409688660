type EventBusOverrides = {
  overrideTargetToWindow?: boolean;
};

class EventBus<TEventType> {
  private eventTarget: EventTarget;

  // NB: This is a generalized class intended for messaging within
  // the DOM. However, we utilize it in rare scenarios to send events
  // in the global window context (e.g. for sending events to mobile
  // apps).
  constructor(eventBusOverrides?: EventBusOverrides) {
    const { overrideTargetToWindow } = eventBusOverrides || {};
    this.eventTarget = overrideTargetToWindow
      ? window
      : document.createDocumentFragment();
  }

  on(type: TEventType, handler: (e: CustomEvent) => void): void {
    this.eventTarget.addEventListener(
      type as unknown as string,
      handler as EventListenerOrEventListenerObject
    );
  }

  off(type: TEventType, handler: (e: CustomEvent) => void): void {
    this.eventTarget.removeEventListener(
      type as unknown as string,
      handler as EventListenerOrEventListenerObject
    );
  }

  emit(type: TEventType, detail?: Record<string, unknown>): boolean {
    return this.eventTarget.dispatchEvent(
      new CustomEvent(type as unknown as string, { detail })
    );
  }
}
export default EventBus;
