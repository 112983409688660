import React from "react";
import { useSelector } from "react-redux";

import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { WrappedTooltip } from "~common/components/tooltips";
import { selectDisableDebit } from "~src/store";

type PaymentMethodConnectPromptProps = {
  isAnywhere?: boolean;
};

const PaymentMethodConnectPrompt: React.VFC<PaymentMethodConnectPromptProps> =
  ({ isAnywhere }) => {
    const disableDebit = useSelector(selectDisableDebit);

    if (isAnywhere) {
      return (
        <Typography variant="bodyLarge" color="grey.700">
          Earn rewards whenever you shop with the Catch card.
        </Typography>
      );
    }

    return (
      <Stack direction="row" gap={1}>
        <Typography variant="bodyLarge" color="grey.700">
          {!disableDebit
            ? "Connect your debit card or bank account"
            : "Connect your bank account"}
        </Typography>

        <WrappedTooltip
          title={
            <>
              {!disableDebit
                ? "Catch lets you pay online using your debit card or bank account. This saves merchants " +
                  "from paying credit card fees — so they pass the savings back to you as store credit."
                : "Catch lets you pay online using your bank account. This saves merchants from paying credit " +
                  "& debit card fees — so they pass the savings back to you as store credit."}
            </>
          }
          placement="top"
          sx={({ palette }) => ({
            verticalAlign: "middle",
            mb: "3px",
            color: palette.grey[500],
            cursor: "default",
          })}
        >
          <InfoOutlinedIcon />
        </WrappedTooltip>
      </Stack>
    );
  };
export default PaymentMethodConnectPrompt;
