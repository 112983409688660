const BROWSER_EXTENSION_CARD_NUX = "browser-extension-card-nux";

type NuxName = string;

type DateTime = string;

type NuxsResponseData = {
  nuxs: NuxName[];
};

type MarkNuxSeenPathParams = {
  nuxName: NuxName;
};

type MarkNuxSeenResponseData = {
  nux_name: NuxName;
  last_seen_time: DateTime;
  seen_count: number;
};

type BatchUpdateNuxResponseData = {
  updated_nuxs: MarkNuxSeenResponseData[];
  unchanged_nuxs: MarkNuxSeenResponseData[];
  failed_nuxs: string[];
};

type BatchUpdateNuxRequestData = {
  nuxs: string[];
};

export { BROWSER_EXTENSION_CARD_NUX };

export type {
  BatchUpdateNuxRequestData,
  BatchUpdateNuxResponseData,
  MarkNuxSeenPathParams,
  MarkNuxSeenResponseData,
  NuxName,
  NuxsResponseData,
};
