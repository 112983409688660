import { RecommendedMerchantCategoryResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectRecommendedMerchants = makeFetchSelectors<
  RecommendedMerchantCategoryResponseData,
  RootState
>("recommendedMerchants");

export { selectRecommendedMerchants };
