const CIRCULAR_FONT_FAMILY = "Circular";
const GRAPHIK_FONT_FAMILY = "Graphik";
const POPPINS_FONT_FAMILY = "Poppins";
type SupportedTypeface =
  | typeof CIRCULAR_FONT_FAMILY
  | typeof GRAPHIK_FONT_FAMILY
  | typeof POPPINS_FONT_FAMILY;

type PaletteColoringStyle = "color" | "mono";

type BaseColorKey =
  | "white"
  | "black"
  | "grey1"
  | "grey2"
  | "grey3"
  | "grey4"
  | "grey5"
  | "grey6"
  | "grey7"
  | "primaryMain"
  | "primaryLight"
  | "secondaryMain"
  | "secondaryLight";

type BaseColors = {
  [key in BaseColorKey]?: string;
};

type TypescaleVariant =
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "bodyLarge"
  | "bodyRegular"
  | "bodySmall"
  | "linkLarge"
  | "linkRegular"
  | "linkSmall"
  | "buttonLabel"
  | "buttonLabelCompact";

type TypescaleVariantSpec = {
  size: number;
  height: number;
  weight: 400 | 500 | 600 | 700;
  underline?: boolean;
};

type Typescale = {
  [key in TypescaleVariant]?: TypescaleVariantSpec;
};

type WidgetSizeVariant = "default" | "small";

type StyleTokens = {
  typeface: SupportedTypeface;

  buttonBorderRadius?: number;
  textFieldBorderRadius?: number;

  primaryText?: BaseColorKey;
  secondaryText?: BaseColorKey;
  tertiaryText?: BaseColorKey;
  regularButtonBackground?: BaseColorKey;
  regularButtonText?: BaseColorKey;
  bankButtonBackground?: BaseColorKey;
  cartBackground?: BaseColorKey;
  cartPrimaryText?: BaseColorKey;
  cartSecondaryText?: BaseColorKey;
  cartEarningCreditsText?: BaseColorKey;
  cartRedeemingCreditsText?: BaseColorKey;
  cartRedeemingCreditsBackground?: BaseColorKey;
  tofuPrimaryAccent?: BaseColorKey;
  tofuSecondaryAccent?: BaseColorKey;
  tofuTertiaryAccent?: BaseColorKey;
  tofuTertiaryAccentContrast?: BaseColorKey;
  tofuPrimaryBackground?: BaseColorKey;
  primaryStroke?: BaseColorKey;

  // Catch.js-specific
  widgetAccent?: BaseColorKey;
  widgetLink?: BaseColorKey;
  widgetSizeVariant?: WidgetSizeVariant;
};

type MerchantThemeConfig = {
  // A name identifying the theme.
  name: string;
  baseColors: BaseColors;
  typescale: Typescale;
  styleTokens: StyleTokens;
};

export { CIRCULAR_FONT_FAMILY, GRAPHIK_FONT_FAMILY };
export type {
  BaseColorKey,
  BaseColors,
  MerchantThemeConfig,
  PaletteColoringStyle,
  StyleTokens,
  SupportedTypeface,
  Typescale,
  TypescaleVariant,
  TypescaleVariantSpec,
  WidgetSizeVariant,
};
