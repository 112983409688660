import { selectUserData } from "~src/store";
import { userDataActions } from "~src/store/slices/services/userData-slice";

import useLazyService from "./useLazyService";

const useUserData = () => {
  const {
    data: userData,
    loading,
    error,
  } = useLazyService(selectUserData, userDataActions);

  return { userData, loading, error };
};

export default useUserData;
