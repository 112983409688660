import React from "react";

import { useTrackPageView } from "~common/tracking";
import {
  AccountInfoSection,
  AccountSettingsSection,
  HelpPoliciesSection,
  LogoutSection,
  ReferralsSection,
} from "~src/components/account";
import CatchCardSection from "~src/components/account/CatchCardSection";
import CommunicationSection from "~src/components/account/CommunicationSection";
import PaymentMethodsSection from "~src/components/account/PaymentMethodsSection";
import WrappedBankLinkManager from "~src/components/bank/WrappedBankLinkManager";
import LargePagePanel from "~src/components/layout/LargePagePanel";
import useFetchAnywhereCardForUser from "~src/hooks/fetching/useFetchAnywhereCardForUser";
import useFetchNuxs from "~src/hooks/fetching/useFetchNuxs";

const Account: React.VFC = () => {
  useTrackPageView("Account");
  useFetchAnywhereCardForUser();
  useFetchNuxs();

  return (
    <WrappedBankLinkManager>
      <LargePagePanel width="medium">
        <CatchCardSection />
        <AccountInfoSection />
        <AccountSettingsSection />
        <CommunicationSection />
        <PaymentMethodsSection />
        <ReferralsSection />
        <HelpPoliciesSection />
        <LogoutSection />
      </LargePagePanel>
    </WrappedBankLinkManager>
  );
};

export default Account;
