import React from "react";

import { css, Global } from "@emotion/react";

import useHideIntercom from "~common/hooks/useHideIntercom";
import { useTrackPageView } from "~common/tracking";
import LargePagePanel from "~src/components/layout/LargePagePanel";
import ShopCategories from "~src/components/shop/ShopCategories";

const PublicShop: React.VFC = () => {
  useTrackPageView("External Public Shop");
  useHideIntercom(true);

  // NB: We uniquely remove the `overflow-y: scroll` from the root element
  // on the external shop page because it causes an unwanted scrollbar to
  // render nested within the `iframe` that contains the shop page on the
  // marketing website. If/when the external shop page is moved 100% to the
  // app, we can remove this. (On other pages, we maintain the `overflow-y:
  // scroll` to ensure the horizontal alignment of the page content is
  // consistent when the scrollbar is visible, across both pages that are
  // and aren't tall enough to require scrolling.)

  return (
    <LargePagePanel
      sx={({ palette }) => ({
        overflowX: "hidden",
      })}
      shopPage
    >
      <Global
        styles={css`
          html,
          body,
          #root {
            overflow-y: auto;
          }
        `}
      />
      <ShopCategories utmCampaign="website" isPublicPageVariant />
    </LargePagePanel>
  );
};

export default PublicShop;
