import React from "react";

import { BankLinkingProvider as CommonBankLinkingProvider } from "~common/contexts/BankLinkingContext";
import { ADYEN_CLIENT_KEY, TELLER_APP_ID } from "~src/config";
import { eventBus } from "~src/event-bus";

const BankLinkingProvider: React.FC = ({ children }) => (
  <CommonBankLinkingProvider
    options={{
      tellerAppId: TELLER_APP_ID,
      adyenClientKey: ADYEN_CLIENT_KEY,
      bus: eventBus,
    }}
  >
    {children}
  </CommonBankLinkingProvider>
);

export default BankLinkingProvider;
