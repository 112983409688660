import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.3364 62.8262C77.6923 65.7999 79.5266 69.7209 81.4283 76.7145C82.8266 81.8909 83.1177 85.6243 84.9634 85.6243C86.7532 85.6243 87.5922 83.752 88.7108 76.5932C89.8295 69.4344 89.4939 64.4232 91.3955 60.8989C93.3531 57.3194 94.5836 56.2731 95.5345 56.989C96.709 57.815 95.5345 60.8438 94.9752 63.5972C94.4158 66.3506 97.2124 68.0026 99.4497 67.4519C101.687 66.9012 102.246 63.0465 101.128 61.3945C100.009 59.7424 97.7717 58.6411 96.6531 56.989C95.5345 55.337 97.2124 54.2356 98.8904 54.7863C100.568 55.337 103.365 56.4384 105.043 59.1917C106.609 61.7249 105.602 67.4519 105.602 67.4519C105.602 67.4519 109.517 61.9451 112.873 60.2931C115.558 58.9715 116.788 60.8438 114.551 63.0465C113.153 64.4232 107.839 69.6546 107.839 69.6546C107.839 69.6546 116.229 64.6985 117.907 66.3506C119.585 68.0026 116.229 69.104 113.433 70.756C111.028 72.1878 106.106 74.3354 103.309 81.4942C100.512 88.6531 99.7293 94.2149 95.5345 98.8406C90.5006 104.347 78.1957 105.449 73.7212 98.8406C69.2467 92.2325 67.0094 76.9787 60.8569 74.6658"
      fill="#E1D4F8"
    />
    <path
      d="M74.3364 62.8262C77.6923 65.7999 79.5266 69.7209 81.4283 76.7145C82.8266 81.8909 83.1177 85.6243 84.9634 85.6243C86.7532 85.6243 87.5922 83.752 88.7108 76.5932C89.8295 69.4344 89.4939 64.4232 91.3955 60.8989C93.3531 57.3194 94.5836 56.2731 95.5345 56.989C96.709 57.815 95.5345 60.8438 94.9752 63.5972C94.4158 66.3506 97.2124 68.0026 99.4497 67.4519C101.687 66.9012 102.246 63.0465 101.128 61.3945C100.009 59.7424 97.7717 58.6411 96.6531 56.989C95.5345 55.337 97.2124 54.2356 98.8904 54.7863C100.568 55.337 103.365 56.4384 105.043 59.1917C106.609 61.7249 105.602 67.4519 105.602 67.4519C105.602 67.4519 109.517 61.9451 112.873 60.2931C115.558 58.9715 116.788 60.8438 114.551 63.0465C113.153 64.4232 107.839 69.6546 107.839 69.6546C107.839 69.6546 116.229 64.6985 117.907 66.3506C119.585 68.0026 116.229 69.104 113.433 70.756C111.028 72.1878 106.106 74.3354 103.309 81.4942C100.512 88.6531 99.7293 94.2149 95.5345 98.8406C90.5006 104.347 78.1957 105.449 73.7212 98.8406C69.2467 92.2325 67.0094 76.9787 60.8569 74.6658"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0299 61.4878C36.1724 62.6442 30.7986 76.9614 28.649 81.9173C26.4995 86.8733 26.4995 89.0759 24.8874 89.0759C23.2752 89.0759 23.2752 83.5693 24.35 76.4107C25.4248 69.2521 26.4995 58.7896 23.8126 58.239C21.1257 57.6883 21.6631 63.1949 20.0509 65.3975C18.4388 67.6002 16.2893 68.1508 14.6771 67.0495C13.065 65.9482 13.6024 62.6442 15.7519 61.5429C17.9014 60.4416 20.5883 59.8909 21.6631 58.239C22.7379 56.587 20.5883 55.4856 17.9014 56.587C15.2145 57.6883 11.9902 58.239 10.9155 60.4416C9.8407 62.6442 9.30332 64.2962 9.30332 64.2962C9.30332 64.2962 6.61641 59.8909 5.54164 58.239C4.46688 56.587 3.39212 55.4856 2.31736 56.0363C1.24259 56.587 1.24259 59.8909 6.61641 66.4988C6.61641 66.4988 2.85474 61.5429 1.24259 62.6442C-0.369552 63.7455 3.39212 67.6002 6.61641 71.4548C9.8407 75.3094 8.76593 79.164 8.76593 84.12C8.76593 89.0759 9.30332 103.393 21.6631 103.393C34.0229 103.393 39.3967 92.3799 41.5462 89.0759C43.6957 85.7719 47.9948 75.3094 49.6069 73.6574"
      fill="#E1D4F8"
    />
    <path
      d="M42.0298 61.4878C36.1724 62.6442 30.7986 76.9614 28.6491 81.9173C26.4995 86.8733 26.4995 89.0759 24.8874 89.0759C23.2752 89.0759 23.2752 83.5693 24.35 76.4107C25.4248 69.2521 26.4995 58.7896 23.8126 58.239C21.1257 57.6883 21.6631 63.1949 20.0509 65.3975C18.4388 67.6002 16.2893 68.1508 14.6771 67.0495C13.065 65.9482 13.6024 62.6442 15.7519 61.5429C17.9014 60.4416 20.5883 59.8909 21.6631 58.239C22.7379 56.587 20.5883 55.4856 17.9014 56.587C15.2145 57.6883 11.9902 58.239 10.9155 60.4416C9.8407 62.6442 9.30332 64.2962 9.30332 64.2962C9.30332 64.2962 6.61641 59.8909 5.54164 58.239C4.46688 56.587 3.39212 55.4856 2.31736 56.0363C1.24259 56.587 1.24259 59.8909 6.61641 66.4988C6.61641 66.4988 2.85474 61.5429 1.24259 62.6442C-0.369552 63.7455 3.39212 67.6002 6.61641 71.4548C9.8407 75.3094 8.76593 79.164 8.76593 84.12C8.76593 89.0759 9.30332 103.393 21.6631 103.393C34.0229 103.393 39.3967 92.3799 41.5462 89.0759C43.6957 85.7719 47.9948 75.3094 49.6069 73.6574"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.6069 25.8931L89.6069 17.1431"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.3768 76.9908C69.9166 78.5936 82.4409 67.3741 84.359 51.9542C86.2772 36.479 76.8557 22.6619 63.316 21.0591H55.7141L49.6069 75.2683L56.3768 76.9908Z"
      fill="#6B27DD"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M71.8848 29.2114H77.9378"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.8315 24.0215H71.8846"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.6265 35.6157H82.0097"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.7178 42.0195H83.7708"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.2681 48.4243H84.4862"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.4976 54.8286H83.7157"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.6265 61.2329H81.8446"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.5547 67.6372H77.8279"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.1353 74.0415H70.2338"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.1088 51.8418C80.9439 36.4508 71.7407 22.6993 58.5528 21.1268C45.3648 19.5544 33.1863 30.7565 31.3511 46.1474C29.516 61.5383 38.7192 75.2899 51.9072 76.8623C65.0951 78.4348 77.2737 67.2327 79.1088 51.8418Z"
      fill="#E1D4F8"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.7919 51.3273C76.2959 38.7127 68.757 27.4424 57.9532 26.1542C47.1494 24.866 37.1718 34.0478 35.6677 46.6623C34.1636 59.2768 41.7026 70.5472 52.5064 71.8354C63.3102 73.1236 73.2878 63.9418 74.7919 51.3273Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8569 43.3931C40.8569 43.3931 44.7963 52.6023 50.8569 46.0243"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.6069 45.8931C62.1069 48.8707 65.5444 51.4229 69.6069 48.0199"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.8569 52.1431C48.3418 57.8073 58.5986 59.33 63.3569 53.1785"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.3569 32.1431L99.6069 28.3931"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.8569 39.6431L99.6069 42.1431"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4827 75.5967L9.01981 79.0329C8.67619 81.4382 8.87663 84.044 9.01981 85.0462L23.6236 81.61L24.4827 75.5967Z"
      fill="#FBDA69"
      stroke="#232639"
      strokeWidth="1.5"
    />
    <path
      d="M89.3409 74.7375L103.275 82.2144C102.656 85.046 101.776 87.1262 101.368 88.0528L88.0524 80.7508L89.3409 74.7375Z"
      fill="#FBDA69"
      stroke="#232639"
      strokeWidth="1.5"
    />
  </svg>
);

// TODO: Parametrize color. The last two `<path>` elements with
// `fill="#FBDA69"` above are the yellow accent color paths.

export default SVG;
