import React from "react";

import { keyframes } from "@emotion/react";
import { Card, CardProps, Stack } from "@mui/material";

import { useReduceMotion } from "../../hooks/accessibility-hooks";

type FlippableCardProps = CardProps & {
  front: React.ReactNode;
  back: React.ReactNode;
  flipped: boolean;
  infinite?: boolean;
};

const FlippableCard: React.VFC<FlippableCardProps> = ({
  front,
  back,
  flipped,
  infinite,
  ...props
}) => {
  const reduceMotion = useReduceMotion();

  return (
    <Card
      {...props}
      sx={({ palette, shape }) => ({
        background: "transparent",
        overflow: "visible",
        transformStyle: "preserve-3d",
        transition: reduceMotion ? "none" : "transform 1s",
        borderRadius: `${shape.borderRadius * 4}px`,
        ...(infinite
          ? {
              animation: reduceMotion
                ? "none"
                : `${keyframes`
            from {
              transform: rotateY(0deg);
            }
            to {
              transform: rotateY(360deg);
            }
          `} 2.5s infinite linear`,
            }
          : {
              transform: `rotateY(${flipped ? 180 : 0}deg)`,
            }),
        "& > div": {
          background: palette.background.default,
          backfaceVisibility: "hidden",
          borderRadius: "inherit",
          overflow: "hidden",
          transform: "translateZ(0)",
        },
        ...(!infinite && {
          "& > div:first-of-type": {
            transition: `visibility 0s linear ${
              flipped && !reduceMotion ? "1s" : "0s"
            }`,
            visibility: flipped ? "hidden" : "visible",
          },
        }),
        "& > div:last-of-type": {
          position: "absolute",
          inset: 0,
          transform: "rotateY(180deg)",
          ...(!infinite && {
            transition: `visibility 0s linear ${
              flipped || reduceMotion ? "0s" : "1s"
            }`,
            visibility: flipped ? "visible" : "hidden",
          }),
        },
      })}
    >
      <Stack justifyContent="center">{front}</Stack>
      <Stack justifyContent="center">{back}</Stack>
    </Card>
  );
};

export default FlippableCard;
