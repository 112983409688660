import React from "react";
import { BrowserRouter } from "react-router-dom";

type AdaptiveBasenameRouterProps = {
  defaultBasename: string;
  alternatives: string[];
};

const AdaptiveBasenameRouter: React.FC<AdaptiveBasenameRouterProps> = ({
  defaultBasename,
  alternatives,
  children,
}) => {
  const pathRoot = window.location.pathname.split("/")[1];
  // Default to the first supplied basename if none match.
  let targetBasename = defaultBasename;
  let redirect = `/${pathRoot}` !== defaultBasename;

  alternatives.forEach((basename) => {
    if (`/${pathRoot}` === basename) {
      targetBasename = basename;
      redirect = false;
    }
  });

  if (redirect) {
    window.location.replace(defaultBasename);
  }

  return <BrowserRouter basename={targetBasename}>{children}</BrowserRouter>;
};

export default AdaptiveBasenameRouter;
