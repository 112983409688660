import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FetchState } from "~common/store/store-utils";

const useLazyService = <TData, TState>(
  selectService: {
    data: (state: TState) => FetchState<TData>["data"];
    loading: (state: TState) => FetchState<TData>["loading"];
    error: (state: TState) => FetchState<TData>["error"];
    status: (state: TState) => FetchState<TData>["status"];
  },
  actions: {
    setStatus: (status: FetchState<TData>["status"]) => {
      payload: FetchState<TData>["status"];
      type: string;
    };
  }
) => {
  const data = useSelector(selectService.data);
  const loading = useSelector(selectService.loading);
  const error = useSelector(selectService.error);

  const status = useSelector(selectService.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "unfetched") {
      dispatch(actions.setStatus("requested"));
    }
  }, [dispatch, status, actions]);

  return { data, loading: status !== "fetched" || loading, error };
};

export default useLazyService;
