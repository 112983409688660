import { useGetExplorePublicMerchantCategories } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectExploreMerchants } from "~src/store";
import { exploreMerchantsActions } from "~src/store/slices/services/exploreMerchants-slice";

const useGet = () => useGetExplorePublicMerchantCategories({ lazy: true });

const useFetchExploreMerchants = () => {
  useLazyFetch(
    "ExploreMerchants",
    exploreMerchantsActions,
    selectExploreMerchants,
    useGet,
    (data) => data
  );
};

export default useFetchExploreMerchants;
