import React from "react";

import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { AlertIcon } from "~common/components/icons/vector";

type ErrorProps = {
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  idPrefix?: string;
};

const Error: React.VFC<ErrorProps> = ({
  title,
  subtitle,
  onClick,
  idPrefix,
}) => (
  <Stack
    spacing={6}
    alignItems="center"
    sx={{
      textAlign: "center",
    }}
  >
    <AlertIcon size="medium" />

    {title && (
      <Typography variant="h1" id={idPrefix ? `${idPrefix}-heading` : ""}>
        {title}
      </Typography>
    )}

    {subtitle && (
      <Typography
        variant="bodyRegular"
        color="grey.600"
        id={idPrefix ? `${idPrefix}-subheading` : ""}
      >
        {subtitle}
      </Typography>
    )}

    {onClick && (
      <DefaultButton onClick={onClick} fullWidth>
        Got it
      </DefaultButton>
    )}
  </Stack>
);

export default Error;
