// GENERATED FILE. DO NOT EDIT.

import React from "react";

import SmallSVG from "../vector-source/BankSync.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const BankSyncIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} {...props} />
);

export default BankSyncIcon;
