import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";

import NavItem from "~src/components/nav/NavItem";

const NavItemsAuthed: React.VFC = () => (
  <>
    <NavItem name="Home" href="/home">
      My credits
    </NavItem>
    <NavItem name="Shop" href="/shop">
      Shop
    </NavItem>
    <NavItem name="Account" href="/account">
      Account
    </NavItem>
    <NavItem name="FAQ" href="https://help.getcatch.com/en" external>
      <Stack gap="0.35em" direction="row" alignItems="center">
        FAQ <OpenInNewIcon sx={{ width: 16, height: 16 }} />
      </Stack>
    </NavItem>
  </>
);

export default NavItemsAuthed;
