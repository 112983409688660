import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  BatchUpdateNuxRequestData,
  BatchUpdateNuxResponseData,
  MarkNuxSeenPathParams,
  MarkNuxSeenResponseData,
  NuxsResponseData,
} from "./types/nuxs-types";

const useGetNuxs = (
  options: {
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<NuxsResponseData, ErrorResponseData, undefined, undefined>({
    ...options,
    path: `users-svc/nuxs`,
  });

const useMarkNuxSeen = () =>
  useMutate<
    MarkNuxSeenResponseData,
    ErrorResponseData,
    undefined,
    undefined,
    MarkNuxSeenPathParams
  >({
    verb: "PUT",
    path: ({ nuxName }) => `users-svc/nuxs/${nuxName}`,
  });

const useBatchUpdateNux = () =>
  useMutate<
    BatchUpdateNuxResponseData,
    ErrorResponseData,
    undefined,
    BatchUpdateNuxRequestData,
    undefined
  >({
    verb: "POST",
    path: () => "users-svc/nux/batch_update",
  });

export { useBatchUpdateNux, useGetNuxs, useMarkNuxSeen };
