import React from "react";
import { useSelector } from "react-redux";

import {
  selectDisableDebit,
  selectPaymentInstrument,
} from "../../../../../store/selectors";
import { ConnectBankVariant } from "../../../../../store/slices/bankLinking-slice";
import {
  BankHeaderExistingAccount,
  BankHeaderIncompatibleAccount,
  BankHeaderInsufficientFunds,
  BankHeaderPushToDebit,
  BankHeaderReconnect,
  BankHeaderRetry,
  DebitHeaderCannotConnect,
  DebitHeaderCardVerificationFailed,
  DebitHeaderExpiredLinkedCard,
  DebitHeaderNotDebit,
  DebitHeaderNotUS,
  DebitHeaderUnsupportedCardBrand,
} from "..";

type PaymentInstrumentErrorHeadersProps = {
  variant?: ConnectBankVariant;
  headingId?: string;
};

const PaymentInstrumentErrorHeaders: React.VFC<
  PaymentInstrumentErrorHeadersProps
> = ({ variant, headingId }) => {
  const disableDebit = useSelector(selectDisableDebit);
  const paymentInstrument = useSelector(selectPaymentInstrument);

  if (variant === "Retry") {
    return <BankHeaderRetry headingId={headingId} />;
  }

  if (variant === "Reconnect") {
    return (
      <BankHeaderReconnect
        headingId={headingId}
        institutionName={paymentInstrument?.institution_name}
      />
    );
  }

  if (variant === "ErrorInsufficientFunds") {
    return (
      <BankHeaderInsufficientFunds
        headingId={headingId}
        accountName={paymentInstrument?.display_name}
      />
    );
  }

  if (variant === "ErrorExistingAccount") {
    return <BankHeaderExistingAccount headingId={headingId} />;
  }

  if (variant === "ErrorIncompatibleAccount") {
    return <BankHeaderIncompatibleAccount headingId={headingId} />;
  }

  if (!disableDebit) {
    if (variant === "ErrorAdyenCardNotDebit") {
      return <DebitHeaderNotDebit headingId={headingId} />;
    }

    if (variant === "ErrorCardNotUS") {
      return <DebitHeaderNotUS headingId={headingId} />;
    }

    if (variant === "ErrorCardCannotConnect") {
      return <DebitHeaderCannotConnect headingId={headingId} />;
    }

    if (variant === "ErrorCardVerificationFailed") {
      return <DebitHeaderCardVerificationFailed headingId={headingId} />;
    }

    if (variant === "ErrorExpiredLinkedCard") {
      return <DebitHeaderExpiredLinkedCard headingId={headingId} />;
    }

    if (variant === "ErrorUnsupportedCardBrand") {
      return <DebitHeaderUnsupportedCardBrand headingId={headingId} />;
    }

    if (variant === "ErrorPushToDebit") {
      return <BankHeaderPushToDebit headingId={headingId} />;
    }
  }

  return null;
};

export default PaymentInstrumentErrorHeaders;
