import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Add as AddIcon,
  Lock as LockIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import getStartedRedeemImage from "~common/assets/illustrations/get_started_redeem.png";
import {
  BankButton,
  PrimaryButton,
  WrappedButton,
} from "~common/components/controls/buttons";
import GetStartedModal from "~common/components/faq/GetStartedModal";
import { SIGN_UP_BONUS } from "~common/constants/bonuses";
import useRecentMerchantVisit from "~common/hooks/useRecentMerchantVisit";
import {
  openBankModal,
  setBankLinkingStep,
  setConnectBankVariant,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import useUserRewards from "~src/hooks/services/useUserRewards";

const EarnRedeemFAQ: React.VFC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const { paymentInstruments } = usePaymentInstruments();
  const { userRewards } = useUserRewards();
  const hasLinkedPaymentInstrument = paymentInstruments.length > 0;
  const hasRewards = userRewards.length > 0;

  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const titleCopy = `How do I ${hasRewards ? "redeem" : "earn"} credits? 💸`;

  const { recentMerchantVisit, clearRecentMerchantVisit } =
    useRecentMerchantVisit();

  const handleOpen = () => {
    trackEvent("Get Started How do I Redeem Open");
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // Click handler for the bank button.
  const handleBankClick = () => {
    trackEvent("Get Started Link Bank Click");
    dispatch(openBankModal());
    dispatch(setConnectBankVariant("Default"));
    dispatch(setBankLinkingStep("ConnectBank"));
    setOpen(false);
  };

  // Click handler for the primary button.
  const handlePrimaryClick = () => {
    setOpen(false);
  };

  // Click handler for the return to merchant button.
  const handleReturnToMerchantClick = () => {
    trackEvent("Earn Redeem FAQ return to merchant click", {
      recentMerchantVisit,
    });
    clearRecentMerchantVisit();

    setOpen(false);
  };

  return (
    <>
      <WrappedButton
        variant="outlined"
        fullWidth
        onClick={handleOpen}
        size="large"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="h3" component="span" textAlign="left">
            {titleCopy}
          </Typography>
          <AddIcon />
        </Stack>
      </WrappedButton>
      <GetStartedModal open={open} onClose={handleClose} title={titleCopy}>
        <Stack
          sx={{
            gap: 6,
            mt: 6,
          }}
        >
          <Box sx={{
            background: `center top / cover url(${getStartedRedeemImage}) no-repeat`,
            height: 194,
          }} />

          <Stack sx={{ gap: 4 }}>
            <Typography color="grey.600">
              <strong>Select Catch as your payment method</strong> when shopping
              at your favorite brands.
              {hasRewards
                ? " Your credits will be auto-applied."
                : ` Earn up to 10% back every time plus a ${SIGN_UP_BONUS} sign-up bonus.`}
            </Typography>
            {!hasLinkedPaymentInstrument && (
              <Typography color="grey.600">
                <Typography component="span" color="info.main">
                  <strong>Hot tip:</strong>
                </Typography>{" "}
                To speed through checkout, link a payment method now. It&apos;s
                instant and secure — just like with Venmo.
              </Typography>
            )}
          </Stack>
          {!hasLinkedPaymentInstrument && (
            <BankButton startIcon={<LockIcon />} onClick={handleBankClick}>
              Link a payment method
            </BankButton>
          )}
          {hasLinkedPaymentInstrument && !recentMerchantVisit && (
            <PrimaryButton onClick={handlePrimaryClick}>Got it</PrimaryButton>
          )}
          {hasLinkedPaymentInstrument && recentMerchantVisit && (
            <PrimaryButton
              onClick={handleReturnToMerchantClick}
              endIcon={<OpenInNewIcon />}
              href={recentMerchantVisit.pageUrl}
              target="_blank"
            >
              Go back to {recentMerchantVisit.merchantName}
            </PrimaryButton>
          )}
        </Stack>
      </GetStartedModal>
    </>
  );
};

export default EarnRedeemFAQ;
