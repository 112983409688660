import React from "react";

import { Box } from "@mui/material";

import { LinkCopyState } from "../../hooks/useCopyLink";

type CopyLinkButtonLabelProps = {
  linkCopied: LinkCopyState;
};

const CopyLinkButtonLabel: React.VFC<CopyLinkButtonLabelProps> = ({
  linkCopied,
}) => (
  <>
    {linkCopied === null && <>Copy link</>}
    {linkCopied === "succeeded" && <>Link copied</>}
    {(linkCopied === "permission-denied" || linkCopied === "errored") && (
      <Box component="span" color="error.main">
        Error copying to clipboard
      </Box>
    )}
  </>
);

export default CopyLinkButtonLabel;
