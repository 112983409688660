import React from "react";

import { Box } from "@mui/material";

import { useTracking } from "~common/tracking";
import AccountSectionRow from "~src/components/account/AccountSectionRow";

type ExternalLinkRowProps = {
  href: string;
  title: string;
};

const ExternalLinkRow: React.VFC<ExternalLinkRowProps> = ({ href, title }) => {
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent(`${title} external link clicked`, {
      component: "ExternalLinkRow",
      title,
      href,
    });
  };

  return (
    <Box
      sx={{
        my: 3,
      }}
    >
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <AccountSectionRow title={title} />
      </a>
    </Box>
  );
};

export default ExternalLinkRow;
