import { RewardPoolEntriesResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectRewardPoolEntries = makeFetchSelectors<
  RewardPoolEntriesResponseData | null,
  RootState
>("rewardPoolEntries");

export { selectRewardPoolEntries };
