import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUniqueId } from "../../../hooks/accessibility-hooks";
import {
  selectBankModalOpen,
  selectDisableAch,
  selectIsCatchPassFlow,
  selectIsProcessingLinkUpdate,
} from "../../../store/selectors";
import { closeBankModal } from "../../../store/slices/bankLinking-slice";
import BankDialog from "../../modals/BankDialog";
import ConnectBank from "./ConnectBank";

const ConnectBankModal: React.VFC = () => {
  const bankModalOpen = useSelector(selectBankModalOpen);
  const disableAch = useSelector(selectDisableAch);
  const isProcessingLinkUpdate = useSelector(selectIsProcessingLinkUpdate);
  const isCatchPassFlow = useSelector(selectIsCatchPassFlow);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeBankModal());
  };

  const headingId = useUniqueId("connect-bank-modal-heading");

  return (
    <BankDialog
      open={bankModalOpen}
      onClose={onClose}
      ariaLabelledBy={headingId}
      ariaDescribedBy={headingId}
      sx={{
        display: disableAch && isProcessingLinkUpdate ? "none" : "inherit",
        // During the CatchPass flow in mobile, payment linking is embedded
        // within a webview that doesn't take up the entire screen. So we
        // want to ensure the modal error messages take up the entire webview
        ...(isCatchPassFlow && {
          ".MuiDialog-paper": {
            margin: 0,
            maxHeight: "100%",
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            borderRadius: 0,
          },
          // Remove x button
          ".MuiIconButton-sizeSmall": {
            display: "none",
          },
          ".MuiButton-containedBankButtonBackground": {
            backgroundColor: "primary.main",
            "&:hover, &:active": {
              backgroundColor: "primary.main",
            },
          },
        }),
      }}
    >
      <ConnectBank headingId={headingId} isModal />
    </BankDialog>
  );
};

export default ConnectBankModal;
