/* eslint-disable */

// GENERATED FILE. DO NOT EDIT.

import * as React from "react";
const AnimatedMeditatingCoinFallback = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1200"
    width={1200}
    height={1200}
    style={{
      width: "100%",
      height: "100%",
      transform: "translate3d(0,0,0)",
    }}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h1200v1200H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        fill="transparent"
        d="M0 0h1200v1200H0z"
        style={{
          display: "block",
        }}
      />
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="m994.504 673.082-41.267 10.644 91.694 50.046 29.371-19.795-79.798-40.895z"
        />
        <path
          d="m994.504 673.082-41.267 10.644 91.694 50.046 29.371-19.795-79.798-40.895z"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M1061.625 722.494c-22.378 11.66-56.188 34.745-74.344 78.494l-121.895-46.063c10.91-54.539 11.709-103 29.168-131.909 17.96-29.348 28.756-37.656 36.47-31.236 9.56 7.44-1.581 32.47-7.418 55.434-5.844 22.964 17.038 38.027 36.108 34.306"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M1061.625 722.494c-22.378 11.66-56.188 34.745-74.344 78.494l-121.895-46.063c10.91-54.539 11.709-103 29.168-131.909 17.96-29.348 28.756-37.656 36.47-31.236 9.56 7.44-1.581 32.47-7.418 55.434-5.844 22.964 17.038 38.027 36.108 34.306"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M990.094 793.994c-27.103 58.874-40.362 113.343-77.98 150.199-22.561 21.925-53.962 35.714-91.734 29.286l14.362-139.924c9.647-.443 20.128-30.155 33.11-89.866"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M990.094 793.994c-27.103 58.874-40.362 113.343-77.98 150.199-22.561 21.925-53.962 35.714-91.734 29.286l14.362-139.924c9.647-.443 20.128-30.155 33.11-89.866"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M833.677 975.137c-33.595-1.708-74.261-17.38-98.243-50.04-39.836-54.253-55.032-174.207-106.347-195.169l95.432-105.365c27.584 25.868 43.123 60.824 70.013 124.31 19.518 46.082 33.078 104.701 47.29 79.185"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M833.677 975.137c-33.595-1.708-74.261-17.38-98.243-50.04-39.836-54.253-55.032-174.207-106.347-195.169l95.432-105.365c27.584 25.868 43.123 60.824 70.013 124.31 19.518 46.082 33.078 104.701 47.29 79.185"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M1029.482 703.516s69.808-46.227 80.027-29.935c9.946 15.858-43.707 46.219-49.189 49.075"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M1029.482 703.516s69.808-46.227 80.027-29.935c9.946 15.858-43.707 46.219-49.189 49.075"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M1009.93 689.94s36.851-50.677 65.796-63.217c23.155-10.031 32.756 6.246 13 23.882-12.34 11.023-59.244 52.911-59.244 52.911"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M1009.93 689.94s36.851-50.677 65.796-63.217c23.155-10.031 32.756 6.246 13 23.882-12.34 11.023-59.244 52.911-59.244 52.911"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M954.542 681.673c21.194 1.064 28.64-32.059 21.04-47.07-7.608-15.013-25.152-26.607-32.752-41.618-7.6-15.012 7.612-22.44 21.06-16.048 13.455 6.393 35.185 15.718 51.176 46.824 15.991 31.105-4.7 64.365-4.7 64.365"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M954.542 681.673c21.194 1.064 28.64-32.059 21.04-47.07-7.608-15.013-25.152-26.607-32.752-41.618-7.6-15.012 7.612-22.44 21.06-16.048 13.455 6.393 35.185 15.718 51.176 46.824 15.991 31.105-4.7 64.365-4.7 64.365"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="m209.118 658.21 38.592 18.08-99.358 32.255-25.211-24.878 85.977-25.458z"
        />
        <path
          d="m209.118 658.21 38.592 18.08-99.358 32.255-25.211-24.878 85.977-25.458z"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M134.027 694.378c19.84 15.59 45.115 37.8 59.427 92.202l127.085-24.226c-2.318-39.805 7.876-103.26-3.946-134.895-12.23-32.16-21.308-42.318-30.075-37.433-10.77 5.547-4.441 32.204-2.945 55.85 1.503 23.649-23.766 34.228-41.821 27.05"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M134.027 694.378c19.84 15.59 45.115 37.8 59.427 92.202l127.085-24.226c-2.318-39.805 7.876-103.26-3.946-134.895-12.23-32.16-21.308-42.318-30.075-37.433-10.77 5.547-4.441 32.204-2.945 55.85 1.503 23.649-23.766 34.228-41.821 27.05"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M191.199 778.796c16.646 62.639 20.403 118.572 51.171 161.314 18.484 25.457 46.09 46.702 92.9 44.751l2.237-140.544c-9.43-2.083-14.68-33.15-17.268-94.2"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M191.199 778.796c16.646 62.639 20.403 118.572 51.171 161.314 18.484 25.457 46.09 46.702 92.9 44.751l2.237-140.544c-9.43-2.083-14.68-33.15-17.268-94.2"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M320.549 984.579c33.534 2.648 75.884-7.637 103.886-36.924 46.514-48.65 77.084-165.634 130.678-179.788l-81.015-116.815c-30.695 22.086-50.622 54.741-85.49 114.22-25.31 43.173-46.333 99.547-57.127 72.409"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M320.549 984.579c33.534 2.648 75.884-7.637 103.886-36.924 46.514-48.65 77.084-165.634 130.678-179.788l-81.015-116.815c-30.695 22.086-50.622 54.741-85.49 114.22-25.31 43.173-46.333 99.547-57.127 72.409"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M169.122 681.662s-60.072-58.323-73.123-44.197c-12.704 13.75 34.42 53.494 39.28 57.313"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M169.122 681.662s-60.072-58.323-73.123-44.197c-12.704 13.75 34.42 53.494 39.28 57.313"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M190.844 671.93s-26.86-56.61-52.991-74.28c-20.905-14.134-33.346.09-17.187 21.071 10.093 13.112 48.456 62.94 48.456 62.94"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M190.844 671.93s-26.86-56.61-52.991-74.28c-20.905-14.134-33.346.09-17.187 21.071 10.093 13.112 48.456 62.94 48.456 62.94"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M246.806 674.03c-21.026-2.866-22.227-36.795-11.986-50.145 10.248-13.35 29.632-21.505 39.872-34.854 10.241-13.351-3.338-23.46-17.735-19.661-14.403 3.798-37.482 8.951-58.941 36.569-21.46 27.618-7.265 64.127-7.265 64.127"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={10.843}
          d="M246.806 674.03c-21.026-2.866-22.227-36.795-11.986-50.145 10.248-13.35 29.632-21.505 39.872-34.854 10.241-13.351-3.338-23.46-17.735-19.661-14.403 3.798-37.482 8.951-58.941 36.569-21.46 27.618-7.265 64.127-7.265 64.127"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#6B27DD"
          d="M593.808 783.545c126.493 7.955 229.186-85.062 246.052-220.652 16.866-136.076-65.977-257.573-185.035-271.666l-56.587-.076-49.209 486.997 44.779 5.397z"
        />
        <path
          strokeMiterlimit={10}
          stroke="#240D4A"
          strokeWidth={11.306}
          d="M593.808 783.545c126.493 7.955 229.186-85.062 246.052-220.652 16.866-136.076-65.977-257.573-185.035-271.666l-56.587-.076-49.209 486.997 44.779 5.397z"
          fill="none"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={11.306}
          d="M730.17 362.912h53.225M676.945 317.278h53.226M763.073 419.227h56.129M781.461 475.542h53.226M786.299 531.857h54.678M779.525 588.172h54.677M763.073 644.486h47.005M727.267 700.801h55.161M653.235 757.116h62.419"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M804.633 561.907c16.137-135.335-64.789-256.255-180.753-270.082-115.964-13.827-223.052 84.675-239.189 220.01C368.554 647.17 449.48 768.09 565.444 781.917c115.964 13.827 223.052-84.675 239.189-220.01z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={11.306}
          d="M804.633 561.907c16.137-135.335-64.789-256.255-180.753-270.082-115.964-13.827-223.052 84.675-239.189 220.01C368.554 647.17 449.48 768.09 565.444 781.917c115.964 13.827 223.052-84.675 239.189-220.01z"
          fill="none"
        />
      </g>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#FFF"
        strokeWidth={11.306}
        d="M133.524-78.619C146.75-189.541 80.459-288.643-14.541-299.97c-95-11.327-182.735 69.41-195.96 180.331C-223.727-8.717-157.436 90.386-62.436 101.713c95 11.327 182.734-69.411 195.96-180.332z"
        fill="none"
        transform="translate(633.151 636)"
        style={{
          display: "block",
        }}
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#240D4A"
        strokeWidth={11.306}
        d="M-.001-126.402c21.983 26.183 52.21 48.625 87.932 18.702"
        fill="none"
        transform="translate(626.015 635.484)"
        style={{
          display: "block",
        }}
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#240D4A"
        strokeWidth={11.306}
        d="M-164.873-148.385s34.639 80.978 87.932 23.136"
        fill="none"
        transform="translate(640.183 635.484)"
        style={{
          display: "block",
        }}
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#240D4A"
        strokeWidth={11.306}
        d="M-111.241-56.221c28.421 32.983 92.112 40.563 131.737 6.947"
        fill="none"
        transform="translate(633.571 635.484)"
        style={{
          display: "block",
        }}
      />
    </g>
  </svg>
);
export default AnimatedMeditatingCoinFallback;
