import React from "react";

import { Checkbox, Stack, Typography } from "@mui/material";

type SimpleCheckboxOptionProps = {
  label: string;
  selected: boolean;
  onSelect: () => void;
};

const SimpleCheckboxOption: React.VFC<SimpleCheckboxOptionProps> = ({
  label,
  selected,
  onSelect,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    spacing="10.5px"
    onClick={onSelect}
    sx={{ cursor: "pointer" }}
  >
    <Checkbox checked={selected} sx={{ color: "primary.main" }} />
    <Typography variant="h3">{label}</Typography>
  </Stack>
);

export default SimpleCheckboxOption;
