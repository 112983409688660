import "swiper/css";
import "swiper/css/navigation";

import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { Box, Stack, Typography } from "@mui/material";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { MerchantCardInfo } from "~common/services";

import MerchantCard from "./MerchantCard";
import PinkArrowButton from "./PinkArrowButton";

type ShopCategoryProps = {
  categoryName: string;
  merchants: MerchantCardInfo[];
  utmCampaign: string;
};

// See Swiper React docs: https://swiperjs.com/react

const ShopCategory: React.VFC<ShopCategoryProps> = ({
  categoryName,
  merchants,
  utmCampaign,
}) => {
  const slides = merchants.map((merchant, j) => ({
    key: `${merchant.id}-${j}`,
    node: (
      <MerchantCard
        merchant={merchant}
        utmCampaign={utmCampaign}
        categoryName={categoryName}
        tabIndex={-1}
      />
    ),
  }));

  // Inject our custom navigation buttons into the Swiper instance.
  // This is a bit of a hack, but it works.
  const stackRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stackRef.current) {
      const swiper = stackRef.current.querySelector(".swiper");

      // Set tabIndex to 0 and add an "Enter"/"Space" key listener to the navigation buttons.
      const fixButton = (button: HTMLDivElement) => {
        // Check this condition so we never create multiple event listeners.
        if (button.tabIndex !== 0) {
          button.tabIndex = 0;
          button.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.key === " ") {
              event.preventDefault();
              button.click();
            }
          });
        }
      };

      if (swiper) {
        const prevButton = swiper.querySelector(
          ".swiper-button-prev"
        ) as HTMLDivElement;
        if (prevButton) {
          fixButton(prevButton);
          ReactDOM.render(<PinkArrowButton direction="left" />, prevButton);
        }

        const nextButton = swiper.querySelector(
          ".swiper-button-next"
        ) as HTMLDivElement;
        if (nextButton) {
          fixButton(nextButton);
          ReactDOM.render(<PinkArrowButton direction="right" />, nextButton);
        }
      }
    }
  }, [stackRef]);

  return (
    <Stack
      ref={stackRef}
      sx={({ palette, breakpoints }) => ({
        position: "relative",
        gap: 6,
        // TODO: We're hardcoding heights and preventing text overflow to ensure the iframe
        // version for the public page doesn't break due to scroll issues. We should remove these
        // restrictions once we have a better solution for the public page.
        height: "352px",
        ".swiper": {
          // NB: We use these CSS variables because Swiper React doesn't seem to
          // actually give us access to the buttons in React, which is why we
          // render them in their own React tree, and pass down these dynamic values
          // through parent CSS variables in the DOM.
          "--swiper-button-background": "#d02f90",
          "--swiper-button-background-hover": "#dd3c9d",
          "--swiper-button-shadow": "0px 4px 8px 0px #23263929",
          "--swiper-button-cursor": "pointer",
          width: "100%",
          // Disable navigation on small screens.
          [breakpoints.down("sm")]: {
            ".swiper-button-prev, .swiper-button-next": {
              display: "none",
            },
          },
          overflow: "visible",
          // Override navigation styles.
          ".swiper-button-prev, .swiper-button-next": {
            top: "calc(50% - 28px)",
            height: 52,
            width: 52,
            // Remove default navigation buttons.
            "&::after, &::before": {
              display: "none",
            },
          },
          // Right navigation button.
          ".swiper-button-next": {
            right: -24,
          },
          // Left navigation button.
          ".swiper-button-prev": {
            left: -24,
          },
          // Disabled navigation buttons.
          ".swiper-button-disabled": {
            opacity: 1,
            pointerEvents: "all",
            "--swiper-button-background": "#e9a6d2",
            "--swiper-button-background-hover": "#e9a6d2",
            "--swiper-button-shadow": "none",
            "--swiper-button-cursor": "default",
          },
          // Shared styles for edge blur effect.
          ".edge-blur": {
            width: "50vw",
            height: "calc(100% + 32px)",
            position: "absolute",
            background: palette.grey[100],
            top: -16,
            filter: "blur(4px)",
            zIndex: 2,
          },
          // 1140px is where the auto-x-margin on the desktop starts.
          [breakpoints.down(1140)]: {
            ".edge-blur": {
              display: "none",
            },
          },
        },
        // This element is used to scroll the page to the top of a
        // category, but accounting for the sticky header.
        ".adjusted-scroll-anchor": {
          width: 1,
          height: 1,
          position: "absolute",
          top: -104,
          left: 0,
        },
      })}
    >
      <Box id={categoryName} className="adjusted-scroll-anchor" />

      <Typography variant="h1">{categoryName}</Typography>

      <Swiper
        slidesPerView={2.08}
        spaceBetween={16}
        breakpoints={{
          600: { slidesPerView: 4 },
          840: { slidesPerView: 5 },
        }}
        modules={[Navigation]}
        navigation
        grabCursor
      >
        <Box
          slot="container-start"
          className="edge-blur"
          sx={{ right: "calc(100% + 8px)" }}
        />
        {slides.map((slide) => (
          <SwiperSlide key={slide.key}>{slide.node}</SwiperSlide>
        ))}
        <Box
          slot="container-end"
          className="edge-blur"
          sx={{ left: "calc(100% + 8px)" }}
        />
      </Swiper>
    </Stack>
  );
};

export default ShopCategory;
