// GENERATED FILE. DO NOT EDIT.

import React from "react";

import IconSVG from "../vector-source/House.icon.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const HouseIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon iconSvg={IconSVG} {...props} />
);

export default HouseIcon;
