import { Components, Theme } from "@mui/material";

const buildChip = (theme: Theme): Components["MuiChip"] => {
  const { palette } = theme;

  return {
    styleOverrides: {
      root: {
        "&.Mui-focusVisible": {
          outline: "2px solid",
          outlineColor: palette.info.main,
        },
        ".MuiTouchRipple-root": {
          display: "none",
        },
      },
    },
  };
};

export default buildChip;
