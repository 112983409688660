import React from "react";

import { Typography, TypographyProps } from "@mui/material";

const DialogBody: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography variant="bodyRegular" sx={{ textAlign: "center" }} {...props}>
    {children}
  </Typography>
);

DialogBody.displayName = "DialogBody";

export default DialogBody;
