import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FullHeightDialog from "~common/components/modals/FullHeightDialog";
import CatchCardApplication from "~src/components/shared/onboarding/CatchCardApplication";
import { selectClaims } from "~src/store";

import ClaimReward from "./ClaimReward";

type ConfirmationProps = {
  open: boolean;
  onNext: () => void;
};

const Confirmation: React.VFC<ConfirmationProps> = ({ open, onNext }) => {
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const [success, setSuccess] = useState(false);
  const [claimReward, setClaimReward] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        if (newUserOnboardingRewardCampaign) {
          setClaimReward(true);
        } else {
          onNext();
        }
      }, 3000);
    }
  }, [success, onNext, newUserOnboardingRewardCampaign]);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  const getHeading = () => {
    if (claimReward) {
      return "";
    }

    if (success) {
      return "Success!";
    }

    return "Confirming...";
  };

  return (
    <FullHeightDialog heading={getHeading()} open={open}>
      {claimReward ? (
        <ClaimReward onNext={onNext} />
      ) : (
        <CatchCardApplication
          onSuccess={handleSuccess}
          showContinue
          showConfetti={!newUserOnboardingRewardCampaign}
        />
      )}
    </FullHeightDialog>
  );
};

export default Confirmation;
