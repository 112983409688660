import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack } from "@mui/material";

import RewardCard from "~common/components/cards/RewardCard";
import FullHeightDialog from "~common/components/modals/FullHeightDialog";
import type { UserBillingAddress } from "~common/services";
import { useTrackPageView } from "~common/tracking";
import { setSessionStorageWithFallback } from "~common/utils/browser-storage";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import AddressAutocomplete from "~src/components/shared/onboarding/AddressAutocomplete";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import CurrentUserForm from "~src/components/shared/onboarding/CurrentUserForm";
import { selectCatchCard, selectClaims, selectCurrentUser } from "~src/store";
import {
  markEmailVerified,
  setAuthUserInfo,
} from "~src/store/slices/user-slice";

const CompleteAccount: React.VFC = () => {
  const dispatch = useDispatch();
  const { rewardCampaign, gift, newUserOnboardingRewardCampaign } =
    useSelector(selectClaims);
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const [address, setAddress] = useState(currentUser?.billing_address || null);
  const [editAddress, setEditAddress] = useState(false);

  useTrackPageView("Complete Account");

  useEffect(() => {
    if (currentUser) {
      setAddress(currentUser.billing_address || null);
    }
  }, [currentUser]);

  const handleSelectAddress = (value: UserBillingAddress) => {
    setAddress(value);
    setEditAddress(false);
  };

  const handleSuccess = () => {
    if (catchCard?.user_flow_status !== "approved" || !catchCard?.is_pin_set) {
      setSessionStorageWithFallback("activateCardRequired", "true");
      dispatch(setAuthUserInfo({ activateCardRequired: true }));
    }

    dispatch(markEmailVerified());
  };

  let rewardCard = null;

  if (gift) {
    rewardCard = <RewardCard reward={gift} sx={{ width: 224, mb: 8 }} />;
  }

  if (rewardCampaign) {
    rewardCard = (
      <RewardCard reward={rewardCampaign} sx={{ width: 224, mb: 8 }} />
    );
  }

  return (
    <>
      <SmallPagePanel
        icon={
          newUserOnboardingRewardCampaign ? (
            <CatchCardReward
              reward={newUserOnboardingRewardCampaign}
              percentComplete={(2 / 7) * 100}
            />
          ) : (
            <Stack width="100%" alignItems="center">
              {rewardCard}
            </Stack>
          )
        }
        title="Let's create your profile"
      >
        <CurrentUserForm
          isNewUser
          address={address}
          onEditAddress={() => setEditAddress(true)}
          onSuccess={handleSuccess}
        />
      </SmallPagePanel>

      <FullHeightDialog
        heading="Billing Address"
        open={editAddress}
        onClose={() => setEditAddress(false)}
      >
        <AddressAutocomplete onSelect={handleSelectAddress} />
      </FullHeightDialog>
    </>
  );
};

export default CompleteAccount;
