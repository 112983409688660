import React from "react";

import { Typography } from "@mui/material";

import { BankSyncIcon } from "../../../../icons/vector";
import BankHeader from "../BankHeader";

type BankHeaderChangeMethodProps = {
  headingId?: string;
};

const BankHeaderChangeMethod: React.VFC<BankHeaderChangeMethodProps> = ({
  headingId,
}) => (
  <BankHeader icon={<BankSyncIcon size="small" />}>
    <Typography variant="h1" id={headingId}>
      Change payment method
    </Typography>
    <Typography variant="bodyRegular" color="grey.600">
      Connecting a new bank account will replace your current payment method.
    </Typography>
  </BankHeader>
);

export default BankHeaderChangeMethod;
