import React from "react";

import { DeleteForeverOutlined as DeleteForeverOutlinedIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { useTracking } from "~common/tracking";

type DeleteAccountLinkRowProps = {
  href: string;
  title: string;
};

const DeleteAccountLinkRow: React.VFC<DeleteAccountLinkRowProps> = ({ href, title }) => {
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent(`Delete account link clicked`, {
      component: "DeleteAccountLinkRow",
      title,
      href,
    });
  };

  return (
    <Box
      sx={{
        my: 3,
      }}
    >
      <a
        href="https://ead5e680ees.typeform.com/to/BqwpIu7Y"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={({ palette }) => ({
            py: 3,
            color: palette.error.main,
          })}
        >
          <Typography variant="h3" component="span">
            {title}
          </Typography>
          <DeleteForeverOutlinedIcon
            sx={{
              height: 24,
              width: 24,
            }}
          />
        </Stack>
      </a>
    </Box>
  );
};

export default DeleteAccountLinkRow;
