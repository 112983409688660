import { useGet, useMutate } from "restful-react";

import {
  CreateAdyenCardPaymentInstrumentRequestData,
  CreateAdyenSessionRequestData,
  CreateAdyenSessionResponseData,
  CreatePaymentInstrumentResponseData,
  CreatePlaidLinkTokenRequestData,
  CreatePlaidLinkTokenResponseData,
  CreatePlaidPaymentInstrumentRequestData,
  CreateTellerPaymentInstrumentRequestData,
  PaymentInstrumentsReponseData,
  ReconnectPaymentInstrumentPathParams,
  RefreshPaymentInstrumentPathParams,
  RefreshPaymentInstrumentResponseData,
} from "./types/banking-types";
import { ErrorResponseData } from "./types/error-handling-types";

const useGetPaymentInstruments = (
  options: {
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    PaymentInstrumentsReponseData,
    ErrorResponseData,
    undefined,
    undefined
  >({
    path: `payments-svc/payment_instruments`,
    lazy: options.lazy,
  });

const useCreateTellerPaymentInstrument = () =>
  useMutate<
    CreatePaymentInstrumentResponseData,
    ErrorResponseData,
    undefined,
    CreateTellerPaymentInstrumentRequestData,
    unknown
  >({
    verb: "POST",
    path: "payments-svc/payment_instruments/teller_instant_auth",
  });

const useCreatePlaidPaymentInstrument = () =>
  useMutate<
    CreatePaymentInstrumentResponseData,
    ErrorResponseData,
    undefined,
    CreatePlaidPaymentInstrumentRequestData,
    unknown
  >({
    verb: "POST",
    path: "payments-svc/payment_instruments/plaid_instant_auth",
  });

const useCreateAdyenCardPaymentSession = () =>
  useMutate<
    CreateAdyenSessionResponseData,
    ErrorResponseData,
    undefined,
    CreateAdyenSessionRequestData,
    unknown
  >({
    verb: "POST",
    path: "payments-svc/adyen_sessions",
  });

const useCreateAdyenCardPaymentInstrument = () =>
  useMutate<
    CreatePaymentInstrumentResponseData,
    ErrorResponseData,
    undefined,
    CreateAdyenCardPaymentInstrumentRequestData,
    unknown
  >({
    verb: "POST",
    path: "payments-svc/payment_instruments/adyen_card",
  });

const useRefreshPaymentInstrument = () =>
  useMutate<
    RefreshPaymentInstrumentResponseData,
    ErrorResponseData,
    undefined,
    undefined,
    RefreshPaymentInstrumentPathParams
  >({
    verb: "PUT",
    path: ({ checkoutId }) =>
      `transactions-svc/checkouts/${checkoutId}/refresh_payment_instrument`,
  });

const usePlaidLinkTokens = () =>
  useMutate<
    CreatePlaidLinkTokenResponseData,
    ErrorResponseData,
    undefined,
    CreatePlaidLinkTokenRequestData,
    unknown
  >({
    verb: "POST",
    path: "/payments-svc/link_tokens",
  });

const useReconnectPlaidPaymentInstrument = () =>
  useMutate<
    CreatePaymentInstrumentResponseData,
    ErrorResponseData,
    undefined,
    undefined,
    ReconnectPaymentInstrumentPathParams
  >({
    verb: "PUT",
    path: ({ paymentInstrumentId }) =>
      `payments-svc/payment_instruments/${paymentInstrumentId}/plaid_instant_auth/refresh`,
  });

const useReconnectTellerPaymentInstrument = () =>
  useMutate<
    CreatePaymentInstrumentResponseData,
    ErrorResponseData,
    undefined,
    undefined,
    ReconnectPaymentInstrumentPathParams
  >({
    verb: "PUT",
    path: ({ paymentInstrumentId }) =>
      `payments-svc/payment_instruments/${paymentInstrumentId}/teller_instant_auth/refresh`,
  });

export {
  useCreateAdyenCardPaymentInstrument,
  useCreateAdyenCardPaymentSession,
  useCreatePlaidPaymentInstrument,
  useCreateTellerPaymentInstrument,
  useGetPaymentInstruments,
  usePlaidLinkTokens,
  useReconnectPlaidPaymentInstrument,
  useReconnectTellerPaymentInstrument,
  useRefreshPaymentInstrument,
};
