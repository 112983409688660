import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack, Typography, useTheme } from "@mui/material";

import iosAppImage from "~common/assets/illustrations/ios_app.gif";
import {
  DefaultButton,
  TextButtonSmall,
} from "~common/components/controls/buttons";
import { DefaultDrawer } from "~common/components/modals";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import { useTracking } from "~common/tracking";
import { ADOPTION_URLS } from "~common/utils/adoption";
import useUserData from "~src/hooks/services/useUserData";
import {
  selectAppAdoptionNuxOpen,
  selectNuxs,
  selectShowAppAdoptionNux,
} from "~src/store";
import { setAppAdoptionNuxOpen, setShowAppAdoptionNux } from "~src/store/slices/nuxs-slice";
import { getDevice } from "~src/utils/devices";

const APP_ADOPTION_NUX_NAME = "app-adoption-nux";

const AppAdoptionNux: React.VFC = () => {
  const { palette, shape } = useTheme();
  const { trackEvent } = useTracking();
  const { userData } = useUserData();
  const nuxs = useSelector(selectNuxs);
  const showAppAdoptionNux = useSelector(selectShowAppAdoptionNux);
  const appAdoptionNuxOpen = useSelector(selectAppAdoptionNuxOpen);
  const idPrefix = useUniqueId("appAdoptionDrawer");
  const dispatch = useDispatch();
  const device = getDevice();

  useEffect(() => {
    if (
      device &&
      showAppAdoptionNux &&
      userData?.has_redeemed_rewards &&
      nuxs?.includes(APP_ADOPTION_NUX_NAME)
    ) {
      trackEvent("App Adoption Nux Viewed", {
        device,
      });
      dispatch(setAppAdoptionNuxOpen(true));
    }
  }, [device, showAppAdoptionNux, nuxs, trackEvent, userData, dispatch]);

  const handleAppClick = () => {
    trackEvent("App Adoption Nux Continue on the App Clicked", {
      device,
    });
  };

  const handleBrowserClick = () => {
    trackEvent("App Adoption Nux Continue in Browser Clicked", {
      device,
    });
    handleClose();
  };

  const handleClose = () => {
    dispatch(setAppAdoptionNuxOpen(false));
    dispatch(setShowAppAdoptionNux(false));
  };

  return (
    <DefaultDrawer
      anchor="bottom"
      onClose={handleClose}
      onOpen={() => {}}
      open={appAdoptionNuxOpen}
      ariaLabelledBy={`${idPrefix}-heading`}
      ariaDescribedBy={`${idPrefix}-content`}
      transitionDuration={400}
      sx={{
        ".MuiDrawer-paper": {
          backgroundColor: palette.tertiary.main,
          color: palette.common.white,
        },
        ".MuiBox-root:first-of-type > .MuiBox-root": {
          background: palette.grey[200],
          opacity: 1,
        },
        ".MuiBox-root:last-of-type": {
          pb: 0,
        },
      }}
    >
      <Stack spacing={6} alignItems="center" textAlign="center">
        <Stack spacing={2}>
          <Typography variant="h1" component="h4" id={`${idPrefix}-heading`}>
            It&apos;s better in the app
          </Typography>
          <Typography
            variant="bodyRegular"
            paragraph
            id={`${idPrefix}-content`}
          >
            Access exclusive credit drops, expiration reminders, and tailored
            recommendations.
          </Typography>
        </Stack>

        <Stack spacing={4} width="100%" pb={2}>
          <DefaultButton
            href={
              device === "iphone" ? ADOPTION_URLS.ios : ADOPTION_URLS.android
            }
            onClick={handleAppClick}
            sx={{
              background: palette.common.white,
              border: "none",
              "&.MuiButton-root .MuiBox-root": {
                color: palette.tertiary.main,
              },
            }}
          >
            Continue on the app
          </DefaultButton>

          <TextButtonSmall
            onClick={handleBrowserClick}
            sx={{
              "&.MuiButton-root .MuiBox-root": {
                color: palette.common.white,
              },
            }}
          >
            Continue in browser
          </TextButtonSmall>
        </Stack>

        <Box
          sx={{
            border: `12px solid ${palette.common.white}99`,
            borderBottom: "none",
            borderRadius: `${shape.borderRadius * 7.5}px ${
              shape.borderRadius * 7.5
            }px 0 0`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              background: `center top / cover url(${iosAppImage}) no-repeat`,
              width: 269,
              maxWidth: "calc(100vw - 130px)",
              height: 365,
              maxHeight: "45vh",
            }}
            aria-label="Catch App Demo"
          />
        </Box>
      </Stack>
    </DefaultDrawer>
  );
};

export default AppAdoptionNux;
export { APP_ADOPTION_NUX_NAME };
