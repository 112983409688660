import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import { ResponsiveDrawerDialog } from "~common/components/modals";
import RewardClaimed from "~common/components/reward-campaigns/RewardClaimed";
import { useEnv } from "~common/contexts/env-context";
import { CloseReason } from "~common/utils/modal-utils";
import { formatMoney } from "~common/utils/money";
import { navigateWithUserParams } from "~common/utils/navigation";
import { selectClaims } from "~src/store";
import { clearCampaignClaim } from "~src/store/slices/user-slice";

const RewardClaimedModal: React.VFC = () => {
  const dispatch = useDispatch();
  const claims = useSelector(selectClaims);
  const { APP_ENVIRONMENT, APP_STAGE } = useEnv();

  const handleClose = (e: unknown, reason?: CloseReason) => {
    dispatch(clearCampaignClaim());
  };

  if (!claims.rewardCampaign) {
    return null;
  }

  const standardRewardContents = {
    cta_button: {
      link: claims.rewardCampaign.post_claim_cta_url || "",
      text: <>Shop {claims.rewardCampaign.merchant_name || ""}</>,
    },
    header: `You've got ${formatMoney(claims.rewardCampaign.total_amount, {
      dropCentsIfRound: true,
    })} to spend!`,
    subheader: (
      <>
        Shop at {claims.rewardCampaign.merchant_name || ""} like you normally
        would. Your credit will be <strong>applied automatically</strong> when
        you select Catch at checkout.
      </>
    ),
    subtext: "Go to your Catch account",
  };

  const secondChanceBonusContents = {
    cta_button: {
      text: "Got it",
    },
    header: "It's yours!",
    subheader: (
      <>
        Your Catch account is set up and your{" "}
        {claims.rewardCampaign.merchant_name || ""} credit is{" "}
        <strong>ready to be redeemed.</strong>
      </>
    ),
    subtext: "",
  };

  const contents = claims.rewardCampaign.is_second_chance_bonus
    ? secondChanceBonusContents
    : standardRewardContents;

  return (
    <ResponsiveDrawerDialog
      fullBleed
      name="rewardClaimed"
      onClose={handleClose}
      open={!!claims.rewardCampaign.claimed}
      sx={{
        ".MuiDialog-paper, .MuiDrawer-paper": {
          overflow: "hidden",
        },
      }}
    >
      {({ contentId, headingId }) => (
        <Box
          sx={({ spacing }) => ({
            pt: {
              sm: spacing(5),
            },
            overflowY: "auto",
            maxHeight: {
              zero: `calc(100vh - ${spacing(16)})`,
              sm: `calc(100vh - ${spacing(28)})`,
            },
          })}
        >
          <RewardClaimed
            contents={contents}
            contentId={contentId}
            headingId={headingId}
            onClick={(e) =>
              navigateWithUserParams(e, APP_ENVIRONMENT, APP_STAGE)
            }
            onClose={handleClose}
            rewardCampaign={claims.rewardCampaign || null}
          />
        </Box>
      )}
    </ResponsiveDrawerDialog>
  );
};

export default RewardClaimedModal;
