import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";
import { Dayjs } from "dayjs";

import {
  MailcheckErrorHelperText,
  MailcheckInformationalAlert,
} from "~common/components/alerts";
import { PrimaryButton } from "~common/components/controls/buttons";
import type { UserBillingAddress } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useUpdateCurrentUser } from "~common/services/users";
import { useTracking } from "~common/tracking";
import {
  EMAIL_IN_USE_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  isValidEmailAddress,
  runMailcheck,
} from "~common/utils/email";
import {
  EMPTY_FIRST_NAME_MESSAGE,
  EMPTY_LAST_NAME_MESSAGE,
  getValidName,
} from "~common/utils/names";
import { selectCurrentUser, selectPrefill } from "~src/store";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { setAuthState } from "~src/store/slices/user-slice";
import {
  currentUserHasBirthday,
  EMPTY_DOB_MESSAGE,
  getCurrentUserBirthday,
  getDayjsBirthday,
} from "~src/utils/dayjs";

import EditCard from "../EditCard";

type CurrentUserFormProps = {
  isNewUser?: boolean;
  address: UserBillingAddress | null;
  onEditAddress: () => void;
  onSuccess?: () => void;
};

const CurrentUserForm: React.VFC<CurrentUserFormProps> = ({
  isNewUser,
  address,
  onEditAddress,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { trackEvent, trackError, captureException } = useTracking();
  const { prefillFirstName, prefillLastName, prefillEmail } =
    useSelector(selectPrefill);
  const currentUser = useSelector(selectCurrentUser.data);
  const { mutate: updateCurrentUser } = useUpdateCurrentUser({
    queryParams: { include_billing_address: true },
  });
  const [firstName, setFirstName] = useState(
    currentUser?.first_name || prefillFirstName || ""
  );
  const [lastName, setLastName] = useState(
    currentUser?.last_name || prefillLastName || ""
  );
  const [dob, setDob] = useState<Dayjs | null>(getDayjsBirthday(currentUser));
  const [email, setEmail] = useState(currentUser?.email || prefillEmail || "");
  const incorrectEmail = useRef("");
  const [sms, setSms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<
    Record<string, string | React.ReactNode>
  >({});

  useEffect(() => {
    const parsedDob = getDayjsBirthday(currentUser);

    if (parsedDob) {
      setDob(parsedDob);
    }

    if (currentUser?.first_name) {
      setFirstName(currentUser.first_name);
    }

    if (currentUser?.last_name) {
      setLastName(currentUser.last_name);
    }

    if (currentUser?.email) {
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const handleChange = (setState: () => void, isEmail?: boolean) => {
    setState();

    setErrors({
      ...(errors.mailcheck && {
        mailcheck: errors.mailcheck,
      }),
    });
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    trackEvent(
      `Current User SMS ${e.target.checked ? "Checked" : "Unchecked"}`
    );
    setSms(e.target.checked);
  };

  const handleEditAddress = () => {
    trackEvent("Current User Address Open");
    onEditAddress();

    setErrors({
      ...(errors.mailcheck && {
        mailcheck: errors.mailcheck,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    const validatedData = handleValidation();

    if (validatedData.errors) {
      trackError("CurrentUserForm", "Validation errors", {
        invalidFields: Object.keys(validatedData.errors),
        ...(email && {
          email,
        }),
      });

      setErrors(validatedData.errors);
      return;
    }

    setLoading(true);
    trackEvent("Current User Update Submit");

    try {
      let response = await updateCurrentUser(validatedData.body);

      if (isNewUser && sms) {
        response = await updateCurrentUser({
          sms_credit_reminders_opt_in: sms,
        });
      }

      if (response.refreshed_auth_info) {
        dispatch(setAuthState(response.refreshed_auth_info));
      }

      dispatch(currentUserActions.manualSet(response));
      trackEvent("Current User Update Success");
      onSuccess && onSuccess();
    } catch (err) {
      const error = convertRawServiceError(err);

      setLoading(false);

      if (error.error_type === "EMAIL_IN_USE") {
        incorrectEmail.current = "";

        setErrors({
          email: EMAIL_IN_USE_MESSAGE,
        });

        trackError("CurrentUserForm", "Email in use", {
          email,
          error,
        });

        return;
      }

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });

      captureException({
        component: "CurrentUserForm",
        exceptionMessage: "Unknown issue updating user",
        rawError: err,
      });
    }
  };

  const handleValidation = () => {
    const parsedFirstName = getValidName(firstName);
    const parsedLastName = getValidName(lastName);
    const parsedDob = getCurrentUserBirthday(dob);
    const isValidEmail = isValidEmailAddress(email);
    const parsedEmail = isNewUser && isValidEmail ? handleMailcheck() : email;

    if (
      parsedFirstName &&
      parsedLastName &&
      parsedDob &&
      isValidEmail &&
      (!incorrectEmail.current ||
        parsedEmail === incorrectEmail.current ||
        parsedEmail === email) &&
      address
    ) {
      return {
        body: {
          first_name: parsedFirstName,
          last_name: parsedLastName,
          email: parsedEmail,
          new_user: isNewUser,
          ...(!address.address_id && {
            billing_address: address,
          }),
          ...parsedDob,
        },
      };
    }

    return {
      errors: {
        ...(!parsedFirstName && {
          firstName: EMPTY_FIRST_NAME_MESSAGE,
        }),
        ...(!parsedLastName && {
          lastName: EMPTY_LAST_NAME_MESSAGE,
        }),
        ...(!parsedDob && {
          dob: EMPTY_DOB_MESSAGE,
        }),
        ...(!isValidEmail && {
          email: INVALID_EMAIL_MESSAGE,
        }),
        ...(incorrectEmail.current && {
          mailcheck: (
            <MailcheckErrorHelperText incorrectEmail={incorrectEmail.current} />
          ),
        }),
        ...(!address && {
          address: "Please enter a valid address",
        }),
      },
    };
  };

  const handleMailcheck = () => {
    const suggestion = runMailcheck(email);

    if (
      suggestion &&
      (!incorrectEmail.current || email !== incorrectEmail.current)
    ) {
      incorrectEmail.current = email;
      setEmail(suggestion);
      return suggestion;
    }

    return email;
  };

  return (
    <Stack spacing={5.5} component="form" onSubmit={handleSubmit}>
      <Stack direction="row" spacing={3}>
        <TextField
          label="First name"
          value={firstName}
          error={!!errors.firstName}
          helperText={errors.firstName}
          onChange={(e) => handleChange(() => setFirstName(e.target.value))}
          autoFocus
          name="fname"
          autoComplete="given-name"
        />

        <TextField
          label="Last name"
          value={lastName}
          error={!!errors.lastName}
          helperText={errors.lastName}
          onChange={(e) => handleChange(() => setLastName(e.target.value))}
          name="lname"
          autoComplete="family-name"
        />
      </Stack>

      <DateField
        label="Date of birth"
        value={dob}
        disabled={currentUserHasBirthday(currentUser)}
        InputProps={{
          error: !!errors.dob,
        }}
        InputLabelProps={{
          error: !!errors.dob,
        }}
        helperText={
          errors.dob ? (
            <Box component="span" color="error.main" display="block">
              {errors.dob}
            </Box>
          ) : undefined
        }
        onChange={(value) => handleChange(() => setDob(value))}
      />

      <TextField
        label="Email address"
        value={email}
        error={!!errors.email}
        InputProps={{
          disabled: !!currentUser?.email,
        }}
        helperText={
          errors.email || errors.mailcheck ? (
            <Box
              component="span"
              color={errors.mailcheck ? "info.main" : "errors.main"}
              display="block"
            >
              {errors.email || errors.mailcheck}
            </Box>
          ) : undefined
        }
        onChange={(e) => handleChange(() => setEmail(e.target.value), true)}
        name="email"
        autoComplete="email"
      />

      {errors.mailcheck && <MailcheckInformationalAlert />}

      <Stack>
        {address ? (
          <EditCard
            onClick={address?.address_id ? undefined : handleEditAddress}
          >
            <Box>
              {address?.address_1}
              {address?.address_2 && `, ${address.address_2}`}
            </Box>

            <Box>
              {address?.city}, {address?.zone_code} {address?.postal_code},{" "}
              {address?.country_code}
            </Box>
          </EditCard>
        ) : (
          <Button
            onClick={handleEditAddress}
            sx={({ palette, shape }) => ({
              borderRadius: `${shape.borderRadius}px`,
              justifyContent: "flex-start",
              fontWeight: 400,
              "&.MuiButton-outlined": {
                borderColor: errors.address
                  ? palette.error.main
                  : "rgba(0, 0, 0, 0.23)",
                color: palette.grey[500],
              },
              "&.MuiButton-outlined:hover:not(:active)": {
                backgroundColor: "transparent",
                borderColor: palette.grey[700],
              },
              "&.Mui-focusVisible.MuiButton-outlined": {
                border: `2px solid ${palette.grey[700]}`,
                outline: "none",
              },
            })}
          >
            Billing address
          </Button>
        )}

        {errors.address && (
          <FormHelperText error sx={{ mt: 0.75, mx: 3.5 }}>
            {errors.address}
          </FormHelperText>
        )}
      </Stack>

      {isNewUser && firstName && lastName && dob && address && (
        <FormControlLabel
          control={
            <Checkbox
              checked={sms}
              onChange={handleCheckbox}
              sx={({ palette }) => ({
                mt: -2,
                ml: -2,
                mr: -1,
                color: palette.grey[600],
                "&.Mui-checked": {
                  color: palette.grey[600],
                },
                ".MuiSvgIcon-fontSizeMedium": {
                  fontSize: 20,
                },
              })}
            />
          }
          disableTypography
          label={
            <Typography variant="bodyXSmall" color="grey.500" lineHeight={1.5}>
              <Typography variant="inherit" fontWeight="700" color="grey.700">
                Opt-in to SMS and never miss a drop $$
              </Typography>
              I agree to receive promotional and personalized marketing text
              messages. Message frequency varies. Message and data rates may
              apply. Reply STOP to unsubscribe at any time.
            </Typography>
          }
          sx={{ alignItems: "flex-start" }}
        />
      )}

      {errors.generic && (
        <FormHelperText error>{errors.generic}</FormHelperText>
      )}

      <PrimaryButton type="submit" startIcon={<LockIcon />} loading={loading}>
        Confirm
      </PrimaryButton>
    </Stack>
  );
};

export default CurrentUserForm;
