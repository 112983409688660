import { GetConsumerActivitiesListResponse } from "~common/services";
import { makeFetchSlice } from "~common/store/store-utils";

const slice = makeFetchSlice<GetConsumerActivitiesListResponse>(
  "consumerActivities",
  { activities: [], last_evaluated_key: null }
);

const { reducer, actions: consumerActivitiesActions } = slice;

export { consumerActivitiesActions };
export default reducer;
