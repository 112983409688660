/* eslint-disable */

// GENERATED FILE. DO NOT EDIT.

import * as React from "react";
const AnimatedPhoneSendingFallback = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1200"
    width={1200}
    height={1200}
    style={{
      width: "100%",
      height: "100%",
      transform: "translate3d(0,0,0)",
    }}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h1200v1200H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        fill="transparent"
        d="M0 0h1200v1200H0z"
        style={{
          display: "block",
        }}
      />
      <g
        style={{
          display: "block",
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#232639"
          strokeWidth={31.901}
          d="M760.16 990.418H439.84c-27.774 0-50.497-22.725-50.497-50.498V260.08c0-27.774 22.723-50.498 50.497-50.498h320.32c27.774 0 50.497 22.724 50.497 50.498v679.84c0 27.773-22.723 50.498-50.497 50.498zM522.803 921.371h154.394"
          fill="none"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#232639"
          strokeWidth={31.901}
          d="M513.188 214.305s0 0 0 0c0 33.691 27.312 61.003 61.003 61.003h51.618c33.691 0 61.003-27.312 61.003-61.003v-3.519"
          fill="none"
        />
      </g>
      <path
        fill="#232639"
        d="M-65.047-13.607c0 13.949-11.307 25.256-25.256 25.256S-115.559.342-115.559-13.607s11.307-25.256 25.256-25.256 25.256 11.307 25.256 25.256z"
        transform="translate(600 600)"
        style={{
          display: "block",
        }}
      />
      <path
        fill="#232639"
        d="M-65.047-13.607c0 13.949-11.307 25.256-25.256 25.256S-115.559.342-115.559-13.607s11.307-25.256 25.256-25.256 25.256 11.307 25.256 25.256z"
        transform="translate(690 600)"
        style={{
          display: "block",
        }}
      />
      <path
        fill="#232639"
        d="M-65.047-13.607c0 13.949-11.307 25.256-25.256 25.256S-115.559.342-115.559-13.607s11.307-25.256 25.256-25.256 25.256 11.307 25.256 25.256z"
        transform="translate(780.5 600)"
        style={{
          display: "block",
        }}
      />
    </g>
  </svg>
);
export default AnimatedPhoneSendingFallback;
