import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  TellerConnectError,
  TellerConnectEvent,
  TellerConnectEventParams,
  TellerConnectInstance,
  TellerEnrollmentObject,
  TellerFailure,
  TellerWindow,
} from "../../banking/teller";
import { useBankLinkingOptions } from "../../contexts/BankLinkingContext";
import {
  CreatePaymentInstrumentResponseData,
  useCreateTellerPaymentInstrument,
} from "../../services";
import {
  selectIsReconnectingPaymentInstrument,
  selectPaymentInstrument,
} from "../../store/selectors";
import {
  closeBankModal,
  setBankLinkingStep,
  setIsProcessingLinkUpdate,
  setIsReconnectingPaymentInstrument,
  setTellerInstitutionId,
} from "../../store/slices/bankLinking-slice";
import { paymentInstrumentsActions } from "../../store/slices/paymentInstruments-slice";
import { useTracking } from "../../tracking";
import useBankLinkCallbacks from "./useBankLinkCallbacks";
import useDetermineTellerEnvironment from "./useDetermineTellerEnvironment";

// Require payment instrument fix, payment instrument source,
// and teller enrollment ID comes from `claimedDetails` in Checkout
// and the payment instrument data in User Portal.

type UseInitiateTellerConnect = () => TellerConnectInstance | null;

const clearEmptyKeys = (obj: Record<string, string | object | undefined>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

const toTellerConnectEvent = (data: TellerConnectEvent) =>
  clearEmptyKeys({
    ip: data.ip,
    institution: data.institution,
    params: toTellerConnectParams(data.params),
    view: data.view,
    enrollment_id: data.enrollment_id,
    input: data.input,
    value: data.value,
    field: data.field,
    item: data.item,
    session_id: data.session_id,
    timestamp: data.timestamp,
  });

const toTellerConnectParams = (params?: TellerConnectEventParams) => {
  if (!params) return undefined;
  return clearEmptyKeys({
    institution: params.institution,
    application_id: params.application_id,
    environment: params.environment,
    loader_id: params.loader_id,
    origin: params.origin,
    select_account: params.select_account,
    skip_picker: params.skip_picker,
  });
};

const useInitiateTellerConnect: UseInitiateTellerConnect = () => {
  const { tellerAppId } = useBankLinkingOptions();
  const paymentInstrument = useSelector(selectPaymentInstrument);
  const reconnectingPaymentInstrument = useSelector(
    selectIsReconnectingPaymentInstrument
  );
  const [tellerConnect, setTellerConnect] =
    useState<TellerConnectInstance | null>(null);
  const { mutate: createPaymentInstrument } =
    useCreateTellerPaymentInstrument();
  const { handleBankLinkingError, linkBank } = useBankLinkCallbacks({
    connector: "teller",
  });
  const { trackEvent, trackError } = useTracking();
  const dispatch = useDispatch();

  /*
    1. Get the Teller environment
  */
  const tellerEnvironment = useDetermineTellerEnvironment();

  /*
   2. Define the success and failure callbacks
  */
  const handleOnTellerSuccess = useCallback(
    async (tellerEnrollment: TellerEnrollmentObject) => {
      const createInstrument = async (): Promise<boolean> => {
        try {
          const paymentInstrumentData: CreatePaymentInstrumentResponseData =
            await createPaymentInstrument({
              access_token: tellerEnrollment.accessToken,
              teller_enrollment_id: tellerEnrollment.enrollment.id,
              teller_user_id: tellerEnrollment.user.id,
            });

          dispatch(
            paymentInstrumentsActions.manualSet([paymentInstrumentData])
          );

          trackEvent("Teller Connect Success", {
            bank: tellerEnrollment.enrollment.institution.name,
          });
          trackEvent("Payment method connected successfully");
          return true;
        } catch (rawError) {
          handleBankLinkingError(rawError);
          return false;
        }
      };

      // 1. Set loading screen
      dispatch(setIsProcessingLinkUpdate(true));

      // 2. Either reconnect or create a new payment instrument
      await linkBank(reconnectingPaymentInstrument, createInstrument);
    },
    [
      dispatch,
      linkBank,
      reconnectingPaymentInstrument,
      trackEvent,
      createPaymentInstrument,
      handleBankLinkingError,
    ]
  );

  const handleOnTellerExit = useCallback(() => {
    dispatch(setTellerInstitutionId(null));
    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(closeBankModal());
    dispatch(setIsReconnectingPaymentInstrument(false));
    trackEvent("Teller Connect Exited");
  }, [dispatch, trackEvent]);

  /*
   3. Initiate Teller
  */
  useEffect(() => {
    // Ensure that the application's base HTML file includes the Teller Connect script:
    // <script src="https://cdn.teller.io/connect/connect.js"></script>

    // If the TellerConnect script didn't load properly, calling .setup will fail, so
    // check to see if it exists and if it doesn't, turn Teller off (note we have only seen
    // this happen a couple times ever)

    const teller = (window as unknown as TellerWindow).TellerConnect;
    if (!teller) {
      // TODO: Should we track this if it happens?
      return;
    }

    const getTellerEnrollmentId = () => {
      if (
        reconnectingPaymentInstrument &&
        paymentInstrument?.source === "teller_instant_auth" &&
        // Note in user portal only we ask users to fix their instrument if it is in the
        // errored state (even without the require_payment_instrument_fix flag, which is
        // returned only on claim checkout and refresh instrument)
        (paymentInstrument?.require_payment_instrument_fix ||
          paymentInstrument?.status !== "verified")
      ) {
        return paymentInstrument?.teller_enrollment_id;
      }
      // Connect a new Teller account
      return "";
    };
    const tellerConnectInstance: TellerConnectInstance | null = teller.setup({
      environment: tellerEnvironment,
      applicationId: tellerAppId,
      selectAccount: "single",
      accountFilter: {
        depository: {
          subtypes: ["checking", "savings"],
        },
      },
      onEvent: (
        name: string,
        data: TellerConnectError | TellerConnectEvent
      ) => {
        if (name === "error") {
          const errorData = data as TellerConnectError;
          trackError("useInitiateTellerConnect", `Teller Connect ${name}`, {
            institution: errorData.institution,
            message: errorData.message,
            type: errorData.type,
            session_id: errorData.session_id,
            timestamp: errorData.timestamp,
            ...(errorData.view && { view: errorData.view }),
          });
        } else {
          trackEvent(
            `Teller Connect ${name}`,
            toTellerConnectEvent(data as TellerConnectEvent)
          );
        }
      },
      onSuccess: (tellerEnrollment: TellerEnrollmentObject) => {
        void handleOnTellerSuccess(tellerEnrollment);
      },
      onExit: () => {
        handleOnTellerExit();
      },
      onFailure: (failure: TellerFailure) => {
        trackError("useInitiateTellerConnect", "Teller loading error", {
          tellerError: failure,
        });
      },
      enrollmentId: getTellerEnrollmentId(),
    });

    setTellerConnect(tellerConnectInstance);
  }, [
    paymentInstrument,
    handleOnTellerExit,
    handleOnTellerSuccess,
    reconnectingPaymentInstrument,
    tellerEnvironment,
    tellerAppId,
    trackEvent,
    trackError,
  ]);

  /*
    4. Return the Teller Connect instance
  */
  return tellerConnect;
};

export default useInitiateTellerConnect;
