import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  UpdateUserRequestData,
  UserLatestAddressResponseData,
  UserQueryParams,
  UserResponseData,
} from "./types/users-types";

const useUpdateCurrentUser = (options?: { queryParams?: UserQueryParams }) =>
  useMutate<
    UserResponseData,
    ErrorResponseData,
    UserQueryParams | undefined,
    UpdateUserRequestData,
    unknown
  >({
    ...options,
    verb: "PUT",
    path: "/users-svc/current_user",
  });

const useGetCurrentUser = (
  options: { queryParams?: UserQueryParams; lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<
    UserResponseData,
    ErrorResponseData,
    UserQueryParams | undefined,
    undefined
  >({
    ...options,
    path: "users-svc/current_user",
  });

const useGetUserLatestBillingAddress = (options?: { lazy?: boolean }) =>
  useGet<
    UserLatestAddressResponseData,
    ErrorResponseData,
    undefined,
    undefined
  >({
    path: "transactions-svc/user_data/latest_checkout_billing_address",
    lazy: options?.lazy,
  });

export {
  useGetCurrentUser,
  useGetUserLatestBillingAddress,
  useUpdateCurrentUser,
};
