import React, { useState } from "react";

import { Search as SearchIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import Search from "./Search";

const MobileSearch: React.VFC = () => {
  const [show, setShow] = useState(false);

  return (
    <Box>
      <IconButton
        aria-label="Open search"
        onClick={() => setShow(true)}
        sx={({ palette }) => ({
          background: palette.grey[200],
          "&:hover": {
            background: palette.grey[300],
          },
          "&.MuiIconButton-sizeMedium": {
            width: 52,
            height: 52,
          },
        })}
      >
        <SearchIcon />
      </IconButton>

      {show && (
        <Box
          display="flex"
          alignItems="center"
          sx={({ palette }) => ({
            background: palette.common.white,
            px: 4,
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          })}
        >
          <Search onClose={() => setShow(false)} />
        </Box>
      )}
    </Box>
  );
};

export default MobileSearch;
