import { createSelector } from "@reduxjs/toolkit";

import { BankLinkingState } from "../slices/bankLinking-slice";

type RootState = {
  bankLinking: BankLinkingState;
};

const selectBankLinkingStep = createSelector(
  (state: RootState) => state.bankLinking.step,
  (step) => step
);

const selectConnectBankVariant = createSelector(
  (state: RootState) => state.bankLinking.connectBankVariant,
  (connectBankVariant) => connectBankVariant
);

const selectBankModalOpen = createSelector(
  (state: RootState) => state.bankLinking.bankModalOpen,
  (bankModalOpen) => bankModalOpen
);

const selectIsProcessingLinkUpdate = createSelector(
  (state: RootState) => state.bankLinking.isProcessingLinkUpdate,
  (isProcessingLinkUpdate) => isProcessingLinkUpdate
);

const selectIsReconnectingPaymentInstrument = createSelector(
  (state: RootState) => state.bankLinking.isReconnectingPaymentInstrument,
  (isReconnectingPaymentInstrument) => isReconnectingPaymentInstrument
);

const selectPlaidOpenAllowed = createSelector(
  (state: RootState) => state.bankLinking.plaidOpenAllowed,
  (plaidOpenAllowed) => plaidOpenAllowed
);

const selectPlaidCustomLinkName = createSelector(
  (state: RootState) => state.bankLinking.plaidCustomLinkName,
  (plaidCustomLinkName) => plaidCustomLinkName
);

const selectTellerInstitutionId = createSelector(
  (state: RootState) => state.bankLinking.tellerInstitutionId,
  (tellerInstitutionId) => tellerInstitutionId
);

const selectDisableDebit = createSelector(
  (state: RootState) => state.bankLinking.disableDebit,
  (disableDebit) => disableDebit
);

const selectAdyenSession = createSelector(
  (state: RootState) => state.bankLinking.adyenSession,
  (adyenSession) => adyenSession
);

const selectAdyenReloadNeeded = createSelector(
  (state: RootState) => state.bankLinking.adyenReloadNeeded,
  (adyenReloadNeeded) => adyenReloadNeeded
);

const selectCurrentAdyenCardDetails = createSelector(
  (state: RootState) => state.bankLinking.currentAdyenCardDetails,
  (currentAdyenCardDetails) => currentAdyenCardDetails
);

const selectHideAdyenModal = createSelector(
  (state: RootState) => state.bankLinking.hideAdyenModal,
  (hideAdyenModal) => hideAdyenModal
);

const selectCardVerificationError = createSelector(
  (state: RootState) => state.bankLinking.cardVerificationError,
  (cardVerificationError) => cardVerificationError
);

const selectShowZipCodeError = createSelector(
  (state: RootState) => state.bankLinking.showZipCodeError,
  (showZipCodeError) => showZipCodeError
);

const selectIsZipCodeValid = createSelector(
  (state: RootState) => state.bankLinking.isZipCodeValid,
  (isZipCodeValid) => isZipCodeValid
);

const selectIsDebitSecureInputValid = createSelector(
  (state: RootState) => state.bankLinking.isDebitSecureInputValid,
  (isDebitSecureInputValid) => isDebitSecureInputValid
);

const selectCardBrand = createSelector(
  (state: RootState) => state.bankLinking.cardBrand,
  (cardBrand) => cardBrand
);

const selectcardFieldValidity = createSelector(
  (state: RootState) => state.bankLinking.cardFieldValidity,
  (cardFieldValidity) => cardFieldValidity
);

const selectDisableAch = (state: RootState) => state.bankLinking.disableAch;

const selectIsCatchPassFlow = (state: RootState) =>
  state.bankLinking.isCatchPassFlow;

export {
  selectAdyenReloadNeeded,
  selectAdyenSession,
  selectBankLinkingStep,
  selectBankModalOpen,
  selectCardBrand,
  selectcardFieldValidity,
  selectCardVerificationError,
  selectConnectBankVariant,
  selectCurrentAdyenCardDetails,
  selectDisableAch,
  selectDisableDebit,
  selectHideAdyenModal,
  selectIsCatchPassFlow,
  selectIsDebitSecureInputValid,
  selectIsProcessingLinkUpdate,
  selectIsReconnectingPaymentInstrument,
  selectIsZipCodeValid,
  selectPlaidCustomLinkName,
  selectPlaidOpenAllowed,
  selectShowZipCodeError,
  selectTellerInstitutionId,
};
