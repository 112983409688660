import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useBankLinkingOptions } from "../../contexts/BankLinkingContext";
import {
  useReconnectPlaidPaymentInstrument,
  useReconnectTellerPaymentInstrument,
  useRefreshPaymentInstrument,
} from "../../services";
import { convertRawServiceError } from "../../services/error-handling";
import { ErrorResponseData } from "../../services/types/error-handling-types";
import { selectPaymentInstrument } from "../../store/selectors";
import {
  closeBankModal,
  openBankModal,
  resetConnectionState,
  setAdyenReloadNeeded,
  setBankLinkingStep,
  setCardVerificationError,
  setConnectBankVariant,
  setPlaidOpenAllowed,
} from "../../store/slices/bankLinking-slice";
import { paymentInstrumentsActions } from "../../store/slices/paymentInstruments-slice";
import { useTracking } from "../../tracking";

type BankLinkConnector = "plaid" | "teller" | "adyen_card";

type UseBankLinkCallbackMethods = {
  handleBankLinkingError: (rawError: unknown) => void;
  handleCardLinkingError: (rawError: unknown) => void;
  handleCardAuthorizationError: (errorData: ErrorResponseData) => boolean;
  linkBank: (
    reconnectingPaymentInstrument: boolean,
    createInstrument: () => Promise<boolean>
  ) => Promise<void>;
  refreshInstrument: () => Promise<void>;
  notifyInsufficientFundsAndReconnect: () => void;
};

type UseBankLinkCallbacksOptions = {
  connector: BankLinkConnector;
};

const useBankLinkCallbacks = ({
  connector,
}: UseBankLinkCallbacksOptions): UseBankLinkCallbackMethods => {
  const { patchCheckoutData, checkoutId, bus } = useBankLinkingOptions();
  const paymentInstrument = useSelector(selectPaymentInstrument);
  const { mutate: refreshPaymentInstrument } = useRefreshPaymentInstrument();
  const { mutate: reconnectPlaidPaymentInstrument } =
    useReconnectPlaidPaymentInstrument();
  const { mutate: reconnectTellerPaymentInstrument } =
    useReconnectTellerPaymentInstrument();
  const dispatch = useDispatch();
  const { captureException, trackError, trackEvent } = useTracking();

  const resetBankConnectionState = useCallback(() => {
    dispatch(resetConnectionState());
  }, [dispatch]);

  const handleBankLinkingError = useCallback(
    (rawError: unknown) => {
      const errorData = convertRawServiceError(rawError);
      let erroredOn = "Enrolling user's bank account";

      if (!errorData.error_type) {
        captureException({
          component: "BankLink",
          exceptionMessage: "Unknown issue enrolling bank",
          rawError,
          additionalData: {
            connector,
          },
        });
      }

      const rawErrorMessage = rawError as { message: string };
      if (errorData.error_type === "FORBIDDEN_BANK_ACCOUNT_IN_USE") {
        erroredOn = `${erroredOn}: Too many accounts connected`;
        dispatch(setConnectBankVariant("ErrorExistingAccount"));
      } else if (errorData.error_type === "FORBIDDEN_INVALID_BANK_ACCOUNT") {
        erroredOn = `${erroredOn}: Forbidden/invalid bank account`;
        dispatch(setConnectBankVariant("ErrorIncompatibleAccount"));
      } else if (rawErrorMessage.message.includes("Failed to fetch")) {
        // Don't fallback to the retry modal if it was a "Failed to fetch" error
        trackEvent("Aborting redirect to retry bank link modal");
        return;
        // For any other bank linking service errors, fallback to the try again modal.
      } else {
        erroredOn = `(Service error) ${erroredOn}`;
        dispatch(setConnectBankVariant("Retry"));
      }

      // Track any of the above.
      trackError("BankLink", erroredOn, {
        connector,
        errorData,
      });

      dispatch(openBankModal());
      dispatch(setBankLinkingStep("ConnectBank"));
      resetBankConnectionState();
    },
    [
      captureException,
      connector,
      dispatch,
      resetBankConnectionState,
      trackError,
      trackEvent,
    ]
  );

  const notifyInsufficientFundsAndReconnect = useCallback(() => {
    // The user's payment instrument doesn't have enough funds for a purchase. We want to
    // open the insufficient funds modal and potentially have them link another instrument.
    resetBankConnectionState();
    dispatch(openBankModal());
    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(setConnectBankVariant("ErrorInsufficientFunds"));
  }, [dispatch, resetBankConnectionState]);

  const handleCardAuthorizationError = useCallback(
    (errorData: ErrorResponseData): boolean => {
      let erroredOn = "";
      let errorHandled = false;

      if (
        // Returned from Adyen
        errorData.error_type === "CVC_DECLINED" ||
        errorData.error_type === "INCORRECT_ADDRESS" ||
        // Returned from the risk service
        errorData.error_type === "DEBIT_CARD_ZIP_CODE_MISMATCH"
      ) {
        switch (errorData.error_type) {
          case "CVC_DECLINED":
            erroredOn = `${erroredOn}: CVC Declined`;
            dispatch(setCardVerificationError("Cvc"));
            break;
          case "DEBIT_CARD_ZIP_CODE_MISMATCH":
            erroredOn = `${erroredOn}: Risk-svc zip mismatch`;
            dispatch(setCardVerificationError("Address"));
            break;
          case "INCORRECT_ADDRESS":
            erroredOn = `${erroredOn}: Incorrect addresss`;
            dispatch(setCardVerificationError("Address"));
            break;
        }
        dispatch(setConnectBankVariant("ErrorCardVerificationFailed"));
        errorHandled = true;
      } else if (errorData.error_type === "CARD_EXPIRED") {
        erroredOn = `${erroredOn}: Linked card expired`;
        dispatch(setConnectBankVariant("ErrorExpiredLinkedCard"));
        errorHandled = true;
      }

      // Track any of the above (note this replaces the previous event used
      // "(Service error) Confirming checkout")
      if (errorHandled) {
        trackError("Confirm Checkout: Card Authorization Error", erroredOn, {
          connector,
          errorData,
        });

        resetBankConnectionState();
        dispatch(openBankModal());
        dispatch(setBankLinkingStep("ConnectBank"));
      }

      return errorHandled;
    },
    [connector, dispatch, resetBankConnectionState, trackError]
  );

  const handleCardLinkingError = useCallback(
    (rawError: unknown) => {
      const errorData = convertRawServiceError(rawError);
      let erroredOn = "Connecting user's card";

      if (!errorData || !errorData.error_type) {
        captureException({
          component: "ConnectCard",
          exceptionMessage: "Unknown issue connecting card",
          rawError,
          additionalData: {
            connector,
          },
        });
      }

      let reloadNeeded = true;
      const rawErrorMessage = rawError as { message: string };
      if (errorData.error_type === "NOT_DEBIT_CARD") {
        erroredOn = `${erroredOn}: Not debit card`;
        dispatch(setConnectBankVariant("ErrorAdyenCardNotDebit"));
      } else if (errorData.error_type === "ADYEN_ERROR") {
        erroredOn = `${erroredOn}: Adyen error`;
        dispatch(setConnectBankVariant("ErrorCardCannotConnect"));
      } else if (
        errorData.error_type === "INELIGIBLE_PAYMENT_INSTRUMENT_COUNTRY"
      ) {
        dispatch(setConnectBankVariant("ErrorCardNotUS"));
      } else if (
        errorData.error_type === "CVC_DECLINED" ||
        errorData.error_type === "CARD_EXPIRED" ||
        errorData.error_type === "INCORRECT_ADDRESS"
      ) {
        switch (errorData.error_type) {
          case "CVC_DECLINED":
            erroredOn = `${erroredOn}: CVC Declined`;
            dispatch(setCardVerificationError("Cvc"));
            break;
          case "CARD_EXPIRED":
            erroredOn = `${erroredOn}: Card expired`;
            dispatch(setCardVerificationError("ExpirationDate"));
            break;
          case "INCORRECT_ADDRESS":
            erroredOn = `${erroredOn}: Incorrect addresss`;
            dispatch(setCardVerificationError("Address"));
            break;
        }
        dispatch(setConnectBankVariant("ErrorCardVerificationFailed"));
        reloadNeeded = false;
      } else if (errorData.error_type === "UNSUPPORTED_CARD_BRAND") {
        erroredOn = `${erroredOn}: Unsupported card type`;
        dispatch(setConnectBankVariant("ErrorUnsupportedCardBrand"));
      } else if (errorData.error_type === "INSUFFICIENT_FUNDS") {
        erroredOn = `${erroredOn}: Insufficient funds`;
        dispatch(setConnectBankVariant("ErrorInsufficientFunds"));
      } else if (rawErrorMessage.message.includes("Failed to fetch")) {
        // Don't fallback to the retry modal if it was a "Failed to fetch" error
        trackEvent("Aborting redirect to retry bank link modal");
        return;
        // For any other card service errors, fallback to the try again modal.
      } else {
        erroredOn = `(Service error) ${erroredOn}`;
        dispatch(setConnectBankVariant("Retry"));
      }

      // Track any of the above.
      trackError("CardConnect", erroredOn, {
        connector,
        errorData,
      });

      dispatch(openBankModal());
      dispatch(setBankLinkingStep("ConnectBank"));
      resetBankConnectionState();
      dispatch(setAdyenReloadNeeded(reloadNeeded));
    },
    [
      captureException,
      connector,
      dispatch,
      resetBankConnectionState,
      trackError,
      trackEvent,
    ]
  );

  const refreshInstrument = useCallback(async () => {
    if (!checkoutId || !patchCheckoutData) {
      // Cannot and do not need to refresh outside of checkout.
      resetBankConnectionState();
      dispatch(closeBankModal());
      return;
    }
    try {
      const refreshedPaymentInstrumentData = await refreshPaymentInstrument(
        undefined,
        {
          pathParams: { checkoutId },
        }
      );
      patchCheckoutData(refreshedPaymentInstrumentData);
      dispatch(
        paymentInstrumentsActions.manualSet([
          refreshedPaymentInstrumentData.payment_instrument,
        ])
      );
      // Open the insufficient funds modal (this is the only case of an "error"
      // where the instrument still is successfully created)
      // TODO(CS-649): Delete when payment instrument balance is no longer checked in refreshPaymentInstrument
      if (
        !refreshedPaymentInstrumentData?.payment_instrument_sufficient_balance
      ) {
        notifyInsufficientFundsAndReconnect();
      } else {
        resetBankConnectionState();
        dispatch(closeBankModal());
      }

      bus?.emit("BANK_LINK_UPDATE_RECEIVED");
    } catch (rawError) {
      handleBankLinkingError(rawError);
    }
  }, [
    checkoutId,
    dispatch,
    patchCheckoutData,
    handleBankLinkingError,
    notifyInsufficientFundsAndReconnect,
    refreshPaymentInstrument,
    resetBankConnectionState,
    bus,
  ]);

  const reconnectInstrument = useCallback(async (): Promise<boolean> => {
    if (!paymentInstrument || !paymentInstrument?.payment_instrument_id) {
      return false;
    }

    const paymentInstrumentId = paymentInstrument.payment_instrument_id;

    try {
      let reconnectedPaymentInstrumentData;
      if (connector === "plaid") {
        reconnectedPaymentInstrumentData =
          await reconnectPlaidPaymentInstrument(undefined, {
            pathParams: {
              paymentInstrumentId,
            },
          });
      } else if (connector === "teller") {
        reconnectedPaymentInstrumentData =
          await reconnectTellerPaymentInstrument(undefined, {
            pathParams: {
              paymentInstrumentId,
            },
          });
      }
      if (reconnectedPaymentInstrumentData) {
        dispatch(
          paymentInstrumentsActions.manualSet([
            reconnectedPaymentInstrumentData,
          ])
        );
        return true;
      }

      // If for whatever reason the reconnect calls failed to populate the reconnectedPaymentInstrumentData variable but it didn't get caught
      return false;
    } catch (rawError) {
      handleBankLinkingError(rawError);
      return false;
    }
  }, [
    paymentInstrument,
    connector,
    handleBankLinkingError,
    reconnectPlaidPaymentInstrument,
    reconnectTellerPaymentInstrument,
    dispatch,
  ]);

  const linkBank = useCallback(
    async (
      reconnectingPaymentInstrument: boolean,
      createInstrument: () => Promise<boolean>
    ): Promise<void> => {
      if (reconnectingPaymentInstrument) {
        const shouldContinue = await reconnectInstrument();
        if (!shouldContinue) {
          resetBankConnectionState();
          return;
        }
        // Refresh & set step.
        await refreshInstrument();
      } else {
        // Create a new payment instrument, then refresh.
        const shouldContinue = await createInstrument();
        dispatch(setPlaidOpenAllowed(false));

        if (!shouldContinue) {
          resetBankConnectionState();
          return;
        }
        // Refresh & set step.
        await refreshInstrument();
      }
    },
    [reconnectInstrument, dispatch, refreshInstrument, resetBankConnectionState]
  );

  return useMemo(
    () => ({
      handleBankLinkingError,
      handleCardLinkingError,
      handleCardAuthorizationError,
      linkBank,
      notifyInsufficientFundsAndReconnect,
      refreshInstrument,
    }),
    [
      handleBankLinkingError,
      handleCardLinkingError,
      handleCardAuthorizationError,
      linkBank,
      notifyInsufficientFundsAndReconnect,
      refreshInstrument,
    ]
  );
};

export default useBankLinkCallbacks;
