import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTrackPageView } from "~common/tracking";
import CatchCardBanner from "~src/components/catch-card/CatchCardBanner";
import {
  ActivityFeedSection,
  ForTheCuriousSection,
  HighlightsSection,
  MyCreditsSection,
  RewardClaimedModal,
  WhatNextSection,
} from "~src/components/home";
import GetStartedSection from "~src/components/home/get-started/GetStartedSection";
import ReturnToMerchantModal from "~src/components/home/get-started/ReturnToMerchantModal";
import MyExchangeSection from "~src/components/home/MyExchangeSection";
import RecommendedMerchantsSection from "~src/components/home/RecommendedMerchantsSection";
import LargePagePanel from "~src/components/layout/LargePagePanel";
import useFetchAnywhereCardForUser from "~src/hooks/fetching/useFetchAnywhereCardForUser";
import useFetchNuxs from "~src/hooks/fetching/useFetchNuxs";
import useUserData from "~src/hooks/services/useUserData";
import useUserRewards from "~src/hooks/services/useUserRewards";
import useClaimRewards from "~src/hooks/useClaimRewards";
import { selectCatchCard, selectClaims, selectCurrentUser } from "~src/store";
import {
  setClaimedCampaign,
  setClaimedCampaignId,
} from "~src/store/slices/user-slice";

import HomepageNuxs from "../components/home/HomepageNuxs";

const Home: React.VFC = () => {
  useTrackPageView("Home");
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser.data);
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const catchCard = useSelector(selectCatchCard);

  // Request child data upfront to load faster in parallel.
  const { userData, loading: loadingUserData } = useUserData();
  const { loading: loadingUserRewards } = useUserRewards();
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  useFetchAnywhereCardForUser();
  useFetchNuxs();

  // Claim any new rewards the user has claimed through links.
  useClaimRewards();

  useEffect(() => {
    if (
      newUserOnboardingRewardCampaign &&
      !newUserOnboardingRewardCampaign.claimed &&
      catchCard?.user_flow_status === "approved"
    ) {
      dispatch(
        setClaimedCampaignId(newUserOnboardingRewardCampaign.reward_campaign_id)
      );
      dispatch(
        setClaimedCampaign({
          ...newUserOnboardingRewardCampaign,
          is_second_chance_bonus: true,
        })
      );
    }
  }, [dispatch, newUserOnboardingRewardCampaign, catchCard]);

  useEffect(() => {
    if (!loadingUserData && !loadingUserRewards) {
      setPageLoading(false);
    }
  }, [loadingUserData, loadingUserRewards]);

  const shouldRenderForTheCurious =
    !pageLoading && userData?.has_redeemed_rewards;
  const shouldRenderGetStarted = !pageLoading && !shouldRenderForTheCurious;

  return (
    <>
      <HomepageNuxs pageLoading={pageLoading} />
      <ReturnToMerchantModal />
      <RewardClaimedModal />
      {shouldRenderGetStarted && <GetStartedSection />}

      <LargePagePanel width="medium">
        {currentUser?.is_eligible_for_catch_pass && <CatchCardBanner />}
        <HighlightsSection pageLoading={pageLoading} />
        <MyCreditsSection pageLoading={pageLoading} />
        {currentUser?.is_employee && (
          <RecommendedMerchantsSection pageLoading={pageLoading} />
        )}
        <MyExchangeSection pageLoading={pageLoading} />
        <WhatNextSection pageLoading={pageLoading} />
        <ActivityFeedSection pageLoading={pageLoading} />
        {shouldRenderForTheCurious && <ForTheCuriousSection />}
      </LargePagePanel>
    </>
  );
};

export default Home;
