import React from "react";

import { Stack } from "@mui/material";

import WrappedSkeleton from "../loading/WrappedSkeleton";

const HighlightIconSkeleton: React.VFC = () => (
  <Stack
    alignItems="center"
    spacing={2}
    sx={{
      width: 104,
      display: "inline-flex",
    }}
  >
    <WrappedSkeleton
      variant="circular"
      sx={{
        width: 90,
        height: 90,
      }}
    />

    <Stack alignItems="center">
      <WrappedSkeleton
        variant="text"
        sx={{
          width: 64,
          height: 20,
        }}
      />
      <WrappedSkeleton
        variant="text"
        sx={{
          width: 90,
          height: 20,
        }}
      />
    </Stack>
  </Stack>
);

export default HighlightIconSkeleton;
