import React from "react";
import { useIntercom } from "react-use-intercom";

import { Box, Typography } from "@mui/material";

import InlineTextButtonSmall from "../controls/buttons/InlineTextButtonSmall";
import ExternalTextLink from "../links/ExternalTextLink";

const WrongEmailHelp: React.VFC = () => {
  const intercom = useIntercom();

  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        variant="bodySmall"
        color="grey.500"
        sx={{
          a: {
            typography: "linkSmall",
          },
        }}
      >
        Did you enter the wrong email?
        <br />
        Reach us via{" "}
        <InlineTextButtonSmall
          sx={{
            "&.MuiButton-root.MuiButton-text .label": { color: "inherit" },
          }}
          onClick={intercom.show}
        >
          live chat support
        </InlineTextButtonSmall>{" "}
        or email at{" "}
        <ExternalTextLink
          size="small"
          href="mailto:help@getcatch.com"
          color="grey.500"
          eventName="Help MailTo Link Clicked"
          componentName="VerifyEmail"
        >
          help@getcatch.com
        </ExternalTextLink>{" "}
        so we can help you update your information.
      </Typography>
    </Box>
  );
};

export default WrongEmailHelp;
