import React from "react";

import { Box } from "@mui/material";

import { useMerchantAssetsUrl } from "~common/hooks/asset-hooks";
import { Merchant } from "~common/services";

type MerchantMarqueeBadgeLogoProps = {
  merchant: Merchant;
  diameter: number | string;
};

const MerchantMarqueeBadgeLogo: React.VFC<MerchantMarqueeBadgeLogoProps> = ({
  merchant,
  diameter,
}) => {
  const merchantAssetsUrl = useMerchantAssetsUrl(merchant.merchant_id);
  const badgeUrl = `${merchantAssetsUrl}/shop_badge.png`;

  return (
    <Box
      sx={{
        height: diameter,
        width: diameter,
        borderRadius: "100%",
        overflow: "hidden",
        img: {
          height: diameter,
          width: diameter,
        },
      }}
    >
      <img src={badgeUrl} alt={`${merchant.name} logo`} />
    </Box>
  );
};

export default MerchantMarqueeBadgeLogo;
