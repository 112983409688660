import { selectMerchants } from "~src/store";
import { merchantsActions } from "~src/store/slices/services/merchants-slice";

import useLazyService from "./useLazyService";

const useMerchants = () => {
  const {
    data: merchants,
    loading,
    error,
  } = useLazyService(selectMerchants, merchantsActions);

  return { merchants, loading, error };
};

export default useMerchants;
