import React, { useCallback, useState } from "react";

import { Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import SimpleMultiselect from "~common/components/inputs/SimpleMultiselect";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import { DialogBody, DialogHeader } from "~common/components/modals";
import { Reward } from "~common/services";
import { formatMoney } from "~common/utils/money";
import useUserRewards from "~src/hooks/services/useUserRewards";

type ChooseCreditToGiftModalContentProps = {
  onContinue: (selectedCredits: Reward[]) => void;
  dialogId: string;
  dialogDescriptionId: string;
};

const ChooseCreditToGiftModalContent: React.VFC<
  ChooseCreditToGiftModalContentProps
> = ({ onContinue, dialogId, dialogDescriptionId }) => {
  const { userRewards, loading, error } = useUserRewards();
  const [selectedRewardIndices, setSelectedIndices] = useState<number[]>([]);

  const handleContinueClick = useCallback(() => {
    onContinue(selectedRewardIndices.map((idx) => userRewards[idx]));
  }, [userRewards, selectedRewardIndices, onContinue]);

  return (
    <Stack spacing={4}>
      <DialogHeader id={dialogId} variant="h1">
        Select credits to gift
      </DialogHeader>
      <DialogBody id={dialogDescriptionId}>
        The first person to claim the credits will get them. The credits are
        still available to you in the meantime. 🏆
      </DialogBody>
      <Stack sx={{ height: 120, overflowY: "scroll" }}>
        <LoadingGuard loading={loading} {...{ error }}>
          {userRewards.length <= 0 ? (
            <Typography color="grey.500" sx={{ alignSelf: "center" }}>
              No available rewards to gift.
            </Typography>
          ) : (
            <SimpleMultiselect
              options={userRewards.map(
                ({ amount, merchant_name }) =>
                  `${formatMoney(amount)} ${merchant_name || "flex"} credit`
              )}
              selectedIndices={selectedRewardIndices}
              onSelectionChange={setSelectedIndices}
            />
          )}
        </LoadingGuard>
      </Stack>
      <PrimaryButton
        disabled={selectedRewardIndices.length <= 0}
        onClick={handleContinueClick}
      >
        Continue
      </PrimaryButton>
    </Stack>
  );
};

export default ChooseCreditToGiftModalContent;
