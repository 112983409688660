import React from "react";
import { useSelector } from "react-redux";

import { Box, BoxProps } from "@mui/material";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";
import LabeledSection from "~src/components/layout/LabeledSection";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import useMerchants from "~src/hooks/services/useMerchants";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectDisableDebit } from "~src/store";

import WrappedBankLinkManager from "../bank/WrappedBankLinkManager";
import BonusCreditShopPrompt from "./BonusCreditShopPrompt";
import LinkBankPrompt from "./LinkBankPrompt";
import LinkPaymentMethodPrompt from "./LinkPaymentMethodPrompt";
import ReferralPrompt from "./ReferralPrompt";
import SmsRemindersPrompt from "./SmsRemindersPrompt";

type WhatNextSectionProps = BoxProps & {
  pageLoading: boolean;
};

const WhatNextSection: React.VFC<WhatNextSectionProps> = ({ pageLoading }) => {
  const { loading: loadingMerchants, error } = useMerchants();
  const { paymentInstruments, loading: loadingPaymentInstruments } =
    usePaymentInstruments();
  const { currentUser, loading: loadingCurrentUser } = useCurrentUser();
  const disableDebit = useSelector(selectDisableDebit);
  const loading =
    loadingMerchants || loadingPaymentInstruments || loadingCurrentUser;
  const showLinkPrompt =
    paymentInstruments.length === 0 && !currentUser?.is_eligible_for_catch_pass;
  const showLinkBankPrompt = showLinkPrompt && disableDebit;
  const showLinkPaymentMethodPrompt = showLinkPrompt && !showLinkBankPrompt;
  const showSmsRemindersPrompt =
    !showLinkPrompt && currentUser?.sms_credit_reminders_opt_in === null;

  // We only ever want to show two prompts so this creates an array of valid
  // options, in order of importance, then slices the first two
  const prompts = [
    ...(showLinkBankPrompt
      ? [
          <WrappedBankLinkManager>
            <LinkBankPrompt key="linkBank" />
          </WrappedBankLinkManager>,
        ]
      : []),
    ...(showLinkPaymentMethodPrompt
      ? [
          <WrappedBankLinkManager>
            <LinkPaymentMethodPrompt key="linkPayment" />
          </WrappedBankLinkManager>,
        ]
      : []),
    ...(showSmsRemindersPrompt
      ? [<SmsRemindersPrompt key="smsReminders" />]
      : []),
    ...[<BonusCreditShopPrompt key="bonusCredit" />],
    ...(!showLinkPrompt ? [<ReferralPrompt key="referral" />] : []),
  ].slice(0, 2);

  return (
    <LabeledSection heading="What's next?" loading={pageLoading}>
      <LoadingGuard {...{ error }}>
        <Box
          sx={({ breakpoints }) => ({
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 6,
            [breakpoints.up("md")]: {
              gridTemplateColumns: "repeat(2,1fr)",
            },
          })}
        >
          {pageLoading || loading ? (
            <>
              <WrappedSkeleton
                variant="rectangular"
                sx={{ height: 300, borderRadius: 2 }}
              />
              <WrappedSkeleton
                variant="rectangular"
                sx={{ height: 300, borderRadius: 2 }}
              />
            </>
          ) : (
            prompts.map((prompt) => prompt)
          )}
        </Box>
      </LoadingGuard>
    </LabeledSection>
  );
};

export default WhatNextSection;
