import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";

import BorderedBox from "~common/components/primitives/BorderedBox";
import useRewardPoolEntries from "~src/hooks/services/useRewardPoolEntries";
import useRewardPoolEvents from "~src/hooks/services/useRewardPoolEvents";
import { selectUserId } from "~src/store";

import AvailableCreditRow from "./AvailableCreditRow";
import ExchangeTableTab from "./ExchangeTableTab";
import GiftToCreditExchangeButton from "./GiftToCreditExchangeButton";
import GiftToCreditExchangeModal from "./GiftToCreditExchangeModal";
import RewardPoolEventRow from "./RewardPoolEventRow";

type TableTab = "credits" | "events";

const CreditExchangeTable: React.VFC = () => {
  const [selectedTab, setSelectedTab] = useState<TableTab>("credits");
  const [showModal, setShowModal] = useState<boolean>(false);
  const currentUserId = useSelector(selectUserId);
  const { rewardPoolEntries } = useRewardPoolEntries();
  const { rewardPoolEvents } = useRewardPoolEvents();

  const availableCreditContent = useMemo(() => {
    if (rewardPoolEntries === null) {
      return (
        <Typography color="grey.500" sx={{ alignSelf: "center" }}>
          Get into some credit pools, fool!
        </Typography>
      );
    }
    if (rewardPoolEntries.length <= 0) {
      return (
        <Typography color="grey.500" sx={{ alignSelf: "center" }}>
          Get into some better credit pools, fool!
        </Typography>
      );
    }
    return rewardPoolEntries.map((credit, idx) => (
      <AvailableCreditRow
        key={`${credit.hashed_gift_secret}-${credit.pool_id}`}
        rewardPoolEntry={credit}
        senderIsCurrentUser={currentUserId === credit.sender_id}
        divider={idx !== rewardPoolEntries.length - 1}
      />
    ));
  }, [rewardPoolEntries, currentUserId]);
  const eventsContent = useMemo(() => {
    if (rewardPoolEvents === null) {
      return (
        <Typography color="grey.500" sx={{ alignSelf: "center" }}>
          Get into some credit pools, fool!
        </Typography>
      );
    }
    const filteredEvents = rewardPoolEvents.filter(
      (rewardPoolEvent) => rewardPoolEvent.event_type === "claim"
    );

    if (filteredEvents.length <= 0) {
      return (
        <Typography color="grey.500" sx={{ alignSelf: "center" }}>
          Get into some better credit pools, fool!
        </Typography>
      );
    }
    return filteredEvents.map((rewardPoolEvent, idx) => (
      <RewardPoolEventRow
        key={`${rewardPoolEvent.create_datetime}-${rewardPoolEvent.pool_name}-${rewardPoolEvent.event_type}`}
        event={rewardPoolEvent}
        isCurrentUserEvent={currentUserId === rewardPoolEvent.user_id}
        divider={idx !== filteredEvents.length - 1}
      />
    ));
  }, [rewardPoolEvents, currentUserId]);

  return (
    <>
      <BorderedBox
        sx={({ palette }) => ({
          background: palette.common.white,
          p: 6,
        })}
      >
        <Stack direction="column">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 8 }}
          >
            <Stack direction="row" alignItems="center">
              <ExchangeTableTab
                label="Available credits"
                badgeText={
                  rewardPoolEntries?.length
                    ? `${rewardPoolEntries.length}`
                    : null
                }
                selected={selectedTab === "credits"}
                onClick={() => setSelectedTab("credits")}
              />
              <ExchangeTableTab
                label="Activity feed"
                selected={selectedTab === "events"}
                onClick={() => setSelectedTab("events")}
              />
            </Stack>
            <GiftToCreditExchangeButton onClick={() => setShowModal(true)} />
          </Stack>
          {selectedTab === "credits" ? availableCreditContent : eventsContent}
        </Stack>
      </BorderedBox>
      <GiftToCreditExchangeModal
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default CreditExchangeTable;
