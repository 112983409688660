import { Components, Theme } from "@mui/material";

const buildLink = (theme: Theme): Components["MuiLink"] => {
  const { palette } = theme;

  return {
    defaultProps: {
      color: "inherit",
      variant: "inherit",
    },
    styleOverrides: {
      root: {
        font: "inherit",
        verticalAlign: "baseline",
        "&.Mui-focusVisible": {
          outlineColor: palette.info.main,
        },
      },
    },
  };
};

export default buildLink;
