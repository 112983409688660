import React from "react";
import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { selectDisableAch } from "../../../../../store/selectors";
import { BankSyncIcon } from "../../../../icons/vector";

type PaymentInstrumentHeaderChangeMethodProps = {
  headingId?: string;
};

const PaymentInstrumentHeaderChangeMethod: React.VFC<
  PaymentInstrumentHeaderChangeMethodProps
> = ({ headingId }) => {
  const disableAch = useSelector(selectDisableAch);

  return (
    <Stack spacing={4} className="BankLinking-header">
      <BankSyncIcon size="small" />
      <Stack spacing={2}>
        <Typography variant="h2" id={headingId}>
          Change payment method
        </Typography>
        <Typography variant="bodyRegular" color="grey.600">
          Linking a new debit card {!disableAch && "or bank account"} will
          replace your current payment method.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PaymentInstrumentHeaderChangeMethod;
