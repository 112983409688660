import React from "react";

import { CardGiftcard as GiftIcon } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";

import { RewardPoolEvent } from "~common/services";

type RewardPoolEventRowProps = {
  event: RewardPoolEvent;
  isCurrentUserEvent: boolean;
  divider: boolean;
};

const RewardPoolEventRow: React.VFC<RewardPoolEventRowProps> = ({
  event,
  isCurrentUserEvent,
  divider,
}) => (
  <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
    >
      <Stack direction="row" alignItems="center" gap={4}>
        <GiftIcon sx={{ width: 24, height: 24, minWidth: 24, minHeight: 24 }} />
        <Stack>
          <Typography variant="h3">
            <strong>{isCurrentUserEvent ? "You" : event.user_name}</strong>{" "}
            <Typography variant="bodyRegular">
              claimed {event.merchant_name} credit
            </Typography>
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography variant="bodySmall">In {event.pool_name}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    {divider && <Divider sx={{ my: 4 }} />}
  </>
);

export default RewardPoolEventRow;
