import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DefaultDialog } from "~common/components/modals";
import useAppLogout from "~src/hooks/services/useAppLogout";
import { selectAlertModal } from "~src/store";
import { closeAlertModal } from "~src/store/slices/core-slice";

import Error from "../shared/Error";

const AlertModal: React.VFC = () => {
  const dispatch = useDispatch();
  const alertModal = useSelector(selectAlertModal);
  const logout = useAppLogout();

  const handleClose = () => {
    if (alertModal?.logoutOnClose) {
      void logout({ cause: "Logout Alert Modal closed" });
    }
    dispatch(closeAlertModal());
  };

  return (
    <DefaultDialog
      open={alertModal !== null}
      onClose={handleClose}
      ariaLabelledBy="alert-modal-heading"
      ariaDescribedBy="alert-modal-subheading"
    >
      <Error
        title={alertModal?.title}
        subtitle={alertModal?.subtitle}
        onClick={handleClose}
        idPrefix="alert-modal"
      />
    </DefaultDialog>
  );
};

export default AlertModal;
