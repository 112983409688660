import { useMemo } from "react";

import useMerchants from "./services/useMerchants";

const usePublicMerchantById = (id?: string) => {
  const { merchants, loading } = useMerchants();
  const merchant = useMemo(
    () => merchants.find(({ merchant_id }) => id === merchant_id),
    [id, merchants]
  );
  return { merchant, loading };
};

export default usePublicMerchantById;
