import React from "react";

import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";

type CategoryTabButtonSkeletonProps = {
  width?: number;
};

const CategoryTabButtonSkeleton: React.VFC<CategoryTabButtonSkeletonProps> = ({
  width = 80,
}) => (
  <WrappedSkeleton
    variant="rectangular"
    sx={{
      borderRadius: "20px",
      height: "40px",
      width,
    }}
  />
);

export default CategoryTabButtonSkeleton;
