import React from "react";

import { Box } from "@mui/material";

const ThickDivider: React.VFC = () => (
  <Box
    sx={({ palette }) => ({
      height: 2,
      borderRadius: 2,
      mt: 3,
      mb: 6,
      background: palette.divider,
    })}
  />
);

export default ThickDivider;
