import {
  EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX,
  StatsigUser,
} from "./experimentation-types";

interface BdcClientIpData {
  ipString: string;
  ipNumeric: number;
  ipType: string;
  isBehindProxy: boolean;
  proxyIp?: boolean;
}

const hashUserInformation = (input: string) => {
  let hash = 0;
  let chr;
  if (input.length === 0) return hash;
  for (let i = 0; i < input.length; i += 1) {
    chr = input.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
};

let statsigUser: StatsigUser = {};
let userHashedIp = "";

const storeHashedUserIpAddress = async (): Promise<string | null> => {
  // NB: we use IP address as the unique identifier for the user as it is the
  // most reliable way to track users across our app surfaces due to domain
  // differences and other restrictions with brower storage
  try {
    const response = await fetch(`https://api-bdc.net/data/client-ip`);
    if (response.ok) {
      const data = (await response.json()) as BdcClientIpData;
      userHashedIp = hashUserInformation(data.ipString).toString();
      return userHashedIp;
    }
  } catch {
    // Do nothing
  }
  return null;
};

const patchStatsigUser = (
  updatedUser: StatsigUser,
  // NB: to keep this synchronous, this parameter
  // assumes storeHashedUserIpAddress() has already
  // been called
  usePreviouslySetHashedIp?: boolean
): StatsigUser => {
  // Use the updated user ID if available, otherwise default to
  // the existing or override with the hashed IP.
  let newUserId = updatedUser.userID || statsigUser.userID;
  if (usePreviouslySetHashedIp && userHashedIp !== "") {
    newUserId = userHashedIp;
  }

  statsigUser = {
    userID: newUserId,
    customIDs: {
      ...statsigUser.customIDs,
      ...updatedUser.customIDs,
    },
    custom: {
      ...statsigUser.custom,
      ...updatedUser.custom,
    },
  };

  return statsigUser;
};

const getItemFromSessionStorage = (key: string): string | null => {
  try {
    return sessionStorage.getItem(key);
  } catch {
    // Do nothing
  }
  return null;
};

const setItemInSessionStorage = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch {
    // Do nothing
  }
};

const getExperimentFromSessionStorage = (
  experimentName: string
): string | null =>
  getItemFromSessionStorage(
    `${EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX}${experimentName}`
  );

const setExperimentInSessionStorage = (
  experimentName: string,
  experimentGroup: string
) => {
  setItemInSessionStorage(
    `${EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX}${experimentName}`,
    experimentGroup
  );
};

const getFeatureGateFromSessionStorage = (
  featureGateName: string
): boolean | null => {
  const gateValue = getItemFromSessionStorage(
    `${EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX}${featureGateName}`
  );

  if (gateValue) {
    return gateValue === "true";
  }

  return null;
};

const setFeatureGateInSessionStorage = (
  featureGateName: string,
  gateValue: boolean
) => {
  setItemInSessionStorage(
    `${EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX}${featureGateName}`,
    String(gateValue)
  );
};

export {
  getExperimentFromSessionStorage,
  getFeatureGateFromSessionStorage,
  getItemFromSessionStorage,
  hashUserInformation,
  patchStatsigUser,
  setExperimentInSessionStorage,
  setFeatureGateInSessionStorage,
  setItemInSessionStorage,
  storeHashedUserIpAddress,
};
export type { BdcClientIpData };
