import React, { forwardRef } from "react";

import WrappedDialog, { WrappedDialogProps } from "./WrappedDialog";

const DefaultDialog: React.FC<WrappedDialogProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedDialog {...props} ref={ref}>
      {children}
    </WrappedDialog>
  )
);

DefaultDialog.displayName = "DefaultDialog";

export default DefaultDialog;
