import React from "react";

import { Typography } from "@mui/material";

import { SIGN_UP_BONUS } from "~common/constants/bonuses";

const YouCanStillEarnCredit = () => (
  <Typography variant="bodyLarge">
    You can still earn an extra{" "}
    <Typography
      variant="bodyLarge"
      component="span"
      color="tertiary.contrastText"
      sx={{ fontWeight: 900 }}
    >
      {SIGN_UP_BONUS} credit
    </Typography>{" "}
    on your first purchase.
  </Typography>
);

export default YouCanStillEarnCredit;
