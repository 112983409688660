import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UseGetReturn } from "restful-react";

import { convertRawServiceError } from "~common/services/error-handling";
import { ErrorResponseData } from "~common/services/types/error-handling-types";
import { FetchState } from "~common/store/store-utils";
import { useTracking } from "~common/tracking";

const useLazyFetch = <TData, TState, TPathParams, TQueryParams, TResponseData>(
  name: string,
  actions: {
    setData: (data: TData) => { payload: TData; type: string };
    setLoading: (loading: FetchState<TData>["loading"]) => {
      payload: FetchState<TData>["loading"];
      type: string;
    };
    setError: (error: FetchState<TData>["error"]) => {
      payload: FetchState<TData>["error"];
      type: string;
    };
    setStatus: (status: FetchState<TData>["status"]) => {
      payload: FetchState<TData>["status"];
      type: string;
    };
    setFetched: (time: FetchState<TData>["fetched"]) => {
      payload: FetchState<TData>["fetched"];
      type: string;
    };
  },
  selectService: { status: (state: TState) => FetchState<TData>["status"] },
  useGetHook: () => UseGetReturn<
    TResponseData,
    ErrorResponseData,
    TQueryParams,
    TPathParams
  >,
  formatResponseData: (data: TResponseData) => TData
) => {
  const { captureException } = useTracking();
  const dispatch = useDispatch();
  const status = useSelector(selectService.status);
  const { refetch, error: serviceError } = useGetHook();

  // Fetch when requested and update state.
  useEffect(() => {
    const refresh = async (): Promise<void> => {
      dispatch(actions.setFetched(Date.now()));
      dispatch(actions.setStatus("fetched"));
      dispatch(actions.setLoading(true));

      try {
        const responseData = await refetch();

        if (responseData) {
          dispatch(actions.setData(formatResponseData(responseData)));
        }
      } catch (_error) {
        // Restful-react can't properly handle catching
        // errors on lazy GET requests yet. See the useEffect
        // below for handling.
      }
      dispatch(actions.setLoading(false));
    };

    // Refresh policy.
    if (status === "requested") {
      void refresh();
    }
  }, [dispatch, status, refetch, actions, formatResponseData]);

  // Handle service errors and update error state.
  useEffect(() => {
    let error;

    if (serviceError !== null) {
      error = convertRawServiceError(serviceError);
      captureException({
        component: `useLazyFetch`,
        exceptionMessage: `Error fetching ${name}`,
        rawError: serviceError,
      });
    } else {
      error = null;
    }

    dispatch(actions.setError(error));
  }, [dispatch, serviceError, actions, name, captureException]);
};

export default useLazyFetch;
