import { ConsumerActivity } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectConsumerActivities = makeFetchSelectors<
  { activities: ConsumerActivity[]; last_evaluated_key: string | null },
  RootState
>("consumerActivities");

export { selectConsumerActivities };
