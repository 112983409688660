import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";

type LearnMoreButtonProps = {
  eventComponent: string;
  fullWidth?: boolean;
};

const LearnMoreButton: React.VFC<LearnMoreButtonProps> = ({
  eventComponent,
  fullWidth = false,
}) => {
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent("LearnMoreButton clicked", {
      eventComponent,
    });
  };

  return (
    <DefaultButton
      endIcon={<OpenInNewIcon />}
      href="https://getcatch.com"
      target="_blank"
      fullWidth={fullWidth}
      onClick={handleClick}
    >
      <Box pl={1}>Learn more</Box>
    </DefaultButton>
  );
};

LearnMoreButton.defaultProps = {
  fullWidth: false,
};

export default LearnMoreButton;
