import { useDispatch } from "react-redux";

import { AlertModalData, openAlertModal } from "~src/store/slices/core-slice";
import { REWARD_CAMPAIGN_ERRORS } from "~src/utils/reward-campaigns";

type SetKey =
  | "linkExpired"
  | "existingUserCantClaimReferral"
  | "cantClaimOwnGift"
  | "cantClaimGiftedRewardFromAlreadyClaimedRewardCampaign"
  | "unknownErrorClaimingGift"
  | "creditCanOnlyBeClaimedOnce"
  | "creditOnlyForNewUsers"
  | "creditNoLongerAvailable"
  | "creditOnlyForNewCustomers";

const { creditOnlyForNewCustomers, ...rewardCampaignErrors } =
  REWARD_CAMPAIGN_ERRORS;

const staticAlerts: Partial<Record<SetKey, AlertModalData>> =
  rewardCampaignErrors;

const templateAlerts: Partial<
  Record<SetKey, (...args: string[]) => AlertModalData>
> = {
  creditOnlyForNewCustomers,
};

type AlertHook = (...args: string[]) => void;

const useAlertModal = () => {
  const dispatch = useDispatch();

  const staticAlertEntries: [string, AlertHook][] = Object.entries(
    staticAlerts
  ).map(([methodName, modalData]) => [
    methodName,
    () => dispatch(openAlertModal(modalData)),
  ]);

  const templateAlertEntries: [string, AlertHook][] = Object.entries(
    templateAlerts
  ).map(([methodName, modalDataTemplate]) => [
    methodName,
    (...args: string[]) => dispatch(openAlertModal(modalDataTemplate(...args))),
  ]);

  const setAlertModal = Object.fromEntries([
    ...staticAlertEntries,
    ...templateAlertEntries,
  ]) as Record<SetKey, AlertHook>;

  return setAlertModal;
};

export default useAlertModal;
