import React, { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { useUniqueId } from "../../hooks/accessibility-hooks";
import { useTracking } from "../../tracking";
import CompactButton from "../controls/buttons/CompactButton";
import PrimaryCompactButton from "../controls/buttons/PrimaryCompactButton";
import DefaultDialog from "../modals/DefaultDialog";
import DialogHeader from "../modals/DialogHeader";
import { FAQCard } from "./faq-types";

type FAQCardModalProps = {
  open: boolean;
  onClose: () => void;
  data: FAQCard;
};

const FAQCardModal: React.VFC<FAQCardModalProps> = ({
  open,
  onClose,
  data,
}) => {
  const { trackEvent } = useTracking();
  // Number of steps is zero indexed to avoid array out of bounds exceptions.
  const numberOfSteps = data.steps.length - 1;
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [openTime, setOpenTime] = useState<Date>(new Date());

  const dialogId = useUniqueId(data.id);
  const dialogDescriptionId = useUniqueId(data.id.concat("description"));

  // Set the open time
  useEffect(() => {
    setOpenTime(new Date());
  }, [open, setOpenTime]);

  const onClickNext = () => {
    if (currentStep === numberOfSteps) {
      onClose();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onClickPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const getStepsProgress = () =>
    `${((currentStep + 1) / data.steps.length) * 100}%`;

  const handleClose = () => {
    const currentTime = new Date();
    trackEvent("FAQ Modal closed", {
      question: data.title,
      percentFaqStepsCompleted: getStepsProgress(),
      totalSecondsViewed: (currentTime.getTime() - openTime.getTime()) / 1000,
    });

    onClose();
  };

  return (
    <DefaultDialog
      open={open}
      onClose={handleClose}
      ariaLabelledBy={dialogId}
      ariaDescribedBy={dialogDescriptionId}
      sx={({ shape }) => ({
        "& .MuiPaper-root.MuiDialog-paper": {
          position: "relative",
          m: "auto",
          mt: "calc(50vh - 200px)",
          borderRadius: `${shape.borderRadius * 2}px`,
          " .MuiIconButton-sizeSmall": {
            height: "inherit",
          },
        },
      })}
    >
      <Stack sx={{ width: "100%" }}>
        <DialogHeader
          id={dialogId}
          variant="h3"
          sx={{
            width: "auto",
            mt: -8,
          }}
        >
          {data.title}
        </DialogHeader>
        <Box
          sx={({ palette, shape }) => ({
            my: 6,
            backgroundColor: palette.grey[200],
            borderRadius: `${shape.borderRadius * 2}px`,
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              background: palette.primary.main,
              position: "absolute",
              height: "3px",
              borderRadius: `${shape.borderRadius / 2}px`,
              width: `calc(${getStepsProgress()})`,
            },
          })}
        >
          <Box
            id={dialogDescriptionId}
            sx={{
              p: 4,
              pt: 4.75,
            }}
          >
            {data.steps[currentStep].map((stepData) => (
              <Typography
                key={`${stepData.text}`}
                variant="bodyLarge"
                component="span"
                color={stepData.accented ? "primary.main" : "grey.700"}
              >
                {stepData.text}
              </Typography>
            ))}
          </Box>
        </Box>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <PrimaryCompactButton onClick={onClickNext} sx={{ minWidth: 100 }}>
            {currentStep === numberOfSteps ? "Got it" : "Next →"}
          </PrimaryCompactButton>
          {currentStep !== 0 && (
            <CompactButton onClick={onClickPrevious} sx={{ minWidth: 100 }}>
              Previous
            </CompactButton>
          )}
        </Stack>
      </Stack>
    </DefaultDialog>
  );
};

export default FAQCardModal;
