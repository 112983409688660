import { useGetRewardPools } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectRewardPools } from "~src/store";
import { rewardPoolsActions } from "~src/store/slices/services/rewardPools-slice";

const useGet = () => useGetRewardPools({ lazy: true });

const useFetchRewardPools = () => {
  useLazyFetch(
    "RewardPools",
    rewardPoolsActions,
    selectRewardPools,
    useGet,
    (data) => data
  );
};

export default useFetchRewardPools;
