import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  ClaimRewardCampaignPathParams,
  ClaimRewardCampaignRequestData,
  ClaimRewardCampaignResponseData,
  GetRewardCampaignPathParams,
  RewardCampaignResponseData,
} from "./types/reward-campaigns-types";

const useGetRewardCampaign = (
  options: { pathParams?: GetRewardCampaignPathParams; lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<
    RewardCampaignResponseData,
    ErrorResponseData,
    undefined,
    GetRewardCampaignPathParams
  >({
    path: ({ rewardCampaignId }) =>
      `transactions-svc/reward_campaigns/${rewardCampaignId}/public`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

const useClaimRewardCampaign = () =>
  useMutate<
    ClaimRewardCampaignResponseData,
    ErrorResponseData,
    undefined,
    ClaimRewardCampaignRequestData,
    ClaimRewardCampaignPathParams
  >({
    verb: "PUT",
    path: ({ rewardCampaignId }) =>
      `transactions-svc/reward_campaigns/${rewardCampaignId}`,
  });

export { useClaimRewardCampaign, useGetRewardCampaign };
