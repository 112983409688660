import { useMutate } from "restful-react";

import {
  AnswerOTPRequestData,
  AnswerOTPResponseData,
  AuthResponseData,
  InitiateAuthRequestData,
  InitiateAuthResponseData,
  SendEmailVerificationCodeRequestData,
  SendEmailVerificationCodeResponseData,
  VerifyEmailRequestData,
} from "./types/auth-types";
import {
  AnswerOtpErrorResponseData,
  ErrorResponseData,
} from "./types/error-handling-types";
import { UserResponseData } from "./types/users-types";

const useRefreshToken = () =>
  useMutate<AuthResponseData, ErrorResponseData, undefined, undefined, unknown>(
    {
      verb: "POST",
      path: "users-svc/refresh",
      localErrorOnly: true,
    }
  );

const useLogout = () =>
  useMutate<undefined, ErrorResponseData, undefined, undefined, unknown>({
    verb: "POST",
    path: "/users-svc/logout",
  });

const useInitiateAuth = () =>
  useMutate<
    InitiateAuthResponseData,
    ErrorResponseData,
    undefined,
    InitiateAuthRequestData,
    unknown
  >({
    verb: "POST",
    path: "users-svc/authenticate",
  });

const useAnswerOTP = () =>
  useMutate<
    AnswerOTPResponseData,
    AnswerOtpErrorResponseData,
    undefined,
    AnswerOTPRequestData,
    unknown
  >({
    verb: "POST",
    path: "users-svc/answer_otp",
  });

const useVerifyEmail = () =>
  useMutate<
    UserResponseData,
    ErrorResponseData,
    undefined,
    VerifyEmailRequestData,
    unknown
  >({ verb: "POST", path: "users-svc/verify" });

const useSendEmailVerificationCode = () =>
  useMutate<
    SendEmailVerificationCodeResponseData,
    ErrorResponseData,
    undefined,
    SendEmailVerificationCodeRequestData,
    unknown
  >({
    verb: "POST",
    path: "/users-svc/send_verification_code",
  });

export {
  useAnswerOTP,
  useInitiateAuth,
  useLogout,
  useRefreshToken,
  useSendEmailVerificationCode,
  useVerifyEmail,
};
