import React from "react";

import { Sell as SellIcon } from "@mui/icons-material";
import { Box, Chip, Stack } from "@mui/material";

import { CatchCashIcon } from "~common/components/icons/vector";

const SEARCH_TYPES = {
  all: "",
  partners: "standard",
  offers: "affiliate",
} as const;

type SearchTypesKey = keyof typeof SEARCH_TYPES;
type SearchTypesValue = typeof SEARCH_TYPES[SearchTypesKey];

type SearchTypesProps = {
  activeType: SearchTypesValue;
  onChange: (type: SearchTypesValue) => void;
};

const SearchTypes: React.VFC<SearchTypesProps> = ({ activeType, onChange }) => (
  <Stack
    spacing={1}
    direction="row"
    mb={5}
    pb={5}
    borderBottom="1px solid"
    borderColor="grey.300"
    aria-label="Search type"
    role="group"
  >
    {(Object.keys(SEARCH_TYPES) as SearchTypesKey[]).map((key) => (
      <Chip
        aria-pressed={SEARCH_TYPES[key] === activeType ? true : undefined}
        component="button"
        key={key}
        label={<Box color="grey.700">{key}</Box>}
        onClick={() => onChange(SEARCH_TYPES[key])}
        size="small"
        variant={SEARCH_TYPES[key] === activeType ? "outlined" : "filled"}
        icon={
          <>
            {(SEARCH_TYPES[key] === "standard" ||
              SEARCH_TYPES[key] === "affiliate") && (
              <Stack
                direction="row"
                alignItems="center"
                mr={-1}
                fontSize={14}
                sx={{ ".MuiSvgIcon-root": { width: 14 } }}
              >
                {SEARCH_TYPES[key] === "standard" && (
                  <SellIcon fontSize="small" />
                )}
                {SEARCH_TYPES[key] === "affiliate" && (
                  <CatchCashIcon size="original" />
                )}
              </Stack>
            )}
          </>
        }
        sx={({ palette }) => ({
          width: "100%",
          fontWeight: 700,
          textTransform: "capitalize",
          "&.Mui-focusVisible": {
            backgroundColor: palette.grey[300],
          },
          ...(SEARCH_TYPES[key] === activeType
            ? {
                ...(SEARCH_TYPES[key] === "" && {
                  borderColor: palette.grey[600],
                }),
                ...(SEARCH_TYPES[key] === "standard" && {
                  borderColor: palette.primary.main,
                  color: palette.primary.main,
                }),
                ...(SEARCH_TYPES[key] === "affiliate" && {
                  borderColor: palette.tertiary.main,
                  color: palette.tertiary.main,
                }),
              }
            : {
                backgroundColor: palette.grey[200],
                "&.MuiChip-clickable:hover": {
                  backgroundColor: palette.grey[300],
                },
              }),
        })}
      />
    ))}
  </Stack>
);

export default SearchTypes;
