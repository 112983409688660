import React from "react";

import { Box } from "@mui/material";

const GridWallet: React.FC = ({ children }) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: 6,
    }}
  >
    {children}
  </Box>
);

export default GridWallet;
