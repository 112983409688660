import React from "react";

import { Box } from "@mui/material";

import InlineTextButton from "~common/components/controls/buttons/InlineTextButton";
import AccountSectionRow from "~src/components/account/AccountSectionRow";

type ButtonRowProps = {
  onClick: VoidFunction;
  title: React.ReactNode;
};

const ButtonRow: React.VFC<ButtonRowProps> = ({ onClick, title }) => (
  <Box sx={{ my: 3 }}>
    <InlineTextButton
      onClick={onClick}
      size="large"
      fullWidth
      sx={{
        "&.MuiButton-text.MuiButton-sizeLarge": {
          ".label": {
            textDecoration: "none",
          },
        },
      }}
    >
      <AccountSectionRow title={title} />
    </InlineTextButton>
  </Box>
);

export default ButtonRow;
