import React from "react";

import { Box, BoxProps, SxProps, Theme } from "@mui/material";

type ContentCardProps = BoxProps & {
  children: React.ReactNode;
  variant?: "contained" | "outlined";
  sx?: SxProps<Theme>;
};

const ContentCard: React.VFC<ContentCardProps> = ({
  children,
  variant = "contained",
  sx = {},
  ...props
}) => (
  <Box
    sx={[
      ({ palette, shape }) => ({
        width: "100%",
        p: 6,
        borderRadius: `${shape.borderRadius * 2}px`,
        ...(variant === "contained" && {
          background: palette.grey[200],
        }),
        ...(variant === "outlined" && {
          border: `1px solid ${palette.grey[300]}`,
        }),
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  >
    {children}
  </Box>
);

export default ContentCard;
