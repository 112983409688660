import React, { useState } from "react";

import { Add as AddIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { useTracking } from "../../tracking";
import WrappedButton from "../controls/buttons/WrappedButton";
import { FAQCard } from "./faq-types";
import FAQCardModal from "./FAQCardModal";

type FAQCardGroupProps = {
  faqCards: FAQCard[];
};

const FAQCardGroup: React.FC<FAQCardGroupProps> = ({ faqCards }) => {
  const { trackEvent } = useTracking();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<FAQCard | null>(null);

  const handleCardClick = (faqCardData: FAQCard) => {
    trackEvent("FAQ Card clicked", { question: faqCardData.title });
    setModalData(faqCardData);
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setModalData(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={({ breakpoints }) => ({
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: 4,
          [breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gap: 6,
          },
        })}
      >
        {faqCards.map((faqCardData) => (
          <WrappedButton
            key={faqCardData.id}
            variant="outlined"
            fullWidth
            onClick={() => handleCardClick(faqCardData)}
            size="large"
            sx={({ palette }) => ({
              "&.MuiButton-outlined.MuiButton-colorInherit": {
                p: 6,
                border: `1px solid ${palette.divider}`,
                borderRadius: 2,
                backgroundColor: palette.common.white,
                ":hover": {
                  backgroundColor: palette.grey[200],
                },
                ".label": { width: "100%" },
              },
            })}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Typography variant="h3" component="span" textAlign="left">
                {faqCardData.title}
              </Typography>
              <AddIcon
                sx={({ palette }) => ({
                  color: palette.primary.main,
                  height: 24,
                  width: 24,
                })}
              />
            </Stack>
          </WrappedButton>
        ))}
      </Box>
      {modalData && (
        <FAQCardModal
          open={isModalOpen}
          onClose={handleModalClose}
          data={modalData}
        />
      )}
    </>
  );
};

export default FAQCardGroup;
