import { useCallback, useEffect, useState } from "react";

type RecentMerchantVisit = {
  merchantId: string;
  merchantName: string;
  pageUrl: string;
  time: number;
};

// Set the maximum time since a visit that we'll show return UI, so that
// we don't show return UI for very old visits.
const MAX_RETURN_TIME = 2 * 60 * 60 * 1000; // 2 hours

const LOCAL_STORAGE_KEY = "recentMerchantVisit";

const getLocalRecentMerchantVisit = () => {
  try {
    const visit = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (visit) {
      const parsed = JSON.parse(visit) as RecentMerchantVisit;
      if (parsed.time > Date.now() - MAX_RETURN_TIME) {
        return parsed;
      }
    }
  } catch (e) {
    // Ignore parse errors.
  }
  // NB: We could remove the localStorage item here, but perhaps it's better to
  // leave it in case we want to extend the time limit in the future including
  // previously stale visits.
  return null;
};

/**
 * This hook manages the most recent merchant site activity for the user,
 * which is used across TOFU, Checkout, and the User Portal to remember the
 * last merchant site page the user was on. This is used to redirect the user
 * back to the merchant site in User Portal.
 *
 * We use the fact that all three apps share the same `app.getcatch.com` domain
 * to store the data in localStorage. This is the simplest way to share data
 * across apps on the same browser/device which is the ideal here.
 */
const useRecentMerchantVisit = () => {
  const [recentMerchantVisit, setRecentMerchantVisit] =
    useState<RecentMerchantVisit | null>(getLocalRecentMerchantVisit());

  const updateRecentMerchantVisit = useCallback(
    (visitData: Omit<RecentMerchantVisit, "time">) => {
      const data = { ...visitData, time: Date.now() };
      try {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
        setRecentMerchantVisit(data);
      } catch (e) {
        // Ignore errors.
      }
    },
    []
  );

  const clearRecentMerchantVisit = useCallback(() => {
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      setRecentMerchantVisit(null);
    } catch (e) {
      // Ignore errors.
    }
  }, []);

  return {
    recentMerchantVisit,
    updateRecentMerchantVisit,
    clearRecentMerchantVisit,
  };
};

/**
 * Effect to clear the recent merchant visit on mount only.
 */
const useClearRecentMerchantVisit = () => {
  const { clearRecentMerchantVisit } = useRecentMerchantVisit();

  useEffect(() => {
    clearRecentMerchantVisit();
  }, [clearRecentMerchantVisit]);
};

export default useRecentMerchantVisit;
export { useClearRecentMerchantVisit };
