/**
 * Note on experimentation conventions:
 *  1. All experiment names should be in snakecase and must match what is in
 *     Statsig and if applicable how it is stored on the backend.
 *  2. We always default to storing experiments here instead of in individual apps.
 *     This keeps things clean and enables us to easily set experiments across apps.
 *  3. We use the term "experiment_group" as the parameter that stores the
 *     different "variants"/"buckets"/"groups" etc. (e.g. the parameter value
 *     in Statsig itself)
 *  4. If an experiment is explicitly disabled for a subset of users (e.g. a
 *     certain merchant or all returning users), the stored "experiment_group"
 *     should be "disabled"
 *  5. If something unexpected has gone on while pulling the bucket, the
 *     "experiment_group" should be null. This will show up as a "None" value
 *     in Amplitude indicating there is a problem.
 *  6. At the end of an experiment, all experiment code should be removed, including
 *     from this file.
 *  7. Future versions of experiments should be appended with "V#", e.g. "V2"
 */

/* Constants */
const EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX = "catch_experiment_";

// NB: "disabled" is used for an expected and intentional case of wanting to turn
// the experiment off. For example if it is a returning user and the experiment is
// only for new users. Alternatively, "errored" is used in the case where something
// actually went wrong and we didn't pull the experiment successfully.
type StandardExperimentGroups =
  | "test"
  | "control"
  | "disabled"
  | "errored"
  | null;

const NO_EXPERIMENTS_NAME = "no_experiments";

/* Active experiment types */
// We store all active experiments for all related apps here in one place to
// ensure there is a single source of truth for what is actively running. This
// also is deliberately a painful/repetitive way to store these so that for each
// experiment developers are explicitly aware of 1. where their experiment needs
// to be added for tracking (even if there is no functional changes) and 2. which
// experiments the new code might have interactions with
type TofuActiveExperiments = {
  [NO_EXPERIMENTS_NAME]?: StandardExperimentGroups;
};
type UserPortalActiveExperiments = {
  [NO_EXPERIMENTS_NAME]?: StandardExperimentGroups;
};
type CheckoutActiveExperiments = {
  [NO_EXPERIMENTS_NAME]?: StandardExperimentGroups;
};
type UpfunnelSdkActiveExperiments = {
  [NO_EXPERIMENTS_NAME]?: StandardExperimentGroups;
};

type CheckoutExperimentNames = keyof CheckoutActiveExperiments;
type UserPortalExperimentNames = keyof UserPortalActiveExperiments;
type TofuExperimentNames = keyof TofuActiveExperiments;
type UpfunnelSdkExperimentNames = keyof UpfunnelSdkActiveExperiments;

/* Types for the action payloads in reducers */
type CheckoutExperimentPayload = {
  experimentName: keyof CheckoutActiveExperiments;
  experimentGroup: StandardExperimentGroups;
};

type UserPortalExperimentPayload = {
  experimentName: keyof UserPortalActiveExperiments;
  experimentGroup: StandardExperimentGroups;
};

type TofuExperimentPayload = {
  experimentName: keyof TofuActiveExperiments;
  experimentGroup: StandardExperimentGroups;
};

/* Feature gates */
const STATSIG_BACKEND_INTEGRATION_FEATURE_GATE =
  "statsig_backend_integration_feature_gate";

type UpfunnelSdkFeatureGateNames =
  typeof STATSIG_BACKEND_INTEGRATION_FEATURE_GATE;

// We copy this from the statsig-js library instead of importing
// to prevent statsig-react from importing statsig-js into our
// bundle twice
type StatsigUser = {
  userID?: string | number;
  email?: string;
  ip?: string;
  userAgent?: string;
  country?: string;
  locale?: string;
  appVersion?: string;
  custom?: Record<
    string,
    string | number | boolean | Array<string> | undefined
  >;
  privateAttributes?: Record<
    string,
    string | number | boolean | Array<string> | undefined
  >;
  customIDs?: Record<string, string>;
};

export {
  EXPERIMENTATION_SESSION_STORAGE_KEY_PREFIX,
  NO_EXPERIMENTS_NAME,
  STATSIG_BACKEND_INTEGRATION_FEATURE_GATE,
};

export type {
  CheckoutActiveExperiments,
  CheckoutExperimentNames,
  CheckoutExperimentPayload,
  StandardExperimentGroups,
  StatsigUser,
  TofuActiveExperiments,
  TofuExperimentNames,
  TofuExperimentPayload,
  UpfunnelSdkActiveExperiments,
  UpfunnelSdkExperimentNames,
  UpfunnelSdkFeatureGateNames,
  UserPortalActiveExperiments,
  UserPortalExperimentNames,
  UserPortalExperimentPayload,
};
