import React from "react";

import { Divider, Stack } from "@mui/material";

import ExternalLinkRow from "~src/components/account/ExternalLinkRow";
import LabeledSection from "~src/components/layout/LabeledSection";

const HelpPoliciesSection: React.VFC = () => (
  <LabeledSection heading="Help &amp; policies">
    <Stack>
      <ExternalLinkRow
        href="https://getcatch.com/terms"
        title="Terms of Service"
      />
      <Divider />
      <ExternalLinkRow
        href="https://getcatch.com/privacy"
        title="Privacy Policy"
      />
      <Divider />
      <ExternalLinkRow href="https://help.getcatch.com" title="Help/FAQs" />
      <Divider />
    </Stack>
  </LabeledSection>
);

export default HelpPoliciesSection;
