import React from "react";

import { Box, Stack, Typography } from "@mui/material";

type BaseValueCardProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const BaseValueCard: React.FC<BaseValueCardProps> = ({
  title,
  description,
  icon,
}) => (
  <Stack
    sx={({ palette, shape }) => ({
      px: 4,
      py: 6,
      border: `1px solid ${palette.tertiary.main}`,
      borderRadius: `${shape.borderRadius * 7.5}px`,
      textAlign: "left",
      gap: 4,
    })}
    direction="row"
  >
    <Box color="tertiary.main">{icon}</Box>
 
    <Stack spacing={1}>
      <Typography
        variant="h2"
        component="h4"
        color="tertiary.main"
      >
        {title}
      </Typography>
      <Typography variant="bodyRegular" color="grey.700">
        {description}
      </Typography>
    </Stack>
  </Stack>
);

export default BaseValueCard;
