import React, { forwardRef } from "react";

import { Dialog, DialogProps, Stack } from "@mui/material";

import { CloseReason, ModalOverlayColor } from "../../utils/modal-utils";
import DialogCloseButton from "./DialogCloseButton";

type WrappedDialogProps = DialogProps & {
  fullBleed?: boolean;
  onClose?: (event: unknown, reason: CloseReason) => void;
  ariaLabelledBy: string;
  ariaDescribedBy: string;
  overlayColor?: ModalOverlayColor;
};

const WrappedDialog: React.FC<WrappedDialogProps> = forwardRef(
  (
    {
      fullBleed,
      onClose,
      ariaLabelledBy,
      ariaDescribedBy,
      overlayColor,
      children,
      ...props
    },
    ref
  ) => (
    <Dialog
      fullWidth={fullBleed}
      onClose={onClose}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      ref={ref}
      className={overlayColor ? `overlay-${overlayColor}` : "overlay-medium"}
      {...props}
    >
      {onClose && <DialogCloseButton fullBleed={fullBleed} onClose={onClose} />}

      <Stack
        alignItems="center"
        sx={({ spacing }) => ({
          margin: fullBleed ? spacing(-13, -6, -8) : 0,
        })}
      >
        {children}
      </Stack>
    </Dialog>
  )
);

WrappedDialog.displayName = "WrappedDialog";

export default WrappedDialog;
export type { CloseReason, WrappedDialogProps };
