import React from "react";

import { Divider, Stack, Typography } from "@mui/material";

import { TextButtonSmall } from "../controls/buttons";
import CompletionTextMarker from "../markers/CompletionTextMarker";

// TODO: This component is currently duplicated within
//       checkout-v2/src/components/steps/verify-email/VerifyEmail.tsx
//       and should be used in Checkout as well to share common code.

type ResendEmailVerificationProps = {
  handleResendVerification: () => void;
  isCodeResent?: boolean;
  centered?: boolean;
};

const ResendEmailVerification: React.VFC<ResendEmailVerificationProps> = ({
  handleResendVerification,
  isCodeResent,
  centered = false,
}) =>
  isCodeResent ? (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={centered ? "center" : "flex-start"}
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <CompletionTextMarker>
        <Typography variant="bodyRegular" color="grey.600">
          Sent
        </Typography>
      </CompletionTextMarker>
      <Typography variant="bodyRegular" color="grey.600">
        Make sure to check spam!
      </Typography>
    </Stack>
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={centered ? "center" : "flex-start"}
    >
      <Typography variant="bodySmall" component="span" color="grey.500">
        Didn&apos;t get the email?
      </Typography>
      <TextButtonSmall onClick={handleResendVerification}>
        Send it again
      </TextButtonSmall>
    </Stack>
  );

ResendEmailVerification.defaultProps = {
  centered: false,
};

export default ResendEmailVerification;
