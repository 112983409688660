import React from "react";

import { Box, keyframes } from "@mui/material";

type RotatingTextProps = {
  messages: string[];
};

const RotatingText: React.VFC<RotatingTextProps> = ({ messages }) => {
  const getAnimation = (frameTotal: number) => keyframes`
    0% {
      opacity: 1;
    }
    ${100 / frameTotal}% {
      opacity: 0;
    }
  `;

  return (
    <>
      {messages.map((message, i) => (
        <Box
          key={message.replace(/ /g, "")}
          sx={{
            opacity: 0,
            animation: `${getAnimation(messages.length)} ${
              7000 * messages.length
            }ms infinite steps(1)`,
            animationDelay: `${7000 * i}ms`,
            ...(i !== 0 && {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              textAlign: "center",
            }),
          }}
        >
          {message}
        </Box>
      ))}
    </>
  );
};

export default RotatingText;
