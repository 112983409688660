import { useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import { PhoneUrlMappingRequestData } from "./types/phone-url-mapping-types";

const usePhoneUrlMapping = () =>
  useMutate<
    undefined,
    ErrorResponseData,
    undefined,
    PhoneUrlMappingRequestData,
    undefined
  >({
    verb: "POST",
    path: "promotions-svc/phone_url_mappings",
  });

export { usePhoneUrlMapping };
