import React, { useEffect, useRef, useState } from "react";

import {
  MoreVert as MoreVertIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  ButtonBase,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";

import { useEnv } from "~common/contexts/env-context";
import { navigateWithUserParams } from "~common/utils/navigation";

import MerchantCardButton from "./MerchantCardButton";
import ShareAsGiftButton from "./ShareAsGiftButton";

type MerchantCardMenuProps = {
  merchantUrl: string;
  isGiftable: boolean;
  color: string;
  handleShopNowClick?: () => void;
  handleShareAsGiftClick?: () => void;
  sx?: SxProps<Theme>;
};

const MerchantCardMenu: React.VFC<MerchantCardMenuProps> = ({
  merchantUrl,
  isGiftable,
  color,
  handleShopNowClick,
  handleShareAsGiftClick,
  sx = [],
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const { palette } = useTheme();
  const { APP_ENVIRONMENT, APP_STAGE } = useEnv();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (
      (!ref.current || !ref.current.contains(target)) &&
      !target?.classList.contains("MuiBackdrop-root")
    ) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    if (mobileOpen) {
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [mobileOpen]);

  return (
    <Box
      ref={ref}
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          width: "100%",
          height: "100%",
          "@media (hover: hover)": {
            "&:hover > *[aria-hidden='true'], &:focus > *[aria-hidden='true'], &:focus-within > *[aria-hidden='true']":
              {
                opacity: 1,
                visibility: "visible",
              },
          },
        },
      ]}
    >
      <ButtonBase
        disableRipple
        sx={{
          width: "calc(100% - 32px)",
          pt: 2,
          pb: 4,
          position: "absolute",
          right: 16,
          justifyContent: "flex-end",
        }}
        aria-label="Show credit options"
        onClick={() => setMobileOpen(true)}
      >
        <MoreVertIcon
          sx={{
            color,
            opacity: 0.8,
            "&.MuiSvgIcon-fontSizeMedium": {
              fontSize: 24,
            },
          }}
        />
      </ButtonBase>

      <Stack
        spacing={2}
        sx={{
          background: alpha(palette.grey[700], 0.33),
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          p: 4,
          alignItems: "flex-end",
          overflow: "hidden",
          opacity: mobileOpen ? 1 : 0,
          visibility: mobileOpen ? "visible" : "hidden",
        }}
        aria-hidden={!mobileOpen}
      >
        {handleShopNowClick && (
          <MerchantCardButton
            href={merchantUrl}
            target="_blank"
            onClick={(e) =>
              navigateWithUserParams(
                e,
                APP_ENVIRONMENT,
                APP_STAGE,
                handleShopNowClick
              )
            }
            endIcon={<OpenInNewIcon fontSize="small" />}
          >
            Shop now
          </MerchantCardButton>
        )}

        {handleShareAsGiftClick && (
          <ShareAsGiftButton
            isRewardGiftable={isGiftable}
            onClick={handleShareAsGiftClick}
          />
        )}
      </Stack>
    </Box>
  );
};

export default MerchantCardMenu;
