// GENERATED FILE. DO NOT EDIT.

import React from "react";

import IconSVG from "../vector-source/CatchCash.icon.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const CatchCashIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon iconSvg={IconSVG} {...props} />
);

export default CatchCashIcon;
