import React from "react";

import { useTheme } from "@mui/material";

import GenericLoadingScreen from "./GenericLoadingScreen";
import IllustrationLoadingScreen from "./IllustrationLoadingScreen";

const ThemeAwareLoadingScreen: React.VFC = () => {
  const { isCatch } = useTheme();
  return isCatch ? <IllustrationLoadingScreen /> : <GenericLoadingScreen />;
};

export default ThemeAwareLoadingScreen;
