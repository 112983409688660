import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useTracking } from "~common/tracking";
import { splitPrefillName } from "~common/utils/names";
import { APP_ENVIRONMENT, IS_SANDBOX, SEGMENT_KEY } from "~src/config";
import {
  markFromRewardBoostEmail,
  setPrefill,
} from "~src/store/slices/core-slice";
import { setAuthPhone } from "~src/store/slices/user-slice";

import { extractPrefillSearchParam } from "../../catch-frontend-common/utils/urls";

const usePatchInitialAppContext = () => {
  const { patchTrackingContext, initSegment } = useTracking();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    initSegment(SEGMENT_KEY);
  }, [initSegment]);

  useEffect(() => {
    // Patch tracking context to prevent cross-app mismatch
    patchTrackingContext({
      appName: "user-portal",
      appEnv: APP_ENVIRONMENT,
      isSandbox: IS_SANDBOX,
      ...(searchParams.get("utm_medium") && {
        referrerMedium: searchParams.get("utm_medium"),
      }),
      ...(searchParams.get("utm_campaign") && {
        referrerCampaign: searchParams.get("utm_campaign"),
      }),
      ...(searchParams.get("isSecondChanceBonusUser") && {
        isSecondChanceBonusUser: searchParams.get("isSecondChanceBonusUser"),
      }),
      ...(searchParams.get("rewardCampaignId") && {
        rewardCampaignId: searchParams.get("rewardCampaignId"),
      }),
    });

    // Store the available prefilled user info
    const prefillPhone = extractPrefillSearchParam(
      searchParams.get("prefillPhoneNumber") ||
        searchParams.get("prefillUserPhone")
    );
    const prefillName = extractPrefillSearchParam(
      searchParams.get("prefillUserName")
    );
    const prefillEmail = extractPrefillSearchParam(
      searchParams.get("prefillUserEmail")
    );

    // Needed so that we don't erase the prefill
    // information on navigation
    if (!prefillPhone && !prefillName && !prefillEmail) {
      return;
    }

    const { userFirstName: prefillFirstName, userLastName: prefillLastName } =
      splitPrefillName(prefillName || "");

    dispatch(
      setPrefill({
        prefillPhone,
        prefillEmail,
        prefillFirstName,
        prefillLastName,
      })
    );
  }, [patchTrackingContext, searchParams, dispatch]);

  useEffect(() => {
    // Store whether the user is arriving from a reward boost email
    const fromRewardBoostEmail = searchParams.get("from_reward_boost_email");

    if (fromRewardBoostEmail) {
      dispatch(markFromRewardBoostEmail());

      patchTrackingContext({ fromRewardBoostEmail: true });
    }
  }, [dispatch, patchTrackingContext, searchParams]);

  useEffect(() => {
    if (searchParams.get("phoneNumber") && searchParams.get("skipAuthPhone")) {
      dispatch(setAuthPhone(searchParams.get("phoneNumber") || ""));
    }
  }, [dispatch, searchParams]);
};

export default usePatchInitialAppContext;
