import React from "react";

import { Stack, Typography } from "@mui/material";

import { BankLockIcon } from "../../../../icons/vector";
import BankHeader from "../BankHeader";
import PayByBankAndEarn from "./PayByBankAndEarn";
import YourInfoIsEncrypted from "./YourInfoIsEncrypted";

type BankHeaderDefaultProps = {
  headingId?: string;
};
const BankHeaderDefault: React.VFC<BankHeaderDefaultProps> = ({
  headingId,
}) => (
  <>
    <BankHeader icon={<BankLockIcon size="small" />}>
      <Typography variant="h1" id={headingId}>
        Connect your bank once. Use Catch anywhere.
      </Typography>
    </BankHeader>

    <Stack spacing={4}>
      <PayByBankAndEarn />
      <YourInfoIsEncrypted />
    </Stack>
  </>
);

export default BankHeaderDefault;
