import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Add as AddIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import getStartedWhatIsCatchImage from "~common/assets/illustrations/get_started_what_is_catch.png";
import {
  PrimaryButton,
  WrappedButton,
} from "~common/components/controls/buttons";
import GetStartedModal from "~common/components/faq/GetStartedModal";
import { useTracking } from "~common/tracking";
import { formatRelativeLink } from "~common/utils/urls";

const WhatIsCatchFAQ: React.VFC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const { trackEvent } = useTracking();

  const titleCopy = "Wait... what is Catch? 🖐";

  return (
    <>
      <WrappedButton
        variant="outlined"
        fullWidth
        onClick={() => {
          trackEvent("Get Started What is Catch Open");
          setOpen(true);
        }}
        size="large"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography variant="h3" component="span" textAlign="left">
            {titleCopy}
          </Typography>
          <AddIcon />
        </Stack>
      </WrappedButton>
      <GetStartedModal
        open={open}
        onClose={() => setOpen(false)}
        title={titleCopy}
      >
        <Stack
          sx={{
            gap: 6,
            mt: 6,
          }}
        >
          <Box sx={{
            background: `center top / cover url(${getStartedWhatIsCatchImage}) no-repeat`,
            height: 194,
          }} />

          <Typography color="grey.600">
            <strong>Catch is a new way to pay</strong> that helps your favorite
            brands save on credit card fees and pass those savings to you.
            Everyone wins — except the credit card companies.
          </Typography>
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              trackEvent("Get Started See Brands Click");
              setOpen(false);
              navigate("/shop");
            }}
            href={formatRelativeLink("shop")}
          >
            See all Catch brands
          </PrimaryButton>
        </Stack>
      </GetStartedModal>
    </>
  );
};

export default WhatIsCatchFAQ;
