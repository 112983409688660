import React from "react";

import { Stack, Typography } from "@mui/material";

import ContentCard from "~common/components/cards/ContentCard";

type InfoCardProps = {
  children: React.ReactNode;
  heading?: React.ReactNode;
  cta?: React.ReactNode;
};

const InfoCard: React.VFC<InfoCardProps> = ({ children, heading, cta }) => (
  <ContentCard variant="outlined" height="100%">
    <Stack spacing={6} height="100%" justifyContent="space-between">
      <Stack spacing={6} alignItems="flex-start" height="100%">
        {heading && (
          <Typography variant="h2" component="h3">
            {heading}
          </Typography>
        )}

        {children}
      </Stack>

      {cta}
    </Stack>
  </ContentCard>
);

export default InfoCard;
