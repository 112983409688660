import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="41"
    height="43"
    fill="none"
    viewBox="0 0 41 43"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.444.128s-.637 14.686-1.91 15.518c-1.4.692-7.001-3.88-7.001-3.88s4.71 5.82 3.946 7.205C14.588 20.357.711 21.188.711 21.188s13.877.97 14.768 2.356c.764 1.385-3.946 7.204-3.946 7.204s5.6-4.572 7.001-3.879c1.273.831 1.91 15.518 1.91 15.518S21.08 27.7 22.48 26.869c1.4-.693 6.874 3.88 6.874 3.88s-4.583-5.82-3.819-7.205c.764-1.386 14.64-2.356 14.64-2.356s-13.876-.831-14.64-2.217c-.764-1.385 3.82-7.205 3.82-7.205s-5.475 4.573-6.875 3.88c-1.273-.832-1.91-13.44-2.037-15.518Z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;
