import { selectRecommendedMerchants } from "~src/store";
import { recommendedMerchantsActions } from "~src/store/slices/services/recommendedMerchants-slice";

import useLazyService from "./useLazyService";

const useRecommendedMerchants = () => {
  const {
    data: recommendedMerchants,
    loading,
    error,
  } = useLazyService(selectRecommendedMerchants, recommendedMerchantsActions);

  return { recommendedMerchants, loading, error };
};

export default useRecommendedMerchants;
