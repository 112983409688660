import React from "react";

import { Sell as SellIcon } from "@mui/icons-material";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { CatchCashIcon } from "~common/components/icons/vector";
import { MerchantSearchResult } from "~common/services";
import { getMerchantLogoUrl } from "~common/utils/assets";
import { formatPercent } from "~common/utils/numbers";
import { normalizeUrl } from "~common/utils/urls";
import { EXTERNAL_ASSETS_BASE_URL } from "~src/config";
import { getUtmParams } from "~src/utils/getUtmsParams";

type SearchResultsProps = {
  heading: string;
  results: MerchantSearchResult[];
  onClick: (result: MerchantSearchResult) => void;
};

const SearchResults: React.VFC<SearchResultsProps> = ({
  heading,
  results,
  onClick,
}) => {
  if (!results.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h2">{heading}</Typography>

      <List disablePadding>
        {results?.map((result) => (
          <ListItem key={result.id} disablePadding>
            <ListItemButton
              component="a"
              disableGutters
              disableRipple
              href={`${normalizeUrl(result.url)}?${getUtmParams("web_app")}`}
              onClick={() => onClick(result)}
              target="_blank"
              sx={{ p: 0 }}
            >
              <ListItemAvatar sx={{ minWidth: 44 }}>
                <Avatar
                  src={getMerchantLogoUrl(EXTERNAL_ASSETS_BASE_URL, result)}
                  sx={{ width: 32, height: 32 }}
                />
              </ListItemAvatar>

              <ListItemText
                disableTypography
                primary={
                  <Typography variant="bodyRegular" fontWeight={700}>
                    {result.name}
                  </Typography>
                }
                secondary={
                  <Stack
                    spacing={1}
                    direction="row"
                    color={
                      result.merchant_partner_type === "affiliate"
                        ? "tertiary.main"
                        : "primary.main"
                    }
                    sx={{ ".MuiSvgIcon-root": { width: 14 } }}
                  >
                    {result.merchant_partner_type === "standard" && (
                      <SellIcon fontSize="small" />
                    )}

                    {result.merchant_partner_type === "affiliate" && (
                      <CatchCashIcon size="original" />
                    )}

                    <Typography variant="bodySmall" fontWeight={700}>
                      Earn {formatPercent(result.reward_rate)}{" "}
                    </Typography>

                    <Typography variant="bodySmall" color="grey.700">
                      {result.merchant_partner_type === "affiliate"
                        ? "Catch Cash"
                        : "store credit"}
                    </Typography>
                  </Stack>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default SearchResults;
