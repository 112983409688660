import Cookies from "js-cookie";

const setLocalStorageWithFallback = (
  key: string,
  value: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  try {
    localStorage.setItem(key, value);
    onSuccess && onSuccess();
  } catch (err) {
    onError && onError(err as Error);
  }

  // localStorage does not expire so the fallback expiration is
  // 400 days, the longest time span supported by any browser
  Cookies.set(key, value, {
    expires: 400,
    sameSite: "none",
    secure: true,
  });
};

const getLocalStorageWithFallback = (
  key: string,
  onError?: (err: Error) => void
) => {
  let item;

  try {
    item = localStorage.getItem(key);
  } catch (err) {
    onError && onError(err as Error);
  }

  if (!item) {
    item = Cookies.get(key);
  }

  return item;
};

const removeLocalStorageWithFallback = (
  key: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  try {
    localStorage.removeItem(key);
    onSuccess && onSuccess();
  } catch (err) {
    onError && onError(err as Error);
  }

  Cookies.remove(key);
};

const setSessionStorageWithFallback = (
  key: string,
  value: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  try {
    sessionStorage.setItem(key, value);
    onSuccess && onSuccess();
  } catch (err) {
    onError && onError(err as Error);
  }

  Cookies.set(key, value, {
    sameSite: "none",
    secure: true,
  });
};

const getSessionStorageWithFallback = (
  key: string,
  onError?: (err: Error) => void
) => {
  let item;

  try {
    item = sessionStorage.getItem(key);
  } catch (err) {
    onError && onError(err as Error);
  }

  if (!item) {
    item = Cookies.get(key);
  }

  return item;
};

const removeSessionStorageWithFallback = (
  key: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  try {
    sessionStorage.removeItem(key);
    onSuccess && onSuccess();
  } catch (err) {
    onError && onError(err as Error);
  }

  Cookies.remove(key);
};

const getUtcTimestamp = () => {
  const date = new Date();
  const timezoneOffsetInMs = date.getTimezoneOffset() * 60 * 1000;
  return date.getTime() + timezoneOffsetInMs;
};

const getExpiresAt = (offsetInSeconds: number) => {
  const utcTimestamp = getUtcTimestamp();
  const offsetInMs = offsetInSeconds * 1000;
  return utcTimestamp + offsetInMs;
};

const isExpired = (expiresAt?: number) => {
  if (!expiresAt) {
    return true;
  }

  const utcTimestamp = getUtcTimestamp();
  return expiresAt <= utcTimestamp;
};

export {
  getExpiresAt,
  getLocalStorageWithFallback,
  getSessionStorageWithFallback,
  isExpired,
  removeLocalStorageWithFallback,
  removeSessionStorageWithFallback,
  setLocalStorageWithFallback,
  setSessionStorageWithFallback,
};
