import { RecommendedMerchantCategoryResponseData } from "~common/services";
import { makeFetchSlice } from "~common/store/store-utils";

const slice = makeFetchSlice<RecommendedMerchantCategoryResponseData>(
  "recommendedMerchants",
  {
    merchants: [],
    recommendation_type: "user_purchase_history"
  }
);

const { reducer, actions: recommendedMerchantsActions } = slice;

export { recommendedMerchantsActions };
export default reducer;
