import dayjs, { Dayjs } from "dayjs";

import { UserResponseData } from "~common/services";

const EMPTY_DOB_MESSAGE = "Please enter a valid date of birth";

const currentUserHasBirthday = (currentUser: UserResponseData | null) =>
  !!(
    currentUser?.birthday_year &&
    currentUser?.birthday_month &&
    currentUser?.birthday_day
  );

const getDayjsBirthday = (currentUser: UserResponseData | null) => {
  if (!currentUserHasBirthday(currentUser)) {
    return null;
  }

  return dayjs()
    .year(currentUser?.birthday_year || 0)
    .month((currentUser?.birthday_month || 0) - 1)
    .date(currentUser?.birthday_day || 0);
};

const getCurrentUserBirthday = (date: Dayjs | null) => {
  if (!date) {
    return null;
  }

  const year = date.get("year");
  const maxYear = dayjs(new Date()).get("year") - 13;
  const month = date.get("month");
  const day = date.get("date");

  if (
    !year ||
    year < 1850 ||
    year > maxYear ||
    (!month && month !== 0) ||
    !day
  ) {
    return null;
  }

  return {
    birthday_year: year,
    birthday_month: month + 1,
    birthday_day: day,
  };
};

const getStringBirthday = (currentUser: UserResponseData | null) => {
  if (!currentUserHasBirthday(currentUser)) {
    return null;
  }

  const birthday = `${currentUser?.birthday_year || ""}-${
    currentUser?.birthday_month || ""
  }-${currentUser?.birthday_day || ""}`;
  return dayjs(birthday).format("YYYY-MM-DD");
};

export {
  currentUserHasBirthday,
  EMPTY_DOB_MESSAGE,
  getCurrentUserBirthday,
  getDayjsBirthday,
  getStringBirthday,
};
