import React, { useState } from "react";
import { useIntercom } from "react-use-intercom";

import { Box, Typography } from "@mui/material";

import { InlineTextButtonSmall } from "~common/components/controls/buttons";
import LabeledSetting from "~src/components/account/LabeledSetting";
import useCurrentUser from "~src/hooks/services/useCurrentUser";

import AccountSettingsBirthdayEditForm from "./AccountSettingsBirthdayEditForm";

const AccountSettingsBirthdayRow: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const intercom = useIntercom();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const formattedBirthday =
    currentUser?.birthday_day && currentUser?.birthday_month
      ? `${currentUser?.birthday_month}/${currentUser?.birthday_day}`
      : "";

  return (
    <Box>
      {isEditMode ? (
        <AccountSettingsBirthdayEditForm onClose={() => setIsEditMode(false)} />
      ) : (
        <LabeledSetting
          label="Birthday"
          value={
            formattedBirthday ? (
              <>
                {formattedBirthday}

                <Typography variant="bodySmall" component="div">
                  If you need to change your birthday, please{" "}
                  <InlineTextButtonSmall
                    sx={{
                      "&.MuiButton-sizeSmall": {
                        p: 0,
                        display: "inline",
                        verticalAlign: "baseline",
                      },
                    }}
                    onClick={intercom.show}
                  >
                    contact support
                  </InlineTextButtonSmall>
                  .
                </Typography>
              </>
            ) : (
              ""
            )
          }
          loading={!currentUser}
          onEditClick={
            formattedBirthday ? undefined : () => setIsEditMode(true)
          }
        />
      )}
    </Box>
  );
};

export default AccountSettingsBirthdayRow;
