import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { removeSessionStorageWithFallback } from "~common/utils/browser-storage";
import { formatMoney } from "~common/utils/money";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import CatchCardSuccess from "~src/components/shared/onboarding/CatchCardSuccess";
import { selectCatchCard, selectClaims } from "~src/store";
import { setAuthUserInfo, setCatchCard } from "~src/store/slices/user-slice";
import { getDevice } from "~src/utils/devices";
import { getUtmParams } from "~src/utils/getUtmsParams";

const Success: React.VFC = () => {
  const dispatch = useDispatch();
  const device = getDevice();
  const navigate = useNavigate();
  const catchCard = useSelector(selectCatchCard);
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);

  const handleClose = () => {
    removeSessionStorageWithFallback("activateCardRequired");
    dispatch(setAuthUserInfo({ activateCardRequired: false }));

    /*
     * Even though the PIN was set in the previous step, we delay
     * updating the Redux store until the user exits card activation
     */
    dispatch(
      setCatchCard({
        ...catchCard,
        is_pin_set: true,
      })
    );

    navigate("/home");
  };

  return (
    <SmallPagePanel
      title={`Add it to ${
        device === "android" ? "your digital wallet" : "Apple Pay"
      } so you can use it in-store`}
      subtitle="Groceries, gas, dr appt... 1.5% back anywhere"
      icon={
        newUserOnboardingRewardCampaign ? (
          <CatchCardReward
            reward={newUserOnboardingRewardCampaign}
            percentComplete={(7 / 7) * 100}
          />
        ) : (
          <></>
        )
      }
    >
      <CatchCardSuccess
        onClose={handleClose}
        externalLink={
          newUserOnboardingRewardCampaign?.post_claim_cta_url
            ? {
                link: `${
                  newUserOnboardingRewardCampaign?.post_claim_cta_url
                }?${getUtmParams("web_app")}`,
                text: `Redeem ${formatMoney(
                  newUserOnboardingRewardCampaign?.total_amount,
                  { dropCentsIfRound: true }
                )} at ${newUserOnboardingRewardCampaign?.merchant_name || ""}`,
              }
            : null
        }
      />
    </SmallPagePanel>
  );
};

export default Success;
