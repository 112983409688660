import React from "react";

import { Box } from "@mui/material";

import { CloseReason } from "../../utils/modal-utils";
import { DialogCloseButton, SwipeableDrawerBar } from "../modals";

type HighlightModalWrapperProps = {
  children: React.ReactNode;
  onClose: (event: unknown, reason?: CloseReason) => void;
};

const HighlightModalWrapper: React.VFC<HighlightModalWrapperProps> = ({
  children,
  onClose,
}) => (
  <>
    <Box
      sx={{
        display: {
          sm: "none",
        },
      }}
    >
      <SwipeableDrawerBar anchor="bottom" fullBleed />
    </Box>

    <Box
      sx={{
        display: {
          zero: "none",
          sm: "block",
        },
      }}
    >
      <DialogCloseButton fullBleed onClose={onClose} />
    </Box>

    <Box
      sx={({ spacing }) => ({
        overflowY: "auto",
        maxHeight: {
          zero: `calc(100vh - ${spacing(16)})`,
          sm: `calc(100vh - ${spacing(28)})`,
        },
      })}
    >
      {children}
    </Box>
  </>
);

export default HighlightModalWrapper;
