import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { Box, FormHelperText } from "@mui/material";

import CatchCard from "~common/components/cards/CatchCard";
import FlippableCard from "~common/components/cards/FlippableCard";
import { DefaultButton } from "~common/components/controls/buttons";
import { MARQETA_ERROR_MESSAGE } from "~common/components/marqeta/marqeta";
import MarqetaCard from "~common/components/marqeta/MarqetaCard";
import { useGetAnywhereCardForUser } from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import { IS_DEV } from "~src/config";
import { setCatchCard } from "~src/store/slices/user-slice";

import InfoCard from "../shared/InfoCard";

const CatchCardCard: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const { data: catchCard } = useGetAnywhereCardForUser();
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (catchCard) {
      dispatch(setCatchCard(catchCard));
    }
  }, [dispatch, catchCard]);

  const handleClick = () => {
    setShowDetails(!showDetails);

    trackEvent(
      `Catch Card ${showDetails ? "Hide" : "Show"} card details Clicked`
    );
  };

  const handleError = () => {
    setError(MARQETA_ERROR_MESSAGE);
    trackError("Catch Card Details", "Marqeta bootstrap error");
  };

  const handleCopy = (name: string) => {
    trackEvent(`Catch Card Copy ${name} Clicked`);
  };

  return (
    <InfoCard
      heading="Your Catch card"
      cta={
        <DefaultButton
          startIcon={<VisibilityIcon />}
          onClick={handleClick}
          disabled={!!error}
        >
          {showDetails ? "Hide" : "Show"} card details
        </DefaultButton>
      }
    >
      <Box height={150}>
        <Box
          width={318}
          sx={{
            transform: "scale(0.75)",
            transformOrigin: "top left",
          }}
        >
          {catchCard?.marqeta_token ? (
            <FlippableCard
              flipped={showDetails}
              front={<CatchCard sx={{ borderRadius: 0 }} />}
              back={
                <MarqetaCard
                  token={catchCard?.marqeta_token}
                  isSandbox={IS_DEV}
                  onFailure={handleError}
                  onCopy={handleCopy}
                />
              }
              elevation={0}
            />
          ) : (
            <CatchCard />
          )}
        </Box>
      </Box>

      {error && <FormHelperText error>{error}</FormHelperText>}
    </InfoCard>
  );
};

export default CatchCardCard;
