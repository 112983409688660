import React from "react";

import { Check } from "@mui/icons-material";
import {
  Alert,
  AlertColor,
  Box,
  Fade,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Transition: React.VFC<SlideProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <Slide
      {...props}
      direction={isMobile ? "down" : "left"}
      style={{
        transitionDelay: props.in ? "0ms" : "195ms",
      }}
    >
      <Box>
        <Fade
          in={props.in}
          style={{
            transitionDuration: props.in ? "0ms" : "195ms",
          }}
        >
          {children}
        </Fade>
      </Box>
    </Slide>
  );
};

type DefaultSnackbarProps = SnackbarProps & {
  onClose?: (
    event: Event | React.SyntheticEvent,
    reason: SnackbarCloseReason | "closeButtonClick"
  ) => void;
  severity?: AlertColor | undefined;
  singleLine?: boolean;
};

const DefaultSnackbar: React.VFC<DefaultSnackbarProps> = ({
  children,
  onClose,
  severity,
  singleLine,
  ...props
}) => (
  <Snackbar TransitionComponent={Transition} onClose={onClose} {...props}>
    <Alert
      severity={severity}
      icon={severity === "success" ? <Check fontSize="small" /> : false}
      onClose={onClose ? (e) => onClose(e, "closeButtonClick") : undefined}
      sx={
        singleLine
          ? {
              alignItems: "center",
              ".MuiAlert-message": {
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              },
            }
          : {}
      }
    >
      {children}
    </Alert>
  </Snackbar>
);

export default DefaultSnackbar;
