import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { BROWSER_EXTENSION_CARD_NUX } from "~common/services";
import {
  selectCatchCard,
  selectCurrentUser,
  selectIsMobileApp,
  selectNuxs,
} from "~src/store";

import CatchCardBenefits from "../catch-card/CatchCardBenefits";
import BrowserExtension from "./BrowserExtension";
import CatchCard from "./CatchCard";

const CatchCardSection: React.VFC = () => {
  const isMobileApp = useSelector(selectIsMobileApp);
  const currentUser = useSelector(selectCurrentUser.data);
  const nuxs = useSelector(selectNuxs);
  const catchCard = useSelector(selectCatchCard);

  if (
    isMobileApp ||
    !catchCard ||
    catchCard?.user_flow_status === "not_initiated"
  ) {
    return null;
  }

  return (
    <Grid container spacing={6} alignItems="stretch">
      <Grid item xs={12} sm={6}>
        <CatchCard />
      </Grid>

      <Grid item xs={12} sm={6}>
        {nuxs?.includes(BROWSER_EXTENSION_CARD_NUX) &&
        !currentUser?.chrome_desktop_browser_extension_installed &&
        !currentUser?.safari_mobile_browser_extension_installed ? (
          <BrowserExtension />
        ) : (
          <CatchCardBenefits />
        )}
      </Grid>
    </Grid>
  );
};

export default CatchCardSection;
