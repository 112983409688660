import { selectRewardPools } from "~src/store";
import { rewardPoolsActions } from "~src/store/slices/services/rewardPools-slice";

import useLazyService from "./useLazyService";

const useRewardPools = () => {
  const { data, loading, error } = useLazyService(
    selectRewardPools,
    rewardPoolsActions
  );

  return { rewardPools: data?.reward_pools || null, loading, error };
};

export default useRewardPools;
