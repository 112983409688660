import React from "react";
import { useDispatch } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { BankButton } from "~common/components/controls/buttons";
import { BankWingsIcon } from "~common/components/icons/vector";
import {
  openBankModal,
  setBankLinkingStep,
  setConnectBankVariant,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";

import WhatNextPrompt from "./WhatNextPrompt";

const LinkPaymentMethodPrompt: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const buttonText = "Link payment method";

  const handleLinkPaymentMethod = () => {
    dispatch(openBankModal());
    dispatch(setConnectBankVariant("Default"));
    dispatch(setBankLinkingStep("ConnectBank"));
    trackEvent("Payment method open click", {
      component: "LinkPaymentMethodPrompt",
      connectVariant: "Default",
      buttonText,
    });
  };

  return (
    <WhatNextPrompt
      body={
        <Stack spacing={1}>
          <BankWingsIcon size="small" sx={{ mb: 3 }} />
          <Typography variant="h2">Checkout faster</Typography>
          <Typography variant="bodyRegular" component="div" color="grey.600">
            Link a payment method now and speed through checkout on every
            purchase.
          </Typography>
        </Stack>
      }
      footer={
        <BankButton onClick={handleLinkPaymentMethod} fullWidth>
          {buttonText}
        </BankButton>
      }
    />
  );
};

export default LinkPaymentMethodPrompt;
