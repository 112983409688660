import React from "react";
import { NavLink } from "react-router-dom";

import { Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import LabeledSection from "~src/components/layout/LabeledSection";
import useRecommendedMerchants from "~src/hooks/services/useRecommendedMerchants";

import MerchantCard from "../shop/MerchantCard";
import ShopCategorySkeleton from "../shop/ShopCategorySkeleton";

type RecommendedMerchantsProps = {
  pageLoading: boolean;
};

// Swiper React docs: https://swiperjs.com/react
const RecommendedMerchantsSection: React.VFC<RecommendedMerchantsProps> = ({
  pageLoading,
}) => {
  const { recommendedMerchants, loading, error } = useRecommendedMerchants();
  const utmCampaign = "web_app";

  // If the user hasn't made any purchase, the backend will return
  // the most popular merchants, instead of recommended merchants
  const heading =
    recommendedMerchants.recommendation_type === "user_purchase_history"
      ? "We think you'd like"
      : "Popular at Catch";

  // Map each merchant -> Swiper slide (Merchant ID, MerchantCard)
  const slides = recommendedMerchants.merchants.map((merchant) => ({
    key: `${merchant.id}`,
    node: <MerchantCard merchant={merchant} utmCampaign={utmCampaign} />,
  }));

  if (!pageLoading && !loading && !recommendedMerchants) {
    return null;
  }

  return (
    <LabeledSection
      heading={heading}
      loading={pageLoading}
      topRight={
        <NavLink to="/shop">
          <Typography variant="linkSmall" color="grey.500">
            All brands
          </Typography>
        </NavLink>
      }
    >
      <LoadingGuard {...{ error }}>
        {loading ? (
          <ShopCategorySkeleton columns={4} showHeadingSkeleton={false} />
        ) : (
          <Swiper
            slidesPerView={2.2}
            spaceBetween={16}
            breakpoints={{
              840: { slidesPerView: 4 },
            }}
            grabCursor
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.key}>{slide.node}</SwiperSlide>
            ))}
          </Swiper>
        )}
      </LoadingGuard>
    </LabeledSection>
  );
};

export default RecommendedMerchantsSection;
