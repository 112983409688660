import React from "react";

import { Box, BoxProps } from "@mui/material";

type BorderedBoxProps = BoxProps;

const BorderedBox: React.FC<BorderedBoxProps> = ({
  children,
  sx = [],
  ...props
}) => (
  <Box
    sx={[
      ({ palette }) => ({
        border: `1px solid ${palette.divider}`,
        borderRadius: 2,
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  >
    {children}
  </Box>
);

export default BorderedBox;
