import { selectUserRewards } from "~src/store";
import { userRewardsActions } from "~src/store/slices/services/userRewards-slice";

import useLazyService from "./useLazyService";

const useUserRewards = () => {
  const {
    data: userRewards,
    loading,
    error,
  } = useLazyService(selectUserRewards, userRewardsActions);

  return { userRewards, loading, error };
};

export default useUserRewards;
