import React from "react";

import { Stack, Typography } from "@mui/material";

// This component provides the base UI for /login flow pages,
// (everything below the navbar).

type SmallPagePanelProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  footer?: React.ReactNode;
  centered?: boolean;
};

const SmallPagePanel: React.FC<SmallPagePanelProps> = ({
  icon,
  title,
  subtitle,
  centered,
  footer,
  children,
}) => (
  <Stack
    sx={({ breakpoints }) => ({
      px: 6,
      py: 8,
      [breakpoints.up("md")]: {
        // Add padding if this page doesn't render an icon.
        py: icon ? 8 : 20,
      },
      maxWidth: "calc(var(--small-page-panel-max-width) + 48px)",
      minHeight: "calc(100vh - var(--navbar-height) - 1px)",
      margin: "0 auto",
      textAlign: centered ? "center" : "left",
    })}
    spacing={16}
  >
    <Stack spacing={8} flexGrow={1}>
      {(icon || title || subtitle) && (
        <Stack spacing={4} alignItems={centered ? "center" : "flex-start"}>
          {icon && icon}
          {title && <Typography variant="h1">{title}</Typography>}
          {subtitle && (
            <Typography variant="bodyRegular" color="grey.600" paragraph>
              {subtitle}
            </Typography>
          )}
        </Stack>
      )}
      {children}
    </Stack>
    {footer}
  </Stack>
);

SmallPagePanel.defaultProps = {
  icon: undefined,
  title: undefined,
  subtitle: undefined,
  footer: undefined,
  centered: false,
};

export default SmallPagePanel;
