import React, { forwardRef } from "react";

import {
  Box,
  SwipeableDrawer,
  SwipeableDrawerProps,
  useTheme,
} from "@mui/material";

import {
  ModalOverlayColor,
  SwipeableDrawerAnchor,
} from "../../utils/modal-utils";
import SwipeableDrawerBar from "./SwipeableDrawerBar";

type WrappedDrawerProps = SwipeableDrawerProps & {
  anchor: SwipeableDrawerAnchor;
  fullBleed?: boolean;
  overlayColor?: ModalOverlayColor;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  hideSwipeBar?: boolean;
};

const WrappedDrawer: React.FC<WrappedDrawerProps> = forwardRef(
  (
    {
      anchor,
      fullBleed,
      overlayColor,
      ariaLabelledBy,
      ariaDescribedBy,
      children,
      hideSwipeBar,
      ...props
    },
    ref
  ) => {
    const { spacing } = useTheme();

    const determinePadding = () => {
      if (fullBleed) {
        return 0;
      }
      // The swipeable bar is 3px in height, which we subtract from the normal
      // 24px of padding between the bar and the drawer content
      if (anchor === "top") {
        return spacing(8, 6, 5.25);
      }
      return spacing(5.25, 6, 8);
    };

    return (
      <SwipeableDrawer
        anchor={anchor}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        ref={ref}
        className={overlayColor ? `overlay-${overlayColor}` : "overlay-medium"}
        disableDiscovery
        disableSwipeToOpen
        {...props}
      >
        {anchor === "bottom" && !hideSwipeBar && (
          <SwipeableDrawerBar anchor={anchor} fullBleed={fullBleed} />
        )}

        <Box
          className="container"
          sx={{
            width: fullBleed ? "100%" : "auto",
            padding: determinePadding,
            overflowY: fullBleed ? "hidden" : "auto",
          }}
        >
          {children}
        </Box>

        {anchor === "top" && (
          <SwipeableDrawerBar anchor={anchor} fullBleed={fullBleed} />
        )}
      </SwipeableDrawer>
    );
  }
);

WrappedDrawer.displayName = "WrappedDrawer";

export default WrappedDrawer;
export type { WrappedDrawerProps };
