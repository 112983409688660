import { paymentInstrumentsActions } from "~common/store/slices/paymentInstruments-slice";
import { selectPaymentInstruments } from "~src/store";

import useLazyService from "./useLazyService";

const usePaymentInstruments = () => {
  const {
    data: paymentInstruments,
    loading,
    error,
  } = useLazyService(selectPaymentInstruments, paymentInstrumentsActions);

  return { paymentInstruments, loading, error };
};

export default usePaymentInstruments;
