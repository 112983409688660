import React from "react";
import { Link } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { PhoneBuzzingIcon } from "~common/components/icons/vector";
import { useTracking } from "~common/tracking";

import WhatNextPrompt from "./WhatNextPrompt";

const SmsRemindersPrompt: React.VFC = () => {
  const { trackEvent } = useTracking();

  return (
    <WhatNextPrompt
      body={
        <Stack spacing={1}>
          <PhoneBuzzingIcon size="small" sx={{ mb: 3 }} />
          <Typography variant="h2">Turn on text reminders</Typography>

          <Typography variant="bodyRegular" color="grey.600">
            Let us keep track of credit expirations for you. We&apos;ll send
            automated text messages if your credits are about to expire.
          </Typography>
        </Stack>
      }
      footer={
        <Link
          to={{
            pathname: "/account",
            hash: "#communication",
          }}
        >
          <DefaultButton
            fullWidth
            onClick={() => trackEvent("Update preferences click")}
          >
            Update preferences
          </DefaultButton>
        </Link>
      }
    />
  );
};

export default SmsRemindersPrompt;
