import React from "react";

import SimpleCheckboxOption from "./SimpleCheckboxOption";

type SimpleMultiselectProps = {
  options: string[];
  selectedIndices: number[];
  onSelectionChange: (selectedIndices: number[]) => void;
};

const SimpleMultiselect: React.VFC<SimpleMultiselectProps> = ({
  options,
  selectedIndices,
  onSelectionChange,
}) => (
  <>
    {options.map((option, index) => {
      const isSelected = selectedIndices?.includes(index) === true;
      return (
        <SimpleCheckboxOption
          label={option}
          selected={isSelected}
          key={option}
          onSelect={() =>
            onSelectionChange(
              isSelected
                ? selectedIndices?.filter((value) => value !== index)
                : [...selectedIndices, index]
            )
          }
        />
      );
    })}
  </>
);

export default SimpleMultiselect;
