import React from "react";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";

type PinkArrowButtonProps = {
  direction: "left" | "right";
};

const PinkArrowButton: React.VFC<PinkArrowButtonProps> = ({
  direction = "right",
}) => (
  <Box
    sx={() => ({
      // NB: We use these CSS variables because Swiper React doesn't seem to
      // actually give us access to the buttons in React, which is why we
      // render them in their own React tree, and pass down these dynamic values
      // through parent CSS variables in the DOM.
      backgroundColor: "var(--swiper-button-background)",
      boxShadow: "var(--swiper-button-shadow)",
      cursor: "var(--swiper-button-cursor)",
      border: `1px solid #ffffff`,
      width: 52,
      height: 52,
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ffffff",
      transition: "all 0.125s",
      "& > .MuiSvgIcon-root": {
        width: 24,
        height: 24,
        transition: "all 0.125s",
      },
      "&:hover": {
        backgroundColor: "var(--swiper-button-background-hover)",
        "& > .MuiSvgIcon-root": {
          transform: "scale(1.05)",
        },
      },
      "&:active": {
        "& > .MuiSvgIcon-root": {
          transform: "scale(0.95)",
        },
      },
    })}
  >
    {direction === "right" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
  </Box>
);

export default PinkArrowButton;
