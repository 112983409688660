import React from "react";

type AndroidGooglePlayIconProps = {
  colorIcon?: boolean;
};

const AndroidGooglePlayIcon: React.VFC<AndroidGooglePlayIconProps> = ({
  colorIcon,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="134.4"
    height="31.1"
    x="0"
    y="0"
    viewBox="0 0 134.3 31.1"
    aria-label="Get it on Google Play"
  >
    {colorIcon ? (
      <>
        <path
          style={{ fill: "url(#a)" }}
          d="M.5 1.5c-.3.3-.5.9-.5 1.6v24.8c0 .7.2 1.2.5 1.6l.1.1 14.3-13.9v-.3L.5 1.5z"
        />
        <path
          style={{ fill: "url(#b)" }}
          d="m19.6 20.3-4.8-4.6v-.3l4.8-4.6.1.1 5.6 3.1c1.6.9 1.6 2.3 0 3.2l-5.6 3.1h-.1z"
        />
        <path
          style={{ fill: "url(#c)" }}
          d="m19.8 20.2-4.9-4.7-14.4 14c.5.5 1.4.6 2.4.1l16.9-9.4z"
        />
        <path
          style={{ fill: "url(#d)" }}
          d="M19.8 10.8 2.9 1.4c-1-.5-1.8-.4-2.4.1l14.4 14 4.9-4.7z"
        />
        <defs>
          <linearGradient
            id="a"
            gradientUnits="userSpaceOnUse"
            x1="13.474"
            y1="4.677"
            x2="-5.368"
            y2="24.001"
            gradientTransform="matrix(1 0 0 -1 0 33)"
          >
            <stop offset="0" style={{ stopColor: "#00a0ff" }} />
            <stop offset=".007" style={{ stopColor: "#00a1ff" }} />
            <stop offset=".26" style={{ stopColor: "#00beff" }} />
            <stop offset=".512" style={{ stopColor: "#00d2ff" }} />
            <stop offset=".76" style={{ stopColor: "#00dfff" }} />
            <stop offset="1" style={{ stopColor: "#00e3ff" }} />
          </linearGradient>
          <linearGradient
            id="b"
            gradientUnits="userSpaceOnUse"
            x1="27.487"
            y1="17.505"
            x2="-.384"
            y2="17.505"
            gradientTransform="matrix(1 0 0 -1 0 33)"
          >
            <stop offset="0" style={{ stopColor: "#ffe000" }} />
            <stop offset=".409" style={{ stopColor: "#ffbd00" }} />
            <stop offset=".775" style={{ stopColor: "orange" }} />
            <stop offset="1" style={{ stopColor: "#ff9c00" }} />
          </linearGradient>
          <linearGradient
            id="c"
            gradientUnits="userSpaceOnUse"
            x1="7.568"
            y1="10.778"
            x2="-17.983"
            y2="36.984"
            gradientTransform="matrix(1 0 0 -1 0 33)"
          >
            <stop offset="0" style={{ stopColor: "#ff3a44" }} />
            <stop offset="1" style={{ stopColor: "#c31162" }} />
          </linearGradient>
          <linearGradient
            id="d"
            gradientUnits="userSpaceOnUse"
            x1="-12.11"
            y1="4.047"
            x2="-.7"
            y2="15.749"
            gradientTransform="matrix(1 0 0 -1 0 33)"
          >
            <stop offset="0" style={{ stopColor: "#32a071" }} />
            <stop offset=".069" style={{ stopColor: "#2da771" }} />
            <stop offset=".476" style={{ stopColor: "#15cf74" }} />
            <stop offset=".801" style={{ stopColor: "#06e775" }} />
            <stop offset="1" style={{ stopColor: "#00f076" }} />
          </linearGradient>
        </defs>
      </>
    ) : (
      <path
        fill="currentColor"
        d="M.5 1.3c-.3.4-.5.9-.5 1.6v24.8c0 .7.2 1.2.5 1.6l.1.1 14.3-13.9v-.3L.5 1.3zM21.4 20.1l-4.8-4.6v-.3l4.8-4.6.1.1 5.6 3.1c1.6.9 1.6 2.3 0 3.2l-5.6 3-.1.1zM20.6 20.9l-4.9-4.7-14.4 14c.5.5 1.4.6 2.4.1l16.9-9.4zM20.6 9.7 3.8.4c-1-.5-1.9-.5-2.4.1l14.4 14 4.8-4.8z"
      />
    )}

    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="4.607410e-02"
      strokeMiterlimit="10"
      d="M44 4.3c0 .9-.3 1.7-.9 2.2-.7.7-1.5 1-2.5 1s-1.8-.3-2.5-1-1-1.5-1-2.5.3-1.8 1-2.5 1.5-1 2.5-1c.5 0 1 .1 1.4.3.4.2.8.4 1.1.8l-.6.6c-.5-.5-1.1-.8-1.9-.8-.7 0-1.4.2-1.9.7-.5.5-.8 1.1-.8 1.9 0 .8.3 1.4.8 1.9s1.2.7 1.9.7c.8 0 1.4-.3 1.9-.8.3-.3.5-.8.6-1.4h-2.5v-.6h3.3c.1.2.1.4.1.5zM49.3 1.5h-3.1v2.1H49v.8h-2.8v2.1h3.1v.8h-4V.7h4v.8zM53.1 7.4h-.9V1.5h-1.9V.7H55v.8h-1.9v5.9zM58.4 7.4V.7h.9v6.7h-.9zM63.2 7.4h-.9V1.5h-1.9V.7h4.8v.8h-1.9v5.9zM74.2 6.6c-.7.7-1.5 1-2.5 1s-1.9-.3-2.5-1c-.7-.7-1-1.5-1-2.5s.3-1.8 1-2.5 1.5-1 2.5-1 1.8.3 2.5 1 1 1.5 1 2.5-.4 1.8-1 2.5zM69.8 6c.5.5 1.1.8 1.9.8a2.732 2.732 0 0 0 2.7-2.7c0-.8-.3-1.4-.8-1.9s-1.1-.8-1.9-.8c-.7 0-1.4.3-1.9.8-.6.4-.8 1.1-.8 1.9 0 .8.2 1.4.8 1.9zM76.4 7.4V.7h1.1l3.4 5.2V.7h.9v6.7h-.9l-3.5-5.5v5.5h-1z"
    />
    <path
      fill="currentColor"
      d="M67.9 17.3c-2.7 0-4.9 2-4.9 4.8 0 2.7 2.2 4.8 4.9 4.8s4.9-2 4.9-4.8-2.2-4.8-4.9-4.8zm0 7.6c-1.5 0-2.8-1.2-2.8-2.9s1.3-2.9 2.8-2.9c1.5 0 2.8 1.2 2.8 2.9-.1 1.8-1.4 2.9-2.8 2.9zm-10.8-7.6c-2.7 0-4.9 2-4.9 4.8 0 2.7 2.2 4.8 4.9 4.8s4.9-2 4.9-4.8c.1-2.8-2.2-4.8-4.9-4.8zm0 7.6c-1.5 0-2.8-1.2-2.8-2.9s1.3-2.9 2.8-2.9c1.5 0 2.8 1.2 2.8 2.9 0 1.8-1.3 2.9-2.8 2.9zm-12.7-6.2v2h5c-.1 1.1-.5 2-1.1 2.5-.7.7-1.9 1.5-3.8 1.5-3.1 0-5.5-2.4-5.5-5.4 0-3 2.4-5.4 5.5-5.4 1.7 0 2.9.6 3.7 1.4l1.5-1.4c-1.2-1.2-2.9-2-5.2-2-4.2 0-7.7 3.3-7.7 7.4 0 4.1 3.5 7.4 7.7 7.4 2.3 0 4-.7 5.3-2.1 1.4-1.3 1.8-3.2 1.8-4.7 0-.5 0-.9-.1-1.3h-7.1zm52.2 1.6c-.4-1.1-1.7-3-4.2-3s-4.6 1.9-4.6 4.8c0 2.7 2.1 4.8 4.9 4.8 2.3 0 3.6-1.3 4.1-2.1L95 23.6c-.6.8-1.3 1.3-2.4 1.3-1.1 0-1.9-.5-2.4-1.4l6.6-2.6-.2-.6zm-6.7 1.6c-.1-1.8 1.5-2.8 2.6-2.8.9 0 1.6.4 1.8 1l-4.4 1.8zm-5.3 4.6h2.2v-14h-2.2v14zM81 18.3c-.6-.6-1.5-1.1-2.6-1.1-2.5 0-4.7 2.1-4.7 4.8 0 2.7 2.2 4.8 4.7 4.8 1.2 0 2.1-.5 2.6-1.1v.7c0 1.8-1 2.8-2.6 2.8-1.3 0-2.1-.9-2.5-1.7l-1.9.8c.5 1.3 2 2.8 4.3 2.8 2.5 0 4.7-1.4 4.7-5v-8.6h-2v.8zm-2.4 6.6c-1.5 0-2.7-1.2-2.7-2.9s1.2-2.9 2.7-2.9c1.5 0 2.6 1.2 2.6 2.9s-1.2 2.9-2.6 2.9zm28-12.4h-5.2v14h2.1v-5.3h3c2.4 0 4.7-1.7 4.7-4.4.2-2.6-2.2-4.3-4.6-4.3zm.1 6.8h-3.1v-4.8h3.1c1.6 0 2.5 1.3 2.5 2.4 0 1-.9 2.4-2.5 2.4zm13.3-2.1c-1.6 0-3.2.7-3.8 2.1l1.9.8c.4-.8 1.2-1 2-1 1.1 0 2.2.7 2.3 1.8v.1c-.4-.2-1.2-.5-2.2-.5-2.1 0-4.1 1.1-4.1 3.2 0 1.9 1.7 3.1 3.6 3.1 1.4 0 2.2-.6 2.7-1.4h.1v1.1h2.1v-5.4c-.2-2.5-2.2-3.9-4.6-3.9zm-.3 7.7c-.7 0-1.7-.3-1.7-1.2 0-1.1 1.2-1.5 2.3-1.5.9 0 1.4.2 2 .5-.2 1.3-1.3 2.2-2.6 2.2zm12.2-7.3-2.5 6.1h-.1l-2.6-6.1h-2.3l3.8 8.5-2.2 4.7h2.2l5.9-13.2h-2.2zm-19.3 8.9h2.2v-14h-2.2v14z"
    />
  </svg>
);

export default AndroidGooglePlayIcon;
