import React from "react";

import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { Box, CircularProgress, Stack } from "@mui/material";

type TextMarkerProps = {
  primary?: boolean;
  loading?: boolean;
};

const CompletionTextMarker: React.FC<TextMarkerProps> = ({
  primary,
  loading,
  children,
}) => (
  <Stack
    direction="row"
    spacing={1.5}
    sx={{
      alignItems: "center",
      typography: "h4",
    }}
  >
    <Box
      component="span"
      sx={{
        display: "inline-flex",
        opacity: primary && !loading ? 1 : 0.48,
        transform: loading ? "scale(0.8)" : "none",
      }}
    >
      {loading ? (
        <CircularProgress color="inherit" size={16} />
      ) : (
        <CheckCircleIcon
          color={primary ? "primary" : "inherit"}
          fontSize="medium"
        />
      )}
    </Box>
    <span>{children}</span>
  </Stack>
);

CompletionTextMarker.defaultProps = {
  primary: false,
  loading: false,
};

export default CompletionTextMarker;
