import React from "react";

import {
  AccountBalance as BankIcon,
  Add as AddIcon,
  Lock as LockIcon,
} from "@mui/icons-material";

import { BankButton, DefaultButton } from "../../controls/buttons";
import InformationalButton from "../../controls/buttons/InformationalButton";
import { CreditCardIcon } from "../../icons/vector";

type ConnectPaymentInstrumentButtonsProps = {
  isModal: boolean;
  variant: string;
  cardButtonCopy: string;
  bankButtonEventName: string;
  handleBankClick: (eventName: string, noReconnect?: boolean) => () => void;
  handleCardClick: () => void;
};

const ConnectPaymentInstrumentButtons: React.VFC<
  ConnectPaymentInstrumentButtonsProps
> = ({
  isModal,
  variant,
  handleBankClick,
  handleCardClick,
  cardButtonCopy,
  bankButtonEventName,
}) => (
  <>
    {isModal ? (
      <>
        <BankButton onClick={handleCardClick} startIcon={<LockIcon />}>
          {cardButtonCopy}
        </BankButton>
        {variant !== "ErrorCardVerificationFailed" && variant !== "Retry" && (
          <DefaultButton
            onClick={handleBankClick(bankButtonEventName)}
            startIcon={<LockIcon />}
          >
            Link a bank account
          </DefaultButton>
        )}
      </>
    ) : (
      <>
        <InformationalButton
          onClick={handleCardClick}
          labelIcon={
            <CreditCardIcon
              size="icon"
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          }
          label={<>Link debit card</>}
          informationalText="Provide the information listed on your debit card to effortlessly link it to Catch."
          actionIcon={<AddIcon />}
        />
        <InformationalButton
          onClick={handleBankClick(bankButtonEventName)}
          labelIcon={<BankIcon />}
          label={<>Link bank</>}
          informationalText="Sign in to your bank to instantly link your account. Just like Venmo or Cash App."
          actionIcon={<AddIcon />}
          sx={{
            "&.MuiButton-sizeLarge": {
              mt: -1,
            },
            "&.MuiButton-outlined.MuiButton-colorInherit": {
              borderTop: "none",
            },
          }}
        />
      </>
    )}
  </>
);

export default ConnectPaymentInstrumentButtons;
