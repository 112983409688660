import { RootState } from "../store";

const selectNuxs = (state: RootState) => state.nuxs.nuxs;

const selectShowAppAdoptionNux = (state: RootState) =>
  state.nuxs.showAppAdoptionNux;

const selectAppAdoptionNuxOpen = (state: RootState) =>
  state.nuxs.appAdoptionNuxOpen;

export { selectAppAdoptionNuxOpen, selectNuxs, selectShowAppAdoptionNux };
