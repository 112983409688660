import React, { createContext, useContext } from "react";

import { PatchCheckoutData } from "../components/bank-linking/bankLinking-types";
import EventBus from "../utils/EventBus";

type BankLinkingContextOptions = {
  patchCheckoutData: PatchCheckoutData;
  checkoutId: string | null;
  adyenClientKey: string;
  tellerAppId: string;
  bus: EventBus<"BANK_LINK_UPDATE_RECEIVED" | "USER_IDLE"> | null;
};

type BankLinkingProviderOptions = {
  patchCheckoutData?: PatchCheckoutData;
  checkoutId?: string | null;
  tellerAppId: string;
  adyenClientKey: string;
  bus: EventBus<"BANK_LINK_UPDATE_RECEIVED" | "USER_IDLE"> | null;
};

const defaultValue: BankLinkingContextOptions = {
  patchCheckoutData: () => {},
  checkoutId: null,
  tellerAppId: "",
  adyenClientKey: "",
  bus: null,
};

const BankLinkingContext =
  createContext<BankLinkingContextOptions>(defaultValue);

BankLinkingContext.displayName = "CatchBankLinkingContext";

type BankLinkingProviderProps = {
  options: BankLinkingProviderOptions;
};

const BankLinkingProvider: React.FC<BankLinkingProviderProps> = ({
  options,
  children,
}) => (
  <BankLinkingContext.Provider value={{ ...defaultValue, ...options }}>
    {children}
  </BankLinkingContext.Provider>
);

const useBankLinkingOptions = () => useContext(BankLinkingContext);

export { BankLinkingContext, BankLinkingProvider, useBankLinkingOptions };
