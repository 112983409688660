import React, { createContext, useContext } from "react";

import { AppEnvironment, AppStage } from "../utils/config-utils";

type EnvContextValues = {
  APP_ENVIRONMENT: AppEnvironment;
  APP_STAGE: AppStage;
  EXTERNAL_ASSETS_BASE_URL: string;
  STATSIG_KEY: string;
};

type EnvProviderProps = {
  env: EnvContextValues;
};

const defaultValues: EnvContextValues = {
  APP_ENVIRONMENT: "development",
  APP_STAGE: "sandbox",
  EXTERNAL_ASSETS_BASE_URL: "https://assets.getcatch.com",
  STATSIG_KEY: "client-FTB0onLYajFyclvfnba7ASesRlDjItQYlSd1FlUVa0b",
};

const EnvContext = createContext(defaultValues);
EnvContext.displayName = "CatchEnvContext";

const EnvProvider: React.FC<EnvProviderProps> = ({ env, children }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);

const useEnv = () => useContext(EnvContext);

export { EnvContext, EnvProvider, useEnv };
