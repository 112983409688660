import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Stack } from "@mui/material";

import PinInputStatusBar from "~common/components/auth/PinInputStatusBar";
import VerifyPhoneDisclaimer from "~common/components/auth/VerifyPhoneDisclaimer";
import { PinInput } from "~common/components/controls/text-fields";
import { AnimatedPhoneBuzzingIcon } from "~common/components/icons/animated-vector";
import { useLocalDeviceToken } from "~common/hooks/device-token-hooks";
import {
  useAnswerOTP,
  useGetPublicUserData,
  useInitiateAuth,
} from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { AnswerOtpErrorResponseData } from "~common/services/types/error-handling-types";
import { useTracking, useTrackPageView } from "~common/tracking";
import { delay } from "~common/utils/delays";
import { formatUSPhoneNumber } from "~common/utils/phone-number";
import AnswerOTPErrorPanel, {
  AnswerOTPServiceError,
} from "~src/components/auth/AnswerOTPErrorPanel";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import { selectAuthSession, selectClaims } from "~src/store";
import {
  clearAuthSession,
  setAuthSession,
  setAuthState,
  setPublicData,
} from "~src/store/slices/user-slice";

// TODO (UPv2) Separate logic and UI into smaller files.

const AnswerOTP: React.VFC = () => {
  useTrackPageView("Verify Phone Pin");

  const { trackEvent, trackError, clearUserTraits } = useTracking();
  const authSession = useSelector(selectAuthSession);
  const claims = useSelector(selectClaims);
  const dispatch = useDispatch();
  const { mutate: answerOTP } = useAnswerOTP();
  const { mutate: initiateAuth } = useInitiateAuth();
  const [deviceToken, setDeviceToken] = useLocalDeviceToken();
  const { refetch: loadPublicUserData } = useGetPublicUserData({
    lazy: true,
  });

  const [pin, setPin] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);
  const [serviceError, setServiceError] =
    useState<AnswerOTPServiceError | null>(null);

  const hasServiceError = serviceError !== null;
  const hasIncorrectCodeError = serviceError === "incorrect-code";

  if (!authSession) {
    return <Navigate to="/sign-in" replace />;
  }

  const clearErrors = (): void => {
    if (hasServiceError) {
      setServiceError(null);
    }
  };

  if (hasServiceError && !hasIncorrectCodeError) {
    const handleCodeResent = () => {
      setPin("");
      clearErrors();
    };

    return (
      <AnswerOTPErrorPanel
        serviceError={serviceError}
        onCodeResent={handleCodeResent}
      />
    );
  }

  const process = async (value: string): Promise<void> => {
    trackEvent("Phone PIN Submitted");
    setIsProcessing(true);
    try {
      const data = await answerOTP({
        otp: value,
        session: authSession.id,
        phone_number: authSession.phoneNumber,
        device_token: deviceToken,
        referral_code: claims.referralCode,
      });

      trackEvent("Phone PIN Successfully Verified");

      setIsProcessing(false);
      setIsVerified(true);
      await delay(1);

      dispatch(setAuthState(data));
      const newDeviceToken = data.device_token;
      setDeviceToken(newDeviceToken || null);
      if (newDeviceToken) {
        try {
          const publicUserData = await loadPublicUserData({
            pathParams: { deviceToken: newDeviceToken },
          });
          if (publicUserData) {
            dispatch(setPublicData(publicUserData));
          }
        } catch (rawError) {
          const error = convertRawServiceError(rawError);
          trackError("AnswerOTP", "Accessing public user data", {
            error,
          });
        }
      }
      dispatch(clearAuthSession());
    } catch (rawError) {
      const error =
        convertRawServiceError<AnswerOtpErrorResponseData>(rawError);
      const { error_type: errorType, message, data } = error;
      // If a session was returned as part of the error data, it is needed for the next
      // try to /answer_otp.
      const newSessionId = data?.session;
      if (newSessionId) {
        dispatch(
          setAuthSession({
            id: newSessionId,
            phoneNumber: authSession.phoneNumber,
          })
        );
      }
      if (errorType === "NotAuthorizedException") {
        // A NotAuthorizedException indicates that the user has failed to enter the
        // correct code the max number of times or hasn't entered the correct code
        // within the time limit.
        if (message === "Incorrect username or password.") {
          setServiceError("max-attempts");
        } else if (message === "Invalid session for the user.") {
          setServiceError("expired");
        }
      } else if (errorType === "INCORRECT_CODE") {
        setServiceError("incorrect-code");
      } else {
        // (Service error) Phone PIN verification failed.
        setServiceError("generic");
      }
      trackError("AnswerOTP", `Errored on ${errorType}`, {
        error,
      });
      setIsProcessing(false);
    }
  };

  const handlePinInputUpdateValue = (value: string): void => {
    setPin(value);
    clearErrors();
    if (value.length === 6) {
      void process(value);
    }
  };

  const handleResendCodeClick = async (): Promise<void> => {
    trackEvent("Resend Code Clicked");
    setPin("");
    clearErrors();
    setIsCodeResent(true);
    const { phoneNumber } = authSession;
    try {
      const { session } = await initiateAuth({
        phone_number: phoneNumber,
      });
      dispatch(
        setAuthSession({
          id: session,
          phoneNumber,
        })
      );
    } catch (rawError) {
      const error = convertRawServiceError(rawError);
      trackError("AnswerOTP", "Resending code", { error });
    }
  };

  const handleUseDifferentNumberClick = (): void => {
    trackEvent("Edit Phone Number Clicked");
    clearUserTraits();
    dispatch(clearAuthSession());
  };

  return (
    <SmallPagePanel
      icon={
        claims.newUserOnboardingRewardCampaign ? (
          <CatchCardReward
            reward={claims.newUserOnboardingRewardCampaign}
            percentComplete={(1 / 7) * 100}
          />
        ) : (
          <AnimatedPhoneBuzzingIcon />
        )
      }
      title="Buzz buzz, did you get our text?"
      subtitle={`Enter the six-digit code sent to ${formatUSPhoneNumber(
        authSession.phoneNumber
      )} to log into Catch or create an account.`}
    >
      <Stack spacing={4}>
        <PinInput
          value={pin}
          updateValue={handlePinInputUpdateValue}
          error={hasIncorrectCodeError}
          disabled={isProcessing || isVerified}
        />

        <PinInputStatusBar
          {...{
            isProcessing,
            isVerified,
            isCodeResent,
            handleResendCodeClick,
            handleUseDifferentNumberClick,
          }}
        />
      </Stack>

      <VerifyPhoneDisclaimer />
    </SmallPagePanel>
  );
};

export default AnswerOTP;
