import React from "react";

import { Box, BoxProps } from "@mui/material";

const CatchCard: React.FC<BoxProps> = (props) => (
  <Box borderRadius="6%/10%" overflow="hidden" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 327 206"
      xmlSpace="preserve"
      style={{ display: "block" }}
      aria-label="Catch card"
    >
      <path fill="#6b27dd" d="M0 0h327v206H0z" />
      <path
        d="M51.6 40.4c-2.5 0-4.7-.8-6.4-2.5s-2.6-3.9-2.6-6.6.9-4.9 2.6-6.6c1.8-1.7 3.9-2.6 6.3-2.6 2.3 0 4.1.6 5.4 1.7 1.4 1.1 2.3 2.5 2.7 4.1l-3.2 1c-.2-1-.8-1.8-1.6-2.5s-1.9-1-3.3-1-2.7.5-3.8 1.5-1.6 2.5-1.6 4.3.5 3.2 1.6 4.2 2.4 1.6 3.9 1.6c1.4 0 2.5-.4 3.3-1.1.8-.7 1.4-1.6 1.7-2.5l3.2 1c-.2.7-.5 1.4-.9 2.1-.4.7-.9 1.3-1.6 1.9-.6.6-1.5 1.1-2.5 1.4-.9.4-2 .6-3.2.6zm9.7-3.7c0-1 .3-1.9 1-2.5s1.6-1 2.6-1.2l3-.4c.6-.1.9-.4.9-.9s-.2-.8-.5-1.1c-.3-.3-.9-.4-1.5-.4-.7 0-1.2.2-1.7.6-.4.4-.6.9-.7 1.4l-2.9-.6c.1-1.1.6-2 1.6-2.8.9-.8 2.2-1.2 3.7-1.2 1.8 0 3.2.4 4 1.3.9.9 1.3 2 1.3 3.3v6c0 .7 0 1.4.1 1.9h-3c-.1-.4-.1-.9-.1-1.5-.8 1.2-2 1.8-3.6 1.8-1.3 0-2.3-.4-3.1-1.1-.7-.8-1.1-1.6-1.1-2.6zm4.9 1.2c.8 0 1.4-.2 1.9-.6.5-.4.8-1.2.8-2.2v-.5l-2.8.4c-1 .1-1.5.7-1.5 1.5 0 .4.1.7.4 1s.7.4 1.2.4zm13.2-13.8v3.6h2.5v2.9h-2.5v5.1c0 .5.1.9.3 1.1.2.2.6.3 1.1.3.4 0 .8 0 1-.1v2.7c-.5.2-1.1.3-1.9.3-1.2 0-2.1-.3-2.8-1-.7-.7-1-1.6-1-2.8v-5.7h-2.2v-2.9h.6c.6 0 1.1-.2 1.4-.5.3-.4.5-.8.5-1.4V24h3v.1zM90 30.5c-.9 0-1.6.3-2.2.9s-.9 1.4-.9 2.5.3 1.9.9 2.5 1.4.9 2.2.9c.8 0 1.4-.2 1.9-.6.5-.4.8-.9.9-1.5l2.9 1c-.3 1.1-.9 2.1-1.9 2.9-1 .8-2.3 1.2-3.8 1.2-1.8 0-3.4-.6-4.6-1.8s-1.8-2.8-1.8-4.6c0-1.9.6-3.4 1.8-4.6 1.2-1.2 2.7-1.8 4.5-1.8 1.6 0 2.8.4 3.8 1.2 1 .8 1.6 1.8 1.9 2.9l-3 1c-.3-1.4-1.2-2.1-2.6-2.1zm11.5 2.3V40h-3.3V22h3.3v6.8c.7-.9 1.8-1.3 3.3-1.3s2.6.5 3.4 1.4 1.2 2.1 1.2 3.5V40H106v-7.1c0-.7-.2-1.3-.6-1.8-.4-.4-.9-.7-1.7-.7-.7 0-1.2.2-1.6.7s-.6 1-.6 1.7z"
        fill="#fff"
      />
      <path
        d="M33.6 26.7C32 22 21.4 17.4 18.2 16.1c-.6-.2-1.3-.1-1.7.4-.7.7-.6 1.9.3 2.5.2.2.5.3.8.5 2 1.3 4.8 3.3 6.9 5.4 2.3 2.2 4.3 5 5.4 8 .8 2 1.2 4.1 1 6.1 1.9-4.1 3.8-9.3 2.7-12.3zm-8.5 15.6c.9-3.3 2.5-9 2-12.7.7 1.1 1.4 2.3 1.9 3.6 1.3 3.3 1.4 6.7-.1 9.9-.3.6-.6 1.1-.9 1.5-.3.6-1 .9-1.7.8-1-.2-1.7-1.2-1.4-2.2 0-.2.1-.5.2-.9z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
      <path d="M326.7 186.9c0 10.8-8.8 19.6-19.6 19.6H19.3" fill="none" />
      <circle cx="270.7" cy="175.3" r="20.4" fill="#d8332a" />
      <circle cx="296.2" cy="175.3" r="20.4" fill="#eb9636" />
      <path
        d="M283.5 191.2c4.7-3.7 7.7-9.5 7.7-16s-3-12.2-7.7-16c-4.7 3.7-7.7 9.5-7.7 16s3 12.3 7.7 16z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#e25e2e"
      />
      <path
        d="M326.9 100.2c-15.5 5.3-34 7.7-53.8 6.7-8-.4-15.3-.9-22.4-1.4-37-2.7-52.3-3.8-70.2 18.6-.9 1.2-1.8 2.3-2.7 3.4-11.9 14.9-23.1 28.9-39.5 37.1-18.8 9.4-40.2 9.2-67.1-.8-31.5-11.6-56.6 3.5-71.2 16.6v-43.3c21.1-11.7 49.8-19.1 83.5-6.7 22.5 8.3 33 5.3 38.9 2.3 9.4-4.7 17.8-15.3 27.6-27.5.9-1.2 1.9-2.3 2.8-3.5 29.6-36.9 60.9-34.6 100.5-31.8 6.8.5 13.9 1 21.5 1.4 23.4 1.1 41.5-3.7 52.1-10.2v39.1z"
        fill="#e052a8"
      />
    </svg>
  </Box>
);

export default CatchCard;
