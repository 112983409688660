import React from "react";
import { Navigate } from "react-router-dom";

import { useTrackPageView } from "~common/tracking";

const NotFound: React.VFC = () => {
  useTrackPageView("Not Found");

  return <Navigate to="/home" replace />;
};

export default NotFound;
