import React from "react";

import { Box } from "@mui/material";

import BorderedBox from "~common/components/primitives/BorderedBox";

type WhatNextPromptProps = {
  header?: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
};

const WhatNextPrompt: React.VFC<WhatNextPromptProps> = ({
  header,
  body,
  footer,
}) => (
  <BorderedBox
    sx={({ palette }) => ({
      background: palette.common.white,
      overflow: "hidden",
      display: "grid",
      // Ensure button sections align vertically:
      gridTemplateRows: "max-content 1fr max-content",
      gridTemplateColumns: "1fr",
      height: "100%",
    })}
  >
    {header || <Box />}
    <Box sx={{ p: 6 }}>{body}</Box>
    <Box sx={{ p: 6, pt: 0 }}>{footer}</Box>
  </BorderedBox>
);

export default WhatNextPrompt;
