import React from "react";

import { keyframes } from "@emotion/react";
import { Stack, SxProps, Theme } from "@mui/material";

import { Merchant } from "~common/services";
import useMerchants from "~src/hooks/services/useMerchants";

import MerchantMarqueeBadgeLogo from "./MerchantMarqueeBadgeLogo";

type MerchantMarqueeProps = {
  sx?: SxProps<Theme>;
}

const MerchantMarquee: React.VFC<MerchantMarqueeProps> = ({ sx = {} }) => {
  const { merchants } = useMerchants();

  const badgeDiameter = 56;
  const badgeGapPixels = 16;
  const requiredBadges = 15; // Enough to fill the widest screen.
  const animationDurationPerBadge = 1250; // Milliseconds.
  const marqueeStackWidth =
    (badgeDiameter + badgeGapPixels) * requiredBadges * 2;

  const makeMarqueeMerchantsList = (uniqueMerchants: Merchant[]) => {
    const output = [];

    if (uniqueMerchants.length >= requiredBadges) {
      const requiredBadgesSlice = uniqueMerchants.slice(0, requiredBadges);
      return [...requiredBadgesSlice, ...requiredBadgesSlice];
    }

    if (uniqueMerchants.length === 0) {
      return [];
    }

    for (let index = 0; index < requiredBadges; index += 1) {
      output[index] = uniqueMerchants[index % uniqueMerchants.length];
    }

    return [...output, ...output];
  };

  return (
    <Stack
      justifyContent="center"
      sx={[
        ({ palette }) => ({
          height: 96,
          width: "100%",
          overflow: "hidden",
          background: palette.grey[200],
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        direction="row"
        spacing={`${badgeGapPixels}px`}
        sx={{
          width: marqueeStackWidth,
          animation: `${keyframes`
        from {
          margin-left: 0;
        }
        to {
          margin-left: -${marqueeStackWidth / 2}px;
        }
      `} ${requiredBadges * 2 * animationDurationPerBadge}ms infinite linear`,
        }}
      >
        {makeMarqueeMerchantsList(merchants).map((merchant, index) => (
          <MerchantMarqueeBadgeLogo
            // eslint-disable-next-line react/no-array-index-key
            key={`${merchant.merchant_id}-${index}`}
            merchant={merchant}
            diameter={`${badgeDiameter}px`}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default MerchantMarquee;
