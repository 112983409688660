import { Components, Theme } from "@mui/material";

const buildListItemButton = (theme: Theme): Components["MuiListItemButton"] => {
  const { palette, shape } = theme;

  return {
    styleOverrides: {
      root: {
        "&.Mui-focusVisible": {
          backgroundColor: "transparent",
          outline: "2px solid",
          outlineColor: palette.info.main,
          borderRadius: `${shape.borderRadius / 2}px`,
        },
      },
    },
  };
};

export default buildListItemButton;
