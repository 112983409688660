import React from "react";

import { AccountBalance as AccountBalanceIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import SummaryListItem from "./SummaryListItem";

const PayByBankAndEarn: React.VFC = () => (
  <SummaryListItem icon={<AccountBalanceIcon />}>
    <Typography variant="h3">
      Pay by bank. Earn 5&ndash;10% every time.
    </Typography>
    <Typography paragraph variant="bodySmall" color="grey.600">
      Help brands save on credit card fees and earn credit toward your next
      order.
    </Typography>
  </SummaryListItem>
);

export default PayByBankAndEarn;
