import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Typography } from "@mui/material";

import { setPlaidOpenAllowed } from "../../../../../store/slices/bankLinking-slice";
import { BankLockIcon } from "../../../../icons/vector";
import ExternalTextLink from "../../../../links/ExternalTextLink";
import BankHeader from "../BankHeader";

type BankHeaderReconnectProps = {
  institutionName?: React.ReactNode;
  headingId?: string;
};

const BankHeaderReconnect: React.VFC<BankHeaderReconnectProps> = ({
  institutionName,
  headingId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPlaidOpenAllowed(true));
  }, [dispatch]);

  return (
    <BankHeader icon={<BankLockIcon size="small" />}>
      <Typography variant="h1" id={headingId}>
        Let&apos;s reconnect your {institutionName || "bank"} account
      </Typography>
      <Typography variant="bodyRegular" color="grey.600">
        Looks like we need to reconnect your bank account. This may have
        happened if you recently changed your bank login info.{" "}
        <ExternalTextLink
          size="regular"
          href="https://help.getcatch.com/en/articles/5010842-why-am-i-being-asked-to-relink-my-bank-account-if-i-never-unlinked-it"
          eventName="Why Do I Have To Reconnect Clicked"
        >
          Why do I have to reconnect?
        </ExternalTextLink>
      </Typography>
    </BankHeader>
  );
};

export default BankHeaderReconnect;
