import React from "react";
import { useDispatch } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { BankButton } from "~common/components/controls/buttons";
import { BankWingsIcon } from "~common/components/icons/vector";
import { WrappedTooltip } from "~common/components/tooltips";
import {
  openBankModal,
  setBankLinkingStep,
  setConnectBankVariant,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";

import WhatNextPrompt from "./WhatNextPrompt";

const LinkBankPrompt: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  const buttonText = "Link bank";

  const handleLinkBank = () => {
    dispatch(openBankModal());
    dispatch(setConnectBankVariant("Default"));
    dispatch(setBankLinkingStep("ConnectBank"));
    trackEvent("Bank Linking open click", {
      component: "LinkBankPrompt",
      connectVariant: "Default",
      buttonText,
    });
  };

  const handleTooltipOpen = () => {
    trackEvent("Why link my bank tooltip", {
      component: "LinkBankPrompt",
    });
  };

  return (
    <WhatNextPrompt
      body={
        <Stack spacing={1}>
          <BankWingsIcon size="small" sx={{ mb: 3 }} />
          <Typography variant="h2">Checkout faster</Typography>
          <Typography variant="bodyRegular" component="div" color="grey.600">
            Link a payment method now so you don&apos;t have to later.{" "}
            <WrappedTooltip
              onOpen={handleTooltipOpen}
              title={
                <>
                  When you pay by bank, brands save on credit card fees. Catch
                  makes bank payments safe and easy, so brands can pass their
                  savings to you!
                </>
              }
              placement="top"
              sx={{
                cursor: "default",
              }}
            >
              <Typography color="grey.600" variant="linkRegular">
                Why link my bank?
              </Typography>
            </WrappedTooltip>
          </Typography>
        </Stack>
      }
      footer={
        <BankButton onClick={handleLinkBank} fullWidth>
          {buttonText}
        </BankButton>
      }
    />
  );
};

export default LinkBankPrompt;
