import React from "react";

import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const BankHeaderExistingAccount: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => (
  <ConnectPaymentInstrumentAlertHeader
    headingId={headingId}
    title={<>Too many users have linked that bank account</>}
    subtitle={
      <>
        For security reasons, no more than two Catch users can link the same
        bank account. To continue, please select a different bank account.
      </>
    }
  />
);

export default BankHeaderExistingAccount;
