import React from "react";
import { useDispatch } from "react-redux";

import { alpha, Box, Stack } from "@mui/material";

import { setMenuOpen } from "~src/store/slices/core-slice";

import NavBarContainer from "./NavBarContainer";
import NavItemsAuthed from "./NavItemsAuthed";
import NavLogo from "./NavLogo";
import NavMenuButton from "./NavMenuButton";

const MobileMenu: React.VFC = () => {
  const dispatch = useDispatch();

  const handleOverlayClick = () => {
    dispatch(setMenuOpen(false));
  };

  return (
    <Stack
      sx={{
        position: "fixed",
        width: "100%",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <Box
        sx={({ palette }) => ({
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: alpha(palette.grey[700], 0.72),
        })}
        onClick={handleOverlayClick}
      />

      <NavBarContainer>
        <NavLogo />

        <NavMenuButton />

        <Stack
          spacing={0}
          direction="column"
          alignItems="center"
          sx={({ palette }) => ({
            position: "absolute",
            top: "calc(var(--navbar-height) + 1px)",
            left: 0,
            width: "100%",
            background: palette.common.white,
            zIndex: 1002,
            boxShadow: `0 2px 4px ${alpha(palette.grey[700], 0.25)}`,
          })}
        >
          <NavItemsAuthed />
        </Stack>
      </NavBarContainer>
    </Stack>
  );
};

export default MobileMenu;
