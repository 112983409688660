import React from "react";
import { useDispatch } from "react-redux";

import { Bank } from "../../../banking/banks";
import useMatchesMedia from "../../../hooks/useMatchesMedia";
import {
  setPlaidCustomLinkName,
  setPlaidOpenAllowed,
  setTellerInstitutionId,
} from "../../../store/slices/bankLinking-slice";
import DefaultImageButton from "../../controls/buttons/DefaultImageButton";

type BankPickerItemProps = {
  item: Bank;
  inline?: boolean;
};

const BankPickerItem: React.VFC<BankPickerItemProps> = ({ item, inline }) => {
  const dispatch = useDispatch();

  const isMobile = useMatchesMedia("sm");

  const handleClick =
    item.bank_connector === "plaid"
      ? () => {
          if (item.plaid_custom_link_name) {
            dispatch(setPlaidCustomLinkName(item.plaid_custom_link_name));
            dispatch(setPlaidOpenAllowed(true));
          }
        }
      : () => {
          if (item.teller_institution_id) {
            dispatch(setTellerInstitutionId(item.teller_institution_id));
          }
        };

  const shouldRenderInline = !isMobile && inline;

  return (
    <DefaultImageButton
      onClick={handleClick}
      sx={
        shouldRenderInline
          ? {
              // For inline debit form, we use 4
              // squares per row on wider screens.
              aspectRatio: "1",
              height: "inherit",
              width: "100%",
              overflow: "hidden",
              "&.MuiButton-root": {
                padding: "24px",
                img: {
                  width: "100%",
                  maxWidth: 56,
                  maxHeight: 48,
                },
              },
            }
          : {
              aspectRatio: "1.618 / 1",
              height: "inherit",
              maxHeight: 96,
              width: "100%",
              overflow: "hidden",
              "&.MuiButton-root": {
                padding: "24px",
                img: {
                  width: "100%",
                  maxWidth: 106,
                  maxHeight: 48,
                },
              },
            }
      }
      image={
        shouldRenderInline ? (
          <img src={item.logo_natural_aspect} alt={item.name} />
        ) : (
          <img src={item.logo} alt={item.name} />
        )
      }
    />
  );
};

export default BankPickerItem;
export type { BankPickerItemProps };
