import React, { useMemo } from "react";

import { Stack } from "@mui/material";

import {
  AnimatedMeditatingCoinIcon,
  AnimatedPiggyBankIcon,
} from "../icons/animated-vector";

const icons = [<AnimatedMeditatingCoinIcon />, <AnimatedPiggyBankIcon />];

const IllustrationLoadingScreen: React.VFC = () => {
  const { min, floor, random } = Math;

  const icon = useMemo(
    () => icons[min(floor(random() * icons.length), icons.length - 1)],
    [min, floor, random]
  );

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={({ palette }) => ({
        width: "100%",
        height: "100%",
        mt: 48,
        background: palette.background.default,
      })}
    >
      {icon}
    </Stack>
  );
};

export default IllustrationLoadingScreen;
