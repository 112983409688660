import React from "react";

import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const DebitHeaderCannotConnect: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => (
  <ConnectPaymentInstrumentAlertHeader
    headingId={headingId}
    title={<>Sorry, we are having trouble connecting this card</>}
    subtitle={
      <>
        You can try re-entering the information one more time. Otherwise, we
        recommend you use a new payment option.
      </>
    }
  />
);

export default DebitHeaderCannotConnect;
