import { useGet } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  GetPublicUserDataPathParams,
  GetUserPublicDataQueryParams,
  PublicUserDataResponseData,
  UserDataResponseData,
} from "./types/user-data-types";

const useGetPublicUserData = (
  options: {
    pathParams?: GetPublicUserDataPathParams;
    queryParams?: GetUserPublicDataQueryParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    PublicUserDataResponseData,
    ErrorResponseData,
    GetUserPublicDataQueryParams,
    GetPublicUserDataPathParams
  >({
    path: ({ deviceToken }) =>
      `transactions-svc/user_devices/${deviceToken}/user_data`,
    pathParams: options.pathParams,
    queryParams: options.queryParams,
    localErrorOnly: true,
    lazy: options.lazy,
  });

const useGetUserData = (
  options: {
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<UserDataResponseData, ErrorResponseData, undefined, undefined>({
    path: `transactions-svc/user_data`,
    lazy: options.lazy,
  });

export { useGetPublicUserData, useGetUserData };
