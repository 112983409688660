import React, { forwardRef } from "react";

import WrappedDrawer, { WrappedDrawerProps } from "./WrappedDrawer";

const DefaultDrawer: React.FC<WrappedDrawerProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <WrappedDrawer {...props} ref={ref}>
      {children}
    </WrappedDrawer>
  )
);

DefaultDrawer.displayName = "DefaultDrawer";

export default DefaultDrawer;
