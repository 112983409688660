import React from "react";

import { Box } from "@mui/material";

import {
  MeditatingCoinIcon,
  PartyPopperIcon,
} from "~common/components/icons/vector";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import { formatUSDate } from "~common/utils/dates";
import { formatMoney } from "~common/utils/money";
import AccountInfoBox from "~src/components/account/AccountInfoBox";
import LabeledSection from "~src/components/layout/LabeledSection";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import useUserData from "~src/hooks/services/useUserData";

const AccountInfoSection: React.VFC = () => {
  const { userData, error: errorUserData } = useUserData();

  const { currentUser, error: errorCurrentUser } = useCurrentUser();

  const memberSince = formatUSDate(currentUser?.create_date ?? "");
  const lifetimeCredits = formatMoney(userData?.lifetime_rewards_earned || 0);

  return (
    <LabeledSection heading="Account">
      <LoadingGuard
        {...{
          error: errorUserData || errorCurrentUser,
        }}
      >
        <Box
          sx={({ breakpoints }) => ({
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 6,
            [breakpoints.up("md")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <AccountInfoBox
            icon={
              <MeditatingCoinIcon size="extrasmall" />
            }
            title="Lifetime credits earned"
            subtitle={lifetimeCredits}
            loading={!userData}
          />
          <AccountInfoBox
            icon={<PartyPopperIcon size="extrasmall" />}
            title="Member since"
            subtitle={memberSince}
            loading={!currentUser}
          />
        </Box>
      </LoadingGuard>
    </LabeledSection>
  );
};

export default AccountInfoSection;
