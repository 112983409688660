import React from "react";
import { useSelector } from "react-redux";

import { selectDisableAch } from "../../../../../store/selectors";
import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const DebitHeaderNotUS: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => {
  const disableAch = useSelector(selectDisableAch);

  return (
    <ConnectPaymentInstrumentAlertHeader
      headingId={headingId}
      title={<>Sorry, Catch only supports U.S. debit cards at the moment</>}
      subtitle={
        <>
          But we’re working on it. To continue, please connect a U.S. debit card
          {!disableAch && " or link your U.S. bank account directly."}.
        </>
      }
    />
  );
};

export default DebitHeaderNotUS;
