import { selectExploreMerchants } from "~src/store";
import { exploreMerchantsActions } from "~src/store/slices/services/exploreMerchants-slice";

import useLazyService from "./useLazyService";

const useExploreMerchants = () => {
  const {
    data: exploreMerchants,
    loading,
    error,
  } = useLazyService(selectExploreMerchants, exploreMerchantsActions);

  return { exploreMerchants, loading, error };
};

export default useExploreMerchants;
