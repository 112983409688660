import React from "react";

import { Box, BoxProps } from "@mui/material";

const CatchCardPlaceholders: React.FC<BoxProps> = (props) => (
  <Box borderRadius="6%/10%" overflow="hidden" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 327 206"
      xmlSpace="preserve"
      style={{ display: "block" }}
      aria-label="Catch card with placeholders"
    >
      <defs>
        <clipPath id="clippath">
          <rect
            fill="none"
            strokeWidth="0"
            width="327"
            height="206"
            rx="19.6"
            ry="19.6"
          />
        </clipPath>
        <clipPath id="clippath-2">
          <path fill="none" strokeWidth="0" d="M16 16.3h93.5V47H16z" />
        </clipPath>
        <clipPath id="clippath-3">
          <path fill="none" strokeWidth="0" d="M16 16.3h93.5v29.8H16z" />
        </clipPath>
        <clipPath id="clippath-4">
          <path fill="none" strokeWidth="0" d="M279 88h20v20h-20z" />
        </clipPath>
        <clipPath id="clippath-5">
          <path fill="none" strokeWidth="0" d="M120 158h20v20h-20z" />
        </clipPath>
        <clipPath id="clippath-6">
          <path fill="none" strokeWidth="0" d="M279.5 158h20v20h-20z" />
        </clipPath>
      </defs>
      <path fill="#6b27dd" strokeWidth="0" d="M0 0h327v207H0z" />
      <g clipPath="url(#clippath)">
        <g clipPath="url(#clippath-2)">
          <g clipPath="url(#clippath-3)">
            <path
              fill="#ffffff"
              strokeWidth="0"
              d="M51.6 40.7c-2.5 0-4.7-.8-6.4-2.5-1.7-1.7-2.6-3.9-2.6-6.6s.9-4.9 2.6-6.6c1.8-1.7 3.9-2.6 6.3-2.6s4.1.6 5.4 1.7c1.4 1.1 2.3 2.5 2.7 4.1l-3.2 1c-.2-1-.8-1.8-1.6-2.5s-1.9-1-3.3-1-2.7.5-3.8 1.5c-1.1 1-1.6 2.5-1.6 4.3s.5 3.2 1.6 4.2 2.4 1.6 3.9 1.6 2.5-.4 3.3-1.1c.8-.7 1.4-1.6 1.7-2.5l3.2 1c-.2.7-.5 1.4-.9 2.1-.4.7-.9 1.3-1.6 1.9-.6.6-1.5 1.1-2.5 1.4-1 .4-2.1.5-3.2.5ZM61.3 37c0-1 .3-1.9 1-2.5.7-.6 1.6-1 2.6-1.2l3-.4c.6 0 .9-.4.9-.9s-.2-.8-.5-1.1c-.3-.3-.9-.4-1.5-.4s-1.2.2-1.7.6c-.4.4-.6.9-.7 1.4l-2.9-.6c.1-1.1.6-2 1.6-2.8.9-.8 2.2-1.2 3.7-1.2s3.2.4 4 1.3c.9.9 1.3 2 1.3 3.3v6c0 .7 0 1.4.1 1.9h-3c0-.4-.1-.9-.1-1.5-.8 1.2-2 1.8-3.6 1.8s-2.3-.4-3.1-1.1c-.8-.7-1.1-1.6-1.1-2.6Zm4.9 1.2c.8 0 1.4-.2 1.9-.6.5-.4.8-1.2.8-2.2v-.5l-2.8.4c-1 .1-1.5.7-1.5 1.5s.1.7.4 1c.3.3.7.4 1.2.4ZM79.4 24.4V28h2.5v2.9h-2.5V36c0 .5.1.9.3 1.1.2.2.6.3 1.1.3h1v2.7c-.5.2-1.1.3-1.9.3-1.2 0-2.1-.3-2.8-1-.7-.7-1-1.6-1-2.8v-5.7h-2.2V28h.6c.6 0 1.1-.2 1.4-.5.3-.4.5-.8.5-1.4v-1.7h3ZM90 30.8c-.9 0-1.6.3-2.2.9-.6.6-.9 1.4-.9 2.5s.3 1.9.9 2.5c.6.6 1.4.9 2.2.9s1.4-.2 1.9-.6c.5-.4.8-.9.9-1.5l2.9 1c-.3 1.1-.9 2.1-1.9 2.9-1 .8-2.3 1.2-3.8 1.2s-3.4-.6-4.6-1.8c-1.2-1.2-1.8-2.8-1.8-4.6s.6-3.4 1.8-4.6c1.2-1.2 2.7-1.8 4.5-1.8s2.8.4 3.8 1.2c1 .8 1.6 1.8 1.9 2.9l-3 1c-.4-1.4-1.3-2.1-2.7-2.1ZM101.5 33.1v7.2h-3.3v-18h3.3v6.8c.7-.9 1.8-1.3 3.3-1.3s2.6.5 3.4 1.4c.8.9 1.2 2.1 1.2 3.5v7.7h-3.3v-7.1c0-.7-.2-1.3-.6-1.8-.4-.4-.9-.7-1.7-.7s-1.2.2-1.6.7c-.4.4-.6 1-.6 1.7Z"
            />
            <path
              d="M33.6 27c-1.6-4.8-12.2-9.3-15.5-10.6-.6-.2-1.3 0-1.7.4-.7.7-.6 1.9.3 2.5.2.2.5.3.8.5 2 1.3 4.8 3.3 6.9 5.4 2.3 2.2 4.3 5 5.4 8 .8 2 1.2 4.1 1 6.1 2-4.2 3.8-9.3 2.8-12.3Zm-8.5 15.6c.9-3.3 2.5-9 2-12.7.7 1.1 1.4 2.3 1.9 3.6 1.3 3.3 1.4 6.7-.1 9.9-.3.6-.6 1.1-.9 1.5-.3.6-1 .9-1.7.8-1-.2-1.7-1.2-1.4-2.2 0-.3.2-.6.3-.9Z"
              fill="#fff"
              fillRule="evenodd"
              strokeWidth="0"
            />
          </g>
        </g>
        <rect
          fill="none"
          stroke="#e1d4f8"
          x="12.5"
          y="72.5"
          width="302"
          height="51"
          rx="3.5"
          ry="3.5"
        />
        <path
          fill="#ffffff"
          strokeWidth="0"
          d="M33 91.2v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.4 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.4 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.3 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.4 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.4 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1L56 95l-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm12.7 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.3 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.4 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4H79Zm8.4 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.3 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm11.9 7.5-1-1.6 3.2-2.9h-5.3v-1.9h8.1v1.8l-3.2 2.8c1.7 0 3.5 1.3 3.5 3.5s-1.6 3.8-4.4 3.8-4.3-1.7-4.4-3.7l2.1-.4c0 1.3 1 2.3 2.3 2.3s2.2-.9 2.2-2-1.1-1.9-2.1-1.9-.9.1-1.1.2Zm6.7 2.7v-2.3l5-6.8h2.8v7.2h2v1.9h-2v2.6h-2.1v-2.6h-5.6Zm5.6-1.9v-5l-3.7 5h3.7Zm5.2 1.3 2-.5c0 1.2 1 2.2 2.4 2.2s2.2-.8 2.2-2.1-1.1-2.2-2.2-2.2-1.5.3-1.9.8l-2-.7 1.4-5.9h6.4v1.9h-4.9l-.7 3c.5-.5 1.4-.8 2.2-.8 2.3 0 4 1.4 4 3.9s-1.7 4-4.4 4-4.2-1.7-4.4-3.5Zm19-8.2-.6 1.8c-.4-.2-1-.5-2-.5-2.2 0-3.8 1.5-4 3.7.6-.9 1.7-1.4 3-1.4 2.3 0 4 1.5 4 3.9s-2 4-4.4 4-4.7-2.4-4.7-5.4c0-4.2 2.6-6.8 6.3-6.8s2 .3 2.5.6Zm-6.3 7.7c0 1.3 1.1 2.2 2.3 2.2s2.3-.8 2.3-2.2-1-2.2-2.3-2.2-2.3.8-2.3 2.2Z"
        />
        <g clipPath="url(#clippath-4)">
          <path
            fill="#ffffff"
            strokeWidth="0"
            d="M292.3 88.8h-10c-.9 0-1.7.8-1.7 1.7v11.7h1.7V90.5h10v-1.7Zm2.5 3.4h-9.2c-.9 0-1.7.8-1.7 1.7v11.7c0 .9.8 1.7 1.7 1.7h9.2c.9 0 1.7-.8 1.7-1.7V93.9c0-.9-.8-1.7-1.7-1.7Zm0 13.3h-9.2V93.8h9.2v11.7Z"
          />
        </g>
        <path fill="#6b27dd" strokeWidth="0" d="M24 62h91v20H24z" />
        <path
          fill="#f1eafe"
          strokeWidth="0"
          d="M33.6 77.2c-2.5 0-4.9-1.8-4.9-5.2s2.4-5.2 4.9-5.2 3.9 1.2 4.3 3.2l-1.1.4c-.4-1.6-1.6-2.4-3.2-2.4s-3.7 1.4-3.7 4.1 1.8 4.1 3.7 4.1 2.9-1.1 3.3-2.5l1 .4c-.5 1.9-2.1 3.2-4.4 3.2Zm5.5-2c0-1.2.8-1.8 2-2l1.8-.3c.5 0 .6-.3.6-.6 0-.8-.5-1.3-1.6-1.3s-1.6.6-1.7 1.4l-1.1-.3c.1-1.3 1.3-2.1 2.7-2.1s2.7 1.1 2.7 2.4v4.5h-1.1v-1c-.3.5-1 1.2-2.2 1.2s-2.2-1-2.2-2Zm2.3 1c1.2 0 2.1-.6 2.1-2.1v-.4l-2.2.3c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.2 1.1Zm8.9-5.9v1.2h-.6c-1.1 0-1.9.6-1.9 2.2v3.5h-1.1v-6.7h1.1v1.2c.4-1 1.3-1.3 2-1.3h.5Zm6 5.7v-.2c-.3.7-1 1.3-2.2 1.3-1.9 0-3.1-1.6-3.1-3.6s1.2-3.5 3.1-3.5 1.9.7 2.2 1.3v-4.5h1.1V77h-1.1v-1Zm-2.1.2c1.3 0 2.1-1.1 2.1-2.5s-.8-2.5-2.1-2.5-2.1 1-2.1 2.5.8 2.5 2.1 2.5Zm10.1-3.1V77h-1.1v-6.7h1.1v1c.4-.8 1.2-1.2 2.1-1.2 1.6 0 2.4 1.1 2.4 2.6V77h-1.1v-4.1c0-1-.4-1.8-1.7-1.8s-1.7.9-1.7 1.9Zm11 3c-.3.7-1.1 1.1-2 1.1-1.6 0-2.5-1.2-2.5-2.7v-4.2h1.1v4c0 1 .5 1.9 1.7 1.9s1.7-.8 1.7-1.8v-4h1.1v6.7h-1.1v-.9Zm4.4-5.8v1c.4-.7 1.2-1.1 2.1-1.1s1.7.5 2.1 1.4c.5-1 1.3-1.4 2.3-1.4s2.3.8 2.3 2.5v4.4h-1.1v-4.3c0-.9-.5-1.6-1.5-1.6s-1.7.8-1.7 1.8v4.1h-1.1v-4.3c0-.9-.5-1.6-1.6-1.6s-1.7.8-1.7 1.8v4.1h-1.1v-6.7h1.1ZM91.5 77h-1.1V66.9h1.1v4.5c.3-.6 1.1-1.3 2.3-1.3 2 0 3.1 1.6 3.1 3.5s-1.1 3.6-3.1 3.6-1.9-.6-2.3-1.3V77Zm2.2-.8c1.3 0 2.1-1.1 2.1-2.5s-.8-2.5-2.1-2.5-2.1 1-2.1 2.5.8 2.5 2.1 2.5Zm5.7-3.2h4c0-1.1-.7-1.9-2-1.9s-1.9.9-2 1.9Zm4.1 1.7 1 .3c-.4 1.2-1.4 2.1-3 2.1s-3.3-1.3-3.3-3.6 1.5-3.5 3.2-3.5 3.2 1.4 3.2 3.5v.4h-5.2c0 1.3 1 2.2 2.2 2.2s1.8-.6 2-1.4Zm6.3-4.4v1.2h-.6c-1.1 0-1.9.6-1.9 2.2v3.5h-1.1v-6.7h1.1v1.2c.4-1 1.3-1.3 2-1.3h.5Z"
        />
        <rect
          fill="none"
          stroke="#e1d4f8"
          x="12.5"
          y="142.5"
          width="143"
          height="51"
          rx="3.5"
          ry="3.5"
        />
        <path
          fill="#ffffff"
          strokeWidth="0"
          d="M33 161.2v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.4 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.4 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm11.9 0L47.1 174h-1.9l6.2-12.8h1.9Zm5.5 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.3 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1L65 165l-2.2-.7.5-1.5 2.1.7v-2.4h1.7Z"
        />
        <g clipPath="url(#clippath-5)">
          <path
            fill="#ffffff"
            strokeWidth="0"
            d="M133.3 158.8h-10c-.9 0-1.7.8-1.7 1.7v11.7h1.7v-11.7h10v-1.7Zm2.5 3.4h-9.2c-.9 0-1.7.8-1.7 1.7v11.7c0 .9.8 1.7 1.7 1.7h9.2c.9 0 1.7-.8 1.7-1.7v-11.7c0-.9-.8-1.7-1.7-1.7Zm0 13.3h-9.2v-11.7h9.2v11.7Z"
          />
        </g>
        <path fill="#6b27dd" strokeWidth="0" d="M24 132h54v20H24z" />
        <path
          fill="#f1eafe"
          strokeWidth="0"
          d="M35.3 147h-5.9v-9.9h5.9v1.1h-4.8v3.3h4.3v1.1h-4.3v3.3h4.8v1.1Zm1 0 2.6-3.4-2.4-3.3h1.4l1.8 2.5 1.8-2.5h1.3l-2.5 3.3 2.6 3.4h-1.3l-1.9-2.6-1.9 2.6h-1.3Zm8.9 2.7h-1.1v-9.4h1.1v1.1c.3-.7 1.1-1.3 2.3-1.3 2 0 3.1 1.5 3.1 3.5s-1.1 3.6-3.1 3.6-1.9-.6-2.3-1.2v3.7Zm2.1-3.5c1.3 0 2.1-1.1 2.1-2.5s-.8-2.5-2.1-2.5-2.1 1.1-2.1 2.5.8 2.5 2.1 2.5Zm6.1.8h-1.1v-6.7h1.1v6.7Zm-1.4-9.4c0-.5.4-.8.8-.8s.8.4.8.8-.4.8-.8.8-.8-.4-.8-.8Zm7.2 2.7v1.2h-.6c-1.1 0-1.9.6-1.9 2.2v3.5h-1.1v-6.7h1.1v1.2c.4-1 1.3-1.3 2-1.3h.5Zm1.9 2.7h4c0-1.1-.7-1.9-2-1.9s-1.9.9-2 1.9Zm4.2 1.7 1 .3c-.4 1.2-1.4 2.1-3 2.1s-3.3-1.3-3.3-3.6 1.5-3.5 3.2-3.5 3.2 1.4 3.2 3.5v.4h-5.2c0 1.3 1 2.2 2.2 2.2s1.7-.6 2-1.4Zm2.1.5 1-.3c0 .8.7 1.4 1.7 1.4s1.3-.4 1.3-1-.4-.8-1-1l-1.1-.3c-1-.2-1.7-.9-1.7-1.9s1.1-2 2.3-2 2.3 1.1 2.5 1.8l-1 .4c0-.5-.4-1.2-1.5-1.2s-1.2.4-1.2 1 .3.8.9.9l1.1.3c1.2.3 1.8 1 1.8 1.9s-.8 2-2.4 2-2.6-1.1-2.7-2Z"
        />
        <rect
          fill="none"
          stroke="#e1d4f8"
          x="172"
          y="142.5"
          width="143"
          height="51"
          rx="3.5"
          ry="3.5"
        />
        <path
          fill="#ffffff"
          strokeWidth="0"
          d="M192.5 161.2v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.4 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.4 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Zm8.3 0v2.3l2.1-.7.5 1.5-2.2.7 1.4 1.8-1.3 1-1.4-1.9-1.3 1.9-1.3-1 1.4-1.8-2.2-.7.5-1.5 2.1.7v-2.4h1.7Z"
        />
        <g clipPath="url(#clippath-6)">
          <path
            fill="#ffffff"
            strokeWidth="0"
            d="M292.8 158.8h-10c-.9 0-1.7.8-1.7 1.7v11.7h1.7v-11.7h10v-1.7Zm2.5 3.4h-9.2c-.9 0-1.7.8-1.7 1.7v11.7c0 .9.8 1.7 1.7 1.7h9.2c.9 0 1.7-.8 1.7-1.7v-11.7c0-.9-.8-1.7-1.7-1.7Zm0 13.3h-9.2v-11.7h9.2v11.7Z"
          />
        </g>
        <path fill="#6b27dd" strokeWidth="0" d="M183.5 132h38v20h-38z" />
        <path
          fill="#f1eafe"
          strokeWidth="0"
          d="M193.1 147.2c-2.5 0-4.9-1.8-4.9-5.2s2.4-5.2 4.9-5.2 3.9 1.2 4.3 3.1l-1.1.4c-.4-1.6-1.6-2.5-3.2-2.5s-3.7 1.4-3.7 4.1 1.8 4.1 3.7 4.1 2.9-1.1 3.3-2.5l1 .4c-.5 1.9-2.1 3.2-4.4 3.2Zm9.4-1.7 3.1-8.5h1.2l-3.8 9.9h-1.2L198 137h1.2l3.1 8.5Zm9.9 1.7c-2.5 0-4.9-1.8-4.9-5.2s2.4-5.2 4.9-5.2 3.8 1.2 4.3 3.1l-1.1.4c-.4-1.6-1.6-2.5-3.2-2.5s-3.7 1.4-3.7 4.1 1.8 4.1 3.7 4.1 2.9-1.1 3.3-2.5l1 .4c-.5 1.9-2.1 3.2-4.4 3.2Z"
        />
      </g>
    </svg>
  </Box>
);

export default CatchCardPlaceholders;
