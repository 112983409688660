import React, { useEffect, useState } from "react";

import { Box, Stack } from "@mui/material";

import CatchLogo from "../logos/CatchLogo";
import { bootstrapMarqeta, injectMarqeta } from "./marqeta";
import MarqetaInput from "./MarqetaInput";

type MarqetaCardProps = {
  token: string;
  isShort?: boolean;
  isSandbox?: boolean;
  onSuccess?: () => void;
  onFailure?: () => void;
  onCopy?: (name: string) => void;
};

const MarqetaCard: React.VFC<MarqetaCardProps> = ({
  token,
  isShort,
  isSandbox,
  onSuccess,
  onFailure,
  onCopy,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    injectMarqeta(isSandbox, () => setIsLoaded(true));
  }, [isSandbox]);

  useEffect(() => {
    const handleSuccess = () => {
      setIsRendered(true);
      onSuccess && onSuccess();

      try {
        localStorage.setItem("clipboardWarning", "true");
      } catch {
        // Do nothing
      }
    };

    if (isLoaded && !isRendered && token) {
      bootstrapMarqeta(token, handleSuccess, onFailure, onCopy);
    }
  }, [isLoaded, isRendered, token, onSuccess, onFailure, onCopy]);

  return (
    <Stack
      width="100%"
      height="100%"
      p={4}
      pb={isShort ? 4 : 3}
      justifyContent="space-between"
      color="common.white"
      sx={({ palette }) => ({
        background: palette.tertiary.main,
        ...(isShort && {
          overflowX: "auto",
          overflowY: "hidden",
        }),
      })}
    >
      {!isShort && (
        <Box width={93} height={30}>
          <CatchLogo variant="mono-light" />
        </Box>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap={isShort ? "nowrap" : "wrap"}
        spacing={isShort ? 2 : 0}
      >
        <Box
          width={isShort ? 228 : "100%"}
          position="relative"
          pt={isShort ? 2 : 4.5}
          flexShrink="0"
        >
          <MarqetaInput name="pan" isRendered={isRendered}>
            Card number
          </MarqetaInput>
        </Box>

        <Box
          width={isShort ? 114 : "calc(50% - 8px)"}
          position="relative"
          pt={isShort ? 2 : 4.5}
          flexShrink="0"
        >
          <MarqetaInput name="exp" isRendered={isRendered}>
            Expires
          </MarqetaInput>
        </Box>

        <Box
          width={isShort ? 114 : "calc(50% - 8px)"}
          position="relative"
          pt={isShort ? 2 : 4.5}
          pr={isShort ? 3 : 0}
          flexShrink="0"
        >
          <MarqetaInput name="cvv" isRendered={isRendered}>
            CVC
          </MarqetaInput>
        </Box>
      </Stack>
    </Stack>
  );
};

export default MarqetaCard;
