import React from "react";

import { Stack, Typography } from "@mui/material";

import { CompactButton } from "~common/components/controls/buttons";
import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";

type LabeledSettingProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  loading: boolean;
  onEditClick?: () => void;
};

const LabeledSetting: React.VFC<LabeledSettingProps> = ({
  label,
  value,
  loading,
  onEditClick,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={4}
    width="100%"
  >
    <Stack sx={{ py: 4, minHeight: 80 }} flexGrow={1}>
      <Typography variant="bodySmall" color="grey.500">
        {label}
      </Typography>

      <Typography variant="bodyLarge">
        {loading ? <WrappedSkeleton variant="text" width={240} /> : value}
      </Typography>
    </Stack>

    {onEditClick && (
      <CompactButton
        onClick={onEditClick}
        sx={({ palette }) => ({
          background: palette.common.white,
          minWidth: 100,
        })}
        disabled={loading}
      >
        {value ? "Edit" : "Add"}
      </CompactButton>
    )}
  </Stack>
);

export default LabeledSetting;
