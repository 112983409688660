import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { setIsCatchPassFlow } from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import MobileAppEventBus from "~common/utils/mobile-app-messaging";
import { setIsMobileApp } from "~src/store/slices/core-slice";

const useMobileAppTracking = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { patchTrackingContext } = useTracking();

  useEffect(() => {
    if (searchParams.get("hideHeader") === "true") {
      dispatch(setIsMobileApp(true));
    }
    if (searchParams.get("catchPassFlow") === "true") {
      dispatch(setIsCatchPassFlow(true));
    }
  }, [dispatch, patchTrackingContext, searchParams]);

  useEffect(() => {
    const eventBus = new MobileAppEventBus({ overrideTargetToWindow: true });

    eventBus.listenToEventFromMobile("CATCH_IOS_APP", () => {
      patchTrackingContext({
        appName: "consumer-ios",
        mobileSource: "ios",
      });
    });
    eventBus.listenToEventFromMobile("CATCH_ANDROID_APP", () => {
      patchTrackingContext({
        appName: "consumer-android",
        mobileSource: "android",
      });
    });
    // PD-256 Remove old method of sending events
    window.dispatchEvent(new CustomEvent("CATCH_WEB_APP_OPENED"));
    eventBus.sendEventToMobile("CATCH_WEB_APP_OPENED");
  }, [dispatch, patchTrackingContext]);
};

export default useMobileAppTracking;
