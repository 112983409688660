import React from "react";

import { Box, BoxProps } from "@mui/material";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";
import Wallet from "~common/components/wallet/Wallet";
import LabeledSection from "~src/components/layout/LabeledSection";
import useUserRewards from "~src/hooks/services/useUserRewards";

import MyCreditsCard from "./MyCreditsCard";
import MyCreditsEmpty from "./MyCreditsEmpty";
import MyCreditsPending from "./MyCreditsPending";

type MyCreditsSectionProps = BoxProps & {
  pageLoading: boolean;
};

const MyCreditsSection: React.VFC<MyCreditsSectionProps> = ({
  pageLoading,
}) => {
  const { userRewards, loading, error } = useUserRewards();
  const nonPendingRewards = userRewards.filter(
    (reward) => reward.amount > (reward.total_pending_hold || 0)
  );

  if (!pageLoading && !loading && !userRewards.length) {
    return null;
  }

  return (
    <LabeledSection
      heading="My credits"
      loading={pageLoading}
      topRight={
        !(pageLoading || loading) ? (
          <MyCreditsPending />
        ) : undefined
      }
    >
      <LoadingGuard {...{ error }}>
        <Box>
          {(pageLoading || loading) && (
            <Wallet>
              {[...Array(3).keys()].map((i) => (
                <WrappedSkeleton
                  key={`skeleton${i}`}
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "auto",
                    aspectRatio: "1.618 / 1",
                    borderRadius: "6% / 9.71%",
                  }}
                />
              ))}
            </Wallet>
          )}

          {!(pageLoading || loading) && (
            <Wallet>
              {nonPendingRewards.length
                ? userRewards.map((reward) => (
                    <MyCreditsCard
                      key={reward.reward_ids.join("+")}
                      reward={reward}
                    />
                  ))
                : [<MyCreditsEmpty key="empty" />]}
            </Wallet>
          )}
        </Box>
      </LoadingGuard>
    </LabeledSection>
  );
};

export default MyCreditsSection;
