import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import { EmailIcon, EmailSuccessIcon } from "~common/components/icons/vector";
import GenericLoadingScreen from "~common/components/loading/GenericLoadingScreen";
import { useVerifyEmail } from "~common/services";
import { useTracking, useTrackPageView } from "~common/tracking";
import { delay } from "~common/utils/delays";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import useAlertModal from "~src/hooks/useAlertModal";
import {
  selectAuthData,
  selectAuthUserInfo,
  selectEmailVerificationCode,
  selectIsAuthedVerified,
} from "~src/store";
import {
  markEmailVerified,
  setEmailVerificationCode,
} from "~src/store/slices/user-slice";

const CheckEmailVerification: React.VFC = () => {
  useTrackPageView("Check Email Verified");

  const { trackEvent } = useTracking();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const authData = useSelector(selectAuthData);
  const isAuthedVerified = useSelector(selectIsAuthedVerified);
  const { mutate: verifyEmail } = useVerifyEmail();
  const { emailVerificationCode } = useParams();
  const storeEmailVerificationCode = useSelector(selectEmailVerificationCode);
  const setAlertModal = useAlertModal();
  const dispatch = useDispatch();

  const [hasTriedVerification, setHasTriedVerification] =
    useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    if (
      !hasTriedVerification &&
      !success &&
      !expired &&
      !storeEmailVerificationCode &&
      emailVerificationCode
    ) {
      dispatch(setEmailVerificationCode(emailVerificationCode));
    }
  }, [
    hasTriedVerification,
    success,
    expired,
    storeEmailVerificationCode,
    dispatch,
    emailVerificationCode,
  ]);

  const checkEmailVerificationCode = useCallback(async (): Promise<void> => {
    if (!authData || !storeEmailVerificationCode) {
      return;
    }

    setHasTriedVerification(true);

    try {
      await verifyEmail({
        access_token: authData.access_token,
        attribute_name: "email",
        code: storeEmailVerificationCode,
      });
      trackEvent("Email Successfully Verified");
      setSuccess(true);
      await delay(2);
      dispatch(markEmailVerified());
    } catch (rawError) {
      trackEvent("Expired/Old Email Verification Link Used");
      setAlertModal.linkExpired();
      dispatch(setEmailVerificationCode(null));
      setExpired(true);
    }
  }, [
    trackEvent,
    dispatch,
    authData,
    setAlertModal,
    storeEmailVerificationCode,
    verifyEmail,
  ]);

  useEffect(() => {
    if (
      !hasTriedVerification &&
      storeEmailVerificationCode &&
      !isAuthedVerified
    ) {
      void checkEmailVerificationCode();
    }
  }, [
    isAuthedVerified,
    hasTriedVerification,
    storeEmailVerificationCode,
    checkEmailVerificationCode,
  ]);

  useEffect(() => {
    if (!authData || !authUserInfo) {
      trackEvent("Redirecting anonymous user to login", {
        component: "CheckEmailVerification",
      });
    }
  }, [trackEvent, authData, authUserInfo]);

  if (!authData || !authUserInfo) {
    return <Navigate to="/sign-in" replace />;
  }

  if (isAuthedVerified) {
    return <Navigate to="/home" replace />;
  }

  if (success) {
    return (
      <SmallPagePanel
        icon={<EmailSuccessIcon size="small" />}
        title="Verified!"
        subtitle="Redirecting to your user portal..."
        centered
      />
    );
  }

  return (
    <SmallPagePanel
      icon={<EmailIcon size="small" />}
      title={<GenericLoadingScreen />}
      subtitle="Verifying..."
      centered
    />
  );
};

export default CheckEmailVerification;
