import React from "react";

import { Stack } from "@mui/material";

type BankHeaderProps = {
  icon?: React.ReactNode;
};

const BankHeader: React.FC<BankHeaderProps> = ({ children, icon }) => (
  <Stack
    spacing={4}
    className={`BankLinking-header${icon ? " BankLinking-iconHeader" : ""}`}
  >
    {icon}
    {children}
  </Stack>
);

BankHeader.defaultProps = {
  icon: undefined,
};

export default BankHeader;
