import React, { useState } from "react";

import { Box, Card } from "@mui/material";

import type {
  HighlightWithState,
  RewardCampaignResponseData,
} from "../../services";
import { CloseReason } from "../../utils/modal-utils";
import FlippableCard from "../cards/FlippableCard";
import { ResponsiveDrawerDialog } from "../modals";
import RewardClaimed from "../reward-campaigns/RewardClaimed";
import BaseHighlightModal from "./BaseHighlightModal";
import HighlightIcon from "./HighlightIcon";
import HighlightModalWrapper from "./HighlightModalWrapper";

type HighlightProps = {
  highlight: HighlightWithState;
  onClaim: (activeHighlight: HighlightWithState) => void;
  onRead: (activeHighlight: HighlightWithState) => void;
  onRedirect: (activeHighlight: HighlightWithState) => void;
  onClose: (
    activeHighlight: HighlightWithState,
    event: unknown,
    reason?: CloseReason
  ) => void;
  rewardCampaign: RewardCampaignResponseData | null;
};

// For this particular highlight, we want to show the iOS app
// adoption button only without checking the device type
const EXCLUDE_FROM_DEVICE_CHECK_HIGHLIGHT_ID =
  "hlg-eff71f5d-840b-47aa-9274-961b34535c30";

const Highlight: React.VFC<HighlightProps> = ({
  highlight,
  onClaim,
  onRead,
  onRedirect,
  onClose,
  rewardCampaign,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onRead(highlight);
  };

  const handleClose = (event: unknown, reason?: CloseReason) => {
    setOpen(false);
    onClose(highlight, event, reason);
  };

  const handleClaim = () => {
    onClaim(highlight);
  };

  if (
    (highlight.initial_modal.isAndroidAppAdoption &&
      !navigator.userAgent.match(/Android/i)) ||
    (highlight.initial_modal.isIosAppAdoption &&
      !navigator.userAgent.match(/iPhone/i) &&
      highlight.id !== EXCLUDE_FROM_DEVICE_CHECK_HIGHLIGHT_ID)
  ) {
    return null;
  }

  // If the highlight is completed, change the subtext to match that
  // of the cta button on the completed modal. In the context of
  // reward campaign highlights, the subtext is initially the reward
  // expiration date. However, after the user has claimed the credit,
  // it's more useful to show the information presented in the
  // completed modal CTA button again as the subtext.
  if (highlight.status === "completed" && highlight.completed_modal) {
    highlight = {
      ...highlight,
      initial_modal: {
        ...highlight.initial_modal,
        subtext: highlight.completed_modal.cta_button.text,
        subTextLink: highlight.completed_modal.cta_button.link,
      },
    };
  }

  // If the highlight is unclaimable and not completed, we should show
  // the unclaimale modal.
  const highlightIsUnclaimableAndNotCompleted =
    highlight.is_highlight_claimable === false &&
    highlight.status !== "completed" &&
    !!highlight.unclaimable_modal;

  return (
    <Box>
      <HighlightIcon
        icon={highlight.icon}
        onClick={handleOpen}
        status={highlight.status}
      />

      <ResponsiveDrawerDialog
        fullBleed
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiDialog-paper, .MuiDrawer-paper": {
            background: "none",
            boxShadow: "none",
            overflow: "hidden",
          },
          ".MuiDrawer-paper > .MuiBox-root:first-of-type": {
            display: "none",
          },
          ".MuiDialog-paper > button:first-of-type": {
            display: "none",
          },
          ".MuiDialog-paper > div, .MuiDrawer-paper > div, .MuiCard-root": {
            borderRadius: "inherit",
          },
          ".MuiCard-root": {
            width: "100%",
          },
        }}
      >
        {({ headingId, contentId }) => (
          <>
            {highlight.completed_modal ? (
              <FlippableCard
                front={
                  <HighlightModalWrapper onClose={handleClose}>
                    <BaseHighlightModal
                      headingId={headingId}
                      contentId={contentId}
                      modal={
                        highlightIsUnclaimableAndNotCompleted
                          ? highlight.unclaimable_modal
                          : highlight.initial_modal
                      }
                      onClick={handleClaim}
                      status={
                        highlightIsUnclaimableAndNotCompleted
                          ? "unclaimable"
                          : highlight.status
                      }
                      loading={highlight.rewardCampaignStatus === "claiming"}
                      error={highlight.error}
                    />
                  </HighlightModalWrapper>
                }
                back={
                  <HighlightModalWrapper onClose={handleClose}>
                    {highlight.rewardCampaignStatus === "claimed" && (
                      <RewardClaimed
                        contents={highlight.completed_modal}
                        onClick={() => onRedirect(highlight)}
                        onClose={handleClose}
                        rewardCampaign={rewardCampaign}
                      />
                    )}
                  </HighlightModalWrapper>
                }
                flipped={highlight.rewardCampaignStatus === "claimed"}
              />
            ) : (
              <Card>
                <HighlightModalWrapper onClose={handleClose}>
                  <BaseHighlightModal
                    headingId={headingId}
                    contentId={contentId}
                    modal={highlight.initial_modal}
                    onClick={() => {}}
                    status={highlight.status}
                  />
                </HighlightModalWrapper>
              </Card>
            )}
          </>
        )}
      </ResponsiveDrawerDialog>
    </Box>
  );
};

export default Highlight;
