import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  HighlightsResponseData,
  UpdateHighlightStatusPathParams,
  UpdateHighlightStatusRequestData,
  UpdateHighlightStatusResponseData,
} from "./types/highlights-types";

const useGetHighlights = (
  options: {
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<HighlightsResponseData, ErrorResponseData, undefined, undefined>({
    ...options,
    path: `promotions-svc/highlights`,
  });

const useUpdateHighlightStatus = () =>
  useMutate<
    UpdateHighlightStatusResponseData,
    ErrorResponseData,
    undefined,
    UpdateHighlightStatusRequestData,
    UpdateHighlightStatusPathParams
  >({
    verb: "POST",
    path: ({ highlightId }) =>
      `promotions-svc/highlights/${highlightId}/user_highlight_links`,
  });

export { useGetHighlights, useUpdateHighlightStatus };
