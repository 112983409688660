import React from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { selectIsProcessingLinkUpdate } from "../../store/selectors";
import ThemeAwareLoadingScreen from "../loading/ThemeAwareLoadingScreen";
import BankLinking, { BankLinkingProps } from "./BankLinking";

type BankLinkManagerProps = BankLinkingProps;

const BankLinkManager: React.FC<BankLinkManagerProps> = ({
  isModal,
  children,
}) => {
  const isProcessingLinkUpdate = useSelector(selectIsProcessingLinkUpdate);

  // We use display: none to toggle display of the bank link screen, so we don't unmount
  // the BankLinkConnector during the processing of updating the payment instrument.

  return (
    <>
      {isProcessingLinkUpdate && <ThemeAwareLoadingScreen />}
      <Box
        sx={{
          display: isProcessingLinkUpdate ? "none" : "block",
        }}
      >
        <BankLinking {...{ isModal }} />
        {children}
      </Box>
    </>
  );
};

export default BankLinkManager;
