import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useGetRewardCampaign } from "~common/services";
import {
  getSessionStorageWithFallback,
  setSessionStorageWithFallback,
} from "~common/utils/browser-storage";
import { setNewUserOnboardingRewardCampaign } from "~src/store/slices/user-slice";
import {
  getRewardCampaignId,
  NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
} from "~src/utils/reward-campaigns";

const useNewUserOnboardingReward = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [rewardCampaignId, setRewardCampaignId] = useState("");

  const { data } = useGetRewardCampaign({
    pathParams: {
      rewardCampaignId: getRewardCampaignId(rewardCampaignId),
    },
    lazy: !rewardCampaignId,
  });

  useEffect(() => {
    const storedRewardCampaignId = getSessionStorageWithFallback(
      NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID
    );

    if (storedRewardCampaignId) {
      setRewardCampaignId(storedRewardCampaignId);
    }
  }, []);

  useEffect(() => {
    const rewardCampaignIdParam =
      searchParams.get(NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID) || "";

    if (rewardCampaignIdParam) {
      setRewardCampaignId(rewardCampaignIdParam);

      setSessionStorageWithFallback(
        NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
        rewardCampaignIdParam
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      dispatch(setNewUserOnboardingRewardCampaign(data));
    }
  }, [dispatch, data]);
};

export default useNewUserOnboardingReward;
