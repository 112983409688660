import React, { useEffect, useState } from "react";

import {
  CheckCircle as CheckCircleIcon,
  InsertLink as InsertLinkIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import DefaultButton from "~common/components/controls/buttons/DefaultButton";
import { CopyLinkButtonLabel } from "~common/components/markers";
import DefaultDialog from "~common/components/modals/DefaultDialog";
import DialogBody from "~common/components/modals/DialogBody";
import DialogHeader from "~common/components/modals/DialogHeader";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import useCopyLink, { LinkCopyState } from "~common/hooks/useCopyLink";
import { useCreateGiftSecret } from "~common/services/gifts";
import { useTracking } from "~common/tracking";

type ShareAsGiftModalProps = {
  open: boolean;
  onClose: () => void;
  creditCard: React.ReactNode;
  merchantName: string;
  rewardIds: Array<string>;
  fromRewardCampaign: boolean;
  rewardEventData?: Record<string, unknown>;
};

const ShareAsGiftModal: React.VFC<ShareAsGiftModalProps> = ({
  open,
  onClose,
  creditCard,
  merchantName,
  rewardIds,
  fromRewardCampaign,
  rewardEventData,
}) => {
  const { trackEvent, captureException } = useTracking();
  const [linkCopied, setLinkCopied] = useState<LinkCopyState>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [giftSecret, setGiftSecret] = useState<string>("");
  const copyLink = useCopyLink();

  const { mutate: createGiftSecret } = useCreateGiftSecret();

  const dialogId = useUniqueId(`${merchantName}-gift-modal`);
  const dialogDescriptionId = useUniqueId(
    `${merchantName}-gift-modal-description`
  );

  useEffect(() => {
    const getGiftSecret = async () => {
      try {
        setLoading(true);
        const giftSecretResponse = await createGiftSecret({
          reward_ids: rewardIds,
        });
        setGiftSecret(giftSecretResponse.gift_secret);
      } catch (rawError) {
        captureException({
          component: "ShareAsGiftModal",
          exceptionMessage: "Fetching gift secret from modal",
          rawError,
        });
      }
      setLoading(false);
    };

    if (open && !giftSecret && !loading) {
      void getGiftSecret();
    }
  }, [
    captureException,
    createGiftSecret,
    rewardIds,
    giftSecret,
    loading,
    open,
  ]);

  const handleClick = () => {
    trackEvent("Copy Gift Link", rewardEventData);
    void copyLink(`g/${giftSecret}`, {
      component: "ShareAsGiftModal",
      setLinkCopied,
    });
  };

  return (
    <DefaultDialog
      fullBleed
      open={open}
      onClose={onClose}
      ariaLabelledBy={dialogId}
      ariaDescribedBy={dialogDescriptionId}
      sx={({ breakpoints, shape }) => ({
        "& .MuiPaper-root.MuiDialog-paper": {
          pt: 26,
          borderRadius: `${shape.borderRadius * 2}px`,
          [breakpoints.down("sm")]: {
            left: "auto",
          },
        },
      })}
    >
      {fromRewardCampaign && (
        <Stack
          alignItems="center"
          sx={({ palette }) => ({
            textAlign: "center",
            background: palette.info.light,
            px: 6,
            py: 3,
            marginBottom: 6,
          })}
        >
          <Typography variant="h3" color="info.main">
            One per person ☝️
          </Typography>
          <Typography variant="bodySmall" color="info.main">
            You can gift this credit to anyone who hasn’t already claimed the
            same offer.
          </Typography>
        </Stack>
      )}
      <Stack alignItems="center" spacing={6} sx={{ px: 6, pb: 8 }}>
        <Box>{creditCard}</Box>
        <Stack spacing={4}>
          <DialogHeader id={dialogId} variant="h1">
            Gift your {merchantName} credit
          </DialogHeader>
          <DialogBody id={dialogDescriptionId}>
            Share this link with a friend or on social. The first to claim it
            gets it 🏆
          </DialogBody>
        </Stack>
        <DefaultButton
          onClick={handleClick}
          loading={loading}
          disabled={linkCopied !== null}
          startIcon={(() => {
            if (linkCopied === "succeeded") {
              return <CheckCircleIcon />;
            }
            if (linkCopied === null) {
              return <InsertLinkIcon />;
            }
            return null;
          })()}
          fullWidth
        >
          <CopyLinkButtonLabel linkCopied={linkCopied} />
        </DefaultButton>
      </Stack>
    </DefaultDialog>
  );
};

export default ShareAsGiftModal;
