import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";
import ThickDivider from "~common/components/primitives/ThickDivider";

type LabeledSectionProps = {
  heading: React.ReactNode;
  topRight?: React.ReactNode;
  loading?: boolean;
};

const LabeledSection: React.FC<LabeledSectionProps> = ({
  heading,
  topRight,
  children,
  loading,
}) => (
  <Box>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h2">
        {loading ? <WrappedSkeleton variant="text" width={200} /> : heading}
      </Typography>

      {topRight && topRight}
    </Stack>

    <ThickDivider />
    {children}
  </Box>
);

LabeledSection.defaultProps = {
  loading: false,
  topRight: null,
};

export default LabeledSection;
