import React from "react";

import { Box, Stack, Typography } from "@mui/material";

type ExchangeTableTabProps = {
  selected: boolean;
  label: string;
  badgeText?: string | null;
  onClick?: () => void;
};

const ExchangeTableTab: React.VFC<ExchangeTableTabProps> = ({
  selected,
  label,
  badgeText,
  onClick,
}) => (
  <Stack direction="column" onClick={onClick} sx={{ mr: 6, cursor: "pointer" }}>
    <Typography variant="h3" sx={{ my: 3 }}>
      {label}
      {badgeText && (
        <Typography variant="bodyRegular" color="primary.main">
          {" "}
          ({badgeText})
        </Typography>
      )}
    </Typography>
    <Box
      sx={({ palette }) => ({
        width: "100%",
        height: 4,
        borderRadius: 2,
        backgroundColor: selected ? "primary.main" : "transparent",
      })}
    />
  </Stack>
);

export default ExchangeTableTab;
