import { useState } from "react";

import useCopyLink, { LinkCopyState } from "~common/hooks/useCopyLink";
import { useTracking } from "~common/tracking";
import useCurrentUser from "~src/hooks/services/useCurrentUser";

const useShareReferralLink = (eventComponent: string) => {
  const { trackEvent } = useTracking();
  const [linkCopied, setLinkCopied] = useState<LinkCopyState>(null);
  const { currentUser } = useCurrentUser();
  const copyLink = useCopyLink();

  const copyShareLink = () => {
    if (!currentUser || !currentUser.referral_code) {
      return;
    }

    if (!linkCopied) {
      trackEvent("Referral Link copied", {
        eventComponent,
      });
      void copyLink(`r/${currentUser.referral_code}`, {
        component: "useShareReferralLink",
        setLinkCopied,
      });
    }
  };

  return { copyShareLink, linkCopied };
};

export default useShareReferralLink;
