import { useGet } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  GetReferralPathParams,
  ReferralResponseData,
} from "./types/referrals-types";

const useGetReferral = (
  options: { pathParams?: GetReferralPathParams; lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<
    ReferralResponseData,
    ErrorResponseData,
    undefined,
    GetReferralPathParams
  >({
    path: ({ referralCode }) => `users-svc/users/referral/${referralCode}`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

export { useGetReferral };
