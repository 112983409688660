import React from "react";

import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

type BankHeaderInsufficientFundsProps = {
  accountName?: React.ReactNode;
} & ConnectPaymentInstrumentAlertHeaderInstanceProps;

const BankHeaderInsufficientFunds: React.VFC<
  BankHeaderInsufficientFundsProps
> = ({ accountName, headingId }) => (
  <ConnectPaymentInstrumentAlertHeader
    headingId={headingId}
    title={<>{accountName || "Your account"} has insufficient funds</>}
    subtitle={
      <>
        If possible, we recommend connecting a different account, or adding some
        funds to your account. Otherwise, your best bet is to use another
        payment option.
      </>
    }
  />
);

export default BankHeaderInsufficientFunds;
