import React from "react";
import { Outlet } from "react-router-dom";

import { css, Global } from "@emotion/react";

import AlertModal from "~src/components/layout/AlertModal";
import GlobalStyles from "~src/components/layout/GlobalStyles";

// This layout is for pages that don't need the navbar or other
// in-app features. It's used for the public shop page.

const HeadlessLayout: React.VFC = () => (
  <>
    <GlobalStyles />

    <Global
      styles={css`
        body {
          background: transparent;
        }

        #root {
          overflow: hidden;
        }
      `}
    />

    <main id="main">
      <Outlet />
    </main>

    <AlertModal />
  </>
);

export default HeadlessLayout;
