import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Box, ButtonProps, Stack, Typography } from "@mui/material";

import ContentCard from "~common/components/cards/ContentCard";
import { PrimaryButton } from "~common/components/controls/buttons";

type BrowserExtensionDetailsProps = {
  heading: React.ReactNode;
  body: string;
  img: React.ImgHTMLAttributes<HTMLImageElement>;
  button: ButtonProps;
};

const BrowserExtensionDetails: React.VFC<BrowserExtensionDetailsProps> = ({
  heading,
  body,
  img,
  button,
}) => {
  const { children: buttonChildren, ...buttonProps } = button;

  return (
    <ContentCard variant="outlined" bgcolor="common.white">
      <Stack spacing={6}>
        <Stack
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={1}>
            <Typography variant="h2" component="h3">
              {heading}
            </Typography>

            <Typography variant="bodyRegular">{body}</Typography>
          </Stack>

          <Box width={80} flexShrink={0} textAlign="center">
            <Box component="img" {...img} />
          </Box>
        </Stack>

        <PrimaryButton
          endIcon={buttonProps.href ? <OpenInNewIcon /> : undefined}
          target={buttonProps.href ? "_blank" : undefined}
          {...buttonProps}
        >
          {buttonChildren}
        </PrimaryButton>
      </Stack>
    </ContentCard>
  );
};

export default BrowserExtensionDetails;
