import { createTheme } from "@mui/material";

import { consumerCatchTheme } from "~common/themes";

const userPortalCatchTheme = createTheme({
  ...consumerCatchTheme,
  ...{
    breakpoints: {
      values: {
        zero: 0,
        xs: 0,
        sm: 600,
        md: 840,
        lg: 1140,
        xl: 1440,
      },
    },
  },
});

export { userPortalCatchTheme };
