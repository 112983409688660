// The custom event both apps listen for and then handle
// based on the corresponding types below. We use a single event
// here to optimize the code within our mobile apps.
const WEB_TO_MOBILE_EVENT = "CATCH_CUSTOM_EVENT" as const;

// ============================
// Section: From web to mobile
// ============================
type WebToMobileEventType =
  // One of our react apps has opened (potentially embedded within one of
  // our mobile apps, like in our in app shopping experince in webviews)
  // Tofu, checkout, and user portal send these events
  | "CATCH_WEB_APP_OPENED"
  // User portal logout triggers a logout of the mobile app. This is necessary
  // as the profile page of the mobile app is a webview of the profile in user portal
  | "CATCH_LOGOUT"
  // To know when to trigger the mobile app review experience, the mobile apps need to
  // detect when a purchase was just made.
  | "CATCH_CHECKOUT_COMPLETED"
  // When a user on the mobile app clicks to dismiss "Skip" payment linking (when embedded
  // within the mobile app), or if a user passes OTP and already has a payment method linked
  // we want the payment linking in the webview to dismiss
  | "CATCH_DISMISS_PAYMENT_LINKING";

// ============================
// Section: From mobile to web
// ============================

type MobileToWebEventType =
  // Upon getting confirmation that a Catch web app has opened, each
  // mobile app responds with the respective events to alert the web app
  // to update the relevant tracking context fields. This enables us to
  // determine when events from web are within our mobile apps.
  "CATCH_IOS_APP" | "CATCH_ANDROID_APP";
export type { MobileToWebEventType, WebToMobileEventType };
export { WEB_TO_MOBILE_EVENT };
