import React from "react";

import { Typography } from "@mui/material";

import { BankLockIcon } from "../../../../icons/vector";
import BankHeader from "../BankHeader";

type BankHeaderRetryProps = {
  headingId?: string;
};

const BankHeaderRetry: React.VFC<BankHeaderRetryProps> = ({ headingId }) => (
  <BankHeader icon={<BankLockIcon size="small" />}>
    <Typography variant="h1" id={headingId}>
      Hmm, let&apos;s try that again
    </Typography>
    <Typography variant="bodyRegular" color="grey.600">
      Sometimes we have trouble connecting with certain banks. Sorry about that!
    </Typography>
  </BankHeader>
);

export default BankHeaderRetry;
