import React from "react";

import { CardGiftcard as GiftIcon } from "@mui/icons-material";

import { PrimaryButton } from "~common/components/controls/buttons";

type GiftToCreditExchangeButtonProps = { onClick: () => void };

const GiftToCreditExchangeButton: React.VFC<
  GiftToCreditExchangeButtonProps
> = ({ onClick }) => (
  <PrimaryButton
    startIcon={<GiftIcon />}
    onClick={onClick}
  >
    Gift your credit
  </PrimaryButton>
);

export default GiftToCreditExchangeButton;
