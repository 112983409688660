import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

type AccountSectionRowProps = {
  title: React.ReactNode;
};

// General row with a text label on the left and an icon on the right.
const AccountSectionRow: React.VFC<AccountSectionRowProps> = ({ title }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={({ palette }) => ({
      py: 3,
      color: palette.grey[700],
    })}
  >
    <Typography variant="h3" component="span">
      {title}
    </Typography>
    <OpenInNewIcon
      sx={{
        height: 24,
        width: 24,
      }}
    />
  </Stack>
);

export default AccountSectionRow;
