import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetNuxs } from "~common/services";
import { selectNuxs } from "~src/store";
import { setNuxs } from "~src/store/slices/nuxs-slice";

const useFetchNuxs = () => {
  const dispatch = useDispatch();
  const storedData = useSelector(selectNuxs);
  const { data } = useGetNuxs({ lazy: !!storedData });

  useEffect(() => {
    if (!storedData && data?.nuxs) {
      dispatch(setNuxs(data.nuxs));
    }
  }, [dispatch, data, storedData]);
};

export default useFetchNuxs;
