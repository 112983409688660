import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import {
  MerchantPartnerType,
  MerchantSearchResult,
  useGetMerchantSearch,
} from "~common/services";
import { useTracking } from "~common/tracking";
import {
  filterResults,
  getRecentResults,
  updateRecentResults,
} from "~src/utils/search";

import SearchInput from "./SearchInput";
import SearchResults from "./SearchResults";
import SearchTypes from "./SearchTypes";

type SearchProps = {
  onClose?: () => void;
};

const Search: React.VFC<SearchProps> = ({ onClose }) => {
  const { trackEvent } = useTracking();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [type, setType] = useState<MerchantPartnerType | "">("");
  const [recentResults, setRecentResults] = useState<
    (MerchantSearchResult & { expiresAt: number })[]
  >([]);
  const [activeResults, setActiveResults] = useState<MerchantSearchResult[]>(
    []
  );
  const { data: defaultResults, loading: defaultResultsLoading } =
    useGetMerchantSearch();

  const { data: results, loading: resultsLoading } = useGetMerchantSearch({
    lazy: !query,
    queryParams: {
      search_query: query,
      ...(type && {
        partner_type: type,
      }),
    },
  });

  /*
   * We don't want to show affiliate offers until we have non-iOS app options
   * for enabling CatchPass. To get everything ready and make testing easier,
   * offers can be viewed by adding the param ?showOffers=true. We can delete
   * this code block when we're ready for wider CatchPass release.
   */
  const [showOffers, setShowOffers] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("showOffers")) {
      setShowOffers(true);
    } else {
      setType("standard");
    }
  }, [searchParams]);

  useEffect(() => {
    setRecentResults(getRecentResults());
  }, []);

  useEffect(() => {
    if (!query && defaultResults) {
      setActiveResults(defaultResults);
    }

    if (query && results) {
      setActiveResults(results);
    }
  }, [query, results, defaultResults]);

  useEffect(() => {
    setIsLoading(resultsLoading || defaultResultsLoading);
  }, [resultsLoading, defaultResultsLoading]);

  const handleOpen = () => {
    setIsActive(true);
    trackEvent("Search Opened");
    document.addEventListener("keydown", handleKeydown);
  };

  const handleClose = () => {
    setIsActive(false);
    setQuery("");
    trackEvent("Search Closed");
    onClose && onClose();
    document.removeEventListener("keydown", handleKeydown);
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose();
    }
  };

  const handleTypeChange = (value: typeof type) => {
    setType(value);
    trackEvent(`Search Tab Clicked ${value}`);

    if (query) {
      setIsLoading(true);
    }
  };

  const handleClick = (result: MerchantSearchResult) => {
    const updatedRecentResults = updateRecentResults(result, recentResults);
    setRecentResults(updatedRecentResults);
    trackEvent("Search Merchant Clicked");
  };

  const filteredRecentResults = filterResults(type, recentResults);
  const filteredResults = filterResults(type, activeResults, recentResults);

  return (
    <Box flexGrow="1" position="relative" className="overlay-light">
      <SearchInput
        value={query}
        onChange={setQuery}
        onFocus={handleOpen}
        isActive={isActive}
      />

      {isActive && (
        <>
          <Paper
            sx={({ shape, zIndex }) => ({
              maxHeight: 484,
              mt: 3,
              borderRadius: `${shape.borderRadius * 4}px`,
              boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.20)",
              position: "absolute",
              px: 4,
              pt: 6,
              left: 0,
              right: 0,
              zIndex: zIndex.tooltip + 1,
              display: "flex",
              flexDirection: "column",
            })}
          >
            {showOffers && (
              <SearchTypes activeType={type} onChange={handleTypeChange} />
            )}

            {filteredRecentResults.length || filteredResults.length ? (
              <Stack
                spacing={6}
                pb={6}
                pr={6}
                overflow="auto"
                sx={({ palette, shape }) => ({
                  "&::-webkit-scrollbar": {
                    width: 8,
                  },
                  "&::-webkit-scrollbar-track": {
                    background: palette.grey[200],
                    borderRadius: `${shape.borderRadius * 2}px`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: palette.grey[300],
                    borderRadius: `${shape.borderRadius * 2}px`,
                  },
                })}
              >
                <SearchResults
                  heading="Recent searches"
                  results={filteredRecentResults}
                  onClick={handleClick}
                />

                <SearchResults
                  heading="All brands"
                  results={filteredResults}
                  onClick={handleClick}
                />
              </Stack>
            ) : (
              <>
                {isLoading ? (
                  <Stack pt={2} pb={8} alignItems="center" color="grey.400">
                    <CircularProgress color="inherit" />
                  </Stack>
                ) : (
                  <Stack spacing={2} pt={2} pb={8} textAlign="center">
                    <Typography variant="h2">
                      We couldn&apos;t find any matches for &quot;{query}&quot;
                    </Typography>

                    <Typography variant="bodyRegular">
                      We add brands often, so check back soon.
                    </Typography>
                  </Stack>
                )}
              </>
            )}
          </Paper>

          <Backdrop open onClick={handleClose} />
        </>
      )}
    </Box>
  );
};

export default Search;
