import React, { useCallback } from "react";

import { Grid, Typography } from "@mui/material";

import pickerBanks, { Bank } from "../../../banking/banks";
import BankPickerItem from "./BankPickerItem";
import SearchOtherBanks from "./SearchOtherBanks";

type BankPickerPanelProps = {
  headingId?: string;
  inline?: boolean;
};

const BankPickerPanel: React.VFC<BankPickerPanelProps> = ({
  headingId,
  inline,
}) => {
  const bankData = useCallback(
    (): Array<Bank> =>
      // Filter out banks with position as 0 and sort by position.
      pickerBanks
        .filter((bank) => bank.position_number !== 0)
        .sort((a, b) => (a.position_number > b.position_number ? 1 : -1)),
    []
  );

  return (
    <Grid
      container
      spacing={{
        xs: 4,
        sm: inline ? 6 : 4,
      }}
    >
      {!inline && (
        <Grid item xs={12}>
          <Typography variant="h3" id={headingId} align="center">
            Find your bank
          </Typography>
        </Grid>
      )}

      {bankData().map((item) => (
        <Grid key={item.position_number} item xs={6} sm={inline ? 3 : 6}>
          <BankPickerItem item={item} inline={inline} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <SearchOtherBanks />
      </Grid>
    </Grid>
  );
};

export default BankPickerPanel;
