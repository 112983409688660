import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { KeyboardArrowDown as CaretDownIcon } from "@mui/icons-material";
import {
  ButtonBase,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import BorderedBox from "~common/components/primitives/BorderedBox";
import { ConsumerActivity, useGetConsumerActivities } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { ErrorResponseData } from "~common/services/types/error-handling-types";
import { useTracking } from "~common/tracking";
import LabeledSection from "~src/components/layout/LabeledSection";
import useConsumerActivities from "~src/hooks/services/useConsumerActivities";
import { selectConsumerActivities, selectUserId } from "~src/store";
import { consumerActivitiesActions } from "~src/store/slices/services/consumerActivities-slice";

import ActivityFeedActivity from "./ActivityFeedActivity";
import ActivityFeedSkeleton from "./ActivityFeedSkeleton";

type ActivityFeedSectionProps = {
  pageLoading: boolean;
};

const ActivityFeedSection: React.VFC<ActivityFeedSectionProps> = ({
  pageLoading,
}) => {
  // Fetch the initial 5 activities
  const { loading: initialLoading, error: initialError } =
    useConsumerActivities(5, undefined);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [error, setError] = useState<ErrorResponseData | null>(null);

  const {
    activities: consumerActivities,
    last_evaluated_key: lastEvaluatedKey,
  } = useSelector(selectConsumerActivities.data);
  const userId = useSelector(selectUserId);

  const {
    refetch,
    loading,
    error: serviceError,
  } = useGetConsumerActivities({
    pathParams: { userId: userId ?? "" },
    lazy: true,
  });

  const dispatch = useDispatch();
  const theme = useTheme();
  const { captureException } = useTracking();

  const handleLoadMore = async () => {
    // If we have not yet fetched all of the events from the paginated endpoint, we will
    // have a lastEvaluatedKey stored in the store
    if (lastEvaluatedKey) {
      try {
        const response = await refetch({
          pathParams: {
            userId,
          },
          queryParams: {
            limit: 20,
            last_evaluated_key: lastEvaluatedKey || undefined,
          },
        });

        const activities: ConsumerActivity[] = [];
        if (consumerActivities) {
          activities.push(...consumerActivities);
        }
        if (response) {
          activities.push(...response.activities);
        }

        dispatch(
          consumerActivitiesActions.manualSet({
            activities,
            last_evaluated_key: response?.last_evaluated_key || null,
          })
        );
      } catch (_error) {
        // Restful-react can't properly handle catching
        // errors on lazy GET requests yet. See the useEffect
        // below for handling.
      }
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    let newError;
    if (initialError !== null) {
      // The service error is already converted and captured within the useConsumerActivities
      // hook that we use for the initial 5 activities, so no need to convert again
      newError = initialError;
    }
    if (serviceError !== null) {
      newError = convertRawServiceError(serviceError);
      captureException({
        component: `ActivityFeedSection`,
        exceptionMessage: `Error fetching more activities`,
        rawError: serviceError,
      });
    }
    if (newError) {
      setError(newError);
    }
  }, [serviceError, initialError, captureException]);

  const handleCollapse = () => {
    setCollapsed(true);
  };

  return (
    <LabeledSection heading="Activity" loading={pageLoading}>
      <LoadingGuard {...{ error }}>
        {pageLoading || initialLoading ? (
          <ActivityFeedSkeleton />
        ) : (
          <>
            {consumerActivities.length === 0 ? (
              <Typography
                variant="bodyRegular"
                color="grey.600"
                sx={{
                  display: "block",
                  textAlign: "center",
                  p: 2,
                }}
              >
                This is where your activity will live.
              </Typography>
            ) : (
              <BorderedBox p={6}>
                {consumerActivities
                  .slice(0, collapsed ? 5 : undefined)
                  .map((consumerActivity) => (
                    <ActivityFeedActivity
                      key={consumerActivity.id}
                      activity={consumerActivity}
                    />
                  ))}

                {(lastEvaluatedKey || consumerActivities.length > 5) && (
                  <ButtonBase
                    onClick={
                      lastEvaluatedKey || collapsed
                        ? handleLoadMore
                        : handleCollapse
                    }
                    disabled={loading}
                    disableRipple
                    disableTouchRipple
                    sx={({ palette }) => ({
                      width: "100%",
                      borderTop: `1px solid ${palette.grey[300]}`,
                      py: 6,
                      mb: -6,
                      color: palette.grey[500],
                    })}
                  >
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={theme.spacing(5)}
                        sx={{ flexShrink: 0 }}
                      />
                    ) : (
                      <>
                        <Typography variant="h3">
                          View {lastEvaluatedKey || collapsed ? "more" : "less"}
                        </Typography>

                        <CaretDownIcon
                          sx={{
                            transform:
                              !lastEvaluatedKey && !collapsed
                                ? "rotate(180deg)"
                                : "none",
                            fill: "currentColor",
                            width: 24,
                            height: 24,
                          }}
                        />
                      </>
                    )}
                  </ButtonBase>
                )}
              </BorderedBox>
            )}
          </>
        )}
      </LoadingGuard>
    </LabeledSection>
  );
};

export default ActivityFeedSection;
