import React from "react";

import { Stack, Typography } from "@mui/material";

import { formatMoney } from "~common/utils/money";

type ActivityFeedRewardRowProps = {
  title: string;
  subtitle?: string;
  amount: number;
  amountOperator: "" | "-" | "+";
};

const ActivityFeedRewardRow: React.VFC<ActivityFeedRewardRowProps> = ({
  title,
  subtitle,
  amount,
  amountOperator,
}) => (
  <Stack spacing={4} direction="row" justifyContent="space-between">
    <Stack>
      <Typography variant="bodyXSmall">{title}</Typography>

      {subtitle && (
        <Typography variant="bodyXSmall" color="grey.500">
          {subtitle}
        </Typography>
      )}
    </Stack>

    <Typography variant="bodyXSmall">
      {amountOperator}
      {formatMoney(Math.abs(amount))}
    </Typography>
  </Stack>
);

export default ActivityFeedRewardRow;
