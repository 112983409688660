import "swiper/css";

import React from "react";

import { Stack, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";

import MerchantCardSkeleton from "./MerchantCardSkeleton";

type ShopCategorySkeletonProps = {
  rows?: number;
  columns?: number;
  showHeadingSkeleton?: boolean;
};

const ShopCategorySkeleton: React.VFC<ShopCategorySkeletonProps> = ({
  rows = 1,
  columns = 5,
  showHeadingSkeleton = true,
}) => {
  const slides = [...Array(columns).keys()].map((j) => ({
    key: j,
    node: <MerchantCardSkeleton />,
  }));

  return (
    <>
      {[...Array(rows).keys()].map((i) => (
        <Stack
          key={i}
          sx={{
            gap: 6,
            ".swiper": {
              width: "100%",
              overflow: "visible",
            },
          }}
        >
          {showHeadingSkeleton && (
            <Typography variant="h1">
              <WrappedSkeleton variant="text" width={120} />
            </Typography>
          )}

          <Swiper
            slidesPerView={2.08}
            spaceBetween={16}
            breakpoints={{
              600: { slidesPerView: 4 },
              840: { slidesPerView: 5 },
            }}
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.key}>{slide.node}</SwiperSlide>
            ))}
          </Swiper>
        </Stack>
      ))}
    </>
  );
};

export default ShopCategorySkeleton;
