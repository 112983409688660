import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.2324 51.1528V73.9909H53.2787V51.3558"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.6498 17.4541C40.2066 17.4541 46.3338 23.5442 46.3338 31.0554V51.3559H18.9658V31.0554C18.9658 23.5442 25.093 17.4541 32.6498 17.4541Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.5943 39.9878L28.5654 51.0515"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3613 29.2285C28.3613 29.2285 37.9605 41.7133 45.211 41.7133H46.028"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3613 50.8485V29.2285H46.1301"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.5078 21.2522V4.76611"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.4047 28.1116C61.3222 28.1116 62.8767 26.5665 62.8767 24.6606C62.8767 22.7546 61.3222 21.2095 59.4047 21.2095C57.4871 21.2095 55.9326 22.7546 55.9326 24.6606C55.9326 26.5665 57.4871 28.1116 59.4047 28.1116Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.5078 4.76611H70.9452L68.4943 8.4202L70.9452 12.4803H59.5078V4.76611Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.77148 51.3555H18.9661H46.2319"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.3243 17.4541H33.8754C33.6712 17.4541 33.4669 17.4541 33.2627 17.4541C40.5132 17.7586 46.2318 23.7472 46.2318 30.9539V51.2544H75.2337V32.2734C75.2337 24.0518 68.4939 17.4541 60.3243 17.4541Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
