import React, { forwardRef } from "react";

import { Box } from "@mui/material";

import { DefaultButton } from ".";
import { WrappedButtonProps } from "./WrappedButton";

type DefaultImageButtonProps = WrappedButtonProps & {
  image: React.ReactNode;
};

const DefaultImageButton: React.VFC<DefaultImageButtonProps> = forwardRef(
  ({ loading, image, sx = [], ...props }, ref) => (
    <DefaultButton
      {...props}
      ref={ref}
      loading={loading}
      sx={[
        (theme) => {
          const { spacing, palette } = theme;
          return {
            "&.MuiButton-sizeMedium": {
              padding: spacing(6),
              height: "100%",
            },
            "&.MuiButton-outlined.MuiButton-colorInherit": {
              border: `1px solid ${palette.grey[300]}`,
              borderRadius: "8px",
            },
          };
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        component="span"
        sx={{
          "& img": {
            display: "flex",
            maxWidth: "100%",
            maxHeight: "100%",
            opacity: loading ? "0" : "1",
          },
        }}
      >
        {image}
      </Box>
    </DefaultButton>
  )
);

DefaultImageButton.displayName = "DefaultImageButton";

export default DefaultImageButton;
