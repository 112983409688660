import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";

import { Box, Stack, Typography, useTheme } from "@mui/material";

import CatchCard from "~common/components/cards/CatchCard";
import CatchCardPlaceholders from "~common/components/cards/CatchCardPlaceholders";
import FlippableCard from "~common/components/cards/FlippableCard";
import { useLocalDeviceToken } from "~common/hooks/device-token-hooks";
import {
  useAnywhereCardApplications,
  useGetAnywhereCardForUser,
} from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectCatchCard, selectCurrentUser } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";
import {
  APPLICATION_PROCESSING_MESSAGES,
  getApplicationData,
} from "~src/utils/catch-card";

import RotatingText from "../RotatingText";
import CatchCardError from "./CatchCardError";

type CatchCardApplicationProps = {
  onSuccess: () => void;
  showContinue?: boolean;
  showConfetti?: boolean;
};

const CatchCardApplication: React.VFC<CatchCardApplicationProps> = ({
  onSuccess,
  showContinue,
  showConfetti,
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { trackEvent, trackError } = useTracking();
  const [deviceToken] = useLocalDeviceToken();
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const { mutate: anywhereCardApplications } = useAnywhereCardApplications();
  const { refetch: refetchCatchCard } = useGetAnywhereCardForUser({
    lazy: true,
  });
  const { paymentInstruments } = usePaymentInstruments();
  const paymentInstrument = paymentInstruments[0] || null;
  const [isPolling, setIsPolling] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleSubmit = async () => {
      const applicationData = getApplicationData(
        currentUser,
        paymentInstrument
      );

      if (applicationData) {
        try {
          const response = await anywhereCardApplications({
            ...applicationData,
            ...(deviceToken && {
              device_token: deviceToken,
            }),
          });

          trackEvent("Catch Card Application Submitted");

          if (response.status === "failed") {
            setHasError(true);
          } else {
            setIsPolling(true);
          }
        } catch (err) {
          trackError("CatchCardApplication", "Application", { error: err });
          setHasError(true);
        }
      }
    };

    if (
      currentUser &&
      paymentInstrument &&
      catchCard?.user_flow_status === "not_initiated"
    ) {
      void handleSubmit();
    }
  }, [
    currentUser,
    deviceToken,
    paymentInstrument,
    catchCard,
    anywhereCardApplications,
    trackEvent,
    trackError,
  ]);

  useEffect(() => {
    const handleRequest = async () => {
      let response = await refetchCatchCard();

      while (
        response?.user_flow_status !== "approved" &&
        response?.user_flow_status !== "denied" &&
        response?.user_flow_status !== "pending_manual_review"
      ) {
        /* eslint-disable no-await-in-loop */
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await refetchCatchCard();
        /* eslint-enable no-await-in-loop */
      }

      dispatch(setCatchCard(response));
      setIsPolling(false);

      if (response?.user_flow_status === "approved") {
        setSuccess(true);
      }
    };

    if (isPolling) {
      void handleRequest();
    }
  }, [isPolling, dispatch, refetchCatchCard]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (isPolling) {
      timeout = setTimeout(() => {
        setHasTimedOut(true);
      }, 90000);
    }

    return () => {
      timeout && clearInterval(timeout);
    };
  }, [isPolling]);

  useEffect(() => {
    if (success) {
      /*
       * This event name deviates from our usual pattern because
       * marketing is listening for it in Iterable.
       */
      trackEvent("CatchPass Application Succeeded");
      onSuccess();
    }
  }, [success, onSuccess, trackEvent]);

  if (hasTimedOut) {
    return (
      <CatchCardError
        showIcon
        errorType="timeout"
        showContinue={showContinue}
      />
    );
  }

  if (catchCard?.user_flow_status === "pending_manual_review") {
    trackEvent("Catch Card Application Pending Manual Review");
    return (
      <CatchCardError
        showIcon
        errorType="pending"
        showContinue={showContinue}
      />
    );
  }

  if (catchCard?.user_flow_status === "denied") {
    trackEvent("Catch Card Application Denied");
    return <CatchCardError showIcon errorType="denied" />;
  }

  if (hasError) {
    trackEvent("Catch Card Application Error");
    return <CatchCardError showIcon />;
  }

  return (
    <Box>
      {success && (
        <Box
          position="absolute"
          overflow="hidden"
          sx={{
            inset: 0,
          }}
        >
          {showConfetti && (
            <Confetti
              colors={[
                palette.tertiary.main,
                palette.primary.main,
                palette.primary.light,
              ]}
              initialVelocityY={5}
              numberOfPieces={300}
              recycle={false}
            />
          )}
        </Box>
      )}

      <Stack
        spacing={16}
        alignItems="center"
        textAlign="center"
        maxWidth={328}
        mx="auto"
        position="relative"
        zIndex={3}
      >
        <Stack spacing={6}>
          <Typography
            variant="h2"
            sx={{
              fontSize: 32,
              lineHeight: 1.25,
            }}
          >
            {success
              ? "Meet your new virtual Catch card!"
              : "Your debit card is getting a glow up"}
          </Typography>

          <Typography variant="bodyRegular" position="relative">
            {success ? (
              "Ready to earn $$ anywhere..."
            ) : (
              <RotatingText messages={APPLICATION_PROCESSING_MESSAGES} />
            )}
          </Typography>
        </Stack>

        <Box width="100%">
          <FlippableCard
            flipped={success}
            front={<CatchCard sx={{ borderRadius: 0 }} />}
            back={<CatchCardPlaceholders sx={{ borderRadius: 0 }} />}
            elevation={0}
            infinite
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default CatchCardApplication;
