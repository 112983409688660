import React from "react";
import { IntercomProvider as ReactUseIntercomProvider } from "react-use-intercom";

import { useEnv } from "../../contexts/env-context";

type IntercomProviderProps = {
  children: React.ReactNode;
  noop?: boolean;
};

const IntercomProvider: React.FC<IntercomProviderProps> = ({
  children,
  noop,
}) => {
  const { APP_ENVIRONMENT } = useEnv();

  let appId = "";

  if (noop) {
    // Create an empty Intercom provider so that we can use the Intercom hook
    // without actually loading the Intercom script in TOFU, etc.
    return (
      <ReactUseIntercomProvider
        appId={appId}
        autoBoot={false}
        shouldInitialize={false}
      >
        {children}
      </ReactUseIntercomProvider>
    );
  }

  if (APP_ENVIRONMENT === "development") {
    appId = "o626qg7k";
  } else {
    appId = "v07mmc2x";
  }

  return (
    <ReactUseIntercomProvider appId={appId} autoBoot>
      {children}
    </ReactUseIntercomProvider>
  );
};

IntercomProvider.defaultProps = {
  noop: false,
};

export default IntercomProvider;
