import React, { CSSProperties } from "react";

import { Box, BoxProps, useTheme } from "@mui/material";

type WavySectionProps = BoxProps & {
  backgroundColor?: CSSProperties["color"];
};

const WavySection: React.VFC<WavySectionProps> = ({
  backgroundColor,
  children,
  sx = {},
  ...props
}) => {
  const { palette, spacing } = useTheme();
  backgroundColor = backgroundColor || palette.primaryBackground || "";

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      sx={[
        {
          "&:before": {
            content: "''",
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 442 58' xml:space='preserve'%3E%3Cpath d='m442 58-.2-11.1.2-.15c-32.81 0-64.77-6.88-91.19-19.63C312.88 9.47 267.53 0 221.02 0c-46.5 0-91.86 9.47-129.79 27.11C64.82 39.86 32.86 46.74.05 46.74L0 46.9.05 58' fill='${backgroundColor.replace(
              "#",
              "%23"
            )}'/%3E%3C/svg%3E") repeat-x`,
            backgroundPosition: "center top",
            pb: {
              zero: "calc(100% / 3 * 0.13)",
              xl: 14.5,
            },
            display: "block",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Box
        sx={{
          backgroundColor,
          pt: {
            zero: `calc(${spacing(14.5)} - (100% / 3 * 0.13))`,
            xl: 0,
          },
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default WavySection;
