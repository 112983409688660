import { RewardCampaignResponseData } from "~common/services";
import { ErrorResponseData } from "~common/services/types/error-handling-types";

const NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID =
  "new_user_onboarding_reward_campaign_id";

const getRewardCampaignId = (rewardCampaignId?: string | null) => {
  if (!rewardCampaignId) {
    return "";
  }

  return rewardCampaignId.replace("rc-", "");
};

const rewardCampaignInfo = (
  rewardCampaign: RewardCampaignResponseData | null,
  error: ErrorResponseData | null
) => {
  const isInvalidCampaign = !!(
    error &&
    (error.error_type === "NOT_FOUND" ||
      error.error_type === "REWARD_CAMPAIGN_NOT_ACTIVE")
  );

  const isExpiredCampaign = !!(
    error &&
    (error.error_type === "REWARD_CAMPAIGN_LINK_EXPIRED" ||
      error.error_type === "REWARD_CAMPAIGN_CLAIM_LIMIT_REACHED")
  );

  const isOnlyForNewUsers = !!(
    !error &&
    rewardCampaign &&
    rewardCampaign.eligible_user_type === "new_users_only"
  );

  const isFlexCredit = !!(
    !error &&
    rewardCampaign &&
    rewardCampaign.merchant_id === "FLEX_MERCHANT_ID"
  );

  const isSecondChanceBonus = !!(
    !error &&
    rewardCampaign &&
    rewardCampaign.is_second_chance_bonus
  );

  return {
    isInvalidCampaign,
    isExpiredCampaign,
    isOnlyForNewUsers,
    isFlexCredit,
    isSecondChanceBonus,
  };
};

const REWARD_CAMPAIGN_ERRORS = {
  linkExpired: {
    title: "Oops! That link is expired",
    subtitle:
      "You may have received a more recent link. Otherwise, try logging in again and you'll receive a new one.",
    logoutOnClose: true,
  },
  existingUserCantClaimReferral: {
    title: "Sorry, existing users can't claim a referral bonus",
    subtitle:
      "...but we love the enthusiasm! You can earn bonus credits by referring your friends.",
  },
  cantClaimOwnGift: {
    title: "Sorry, you can't claim your own gift",
    subtitle: "...but we love the enthusiasm!",
  },
  cantClaimGiftedRewardFromAlreadyClaimedRewardCampaign: {
    title: "Sorry, you already got one like this",
    subtitle:
      "This credit came from a special offer that can only be claimed once. You’ve already claimed yours.",
  },
  unknownErrorClaimingGift: {
    title: "Oops! Something went wrong.",
    subtitle:
      "We couldn't claim your gift. Please contact the sender for a new gift link.",
  },
  creditCanOnlyBeClaimedOnce: {
    title: "Sorry, this credit can only be claimed once",
    subtitle: "Looking to earn some extra credit? Try referring a friend.",
  },
  creditOnlyForNewUsers: {
    title: "Sorry, this credit is just for new users",
    subtitle: "Looking to earn some extra credit? Try referring a friend.",
  },
  creditNoLongerAvailable: {
    title: "Sorry, this credit is no longer available",
    subtitle:
      "This campaign has ended, but you can still earn extra credit by referring a friend.",
  },
  creditOnlyForNewCustomers: (merchantName: string) => ({
    title: "Sorry, this credit is just for new customers",
    subtitle: `Looks like you've already shopped at ${merchantName} with Catch.`,
  }),
};

export {
  getRewardCampaignId,
  NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
  REWARD_CAMPAIGN_ERRORS,
  rewardCampaignInfo,
};
