import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Cancel as CancelIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import extensionIcons from "~common/assets/illustrations/extension_icons.png";
import { PrimaryButton } from "~common/components/controls/buttons";
import { BROWSER_EXTENSION_CARD_NUX, useMarkNuxSeen } from "~common/services";
import { useTracking } from "~common/tracking";
import { selectNuxs } from "~src/store";
import { setNuxs } from "~src/store/slices/nuxs-slice";

import InfoCard from "../shared/InfoCard";
import BrowserExtensionModal from "./BrowserExtensionModal";

const BrowserExtension: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const nuxs = useSelector(selectNuxs);
  const [open, setOpen] = useState(false);
  const { mutate: markNuxSeen } = useMarkNuxSeen();

  const handleClick = () => {
    setOpen(true);
    trackEvent("Browser Extension Get the extensions Clicked");
  };

  const handleDismiss = async () => {
    await markNuxSeen(undefined, {
      pathParams: { nuxName: BROWSER_EXTENSION_CARD_NUX },
    });

    dispatch(
      setNuxs(nuxs?.filter((nux) => nux !== BROWSER_EXTENSION_CARD_NUX) || [])
    );

    trackEvent("Browser Extension Dismiss Clicked");
  };

  return (
    <>
      <InfoCard
        cta={
          <PrimaryButton onClick={handleClick}>
            Get the extensions
          </PrimaryButton>
        }
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Box
            component="img"
            src={extensionIcons}
            height={40}
            alt="Safari and Chrome browser icons"
          />

          <IconButton
            onClick={handleDismiss}
            aria-label="Dismiss card"
            sx={({ palette }) => ({
              color: palette.grey[300],
              "&:hover": {
                color: palette.grey[400],
              },
            })}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="h2">
            Earn rewards from the comfort of your browser
          </Typography>

          <Typography variant="bodyRegular">
            Access your Catch card to earn rewards on any website, on your phone
            or computer.
          </Typography>
        </Stack>
      </InfoCard>

      <BrowserExtensionModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default BrowserExtension;
