import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetAnywhereCardForUser } from "~common/services/issued-cards";
import { selectCatchCard, selectIsAuthed } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";

const useFetchAnywhereCardForUser = () => {
  const dispatch = useDispatch();
  const storedData = useSelector(selectCatchCard);
  const isAuthed = useSelector(selectIsAuthed);
  const { data } = useGetAnywhereCardForUser({
    lazy: !isAuthed || !!storedData,
  });

  useEffect(() => {
    if (!storedData && data) {
      dispatch(setCatchCard(data));
    }
  }, [dispatch, data, storedData]);
};

export default useFetchAnywhereCardForUser;
