import { buildTheme } from "./base";

const catchTheme = buildTheme({
  name: "Default Catch Theme",
  isCatch: true,
});

const consumerCatchTheme = buildTheme({
  name: "Consumer Catch Theme",
  isCatch: true,
  shape: {
    buttonBorderRadius: 30,
  },
});

export default catchTheme;
export { consumerCatchTheme };
