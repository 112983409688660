import React, { forwardRef } from "react";

import {
  Box,
  LinearProgress,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";

import { useMerchantbadgeUrl } from "~common/hooks/asset-hooks";
import { RewardCampaignResponseData } from "~common/services";
import { formatMoney } from "~common/utils/money";

type CatchCardRewardProps = StackProps & {
  reward?: (RewardCampaignResponseData & { claimed?: boolean }) | null;
  percentComplete?: number;
  fullWidth?: boolean;
};

const CatchCardReward: React.VFC<CatchCardRewardProps> = forwardRef(
  ({ reward, percentComplete, fullWidth }, ref) => {
    const badgeUrl = useMerchantbadgeUrl(reward?.merchant_id);

    if (!reward) {
      return null;
    }

    return (
      <Stack
        spacing={2}
        width="100%"
        pb={fullWidth ? 0 : 2}
        bgcolor="common.white"
        ref={ref}
      >
        <Stack
          width="100%"
          px={2}
          py={fullWidth ? 3 : 2}
          direction="row"
          alignItems="center"
          justifyContent={fullWidth ? "center" : undefined}
          bgcolor={fullWidth ? "tertiary.light" : "tints.purple.light"}
          sx={
            fullWidth
              ? undefined
              : ({ shape }) => ({
                  borderRadius: `${shape.borderRadius * 3}px`,
                })
          }
        >
          <Box
            component="img"
            src={badgeUrl}
            sx={({ palette }) => ({
              width: 48,
              height: 48,
              ...(reward.merchant_id !== "FLEX_MERCHANT_ID" && {
                mr: 2,
                ...(!fullWidth && {
                  border: `3px solid ${palette.common.white}`,
                  borderRadius: "50%",
                }),
              }),
            })}
          />

          <Stack alignItems="flex-start">
            <Typography variant="h3">
              {reward.claimed
                ? `You have a ${formatMoney(reward.total_amount || 0, {
                    dropCentsIfRound: true,
                  })} reward 🎉`
                : `Claim your ${
                    reward.merchant_id === "FLEX_MERCHANT_ID"
                      ? "Catch Cash"
                      : `${reward.merchant_name || "welcome"} rewards`
                  }`}
            </Typography>

            <Stack
              direction="row"
              my={1}
              sx={({ shape }) => ({
                borderRadius: `${shape.borderRadius * 2.5}px`,
                overflow: "hidden",
              })}
            >
              <Typography
                variant="bodySmall"
                fontWeight="700"
                px={2}
                bgcolor="tertiary.main"
                color="tertiary.contrastText"
              >
                {reward.claimed
                  ? "Claim $20 extra in the app"
                  : `${formatMoney(reward.total_amount || 0, {
                      dropCentsIfRound: true,
                    })} now`}
              </Typography>

              {!reward.claimed && (
                <Typography
                  variant="bodySmall"
                  fontWeight="700"
                  px={2}
                  bgcolor="tertiary.contrastText"
                  color="tertiary.main"
                  sx={({ palette }) => ({
                    position: "relative",
                    "&:after": {
                      content: "''",
                      background: `linear-gradient(105deg, ${
                        palette.tertiary.main
                      } 5px, ${palette.common.white} 5px, ${
                        palette.common.white
                      } 7px, ${palette.tertiary.contrastText || ""} 7px, ${
                        palette.tertiary.contrastText || ""
                      } 12px)`,
                      height: "100%",
                      width: 10,
                      position: "absolute",
                      left: -2.5,
                    },
                  })}
                >
                  $20 extra in the app
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        {percentComplete && (
          <LinearProgress
            variant="determinate"
            value={percentComplete}
            sx={({ shape }) => ({
              borderRadius: `${shape.borderRadius}px`,
            })}
          />
        )}
      </Stack>
    );
  }
);

CatchCardReward.displayName = "CatchCardReward";

export default CatchCardReward;
