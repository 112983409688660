import React from "react";

import { ThemeProvider } from "@mui/material";

import { userPortalCatchTheme } from "~src/theme";

const CatchThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={userPortalCatchTheme}>{children}</ThemeProvider>
);

export default CatchThemeProvider;
