import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7C5 6.44771 5.44772 6 6 6H18C18.5523 6 19 6.44772 19 7V8H5V7ZM5 10H19V16C19 16.5523 18.5523 17 18 17H6C5.44772 17 5 16.5523 5 16V10ZM11 11H6V12H11V11Z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;
