import type { PaymentInstrument, UserResponseData } from "~common/services";

import { getAddressForCatchCardApplication } from "./address";
import { getStringBirthday } from "./dayjs";

const getApplicationData = (
  currentUser: UserResponseData | null,
  paymentInstrument: PaymentInstrument | null
) => {
  const billingAddress = getAddressForCatchCardApplication(
    currentUser?.billing_address
  );
  const birthday = getStringBirthday(currentUser);

  if (!billingAddress || !birthday || !paymentInstrument) {
    return null;
  }

  return {
    first_name: currentUser?.first_name || "",
    last_name: currentUser?.last_name || "",
    email_address: currentUser?.email || "",
    date_of_birth: birthday,
    phone_number: currentUser?.phone_number || "",
    billing_address: billingAddress,
    payment_instrument_id: paymentInstrument.payment_instrument_id,
  };
};

const hasValidApplicationData = (currentUser: UserResponseData | null) =>
  !!(
    currentUser?.first_name &&
    currentUser?.last_name &&
    currentUser?.billing_address &&
    getStringBirthday(currentUser)
  );

const APPLICATION_PROCESSING_MESSAGES = [
  "Generating your card details",
  "Adding some flair to your card ✨",
  "Customizing your welcome rewards 🎁",
  "Curating rewards that'll make you smile 😊",
  "Almost there! Just adding a little magic...",
];

const APPLICATION_ERROR_MESSAGES = {
  error: {
    heading: "Sorry, we weren't able to activate your Catch card.",
    body: [
      "There are a few reasons this might've happened. You can get in touch with our team for more information.",
    ],
    suggestions: undefined,
  },
  timeout: {
    heading: "Looks like it's taking longer than usual.",
    body: [
      "You can navigate away and check your card status by visiting the account page.",
    ],
    suggestions: undefined,
  },
  pending: {
    heading: "Hang tight, you're in the queue!",
    body: [
      "Due to a high volume of applications, expect your card in 1-3 business days. We'll let you know as soon as it's ready.",
      "While you wait, go claim this week's drops!",
    ],
    suggestions: undefined,
  },
  denied: {
    heading: "Your application is under manual review.",
    body: [
      "Your ID verification did not pass the required checks, so we're manually reviewing it.",
      "Keep an eye on your email! If approved, you'll receive a message within 1-3 business days.",
    ],
    suggestions: undefined,
  },
  debitAuthFailed: {
    heading: "Sorry, that card didn't work.",
    body: ["Here are a few things to try:"],
    suggestions: [
      "Check if your bank is asking you to approve our temporary hold.",
      "Increase your balance if it's running low.",
      "Link a different debit card if you have one.",
    ],
  },
};

export {
  APPLICATION_ERROR_MESSAGES,
  APPLICATION_PROCESSING_MESSAGES,
  getApplicationData,
  hasValidApplicationData,
};
