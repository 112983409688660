import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.473 88.586C113.566 75.8232 114.507 64.1989 110.575 62.6223C106.643 61.0457 99.3272 70.1139 94.2343 82.8767C89.1413 95.6395 88.2002 107.264 92.1323 108.84C96.0643 110.417 103.381 101.349 108.473 88.586Z"
      fill="#6B27DD"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.9952 70.9417L88.8491 70.7953C87.7537 63.9875 86.1471 54.2516 85.4168 50.3719C84.1024 43.5642 82.7148 39.2453 81.9846 37.1224C81.0352 34.2675 77.1648 33.9015 77.1648 38.7328C76.5806 33.6087 75.9233 28.7774 75.4852 27.3133C74.5358 24.2389 73.5134 24.0925 72.345 24.2389C70.5923 24.4585 69.716 26.8009 70.0081 28.8506V29.2166C69.9351 28.8506 69.9351 28.5578 69.8621 28.3382C69.1318 24.5317 67.5982 22.9212 65.6995 23.3604C63.5817 23.7997 63.2166 27.6062 64.239 37.6348L64.166 37.1224C63.0706 30.3146 61.683 29.5094 60.5876 29.2166C59.4922 28.9238 57.2284 29.8022 57.5935 34.8531C57.9587 39.9041 61.61 65.3051 61.61 65.3051C61.61 65.3051 56.9363 49.4203 54.4533 49.2739C51.9704 49.1275 50.729 52.0556 51.6783 58.4974C52.6277 64.9391 55.9869 80.3847 60.2955 89.4618C65.5535 100.442 76.2154 101.32 81.4734 100.954C86.8044 100.588 101.921 101.32 101.921 101.32C101.921 101.32 105.207 96.4159 108.566 87.0461C111.561 78.6279 112.948 71.2345 112.948 71.2345L88.9952 70.9417Z"
      fill="#E1D4F8"
    />
    <path
      d="M30.1237 115.378C33.9605 113.581 32.3652 102.029 26.5606 89.5752C20.756 77.1216 12.9401 68.4827 9.10332 70.2796C5.26656 72.0765 6.86183 83.6288 12.6665 96.0823C18.4711 108.536 26.287 117.175 30.1237 115.378Z"
      fill="#6B27DD"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101.92 101.394C101.92 101.394 86.8036 100.662 81.4726 101.028C76.1416 101.394 65.4797 100.516 60.2947 89.5354C55.9861 80.3851 52.6269 65.0127 51.6775 58.571C50.7282 52.1292 51.8966 49.2743 54.4526 49.3475C56.9355 49.4939 61.6092 65.3787 61.6092 65.3787C61.6092 65.3787 57.8848 39.9045 57.5197 34.9267C57.1546 29.949 59.4184 29.0706 60.5138 29.2902C61.6092 29.5098 62.9967 30.3882 64.0921 37.196C65.1875 44.0038 66.7211 53.6664 66.7211 53.6664"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.7095 52.6412C72.1983 49.7132 70.5917 32.1447 69.8615 28.4114C69.1312 24.6049 67.5976 22.9945 65.6989 23.4337C63.5811 23.8729 63.216 27.6794 64.2384 37.7081"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.7708 52.2752C78.1865 47.9563 76.4339 30.4611 75.4845 27.3866C74.5352 24.3121 73.5128 24.1657 72.3444 24.3121C70.5917 24.5317 69.7154 26.7278 70.0075 28.7774"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.1641 38.8062C77.1641 33.9749 80.9615 34.3409 81.9838 37.1958C82.7141 39.3186 84.1016 43.6376 85.4161 50.4453C86.6576 56.7407 90.0168 77.9692 90.0168 77.9692"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.947 71.3079L88.9941 70.9419"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4951 109.665C20.4951 109.665 38.6789 104.468 49.779 100.808C60.8791 97.1479 62.5588 80.0919 62.8509 74.4553C63.143 68.8188 61.7555 51.1771 61.6824 49.7863C61.5364 47.7367 59.5647 46.053 57.2278 48.1759C54.5258 50.5915 54.3067 63.1822 54.3067 63.1822C53.7955 59.1561 52.335 37.4884 51.8968 34.3407C51.4586 31.193 49.5599 29.0702 47.6612 29.8022C45.7625 30.5342 45.1783 32.9499 45.3244 38.806V38.8792C45.0323 34.9263 44.8132 31.2662 44.7401 29.5826C44.5941 25.2637 42.7684 24.1656 40.7237 24.6048C38.6789 25.1173 37.9486 29.2898 38.2407 32.8767C38.0947 31.0466 37.9486 29.729 37.8026 29.2898C37.3644 27.1669 35.0275 26.3617 33.567 27.4597C32.1064 28.5577 31.9604 33.0231 32.6176 41.5877C32.0334 39.4648 31.011 39.0256 29.5505 39.3184C27.4327 39.7576 26.4834 44.2962 26.2643 49.4935C26.1913 52.0556 26.1913 58.4241 26.2643 64.3535C26.3373 70.5024 26.3373 76.0658 26.3373 76.0658L7.56934 81.0435C7.56934 81.0435 10.3444 91.1454 13.1924 97.1479C16.0405 103.15 20.4951 109.665 20.4951 109.665Z"
      fill="#E1D4F8"
    />
    <path
      d="M7.64258 81.1166L26.4106 76.1389C26.4106 76.1389 26.1915 54.764 26.3375 49.5666C26.4836 44.3693 27.506 39.8308 29.6237 39.3916C31.7415 38.9523 32.91 39.904 33.129 44.8085C33.4212 49.713 33.9323 57.5456 33.9323 57.5456"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.213 55.7154C39.8478 52.3481 38.3142 31.4123 37.8761 29.2895C37.4379 27.1666 35.1011 26.3614 33.6405 27.4594C32.18 28.5575 32.0339 33.0228 32.6912 41.5874"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.4194 54.6908C45.9082 51.0307 44.9589 33.9015 44.8128 29.5826C44.6668 25.2637 42.8411 24.1656 40.7963 24.6048C38.6786 25.1173 38.0213 29.4362 38.3865 33.0963"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.2518 38.8792C45.1058 32.9498 45.69 30.6074 47.5887 29.8754C49.4874 29.1433 51.3861 31.2662 51.8243 34.4139C52.2624 37.5616 53.723 59.3025 54.2342 63.2554C54.2342 63.2554 54.4533 50.6647 57.1553 48.249C59.4921 46.1994 61.4639 47.8098 61.6099 49.8595C61.6829 51.2503 63.0705 68.892 62.7783 74.5285C62.4862 80.165 60.8066 97.2211 49.7065 100.881C44.5216 102.565 37.8761 104.614 32.18 106.298C30.9385 106.664 29.7701 107.03 28.6747 107.323C23.8549 108.714 21.0068 109.519 21.0068 109.519"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7295 6.74365L43.9368 16.5527"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.0879 2.57129L54.5991 12.966"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.0811 4.84033L65.1152 14.503"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1347 115.376C26.3373 117.132 18.5234 108.495 12.6812 96.0503C6.83904 83.6059 5.30547 72.1133 9.10288 70.2832"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3824 40.27C28.0903 41.9537 28.3824 46.7118 30.2811 47.517C32.1798 48.3222 33.1292 44.6621 32.9101 43.1249"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9103 29.729C32.7642 32.1446 33.0564 35.2923 34.9551 36.0975C36.9268 36.8296 38.2413 34.9995 38.0222 32.3642C37.8031 29.6558 37.584 27.3865 35.3202 27.1669C33.2024 26.9473 32.9833 28.8506 32.9103 29.729Z"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.19 27.46C39.117 28.9972 39.117 32.1449 40.7966 33.2429C42.4762 34.2678 44.448 33.0233 44.667 31.3397C44.8131 29.5828 44.448 25.5567 43.1335 24.8979C41.819 24.1659 39.3361 24.3855 39.19 27.46Z"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.8359 33.243C45.8359 34.9999 45.909 38.5868 48.0998 38.8796C50.2906 39.1724 51.6051 35.5123 50.8748 32.4378C50.5097 30.8274 49.1952 29.8025 48.0998 29.8757C46.7123 29.8757 45.8359 31.6326 45.8359 33.243Z"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.8856 47.6636C57.3744 49.4936 56.9362 54.3249 58.6889 55.789C60.4415 57.253 62.0481 55.1302 62.0481 55.1302"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101.701 101.54C104.038 98.0994 106.448 93.5609 108.42 88.51C110.976 82.1414 112.509 75.9925 112.875 71.3075L102.067 71.1611L94.8369 101.101L101.701 101.54Z"
      fill="#D02F90"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.1353 108.86C96.0787 110.398 103.381 101.394 108.493 88.5834C113.605 75.8462 114.555 64.2071 110.611 62.5967"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2369 78.3352C14.1415 77.3835 10.7093 77.3835 13.4113 81.9952C16.1133 86.607 17.8659 88.5102 18.5962 92.9755C19.3265 97.4408 21.3712 102.785 24.0732 106.298C26.7752 109.812 29.1851 110.398 29.8424 108.641C29.9884 108.202 30.1345 107.762 30.2805 107.323"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2148 79.3599L16.9899 78.6279"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2533 106.298C31.0119 106.664 29.8435 107.03 28.748 107.323"
      stroke="#232639"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
