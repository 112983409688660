import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.0777 32.0539C62.966 32.0539 62.966 32.0539 62.8544 32.0539C63.1893 31.2746 63.301 30.384 63.301 29.4934C63.301 25.3742 59.9515 22.0343 55.8204 22.0343C55.1505 22.0343 54.5922 22.1456 54.034 22.257C53.4757 16.1338 48.1165 11.2354 41.6408 11.2354C35.3884 11.2354 30.2524 15.6885 29.3592 21.4776C27.6845 20.3644 25.5631 19.6964 23.4418 19.6964C17.4126 19.6964 12.5 24.4835 12.5 30.4953C12.5 36.5071 17.4126 41.2943 23.3301 41.2943H63.0777C65.6456 41.2943 67.767 39.179 67.767 36.6184C67.767 34.0579 65.6456 32.0539 63.0777 32.0539Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3594 21.4775C30.9225 22.4795 32.1506 23.9268 33.0438 25.4854"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.0338 22.2573C54.0338 22.5913 54.0338 22.814 54.0338 23.148C54.0338 25.4859 53.3639 27.7125 52.1357 29.4937"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5629 47.417C25.5629 49.9776 26.9027 53.2061 24.223 56.546C21.5434 59.8859 19.4221 62.7804 20.5386 67.6789H32.9318C32.9318 67.6789 32.0386 63.3371 33.9366 60.4425C35.7231 57.548 37.2862 56.1007 37.3978 52.6495C37.5095 49.1983 36.6163 46.749 36.6163 46.749L25.5629 47.417Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.4704 57.7785C32.0117 56.2086 31.6468 54.6604 30.6555 54.3206C29.6641 53.9808 28.4217 54.978 27.8804 56.548C27.3392 58.1179 27.704 59.6661 28.6954 60.0059C29.6867 60.3457 30.9292 59.3485 31.4704 57.7785Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.0488 41.6274C57.4954 42.5181 58.0537 43.4087 58.7236 44.4107C62.9663 50.8678 59.7284 57.4362 59.7284 57.4362L47.8935 56.2116C48.8983 51.5358 48.2284 47.8619 45.9954 44.0767C45.5488 43.2974 44.9905 42.4067 44.5439 41.6274"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.2531 41.2939H49.9036C49.5687 42.1846 49.6803 43.4092 50.3502 44.5225C51.2434 46.3038 52.8065 47.3057 53.923 46.7491C55.0395 46.1924 55.1512 44.2998 54.258 42.5186C53.923 42.1846 53.5881 41.7393 53.2531 41.2939Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
