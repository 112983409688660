import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { Lock as LockIcon } from "@mui/icons-material";
import { Box, Link } from "@mui/material";

import { ErrorAlert } from "~common/components/alerts";
import { useTracking } from "~common/tracking";
import { selectCatchCard } from "~src/store";

import Banner from "../shared/Banner";
import CatchCardModal from "./CatchCardModal";

const CatchCardBanner: React.VFC = () => {
  const { trackEvent } = useTracking();
  const intercom = useIntercom();
  const catchCard = useSelector(selectCatchCard);
  const [openModal, setOpenModal] = useState(false);
  const [initialScreen, setInitialScreen] =
    useState<"confirmation" | "pin" | "landing" | "">("");
  const showBanner =
    catchCard &&
    (catchCard?.user_flow_status !== "approved" || !catchCard.is_pin_set);
  const hasError =
    catchCard?.user_flow_status === "denied" ||
    catchCard?.card_status === "closed" ||
    catchCard?.card_status === "suspended";

  useEffect(() => {
    if (showBanner) {
      trackEvent("Catch Card Banner Viewed");
    }
  }, [trackEvent, showBanner]);

  useEffect(() => {
    if (catchCard && !initialScreen) {
      if (
        catchCard.user_flow_status === "pending" ||
        catchCard.user_flow_status === "pending_manual_review"
      ) {
        setInitialScreen("confirmation");
        return;
      }

      if (catchCard.user_flow_status === "approved" && !catchCard.is_pin_set) {
        setInitialScreen("pin");
        return;
      }

      setInitialScreen("landing");
    }
  }, [catchCard, initialScreen]);

  const handleClick = () => {
    trackEvent("Catch Card Activate now Clicked");
    setOpenModal(true);
  };

  if (!initialScreen) {
    return null;
  }

  return (
    <>
      {showBanner && (
        <>
          {hasError ? (
            <ErrorAlert>
              <Box textAlign="left">
                <strong>
                  Sorry, we weren&apos;t able to activate your Catch card.
                </strong>{" "}
                There are a few reasons this might&apos;ve happened. You can{" "}
                <Link component="button" onClick={intercom.show}>
                  get in touch with our team
                </Link>{" "}
                for more information.
              </Box>
            </ErrorAlert>
          ) : (
            <Banner
              heading={
                initialScreen === "pin"
                  ? "Finish setting up your Catch card"
                  : "Your Catch card is ready"
              }
              body={
                initialScreen === "pin"
                  ? "Add a secure PIN to your card so you can pay in any store."
                  : "Gas, groceries — you name it! Pay with Catch card to earn 1.5% back."
              }
              button={{
                children:
                  initialScreen === "pin" ? "Set your PIN" : "Activate now",
                onClick: handleClick,
                startIcon: initialScreen === "pin" ? <LockIcon /> : undefined,
              }}
              hideBackground={initialScreen === "pin"}
            />
          )}
        </>
      )}

      <CatchCardModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        initialScreen={initialScreen}
      />
    </>
  );
};

export default CatchCardBanner;
