import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { Box, Stack, Switch, SxProps, Theme, Typography } from "@mui/material";

import { InlineTextButton } from "~common/components/controls/buttons";
import DefaultSnackbar from "~common/components/snackbars/DefaultSnackbar";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import type { UserResponseData } from "~common/services";
import { useUpdateCurrentUser } from "~common/services/users";
import { useTracking } from "~common/tracking";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";

type AccountSettingsToggleProps = {
  label: string;
  trackingLabel?: string;
  name:
    | "sms_credit_reminders_opt_in"
    | "push_credit_reminders_opt_in"
    | "push_highlights_opt_in";
  sx?: SxProps<Theme>;
};

const AccountSettingsToggle: React.VFC<AccountSettingsToggleProps> = ({
  label,
  trackingLabel,
  name,
  sx,
}) => {
  const dispatch = useDispatch();
  const intercom = useIntercom();
  const inputId = useUniqueId(name);
  const { trackEvent, trackError } = useTracking();
  const { currentUser, loading } = useCurrentUser();
  const { mutate: updateCurrentUser } = useUpdateCurrentUser();
  const [toggleChecked, setToggleChecked] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.[name]) {
      setToggleChecked(!!currentUser?.[name]);
    }
  }, [currentUser, name]);

  const handleChange = async (e: React.ChangeEvent, checked: boolean) => {
    if (isUpdating) {
      return;
    }

    setToggleChecked(checked);
    setIsUpdating(true);

    trackEvent(`Change ${trackingLabel || label} Opt In`, {
      optedIn: checked,
    });

    try {
      await updateCurrentUser({
        [name]: checked,
      }).then((userResponseData: UserResponseData) => {
        dispatch(currentUserActions.manualSet(userResponseData));
        setShowSuccessMessage(true);
      });
    } catch (error) {
      trackError(
        `Edit ${trackingLabel || label} Opt In`,
        "Updating current user",
        {
          error,
        }
      );
      setShowErrorMessage(true);
      setToggleChecked(!!currentUser?.[name]);
    }

    setIsUpdating(false);
  };

  return (
    <Box sx={sx}>
      <DefaultSnackbar
        severity="success"
        singleLine
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
      >
        <>Preferences updated</>
      </DefaultSnackbar>

      <DefaultSnackbar
        severity="error"
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
      >
        <>
          Something didn&apos;t work as expected.{" "}
          <InlineTextButton
            sx={{
              "&.MuiButton-root.MuiButton-text .label": {
                color: "inherit",
                fontWeight: 500,
              },
            }}
            onClick={intercom.show}
          >
            Contact support
          </InlineTextButton>{" "}
          for assistance.
        </>
      </DefaultSnackbar>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="bodyRegular" component="label" htmlFor={inputId}>
          {label}
        </Typography>

        <Switch
          id={inputId}
          checked={toggleChecked}
          onChange={handleChange}
          disabled={loading}
        />
      </Stack>
    </Box>
  );
};

export default AccountSettingsToggle;
