import React, { useCallback, useEffect, useState } from "react";

import { LinearProgress } from "@mui/material";

import FullHeightDialog, {
  FullHeightDialogProps,
} from "~common/components/modals/FullHeightDialog";

import CatchCardApplication from "../shared/onboarding/CatchCardApplication";
import PlaidIDVLink from "../shared/onboarding/PlaidIDVLink";
import CatchCardContactInfo from "./CatchCardContactInfo";
import CatchCardLanding from "./CatchCardLanding";
import CatchCardLinkDebit from "./CatchCardLinkDebit";
import CatchCardPinSetup from "./CatchCardPinSetup";

type CatchCardModalProps = FullHeightDialogProps & {
  initialScreen?: "landing" | "contact" | "debit" | "confirmation" | "pin";
};

const CatchCardModal: React.VFC<CatchCardModalProps> = ({
  initialScreen,
  ...props
}) => {
  const [activeScreen, setActiveScreen] = useState<keyof typeof screens>(
    initialScreen || "landing"
  );
  const [isEditing, setIsEditing] = useState(false);
  const [showEditingCancel, setShowEditingCancel] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setActiveScreen("pin");
      }, 8000);
    }
  }, [success]);

  const handleEdit = useCallback((isActive: boolean, hideCancel?: boolean) => {
    setIsEditing(isActive);
    setShowEditingCancel(isActive && !hideCancel);
  }, []);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  const screens = {
    landing: {
      heading: "Activate your Catch Card",
      component: <CatchCardLanding onNext={() => setActiveScreen("contact")} />,
      divider: undefined,
    },
    contact: {
      heading: isEditing ? "Billing Address" : "Contact Info",
      component: (
        <CatchCardContactInfo
          onNext={() => setActiveScreen("debit")}
          onEdit={handleEdit}
          isEditing={isEditing}
        />
      ),
      divider: <LinearProgress variant="determinate" value={0} />,
    },
    debit: {
      heading: "Link a Debit Card",
      component: (
        <CatchCardLinkDebit
          onNext={() => setActiveScreen("idv")}
          onEdit={handleEdit}
          isEditing={isEditing}
        />
      ),
      divider: <LinearProgress variant="determinate" value={33.333} />,
    },
    idv: {
      heading: "Identity Verification",
      component: (
        <PlaidIDVLink
          onSuccess={() => setActiveScreen("confirmation")}
          onRetryDebit={() => setActiveScreen("debit")}
        />
      ),
      divider: <LinearProgress variant="determinate" value={66.666} />,
    },
    confirmation: {
      heading: success ? "Success!" : "Confirming...",
      component: (
        <CatchCardApplication onSuccess={handleSuccess} showConfetti />
      ),
      divider: <LinearProgress variant="determinate" value={100} />,
    },
    pin: {
      heading: "Enter a memorable PIN",
      component: <CatchCardPinSetup />,
      divider: undefined,
    },
  };

  useEffect(() => {
    if (initialScreen) {
      setActiveScreen(initialScreen);
    }
  }, [initialScreen]);

  return (
    <FullHeightDialog
      heading={screens[activeScreen]?.heading}
      divider={screens[activeScreen]?.divider}
      keepMounted
      onBack={showEditingCancel ? () => handleEdit(false) : undefined}
      {...props}
    >
      {screens[activeScreen]?.component}
    </FullHeightDialog>
  );
};

export default CatchCardModal;
