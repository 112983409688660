import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";

import AnimatedPhoneBuzzingFallback from "./AnimatedPhoneBuzzingFallback";

const AnimatedPhoneBuzzingLottie = lazy(
  () => import("./AnimatedPhoneBuzzingLottie")
);

const AnimatedPhoneBuzzing: React.VFC = () => (
  <Suspense
    fallback={
      <Box style={{ width: 80, height: 80, margin: -0 }}>
        <AnimatedPhoneBuzzingFallback />
      </Box>
    }
  >
    <AnimatedPhoneBuzzingLottie />
  </Suspense>
);

export default AnimatedPhoneBuzzing;
