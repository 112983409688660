import React, { forwardRef } from "react";

import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Stack,
} from "@mui/material";

import { useUniqueId } from "../../hooks/accessibility-hooks";
import WrappedAccordion from "./WrappedAccordion";

type CollapsibleBreakdownCardProps = AccordionProps & {
  previewText: React.ReactNode;
  idPrefix?: string;
};

const CollapsibleBreakdownCard: React.FC<CollapsibleBreakdownCardProps> =
  forwardRef(
    (
      { expanded, previewText, onChange, children, idPrefix, ...props },
      ref
    ) => {
      const id = useUniqueId(idPrefix);
      const ariaControls = useUniqueId(`${idPrefix || ""}-details`);

      return (
        <WrappedAccordion
          expanded={expanded}
          variant="card"
          onChange={onChange}
          {...props}
          ref={ref}
        >
          <AccordionSummary
            expandIcon={expanded ? undefined : <KeyboardArrowDownIcon />}
            id={idPrefix ? id : undefined}
            aria-controls={idPrefix ? ariaControls : undefined}
          >
            <Stack direction="row">{previewText}</Stack>
          </AccordionSummary>

          <AccordionDetails>{children}</AccordionDetails>
        </WrappedAccordion>
      );
    }
  );

export default CollapsibleBreakdownCard;
