import React from "react";
import { useSelector } from "react-redux";

import { Stack } from "@mui/material";

import useMedia from "~common/hooks/useMedia";
import { selectMenuOpen } from "~src/store";

import MobileSearch from "../search/MobileSearch";
import Search from "../search/Search";
import MobileMenu from "./MobileMenu";
import NavItemsAuthed from "./NavItemsAuthed";
import NavMenuButton from "./NavMenuButton";

const NavAuthed: React.VFC = () => {
  const menuOpen = useSelector(selectMenuOpen);
  const media = useMedia();

  return (
    <>
      <Stack
        spacing={4}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        flexGrow="1"
      >
        {media.above.sm && <Search />}

        <Stack spacing={6} direction="row" alignItems="center">
          {media.below.sm && <MobileSearch />}
          {media.below.md ? <NavMenuButton /> : <NavItemsAuthed />}
        </Stack>
      </Stack>

      {media.below.md && menuOpen && <MobileMenu />}
    </>
  );
};

export default NavAuthed;
