import React from "react";

import { DialogProps } from "@mui/material";

import { ModalOverlayColor } from "../../utils/modal-utils";
import { DefaultDialog } from ".";

type BankDialogProps = DialogProps & {
  onClose: () => void;
  ariaLabelledBy: string;
  ariaDescribedBy: string;
  overlayColor?: ModalOverlayColor;
};

const BankDialog: React.FC<BankDialogProps> = ({
  open,
  onClose,
  ariaLabelledBy,
  ariaDescribedBy,
  overlayColor,
  sx = [],
  children,
  ...props
}) => (
  <DefaultDialog
    open={open}
    onClose={onClose}
    ariaLabelledBy={ariaLabelledBy}
    ariaDescribedBy={ariaDescribedBy}
    overlayColor={overlayColor}
    sx={[
      {
        textAlign: "center",
        ".VectorIcon": {
          margin: "auto",
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  >
    {children}
  </DefaultDialog>
);

BankDialog.defaultProps = {
  overlayColor: "medium",
};

BankDialog.displayName = "BankDialog";

export default BankDialog;
