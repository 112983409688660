import React from "react";

import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const BankHeaderIncompatibleAccount: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => (
  <ConnectPaymentInstrumentAlertHeader
    headingId={headingId}
    title={<>Sorry, that bank account doesn&apos;t work with Catch</>}
    subtitle={
      <>
        The type of account you tried to link isn&apos;t compatible with Catch
        at the moment. To continue, please connect a different bank account.
      </>
    }
  />
);

export default BankHeaderIncompatibleAccount;
