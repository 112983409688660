import React from "react";

import { Stack } from "@mui/material";

import { useUniqueId } from "../../hooks/accessibility-hooks";
import DefaultDialog from "../modals/DefaultDialog";
import DialogHeader from "../modals/DialogHeader";

type GetStartedModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
};

const GetStartedModal: React.FC<GetStartedModalProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  const dialogId = useUniqueId(title);
  const dialogDescriptionId = useUniqueId(title.concat("description"));

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      ariaLabelledBy={dialogId}
      ariaDescribedBy={dialogDescriptionId}
      sx={({ shape }) => ({
        "& .MuiPaper-root.MuiDialog-paper": {
          borderRadius: `${shape.borderRadius * 2}px`,
          " .MuiIconButton-sizeSmall": {
            height: "inherit",
          },
        },
      })}
    >
      <Stack sx={{ width: "100%" }}>
        <DialogHeader
          id={dialogId}
          variant="h3"
          sx={{
            width: "auto",
            mt: -8,
          }}
        >
          {title}
        </DialogHeader>
        {children}
      </Stack>
    </DefaultDialog>
  );
};

export default GetStartedModal;
