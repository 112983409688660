import React, { useLayoutEffect, useState } from "react";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import InlineTextButton from "~common/components/controls/buttons/InlineTextButton";
import ThickDivider from "~common/components/primitives/ThickDivider";

type ExpandableLabeledSectionProps = {
  heading: React.ReactNode;
  name: string;
};

const ExpandableLabeledSection: React.FC<ExpandableLabeledSectionProps> = ({
  heading,
  name,
  children,
}) => {
  const LS_IS_EXPANDED_KEY = `ExpandableLabeledSection::${name}::isExpanded`;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useLayoutEffect(() => {
    try {
      // Use string "true" in coalesce statement because localStorage.getItem always returns a string.
      const initialIsExpanded =
        window.localStorage.getItem(LS_IS_EXPANDED_KEY) ?? "true";
      setIsExpanded(!!JSON.parse(initialIsExpanded));
    } catch {
      // Do nothing if localStorage access is blocked.
    }
  }, [LS_IS_EXPANDED_KEY]);

  const handleClick = () => {
    try {
      window.localStorage.setItem(
        LS_IS_EXPANDED_KEY,
        JSON.stringify(!isExpanded)
      );
    } catch {
      // Do nothing if localStorage access is blocked.
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      <InlineTextButton
        onClick={handleClick}
        size="large"
        fullWidth
        sx={{
          "&.MuiButton-text.MuiButton-sizeLarge .label": {
            textDecoration: "none",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2" color="grey.700">
            {heading}
          </Typography>
          <ArrowDropDownIcon
            sx={{
              transform: isExpanded ? "rotate(180deg)" : "none",
              fill: "currentColor",
              width: 24,
              height: 24,
            }}
          />
        </Stack>
      </InlineTextButton>
      <ThickDivider />
      {isExpanded && children}
    </Box>
  );
};

export default ExpandableLabeledSection;
