import { selectRewardPoolEvents } from "~src/store";
import { rewardPoolEventsActions } from "~src/store/slices/services/rewardPoolEvents-slice";

import useLazyService from "./useLazyService";

const useRewardPoolEvents = () => {
  const { data, loading, error } = useLazyService(
    selectRewardPoolEvents,
    rewardPoolEventsActions
  );

  return { rewardPoolEvents: data?.reward_pool_events || null, loading, error };
};

export default useRewardPoolEvents;
