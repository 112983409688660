import React from "react";

import { LinkProps, Typography } from "@mui/material";

import { useTracking } from "../../tracking/tracking-hooks";

type ExternalTextLinkProps = LinkProps & {
  size: "small" | "regular" | "large";
  eventName: string;
  componentName?: string;
};

const getLinkVariant = (size: string) => {
  switch (size) {
    case "large":
      return "linkLarge";
    case "small":
      return "linkSmall";
    default:
      return "linkRegular";
  }
};

const ExternalTextLink: React.FC<ExternalTextLinkProps> = ({
  size,
  eventName,
  componentName,
  children,
  onClick,
  ...props
}) => {
  const { trackEvent } = useTracking();

  return (
    <Typography
      component="a"
      variant={getLinkVariant(size)}
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        trackEvent(eventName, {
          component: componentName,
        });
        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

ExternalTextLink.defaultProps = {
  componentName: undefined,
};

export type { ExternalTextLinkProps };
export default ExternalTextLink;
