import React from "react";

import { alpha, Box, Stack, StackProps, Typography } from "@mui/material";

import {
  useMerchantBackgroundUrl,
  useMerchantLogoUrl,
} from "../../hooks/asset-hooks";
import {
  GiftResponseData,
  Reward,
  RewardCampaignResponseData,
} from "../../services";
import { formatExpirationDate } from "../../utils/dates";
import { formatMoney } from "../../utils/money";
import { formatPercent } from "../../utils/numbers";
import RewardCardTooltip from "./RewardCardTooltip";

type RewardCardProps = StackProps & {
  reward: Reward | RewardCampaignResponseData | GiftResponseData;
  amountTooltip?: React.ReactNode;
  expirationTooltip?: React.ReactNode;
  merchantTooltip?: React.ReactNode;
  topRight?: React.ReactNode;
};

const RewardCard: React.VFC<RewardCardProps> = ({
  reward,
  amountTooltip,
  expirationTooltip,
  merchantTooltip,
  topRight,
  sx = {},
  ...props
}) => {
  const merchantBackgroundUrl = useMerchantBackgroundUrl(
    reward.merchant_id,
    reward.merchant_card_background_image_url
  );
  const merchantLogoUrl = useMerchantLogoUrl(reward.merchant_id);
  const merchantName =
    reward.merchant_id === "FLEX_MERCHANT_ID"
      ? "Catch Cash"
      : reward.merchant_name || "";

  const getPercentAmount = () => {
    if ("redeemable_percentage_order_total_max" in reward) {
      return reward.redeemable_percentage_order_total_max;
    }

    if ("claimed_reward_redeemable_percentage_order_total_max" in reward) {
      return reward.claimed_reward_redeemable_percentage_order_total_max;
    }

    return null;
  };

  const getOrderTotalMinAmount = () => {
    if ("redeemable_flat_order_total_min" in reward) {
      return reward.redeemable_flat_order_total_min;
    }

    if ("claimed_reward_redeemable_flat_order_total_min" in reward) {
      return reward.claimed_reward_redeemable_flat_order_total_min;
    }

    return null;
  };

  const percentAmount = getPercentAmount();
  const orderTotalMinAmount = getOrderTotalMinAmount();

  return (
    <Stack
      justifyContent="space-between"
      sx={[
        ({ palette }) => ({
          background: `center / cover ${
            merchantBackgroundUrl
              ? `url("${merchantBackgroundUrl}")`
              : reward.merchant_card_background_color || "#eee"
          }`,
          width: "100%",
          p: 4,
          pb: 2,
          borderRadius: "6% / 9.71%",
          boxShadow: `0 1px 2px ${alpha(palette.grey[700], 0.3)}`,
          aspectRatio: "1.618 / 1",
          position: "relative",
          overflow: "hidden",
          color: alpha(reward.merchant_card_font_color || "#000", 0.96),
          textAlign: "left",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={2} direction="row" alignItems="center" flexShrink={0}>
          <Box
            component="img"
            src={merchantLogoUrl}
            alt={`${merchantName} logo`}
            loading="lazy"
            sx={{
              width: "auto",
              height: 40,
            }}
          />

          {reward.merchant_id === "FLEX_MERCHANT_ID" && (
            <Typography
              variant="h3"
              component="p"
              color="inherit"
              flexShrink={0}
            >
              {merchantName}
            </Typography>
          )}

          {!!merchantTooltip && (
            <RewardCardTooltip
              title={merchantTooltip}
              sx={{
                ml: -1,
                ".MuiIconButton-root.MuiIconButton-sizeSmall .MuiSvgIcon-root":
                  {
                    fontSize: 16,
                  },
              }}
            />
          )}
        </Stack>

        {topRight}
      </Stack>

      <Stack>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="h1" component="p">
            {formatMoney(
              "amount" in reward ? reward.amount : reward.total_amount
            )}
          </Typography>

          {"original_reward_amount" in reward &&
            !!reward.original_reward_amount && (
              <Stack
                direction="row"
                alignItems="center"
                sx={({ shape, palette }) => ({
                  backgroundColor: "#fbda69",
                  px: 1,
                  border: `1px solid ${palette.common.black}1a`,
                  borderRadius: `${shape.borderRadius}px`,
                  color: `${palette.common.black}cc`,
                })}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    lineHeight: 1.33,
                  }}
                >
                  Was {formatMoney(reward.original_reward_amount)}
                </Typography>

                {!!amountTooltip && <RewardCardTooltip title={amountTooltip} />}
              </Stack>
            )}
        </Stack>

        <Typography
          sx={{
            color: alpha(reward.merchant_card_font_color || "#000", 0.8),
            fontSize: 13,
            lineHeight: 1.54,
          }}
        >
          {!!percentAmount &&
            `${formatPercent(percentAmount)} of order total | `}

          {!!orderTotalMinAmount &&
            `${formatMoney(orderTotalMinAmount, {
              dropCentsIfRound: true,
            })} min. order | `}

          {("expiration" in reward && !!reward.expiration) ||
          ("rewards_expiration" in reward && !!reward.rewards_expiration) ? (
            <Stack
              direction="row"
              alignItems="center"
              display="inline-flex"
              component="span"
              gap={0.5}
            >
              <>
                Exp.{" "}
                {"original_reward_expiration" in reward &&
                  !!reward.original_reward_expiration &&
                  `${formatExpirationDate(
                    reward.original_reward_expiration
                  )} → `}
                {formatExpirationDate(
                  ("expiration" in reward
                    ? reward.expiration
                    : reward.rewards_expiration) || ""
                )}
              </>

              {!!expirationTooltip && (
                <RewardCardTooltip title={expirationTooltip} />
              )}
            </Stack>
          ) : (
            <>Doesn&apos;t expire</>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default RewardCard;
