import { RewardPoolEventsResponseData } from "~common/services";
import { makeFetchSlice } from "~common/store/store-utils";

const slice = makeFetchSlice<RewardPoolEventsResponseData | null>(
  "rewardPoolEvents",
  null
);

const { reducer, actions: rewardPoolEventsActions } = slice;

export { rewardPoolEventsActions };
export default reducer;
