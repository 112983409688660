import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Stack, Typography } from "@mui/material";

import { UserBillingAddress } from "~common/services";
import { useGetUserLatestBillingAddress } from "~common/services/users";
import { selectCurrentUser } from "~src/store";
import { getAddressForCatchCardApplication } from "~src/utils/address";

import AddressAutocomplete from "../shared/onboarding/AddressAutocomplete";
import CurrentUserForm from "../shared/onboarding/CurrentUserForm";

type CatchCardContactInfoProps = {
  onNext: () => void;
  onEdit: (isActive: boolean) => void;
  isEditing: boolean;
};

const CatchCardContactInfo: React.VFC<CatchCardContactInfoProps> = ({
  onNext,
  onEdit,
  isEditing,
}) => {
  const currentUser = useSelector(selectCurrentUser.data);
  const { data: latestAddress } = useGetUserLatestBillingAddress();
  const [address, setAddress] = useState(currentUser?.billing_address || null);

  useEffect(() => {
    if (
      !currentUser?.billing_address &&
      latestAddress?.latest_checkout_billing_address
    ) {
      setAddress(
        getAddressForCatchCardApplication(
          latestAddress.latest_checkout_billing_address
        )
      );
    }
  }, [currentUser, latestAddress]);

  const handleSelectAddress = (value: UserBillingAddress) => {
    setAddress(value);
    onEdit(false);
  };

  return (
    <>
      <Stack spacing={6} display={isEditing ? "none" : "flex"}>
        <Typography pb={2}>
          First, let&apos;s make sure we have up-to-date contact information.
        </Typography>

        <CurrentUserForm
          onSuccess={onNext}
          address={address}
          onEditAddress={() => onEdit(true)}
        />
      </Stack>

      <Box display={isEditing ? "block" : "none"}>
        <AddressAutocomplete onSelect={handleSelectAddress} />
      </Box>
    </>
  );
};

export default CatchCardContactInfo;
