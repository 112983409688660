import React from "react";

import { Typography } from "@mui/material";

type MailcheckErrorHelperTextProps = {
  incorrectEmail: string;
};

const MailcheckErrorHelperText: React.VFC<MailcheckErrorHelperTextProps> = ({
  incorrectEmail,
}) => (
  <Typography color="info.main" variant="bodySmall" component="span">
    You had typed{" "}
    <Typography variant="h4" component="span">
      {incorrectEmail}
    </Typography>
  </Typography>
);

export default MailcheckErrorHelperText;
