import { getUrlWithUserParams } from "~common/utils/navigation";
import { APP_ENVIRONMENT, APP_STAGE } from "~src/config";
import { selectHighlights } from "~src/store";
import { highlightsActions } from "~src/store/slices/services/highlights-slice";

import useLazyService from "./useLazyService";

const useHighlights = () => {
  const {
    data,
    loading,
    error,
  } = useLazyService(selectHighlights, highlightsActions);

  const highlights = data.map((highlight) => ({
    ...highlight,
    ...(highlight.completed_modal?.cta_button.link && {
      completed_modal: {
        ...highlight.completed_modal,
        cta_button: {
          ...highlight.completed_modal?.cta_button,
          link: getUrlWithUserParams(highlight.completed_modal?.cta_button.link, APP_ENVIRONMENT, APP_STAGE)
        }
      }
    })
  }));

  return { highlights, loading, error };
};

export default useHighlights;
