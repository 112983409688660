import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Stack } from "@mui/material";

import { GenericServiceErrorAlert } from "~common/components/alerts";
import { PrimaryButton } from "~common/components/controls/buttons";
import { PhoneInput } from "~common/components/controls/text-fields";
import { AnimatedPhoneSendingIcon } from "~common/components/icons/animated-vector";
import { useInitiateAuth } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking, useTrackPageView } from "~common/tracking";
import {
  isValidUSPhoneNumber,
  removePhoneNumberFormatting,
} from "~common/utils/phone-number";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import useRewardCampaign from "~src/hooks/services/useRewardCampaign";
import { selectPrefill } from "~src/store";
import {
  setAuthSession,
  setClaimedCampaign,
  setClaimedCampaignId,
  setClaimedCampaignSource,
  setClaimedSecondChanceBonusSignUp,
} from "~src/store/slices/user-slice";

const SignIn: React.VFC = () => {
  useTrackPageView("Enter Phone Number");
  const { trackEvent, trackError, trackUser } = useTracking();
  const { mutate: initiateAuth } = useInitiateAuth();
  const dispatch = useDispatch();
  const { prefillPhone } = useSelector(selectPrefill);

  const [phoneNumber, setPhoneNumber] = useState<string>(prefillPhone || "");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasPhoneInputError, setHasPhoneInputError] = useState<boolean>(false);
  const [hasServiceError, setHasServiceError] = useState<boolean>(false);

  // If a rewardCampaignId is available in the search params, save the
  // reward campaign info in the store for claiming after authentication
  const [searchParams] = useSearchParams();
  const rewardCampaignId = searchParams.get("rewardCampaignId");
  const { rewardCampaign, error: rewardCampaignError } = useRewardCampaign(
    rewardCampaignId || ""
  );

  useEffect(() => {
    if (searchParams.get("isSecondChanceBonusUser")) {
      dispatch(setClaimedSecondChanceBonusSignUp(true));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (rewardCampaignId && rewardCampaign && !rewardCampaignError) {
      dispatch(setClaimedCampaignId(rewardCampaignId));
      dispatch(setClaimedCampaignSource("user_portal_sign_in_page"));
      dispatch(setClaimedCampaign(rewardCampaign));
    }
  }, [dispatch, rewardCampaignId, rewardCampaign, rewardCampaignError]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isProcessing) {
      return;
    }

    clearErrors();

    if (!isValidUSPhoneNumber(phoneNumber)) {
      setHasPhoneInputError(true);
      return;
    }

    const cleanedPhoneNumber = removePhoneNumberFormatting(phoneNumber);

    trackEvent("Phone Number Submitted", {
      scrubbedPhoneNumber: cleanedPhoneNumber,
    });

    const phoneNumberWithCountryCode = `+1${cleanedPhoneNumber}`;
    trackUser({
      phone: phoneNumberWithCountryCode,
    });

    setIsProcessing(true);
    try {
      const { session } = await initiateAuth({
        phone_number: phoneNumberWithCountryCode,
      });
      dispatch(
        setAuthSession({
          id: session,
          phoneNumber: phoneNumberWithCountryCode,
        })
      );
      setIsProcessing(false);
    } catch (rawError) {
      const error = convertRawServiceError(rawError);
      if (
        error.error_type === "BAD_REQUEST_BODY_FORMAT" &&
        error.data?.json?.phone_number
      ) {
        trackError("SignIn", "Invalid phone number", { error });
        setHasPhoneInputError(true);
      } else {
        trackError("SignIn", "(Service error) Phone submission", { error });
        setHasServiceError(true);
      }
      setIsProcessing(false);
    }
  };

  const clearErrors = (): void => {
    if (hasPhoneInputError) {
      setHasPhoneInputError(false);
    }
    if (hasServiceError) {
      setHasServiceError(false);
    }
  };

  const handlePhoneInputUpdateValue = (value: string) => {
    setPhoneNumber(value);
    clearErrors();
  };

  return (
    <form onSubmit={handleSubmit}>
      <SmallPagePanel
        icon={<AnimatedPhoneSendingIcon />}
        title="Catch uses your phone for login"
        subtitle="It is fast and secure. No need to memorize more passwords."
      >
        <Stack spacing={4}>
          <PhoneInput
            value={phoneNumber}
            updateValue={handlePhoneInputUpdateValue}
            error={hasPhoneInputError}
          />

          {hasServiceError && <GenericServiceErrorAlert />}

          <PrimaryButton type="submit" loading={isProcessing} fullWidth>
            Continue
          </PrimaryButton>
        </Stack>
      </SmallPagePanel>
    </form>
  );
};

export default SignIn;
