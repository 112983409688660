import React from "react";

import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useUniqueId } from "../../hooks/accessibility-hooks";
import { CloseReason } from "../../utils/modal-utils";

type FullHeightDialogProps = DialogProps & {
  heading?: string;
  divider?: React.ReactNode;
  onClose?: (event: unknown, reason?: CloseReason) => void;
  onBack?: () => void;
};

const FullHeightDialog: React.VFC<FullHeightDialogProps> = ({
  children,
  heading,
  divider,
  onClose,
  onBack,
  ...props
}) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up("sm"));
  const idPrefix = useUniqueId("full-height-dialog");

  return (
    <Dialog
      aria-labelledby={`${idPrefix}-heading`}
      aria-describedby={`${idPrefix}-content`}
      fullScreen={!isDesktop}
      onClose={onClose}
      PaperProps={{
        sx: ({ shape }) => ({
          ...(isDesktop && {
            width: "calc(100% - 64px)",
            height: "calc(100% - 96px)",
            maxWidth: 464,
            maxHeight: 726,
            my: 12,
            borderRadius: `${shape.borderRadius * 4}px`,
          }),
        }),
      }}
      {...props}
    >
      {onBack && (
        <IconButton
          aria-label="Back"
          onClick={onBack}
          sx={({ palette }) => ({
            position: "absolute",
            left: 16,
            top: 22,
            zIndex: 1,
            color: palette.grey[600],
          })}
        >
          <ArrowBackIcon />
        </IconButton>
      )}

      <Box position="relative">
        {heading && (
          <Typography
            variant="h2"
            id={`${idPrefix}-heading`}
            py={5}
            px={14}
            borderBottom="1px solid"
            borderColor="grey.300"
            align="center"
          >
            {heading}
          </Typography>
        )}

        {onClose && (
          <IconButton
            aria-label="Close dialog"
            onClick={(e) => onClose(e, "closeButtonClick")}
            sx={({ palette }) => ({
              position: "absolute",
              right: 16,
              top: 22,
              zIndex: 1,
              color: palette.grey[600],
            })}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      {divider && <Box flexShrink={0}>{divider}</Box>}

      <Box
        id={`${idPrefix}-content`}
        flexGrow={1}
        overflow="auto"
        position="relative"
        sx={{
          py: 8,
          px: {
            zero: 6,
            sm: 8,
          },
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

export default FullHeightDialog;
export type { FullHeightDialogProps };
