import { useEffect, useState } from "react";

let uniqueIdCounter = 0;

const useUniqueId = (prefix?: string): string => {
  // eslint-disable-next-line no-return-assign
  const [id] = useState<number>(() => (uniqueIdCounter += 1));
  return prefix ? `${prefix}-${id}` : id.toString();
};

const useReduceMotion = () => {
  const [matches, setMatch] = useState<boolean>(
    window.matchMedia("(prefers-reduced-motion: reduce)").matches
  );

  useEffect(() => {
    const mq = window.matchMedia("(prefers-reduced-motion: reduce)");
    const handleChange = () => {
      setMatch(mq.matches);
    };

    handleChange();
    try {
      // addEventListener works on most browsers, but not Safari 13 and older
      // https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent/60000747#60000747
      mq.addEventListener("change", handleChange);
    } catch {
      try {
        // Safari 13 or older
        mq.addListener(handleChange);
      } catch (rawError) {
        // Do nothing
      }
    }

    return () => {
      mq.removeEventListener("change", handleChange);
    };
  }, []);

  return matches;
};

export { useReduceMotion, useUniqueId };
