import { useGetRecommendedMerchants } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectRecommendedMerchants } from "~src/store";
import { recommendedMerchantsActions } from "~src/store/slices/services/recommendedMerchants-slice";

const useFetchRecommendedMerchants = () => {
  // We'll initially always show up to 4 recommended merchants
  const useGet = () =>
    useGetRecommendedMerchants({ queryParams: { count: 4 }, lazy: true });

  useLazyFetch(
    "RecommendedMerchants",
    recommendedMerchantsActions,
    selectRecommendedMerchants,
    useGet,
    (data) => data
  );
};

export default useFetchRecommendedMerchants;
