import React from "react";

import { Box, Stack, SxProps, Theme } from "@mui/material";

// This component provides the base UI for large pages.

type LargePagePanelProps = {
  footer?: React.ReactNode;
  width?: "full" | "medium";
  sx?: SxProps<Theme>;
  centered?: boolean;
  shopPage?: boolean;
};

const LargePagePanel: React.FC<LargePagePanelProps> = ({
  footer,
  width,
  sx,
  centered,
  children,
  shopPage,
}) => (
  <Box sx={sx}>
    <Stack
      sx={({ breakpoints }) => ({
        px: shopPage ? 4 : 6,
        pt: shopPage ? 0 : 8,
        pb: 24,
        gap: 16,
        width: "100%",
        maxWidth:
          width === "medium"
            ? "var(--medium-page-panel-max-width)"
            : "var(--large-page-panel-max-width)",
        minHeight: "calc(100vh - var(--navbar-height) - 1px)",
        margin: "0 auto",
        textAlign: centered ? "center" : "left",
        [breakpoints.up("md")]: {
          px: shopPage ? 8 : 6,
          pt: shopPage ? 0 : 12,
        },
      })}
    >
      <Stack
        sx={{
          gap: shopPage ? 0 : 12,
          flexGrow: 1,
          alignItems: centered ? "center" : undefined,
        }}
      >
        {children}
      </Stack>

      {footer}
    </Stack>
  </Box>
);

LargePagePanel.defaultProps = {
  footer: undefined,
  width: "full",
  centered: false,
  sx: {},
  shopPage: false,
};

export default LargePagePanel;
