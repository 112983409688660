import React from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";

import { CloseReason } from "../../utils/modal-utils";
import DefaultIconButton from "../controls/buttons/DefaultIconButton";

type DialogCloseButtonProps = ButtonProps & {
  fullBleed?: boolean;
  onClose: (event: unknown, reason: CloseReason) => void;
};

const DialogCloseButton: React.VFC<DialogCloseButtonProps> = ({
  fullBleed,
  onClose,
  sx = [],
}) => (
  <DefaultIconButton
    onClick={(event) => onClose(event, "closeButtonClick")}
    size="small"
    className="close-button"
    aria-label="Close dialog"
    sx={[
      ({ palette, spacing }) => ({
        position: "absolute",
        top: spacing(2.5),
        right: spacing(2.5),
        zIndex: 4,
        "&.MuiIconButton-sizeSmall": {
          height: "40px",
          width: "40px",
          margin: 0,
          ".MuiSvgIcon-root": {
            fontSize: "20px",
          },
        },
        ...(fullBleed && {
          opacity: 0.4,
          "& svg": {
            filter: `drop-shadow(0 0 2px ${palette.background.default})`,
          },
        }),
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <CloseIcon />
  </DefaultIconButton>
);

export default DialogCloseButton;
