import { useGetHighlights } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectHighlights } from "~src/store";
import { highlightsActions } from "~src/store/slices/services/highlights-slice";

const useGet = () => useGetHighlights({ lazy: true });

const useFetchHighlights = () => {
  useLazyFetch(
    "Highlights",
    highlightsActions,
    selectHighlights,
    useGet,
    (data) =>
      data.highlights.map((highlight) => ({
        ...highlight,
        initial_modal: {
          ...highlight.initial_modal,
          isAppAdoption:
            highlight.initial_modal.cta_button.text === "app_download_buttons" || highlight.initial_modal.cta_button.text === "app_download_buttons_ios" || highlight.initial_modal.cta_button.text === "app_download_buttons_android",
          isIosAppAdoption:
            highlight.initial_modal.cta_button.text === "app_download_buttons_ios",
          isAndroidAppAdoption:
            highlight.initial_modal.cta_button.text === "app_download_buttons_android",
        },
      }))
  );
};

export default useFetchHighlights;
