import React, { useState } from "react";
import { useSelector } from "react-redux";

import { track } from "@iterable/web-sdk";
import {
  FormHelperText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import extensionChrome from "~common/assets/illustrations/extension_chrome.png";
import extensionSafari from "~common/assets/illustrations/extension_safari.png";
import { ResponsiveDrawerDialog } from "~common/components/modals";
import { useTracking } from "~common/tracking";
import { ADOPTION_URLS } from "~common/utils/adoption";
import useIterable from "~src/hooks/useIterable";
import { selectAuthData, selectCurrentUser } from "~src/store";
import { getDevice } from "~src/utils/devices";

import BrowserExtensionDetails from "./BrowserExtensionDetails";

type BrowserExtensionModalProps = {
  open: boolean;
  onClose: () => void;
};

type BrowserExtensionType = "Chrome" | "Safari";

const BrowserExtensionModal: React.VFC<BrowserExtensionModalProps> = ({
  open,
  onClose,
}) => {
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up("sm"));
  const { trackEvent, trackError } = useTracking();
  const authData = useSelector(selectAuthData);
  const iterable = useIterable(authData?.id_token);
  const currentUser = useSelector(selectCurrentUser.data);
  const [sentTypes, setSentTypes] = useState<BrowserExtensionType[]>([]);
  const [hasError, setHasError] = useState(false);
  const device = getDevice();
  const isChrome = navigator.userAgent.match(/Chrome/i);

  const handleClose = () => {
    setHasError(false);
    onClose();
  };

  const handleSend = async (type: BrowserExtensionType) => {
    if (!iterable || !currentUser) {
      return;
    }

    setHasError(false);
    setSentTypes([...sentTypes, type]);
    trackEvent(`Browser Extension ${type} Email me the link Clicked`);

    try {
      await iterable.setEmail(currentUser.email || "");

      await track({
        eventName: `Email ${type === "Safari" ? `${type} ` : ""}Browser Extension`,
      });
    } catch (err) {
      setHasError(true);
      setSentTypes(sentTypes.filter((sentType) => sentType !== type));
      trackError("Browser Extension", "Iterable email", { error: err });
    }
  };

  const chromeDetails = (
    <BrowserExtensionDetails
      heading={
        <>
          Desktop{" "}
          <Typography fontWeight="normal" component="span">
            (Chrome)
          </Typography>
        </>
      }
      body={
        isChrome && !device
          ? "We'll open the extension in a new Chrome tab."
          : "We'll send a download link to your computer."
      }
      img={{
        src: extensionChrome,
        width: 80,
        alt: "",
      }}
      button={{
        ...(isChrome && !device
          ? {
              children: "Download",
              href: ADOPTION_URLS.chromeExtension,
              onClick: () =>
                trackEvent("Browser Extension Chrome Download Clicked"),
            }
          : {
              children: "Email me the link",
              onClick: () => handleSend("Chrome"),
              disabled: sentTypes.includes("Chrome"),
            }),
      }}
    />
  );

  const safariDetails = (
    <BrowserExtensionDetails
      heading={
        <>
          Mobile{" "}
          <Typography fontWeight="normal" component="span">
            (Safari)
          </Typography>
        </>
      }
      body={
        device === "iphone"
          ? "Get the Catch extension on your phone."
          : "We'll send a download link to your phone."
      }
      img={{
        src: extensionSafari,
        width: 38,
        alt: "",
      }}
      button={{
        ...(device === "iphone"
          ? {
              children: "Download",
              href: ADOPTION_URLS.ios,
              onClick: () =>
                trackEvent("Browser Extension Safari Download Clicked"),
            }
          : {
              children: "Email me the link",
              onClick: () => handleSend("Safari"),
              disabled: sentTypes.includes("Safari"),
            }),
      }}
    />
  );

  return (
    <ResponsiveDrawerDialog
      open={open}
      name="browser-extension"
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          background: palette.tints?.purple?.light,
        },
      }}
    >
      {({ contentId, headingId }) => (
        <>
          <Typography variant="h2" sx={visuallyHidden} id={headingId}>
            Get the Catch browser extension for desktop or mobile
          </Typography>

          <Stack spacing={6} id={contentId}>
            {isDesktop && chromeDetails}
            {safariDetails}
            {!isDesktop && chromeDetails}

            {hasError && (
              <FormHelperText error sx={{ textAlign: "center" }}>
                Something didn&apos;t work as expected. Please try again.
              </FormHelperText>
            )}
          </Stack>
        </>
      )}
    </ResponsiveDrawerDialog>
  );
};

export default BrowserExtensionModal;
