import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StatsigUser, useExperiment, useGate } from "statsig-react";

import {
  selectIsStatsigErrored,
  selectIsStatsigReady,
} from "../store/selectors";
import {
  setIsStatsigReady,
  setStatsigProviderUser,
} from "../store/slices/experimentation-slice";
import { useTracking } from "../tracking";
import {
  getExperimentFromSessionStorage,
  patchStatsigUser,
  setExperimentInSessionStorage,
  storeHashedUserIpAddress,
} from "./shared/statsig";

type UseExperimentOptions = {
  useSessionStorage?: boolean;
  // We may defer pulling the experiment if we are waiting for
  // specific actions to be completed first. For example, if the
  // experiment is based on merchantId, we need to ensure the Statsig
  // user is updated before attempting to pull the experiment.
  deferPullExperiment?: boolean;
  // By default we DO NOT patch the tracking context to enable decoupling
  // the tracking logic from the experimentation logic. However, this flag
  // enables patching if more complicated logic isn't necessary
  shouldPatchTrackingContext?: boolean;
};

type UseUpdateUserOptions = {
  user: StatsigUser;
  useHashedIp: boolean;
};

const useExperimentationInit = (app: string) => {
  const isStatsigReady = useSelector(selectIsStatsigReady);
  const isStatsigReadyRef = useRef(isStatsigReady);

  const dispatch = useDispatch();
  const disableEventTracking = app === "tofu";
  const { trackEvent, trackError, patchTrackingContext } = useTracking();

  // By default, we immediately update the user to use the hashed IP address
  // as the Statsig userID. Note we set isStatsigReady in the StatsigProvider
  // callback that is triggered when the user is successfully updated
  useEffect(() => {
    if (isStatsigReady) {
      return;
    }

    const createStatsigUser = async () => {
      const hashedIp = await storeHashedUserIpAddress();
      const updatedUser = patchStatsigUser(
        { custom: { app }, ...(hashedIp && { userID: hashedIp }) },
        true
      );
      if (hashedIp) {
        patchTrackingContext({
          hashedIp,
        });
      }
      dispatch(setStatsigProviderUser(updatedUser));
    };

    void createStatsigUser();
  }, [
    app,
    disableEventTracking,
    dispatch,
    isStatsigReady,
    trackEvent,
    patchTrackingContext,
  ]);

  // In the offchance that the StatsigProvider's callback
  // updateUserCompletionCallback fails to fire, or if we have
  // an issue with setting the hashed user IP address, the user
  // could end up on an infinite loading screen. While this
  // isn't expected, this callback prevents this case if it
  // did occur.
  useEffect(() => {
    isStatsigReadyRef.current = isStatsigReady;
  }, [isStatsigReady]);

  useEffect(() => {
    setTimeout(() => {
      if (!isStatsigReadyRef.current) {
        if (!disableEventTracking) {
          trackError(
            "useExperimentationInit",
            "isStatsigReady still false after 5 seconds"
          );
        }
        dispatch(setIsStatsigReady(true));
      }
    }, 5000);
  }, [disableEventTracking, dispatch, trackError]);
};

// Lightweight wrapper around the statsig-react useExperiment hook that
// doesn't return the experiment unless Statsig is "ready" (e.g. after
// we have set isStatsigReady to true once we update the Statsig user
// with the dedault hashed IP as the userID). Also enables the following:
//  1. getting/setting from session storage (will return even if isStatsigRead is false)
//  2. delaying pulling the experiment
//  3. patching the tracking context if needed
const useCatchExperiment = (
  experimentName: string,
  appName: string,
  {
    useSessionStorage = true,
    deferPullExperiment = false,
    shouldPatchTrackingContext = false,
  }: UseExperimentOptions = {}
) => {
  const isStatsigReady = useSelector(selectIsStatsigReady);
  const isStatsigErrored = useSelector(selectIsStatsigErrored);
  const [experimentGroup, setExperimentGroup] = useState<string | null>(null);
  const { patchTrackingContext, trackEvent } = useTracking();
  // Supposedly, if we use the "waitForInitiation" option in the StatsigProvider,
  // isLoading should always be true. We check here because this is actively not true
  // based on our testing
  const { config: statsigExperimentConfig, isLoading } =
    useExperiment(experimentName);

  useEffect(() => {
    if (deferPullExperiment || experimentGroup || isStatsigErrored) {
      return;
    }

    let shouldContinue = true;
    if (useSessionStorage) {
      const sessionStorageGroup =
        getExperimentFromSessionStorage(experimentName);
      if (sessionStorageGroup) {
        if (shouldPatchTrackingContext) {
          patchTrackingContext({
            [experimentName]: { experimentGroup: sessionStorageGroup },
          });
        }
        setExperimentGroup(sessionStorageGroup);
        shouldContinue = false;
      }
    }
    if (isStatsigReady && !isLoading && shouldContinue) {
      const group = statsigExperimentConfig.get("experiment_group", null);

      if (appName !== "tofu") {
        if (group) {
          setExperimentGroup(group);
        }
      }

      if (shouldPatchTrackingContext && group) {
        patchTrackingContext({
          [experimentName]: { experimentGroup: group },
        });
      }

      if (useSessionStorage && group) {
        setExperimentInSessionStorage(experimentName, group);
      }
    }
  }, [
    appName,
    deferPullExperiment,
    experimentGroup,
    experimentName,
    isLoading,
    isStatsigErrored,
    isStatsigReady,
    patchTrackingContext,
    shouldPatchTrackingContext,
    statsigExperimentConfig,
    trackEvent,
    useSessionStorage,
  ]);

  return experimentGroup;
};

// Lightweight wrapper for Statig's useGate hook that:
// 1. Ensures the Statsig userID is set (hashed IP by default)
// 2. Enables the option shouldPatchTrackingContext to add the gate
//     value to the tracking context
const useCatchFeatureGate = (
  gateName: string,
  { shouldPatchTrackingContext = false }: UseExperimentOptions = {}
) => {
  const isStatsigReady = useSelector(selectIsStatsigReady);
  const [gate, setGate] = useState<boolean | null>(null);
  const { patchTrackingContext, trackError } = useTracking();
  const { value: gateValue, isLoading } = useGate(gateName);

  useEffect(() => {
    if (!isStatsigReady || isLoading) {
      return;
    }

    if (gateValue) {
      setGate(gateValue);
      if (shouldPatchTrackingContext) {
        patchTrackingContext({
          [gateName]: gateValue,
        });
      }
    }
  }, [
    gateName,
    isLoading,
    isStatsigReady,
    patchTrackingContext,
    shouldPatchTrackingContext,
    trackError,
    gateValue,
  ]);

  return gate;
};

// This hook updates the stored "statsigProviderUser" that must be passed into the
// Statsig provider in each react app.
// Note that a success callback is available in the StatsigProvider options parameter
// if necessary (updateUserCompletionCallback).
const useUpdateUser = ({ user, useHashedIp = true }: UseUpdateUserOptions) => {
  const { patchTrackingContext } = useTracking();
  const isStatsigReady = useSelector(selectIsStatsigReady);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isStatsigReady) {
      const patchedUser = patchStatsigUser(user, useHashedIp);
      dispatch(setStatsigProviderUser(patchedUser));
      if (useHashedIp) {
        patchTrackingContext({
          hashedIp: user.userID,
        });
      }
    }
  }, [dispatch, isStatsigReady, patchTrackingContext, useHashedIp, user]);
};

export type { UseExperimentOptions };
export {
  useCatchExperiment,
  useCatchFeatureGate,
  useExperimentationInit,
  useUpdateUser,
};
