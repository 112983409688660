import { getLocalDeviceTokenKey } from "../hooks/device-token-hooks";
import { getLocalStorageWithFallback } from "./browser-storage";
import type { AppEnvironment, AppStage } from "./config-utils";

const getUrlWithUserParams = (
  href: string,
  appEnv: AppEnvironment,
  appStage: AppStage
) => {
  const url = new URL(href);
  const deviceTokenKey = getLocalDeviceTokenKey(appEnv, appStage);
  const deviceToken = getLocalStorageWithFallback(deviceTokenKey);
  const segmentAnonId = window.analytics?.user
    ? window.analytics.user().anonymousId()
    : null;

  if (deviceToken) {
    url.searchParams.append("catch_device_token", deviceToken);
  }

  if (segmentAnonId) {
    url.searchParams.append("catch_ajs_anonymous_id", segmentAnonId);
  }

  return url.toString();
};

const navigateWithUserParams = (
  e: React.SyntheticEvent,
  appEnv: AppEnvironment,
  appStage: AppStage,
  beforeNavigate?: () => void
) => {
  e.preventDefault();
  beforeNavigate && beforeNavigate();

  const currentTarget = e.currentTarget as HTMLAnchorElement;
  const url = getUrlWithUserParams(currentTarget.href, appEnv, appStage);

  if (currentTarget.hasAttribute("target")) {
    window.open(url, currentTarget.getAttribute("target") || "");
  } else {
    window.location.href = url;
  }
};

export { getUrlWithUserParams, navigateWithUserParams };
