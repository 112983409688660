import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { alpha, Box, Stack, Typography } from "@mui/material";

import chaChing from "~common/assets/illustrations/cha_ching.png";
import RewardCard from "~common/components/cards/RewardCard";
import { DefaultButton } from "~common/components/controls/buttons";
import { CatchCashIcon } from "~common/components/icons/vector";
import { useClaimRewardCampaign } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking } from "~common/tracking";
import { removeSessionStorageWithFallback } from "~common/utils/browser-storage";
import { formatMoney } from "~common/utils/money";
import { selectClaims } from "~src/store";
import { setNewUserOnboardingRewardCampaign } from "~src/store/slices/user-slice";
import {
  getRewardCampaignId,
  NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID,
  REWARD_CAMPAIGN_ERRORS,
} from "~src/utils/reward-campaigns";

import Error from "../../../components/shared/Error";

type ClaimRewardErrors =
  | "creditOnlyForNewUsers"
  | "creditCanOnlyBeClaimedOnce"
  | "creditNoLongerAvailable"
  | null;

type ClaimRewardProps = {
  onNext: () => void;
};

const ClaimReward: React.VFC<ClaimRewardProps> = ({ onNext }) => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const { mutate: claimRewardCampaign } = useClaimRewardCampaign();
  const [errorType, setErrorType] = useState<ClaimRewardErrors>(null);

  useEffect(() => {
    const handleSubmit = async () => {
      if (newUserOnboardingRewardCampaign) {
        const rewardCampaignId = getRewardCampaignId(
          newUserOnboardingRewardCampaign.reward_campaign_id
        );

        try {
          await claimRewardCampaign(
            {
              reward_campaign_claim_source: "user_portal_sign_in_page",
            },
            {
              pathParams: {
                rewardCampaignId,
              },
            }
          );

          dispatch(
            setNewUserOnboardingRewardCampaign({
              ...newUserOnboardingRewardCampaign,
              claimed: true,
            })
          );

          trackEvent("Catch Card Onboarding Credit Earned", {
            rewardCampaignId,
          });
        } catch (err) {
          const error = convertRawServiceError(err);

          switch (error.error_type) {
            case "REWARD_CAMPAIGN_NEW_USERS_ONLY":
              setErrorType("creditOnlyForNewUsers");
              break;
            case "REWARD_CAMPAIGN_USER_ALREADY_CLAIMED":
              setErrorType("creditCanOnlyBeClaimedOnce");
              break;
            default:
              setErrorType("creditNoLongerAvailable");
          }

          trackError("ClaimReward", "Failure claiming reward", {
            error,
          });
        }

        removeSessionStorageWithFallback(
          NEW_USER_ONBOARDING_REWARD_CAMPAIGN_ID
        );
      }
    };

    if (!newUserOnboardingRewardCampaign?.claimed) {
      void handleSubmit();
    }
  }, [
    newUserOnboardingRewardCampaign,
    dispatch,
    claimRewardCampaign,
    trackEvent,
    trackError,
  ]);

  const handleClick = (isError?: boolean) => {
    trackEvent(
      `Onboarding Claim Reward ${isError ? "Error" : ""} Continue Clicked`
    );

    onNext();
  };

  if (!newUserOnboardingRewardCampaign) {
    return null;
  }

  if (errorType) {
    return (
      <Error
        title={REWARD_CAMPAIGN_ERRORS[errorType]?.title}
        subtitle={REWARD_CAMPAIGN_ERRORS[errorType]?.subtitle}
        onClick={() => handleClick(true)}
      />
    );
  }

  return (
    <Stack
      height="100%"
      sx={({ palette }) => ({
        "&:after": {
          content: "''",
          background: palette.tertiary.main,
          position: "absolute",
          inset: 0,
        },
      })}
    >
      <Stack width="100%" height="100%" zIndex={1} color="common.white">
        {newUserOnboardingRewardCampaign.merchant_id === "FLEX_MERCHANT_ID" && (
          <Box position="relative" alignSelf="flex-end">
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={({ palette, shape }) => ({
                py: 0.25,
                pl: 2.5,
                pr: 1.75,
                border: `2px solid ${palette.common.white}`,
                borderRadius: `${shape.borderRadius * 4}px`,
              })}
            >
              <CatchCashIcon
                size="icon"
                sx={{
                  "&.MuiBox-root": {
                    width: 16,
                    height: 16,
                  },
                }}
              />

              <Typography variant="h3" component="span">
                {formatMoney(newUserOnboardingRewardCampaign.total_amount || 0)}
              </Typography>
            </Stack>
          </Box>
        )}

        <Stack
          spacing={6}
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
        >
          <Box component="img" src={chaChing} width={326} alt="Cha-Ching!" />

          {newUserOnboardingRewardCampaign.merchant_id ===
            "FLEX_MERCHANT_ID" && (
            <Typography
              variant="h2"
              component="div"
              sx={({ palette, shape }) => ({
                background: palette.common.white,
                py: 2,
                px: 4,
                borderRadius: `${shape.borderRadius * 5.5}px`,
                color: palette.tertiary.main,
              })}
            >
              +{formatMoney(newUserOnboardingRewardCampaign.total_amount || 0)}{" "}
              Catch Cash
            </Typography>
          )}

          {newUserOnboardingRewardCampaign.merchant_id ===
            "FLEX_MERCHANT_ID" && (
            <Typography variant="h2">Redeem at any Catch brand</Typography>
          )}

          {newUserOnboardingRewardCampaign.merchant_id !==
            "FLEX_MERCHANT_ID" && (
            <>
              <Box width={247}>
                <RewardCard reward={newUserOnboardingRewardCampaign} />
              </Box>

              <Stack spacing={2} alignItems="center">
                <Typography variant="h1">
                  You earned {newUserOnboardingRewardCampaign.merchant_name}{" "}
                  credit!
                </Typography>

                <Typography variant="bodyLarge" fontWeight={700}>
                  Let&apos;s get ready to redeem it.
                </Typography>
              </Stack>
            </>
          )}
        </Stack>

        <DefaultButton
          onClick={() => handleClick()}
          fullWidth
          sx={({ palette }) => ({
            "&.MuiButton-outlined": {
              borderColor: palette.common.white,
              color: palette.common.white,
              "&:hover:not(:active)": {
                backgroundColor: alpha(
                  palette.grey[700],
                  palette.action.hoverOpacity
                ),
              },
            },
          })}
        >
          Continue
        </DefaultButton>
      </Stack>
    </Stack>
  );
};

export default ClaimReward;
