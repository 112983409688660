import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack } from "@mui/material";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import { paymentInstrumentsActions } from "~common/store/slices/paymentInstruments-slice";
import LabeledSection from "~src/components/layout/LabeledSection";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectCurrentUser } from "~src/store";

import PaymentMethodRow from "./PaymentMethodRow";

const PaymentMethodsSection: React.VFC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser.data);
  const paymentInstrumentsLength = useRef(0);
  const { paymentInstruments, loading, error } = usePaymentInstruments();

  useEffect(() => {
    /*
     * Currently, editing a payment instrument removes any other instruments from
     * the array. This happens over separate Redux stores in separate repos and
     * will be a time consuming fix. For now I'm refetching the data if the second
     * payment method disappears.
     */
    if (
      paymentInstrumentsLength.current === 2 &&
      paymentInstruments.length === 1
    ) {
      dispatch(paymentInstrumentsActions.setStatus("requested"));
    }

    paymentInstrumentsLength.current = paymentInstruments.length;
  }, [dispatch, paymentInstruments.length]);

  const defaultInstrument = paymentInstruments.find(
    (instrument) => instrument.precedence === "default"
  );

  const catchCardInstrument = paymentInstruments.find(
    (instrument) => instrument.is_anywhere_payment_instrument
  );

  return (
    <LabeledSection heading="Payment methods">
      <LoadingGuard {...{ error }}>
        <Stack spacing={6}>
          <PaymentMethodRow
            loading={loading}
            heading="Default"
            paymentInstrument={defaultInstrument}
          />

          {currentUser?.is_eligible_for_catch_pass && (
            <PaymentMethodRow
              loading={loading}
              heading="Catch card"
              paymentInstrument={catchCardInstrument}
            />
          )}
        </Stack>
      </LoadingGuard>
    </LabeledSection>
  );
};

export default PaymentMethodsSection;
