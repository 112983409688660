/* eslint-disable */

// GENERATED FILE. DO NOT EDIT.

import * as React from "react";
const AnimatedPiggyBankFallback = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1200"
    width={1200}
    height={1200}
    style={{
      width: "100%",
      height: "100%",
      transform: "translate3d(0,0,0)",
    }}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h1200v1200H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        fill="transparent"
        d="M0 0h1200v1200H0z"
        style={{
          display: "block",
        }}
      />
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M418.206 939.418s40.688 21.282 60.403 42.374c19.9 21.29 46.114 73.78 46.114 73.78l14.64-49.83 32.706 19.544s-7.32-101.74-63.376-174.44l-90.487 88.572z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={9.492}
          d="M418.206 939.418s40.688 21.282 60.403 42.374c19.9 21.29 46.114 73.78 46.114 73.78l14.64-49.83 32.706 19.544s-7.32-101.74-63.376-174.44l-90.487 88.572z"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M796.043 837.722c52.647 125.125-1.18 204.264-1.18 204.264l-24.38-32.372-31.101 47.491s-13.378-71.2-25.285-115.643c-9.057-33.808-24.479-56.593-24.479-56.593l106.425-47.147z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={9.492}
          d="M796.043 837.722c52.647 125.125-1.18 204.264-1.18 204.264l-24.38-32.372-31.101 47.491s-13.378-71.2-25.285-115.643c-9.057-33.808-24.479-56.593-24.479-56.593l106.425-47.147z"
          fill="none"
        />
      </g>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#240D4A"
        strokeWidth={11.306}
        d="M-340.024 176.121c-79.612 11.824-112.784 39.168-114.995 85.729-1.474 41.386 41.57 41.728 56.729 12.211 19.541-37.721 9.413-89.847-66.739-95.632"
        fill="none"
        transform="rotate(-11.535 3398.593 -2620.85)"
        style={{
          display: "block",
        }}
      />
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M618.36 601.05s55.807-94.137 124.631-98.182c0 0-27.862 43.886-10.323 108.661"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={9.492}
          d="M618.36 601.05s55.807-94.137 124.631-98.182c0 0-27.862 43.886-10.323 108.661"
          fill="none"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="m953.386 637.348-57.55 36.878c-38.806-68.794-119.704-107.454-200.834-89.932l-281.915 62.503c-99.055 21.707-162.825 121.17-140.686 221.149 21.594 99.292 120.773 163.161 220.514 140.908l281.916-62.5c72.852-15.977 126.194-73.546 140.97-142.42l81.957-43.877"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={9.492}
          d="m953.386 637.348-57.55 36.878c-38.806-68.794-119.703-107.455-200.834-89.932l-281.915 62.503c-99.055 21.707-162.825 121.17-140.686 221.149 21.594 99.292 120.773 163.161 220.514 140.908l281.916-62.5c72.852-15.977 126.194-73.546 140.97-142.42l81.957-43.877"
          fill="none"
        />
        <path
          fill="#240D4A"
          d="m614.147 653.962-223.768 49.83c-5.131 1.284-8.158 5.933-6.81 10.46 1.278 5.142 5.913 8.174 10.43 6.82l223.767-49.83c5.131-1.283 8.158-5.932 6.81-10.46-1.35-4.527-5.984-7.557-10.429-6.82z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={9.492}
          d="m614.147 653.962-223.768 49.83c-5.131 1.284-8.158 5.933-6.81 10.46 1.278 5.142 5.913 8.174 10.43 6.82l223.767-49.83c5.131-1.283 8.158-5.932 6.81-10.46-1.35-4.527-5.984-7.557-10.429-6.82z"
          fill="none"
        />
      </g>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#240D4A"
        strokeWidth={9.492}
        d="M174.77-58.731c20.455-23.88 57.26-24.573 77.291 9.02"
        fill="none"
        transform="rotate(-13.058 3671.242 -2019.153)"
        style={{
          display: "block",
        }}
      />
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="M1000.582 758.43c19.272-5.951 26.233-38.915 15.55-73.627-10.678-34.712-34.96-58.027-54.233-52.076-19.266 5.95-26.227 38.914-15.55 73.626 10.685 34.712 34.967 58.027 54.233 52.076z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#240D4A"
          strokeWidth={9.492}
          d="M1000.582 758.43c19.272-5.951 26.233-38.915 15.55-73.627-10.678-34.712-34.96-58.027-54.233-52.076-19.266 5.95-26.227 38.914-15.55 73.626 10.685 34.712 34.967 58.027 54.233 52.076z"
          fill="none"
        />
        <path
          fill="#240D4A"
          d="M994.154 694.31c5.108-.486 8.846-5.029 8.359-10.145-.487-5.117-5.015-8.87-10.124-8.385-5.102.486-8.846 5.027-8.358 10.144.487 5.117 5.02 8.871 10.123 8.386zM969.164 706.038c5.102-.485 8.847-5.028 8.36-10.145-.488-5.117-5.021-8.871-10.124-8.386-5.102.486-8.846 5.029-8.359 10.145.487 5.117 5.02 8.871 10.123 8.386z"
        />
      </g>
      <g
        style={{
          display: "block",
        }}
      >
        <path
          fill="#E1D4F8"
          d="m687.708 660.667-314.396 69.837 42.361 245.687 357.842-72.017-85.807-243.507z"
        />
        <path
          d="m687.708 660.667-314.396 69.837 42.361 245.687 357.842-72.017-85.807-243.507z"
          fill="none"
        />
      </g>
    </g>
  </svg>
);
export default AnimatedPiggyBankFallback;
