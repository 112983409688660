import { GetDataError } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";

const convertRawServiceError = <
  T extends ErrorResponseData = ErrorResponseData
>(
  rawError: unknown
): T => {
  // restful-react returns errors in a wrapper object
  // (see https://github.com/contiamo/restful-react/blob/master/src/Get.tsx#L19).
  // The `data` member of this object should be the TError error type described
  // when invoking useGet<TData, TError>() or useMutate<TData, TError>().
  // For some reason, restful-react defines this type as TError | string.
  // However, we generally expect this to be TError. In case this somehow is a string,
  // we fallback to a fake error object (but this normally should never happen).
  const wrapper = rawError as GetDataError<T>;
  const { data, status } = wrapper;
  if (typeof data !== "string") {
    if (status) {
      data.status = status;
    }
    return data;
  }
  return {
    error_type: "",
    message: "",
    status,
  } as unknown as T;
};

export { convertRawServiceError };
