import React from "react";

import useActiveExperiments from "~src/hooks/useActiveExperiments";

// NB: this component is separated out as it seems that if calls to the react Statsig hooks
// are made at the top level of the app (e.g. the level at which the StatsigProvider is)
// it results in the experiments never actually loading. For example if we call "useActiveExperiments" within
// useAppInit (inside of App), the Statsig useExperiment hook will always return an uninitialized config with
// a 'isLoading' value of true
const Experiments: React.FC = ({ children }) => (
  // Uncomment when experiments are running
  // useActiveExperiments();

  <>{children}</>
);
export default Experiments;
