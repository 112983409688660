import React from "react";

import { Stack } from "@mui/material";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import useExploreMerchants from "~src/hooks/services/useExplorablePublicMerchants";

import ShopCategory from "./ShopCategory";
import ShopCategorySkeleton from "./ShopCategorySkeleton";

type ShopCategoriesProps = {
  utmCampaign: string;
  isPublicPageVariant?: boolean;
};

const ShopCategories: React.VFC<ShopCategoriesProps> = ({
  utmCampaign,
  isPublicPageVariant = false,
}) => {
  const { loading, exploreMerchants, error } = useExploreMerchants();

  const { categories } = exploreMerchants;

  return (
    <LoadingGuard {...{ error }}>
      <Stack
        sx={({ breakpoints }) => ({
          // TODO: We're hardcoding heights and preventing text overflow to ensure the iframe
          // version for the public page doesn't break due to scroll issues. We should remove these
          // restrictions once we have a better solution for the public page.
          // In the internal page spec, the margin should just be 3 units below the `lg` breakpoint.
          mt: isPublicPageVariant ? 7 : 3,
          gap: 9,
          [breakpoints.up("lg")]: {
            mt: 7,
          },
        })}
      >
        {loading ? (
          <ShopCategorySkeleton rows={7} />
        ) : (
          categories.map((category) => (
            <ShopCategory
              key={category.id}
              categoryName={category.name}
              merchants={category.merchants}
              utmCampaign={utmCampaign}
            />
          ))
        )}
      </Stack>
    </LoadingGuard>
  );
};

export default ShopCategories;
