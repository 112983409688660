import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.293 50.3232V71.5572H48.8932V50.512"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5707 18.9912C36.6492 18.9912 42.3885 24.6536 42.3885 31.6372V50.5119H16.7529V31.6372C16.7529 24.6536 22.4922 18.9912 29.5707 18.9912Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0759 39.9424L25.7451 50.2291"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5527 29.939C25.5527 29.939 34.5443 41.5469 41.3358 41.5469H42.1011"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5527 50.0405V29.939H42.1967"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.7285 21.8761V7.19482"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.6322 28.9003C56.4283 28.9003 57.8844 27.4637 57.8844 25.6916C57.8844 23.9195 56.4283 22.4829 54.6322 22.4829C52.836 22.4829 51.3799 23.9195 51.3799 25.6916C51.3799 27.4637 52.836 28.9003 54.6322 28.9003Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.7285 7.19482H65.4419L63.1462 10.5923L65.4419 14.3672H54.7285V7.19482Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.45703 50.5122H16.7531H42.293"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.4588 38.5265V32.7697C69.4588 25.1255 63.2412 18.9912 55.4931 18.9912H30.7185C30.5272 18.9912 30.3358 18.9912 30.1445 18.9912C36.936 19.2743 42.2927 24.8424 42.2927 31.5429V50.4175H57.5975"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.1106 56.2691C71.1822 56.2691 75.2935 52.2129 75.2935 47.2092C75.2935 42.2056 71.1822 38.1494 66.1106 38.1494C61.039 38.1494 56.9277 42.2056 56.9277 47.2092C56.9277 52.2129 61.039 56.2691 66.1106 56.2691Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.7109 46.8319L64.9632 50.0406L70.7982 44.1895"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
