import { ExperimentationState } from "../slices/experimentation-slice";

type RootState = {
  experimentation: ExperimentationState;
};

const selectStatsigProviderUser = (state: RootState) =>
  state.experimentation.statsigProviderUser;

const selectIsStatsigReady = (state: RootState) =>
  state.experimentation.isStatsigReady;

const selectIsStatsigErrored = (state: RootState) =>
  state.experimentation.isStatsigErrored;

export {
  selectIsStatsigErrored,
  selectIsStatsigReady,
  selectStatsigProviderUser,
};
