import { GiftResponseData } from "~common/services";

const giftInfo = (
  gift: GiftResponseData | null,
  userId = "",
  error = false
) => {
  const isOwnGift = gift && gift.sender_user_id === userId;
  const isClaimed = gift?.status === "claimed";
  const isExpired = gift && new Date(gift.link_expiration) < new Date();
  const isClaimable = gift && !(isOwnGift || isClaimed || isExpired || error);
  // Currently we don't support gifting flex credits,
  // this exists as a safety measure for GiftCard.tsx.
  const isFlexCredit = gift && gift.merchant_id === "FLEX_MERCHANT_ID";

  return { isOwnGift, isClaimed, isExpired, isClaimable, isFlexCredit };
};

export { giftInfo };
