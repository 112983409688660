import React from "react";

import { Box, Stack, StackProps, Typography } from "@mui/material";

type RewardAmountBadgeProps = StackProps & {
  percent: number;
  qualifier?: string;
};

const RewardAmountBadge: React.VFC<RewardAmountBadgeProps> = ({
  percent,
  qualifier,
  ...props
}) => (
  <Stack
    bgcolor="tints.pink.light"
    width={64}
    height={64}
    borderRadius="50%"
    textAlign="center"
    color="primary.main"
    fontWeight="700"
    flexShrink={0}
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {qualifier && (
      <Typography variant="h4" component="div" mb={-1.5}>
        {qualifier}
      </Typography>
    )}

    <Box>
      <Typography variant="h1" component="span">
        {percent}
      </Typography>

      <Typography
        variant="h4"
        component="span"
        sx={{
          verticalAlign: "super",
        }}
      >
        %
      </Typography>
    </Box>
  </Stack>
);

export default RewardAmountBadge;
