import React, { lazy, Suspense } from "react";
import Confetti from "react-confetti";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";

import { RewardCampaignResponseData } from "../../services";
import { CloseReason } from "../../utils/modal-utils";
import RewardCard from "../cards/RewardCard";
import { PrimaryButton, TextButton } from "../controls/buttons";

const SwiperWrapper = lazy(() => import("../carousels/SwiperWrapper"));

type RewardClaimedProps = {
  contents: {
    cta_button: {
      text: React.ReactNode;
      link?: string;
      hideEndIcon?: boolean;
    };
    header: string;
    subheader: React.ReactNode;
    subtext: string;
  };
  contentId?: string;
  headingId?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  onClose: (event: unknown, reason: CloseReason) => void;
  rewardCampaign?: RewardCampaignResponseData | null;
  rewardCampaigns?: RewardCampaignResponseData[] | null;
  hideConfetti?: boolean;
};

const RewardClaimed: React.VFC<RewardClaimedProps> = ({
  contents,
  contentId,
  headingId,
  onClick,
  onClose,
  rewardCampaign,
  rewardCampaigns,
  hideConfetti,
}) => {
  const { palette } = useTheme();

  return (
    <>
      {!hideConfetti && (
        <Confetti
          colors={[
            palette.tertiary.main,
            palette.primary.main,
            palette.primary.light,
          ]}
          initialVelocityY={5}
          numberOfPieces={300}
          recycle={false}
        />
      )}

      <Stack
        spacing={2}
        sx={{
          px: 6,
          py: 8,
          textAlign: "center",
          position: "relative",
          zIndex: 3,
        }}
      >
        {rewardCampaign && (
          <Stack alignItems="center" sx={{ mb: -4 }}>
            <RewardCard reward={rewardCampaign} sx={{ width: 224, mb: 8 }} />
          </Stack>
        )}

        {rewardCampaigns && rewardCampaigns.length > 0 && (
          <Stack alignItems="center" sx={{ mb: -4 }}>
            {rewardCampaigns.length === 1 ? (
              <RewardCard
                reward={rewardCampaigns[0]}
                sx={{ width: 224, mb: 8 }}
              />
            ) : (
              <Suspense
                fallback={
                  <RewardCard
                    reward={rewardCampaigns[0]}
                    sx={{ width: 224, mb: 8 }}
                  />
                }
              >
                <SwiperWrapper
                  containerProps={{
                    sx: {
                      width: "100%",
                    },
                  }}
                  slides={rewardCampaigns.map((campaign) => (
                    <RewardCard
                      reward={campaign}
                      sx={{
                        width: 224,
                        mx: "auto",
                        mb: 14,
                        boxSizing: "border-box",
                      }}
                    />
                  ))}
                  swiperProps={{
                    pagination: {
                      clickable: true,
                    },
                  }}
                />
              </Suspense>
            )}
          </Stack>
        )}

        <Typography component="h4" variant="h1" id={headingId}>
          {contents.header}
        </Typography>

        <Typography
          color="grey.600"
          variant="bodyRegular"
          id={contentId}
          sx={{ pb: 4 }}
        >
          {contents.subheader}
        </Typography>

        {contents.cta_button.link ? (
          <PrimaryButton
            href={contents.cta_button.link}
            onClick={onClick}
            target="_blank"
            endIcon={
              contents.cta_button.hideEndIcon ? undefined : (
                <OpenInNewIcon sx={{ width: 16, height: 16 }} />
              )
            }
          >
            {contents.cta_button.text}
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={(e) => onClose(e, "ctaClick")}>
            {contents.cta_button.text}
          </PrimaryButton>
        )}

        {contents.subtext && (
          <TextButton
            size="small"
            onClick={(e) => onClose(e, "ctaClick")}
            sx={{
              color: palette.grey[600],
              mx: "auto",
              "&.MuiButton-text": {
                p: 0,
                mt: 4,
              },
            }}
          >
            {contents.subtext}
          </TextButton>
        )}
      </Stack>
    </>
  );
};

export default RewardClaimed;
