import { useEnv } from "../../contexts/env-context";

const useDetermineTellerEnvironment = () => {
  const { APP_STAGE, APP_ENVIRONMENT } = useEnv();

  if (APP_STAGE === "sandbox" || APP_ENVIRONMENT !== "production") {
    return "sandbox";
  }
  return "production";
};

export default useDetermineTellerEnvironment;
