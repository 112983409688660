import { ConsumerActivity } from "~common/services";
import { formatNumDays } from "~common/utils/dates";

type Operator = "" | "-" | "+";

const getAmountOperator = (activity: ConsumerActivity) => {
  if (activity.details.reward_change_amount === 0) {
    return "";
  }

  if (
    activity.activity_type === "gift_sent" ||
    activity.activity_type === "donation" ||
    activity.activity_type === "reward_applied" ||
    activity.activity_type === "reward_expired" ||
    (activity.activity_type === "refund" &&
      activity.details.reward_change_amount <= 0)
  ) {
    return "-";
  }

  return "+";
};

const getAmountColor = (activity: ConsumerActivity, operator: Operator) => {
  if (
    activity.activity_type === "purchase" &&
    (activity.details.purchase_type === "affiliate" ||
      activity.details.purchase_type === "external")
  ) {
    return "tertiary.main";
  }

  if (operator === "+") {
    return "primary.main";
  }

  return undefined;
};

const getDescription = (activity: ConsumerActivity) => {
  if (activity.activity_type === "admin_granted_reward") {
    return `${activity.details.merchant_name} credits granted`;
  }

  if (activity.activity_type === "donation") {
    return `${activity.details.merchant_name} donation`;
  }

  if (activity.activity_type === "gift_received") {
    return `${activity.details.merchant_name} gift from ${activity.details.user_name}`;
  }

  if (activity.activity_type === "gift_sent") {
    return `${activity.details.merchant_name} gift to ${activity.details.user_name}`;
  }

  if (activity.activity_type === "new_user_bonus_earned") {
    return `First order bonus at ${activity.details.merchant_name}`;
  }

  if (activity.activity_type === "purchase") {
    return `${activity.details.merchant_name} purchase`;
  }

  if (activity.activity_type === "referral") {
    return `Referral credit${
      activity.details.user_name ? ` for ${activity.details.user_name}` : ""
    }`;
  }

  if (activity.activity_type === "refund") {
    return `${activity.details.merchant_name} return/partial return`;
  }

  if (activity.activity_type === "reward_applied") {
    return `${activity.details.merchant_name} credits redeemed`;
  }

  if (activity.activity_type === "reward_boost") {
    return `${activity.details.merchant_name} credit ${
      activity.details.reward_boost_type === "expired_reward_revival"
        ? "extension"
        : `${formatNumDays(activity.details.expiration_in_days)} boost`
    }`;
  }

  if (activity.activity_type === "reward_campaign_claimed") {
    return `${activity.details.merchant_name} credits claimed`;
  }

  if (activity.activity_type === "reward_expired") {
    return `${activity.details.merchant_name} credits expired`;
  }

  return "";
};

export { getAmountColor, getAmountOperator, getDescription };
