import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { SIGN_UP_BONUS } from "~common/constants/bonuses";
import { useTracking } from "~common/tracking";
import { formatRelativeLink } from "~common/utils/urls";
import useUserData from "~src/hooks/services/useUserData";

import MerchantMarquee from "./MerchantMarquee";
import WhatNextPrompt from "./WhatNextPrompt";

const BonusCreditShopPrompt: React.VFC = () => {
  const { userData } = useUserData();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    navigate("/shop");
    trackEvent("See all brands clicked", {
      component: "BonusCreditShopPrompt",
    });
  };

  return (
    <WhatNextPrompt
      header={<MerchantMarquee />}
      body={
        <>
          {(userData?.lifetime_rewards_earned || 0) > 0 ? (
            <Typography variant="h2" sx={{ mb: 2 }}>
              <Typography variant="h2" component="span" color="primary.main">
                Earn 5-10%
              </Typography>{" "}
              store credit on every purchase
            </Typography>
          ) : (
            <Typography variant="h2" sx={{ mb: 2 }}> 
              Earn{" "}
              <Typography variant="h2" component="span" color="primary.main">
                {SIGN_UP_BONUS} bonus credit
              </Typography>{" "}
              to use after your first order
            </Typography>
          )}

          <Typography variant="bodyRegular" component="p" color="grey.600">
            Shop at any Catch brand to earn.
          </Typography>
        </>
      }
      footer={
        <PrimaryButton
          href={formatRelativeLink("shop")}
          onClick={handleClick}
          fullWidth
        >
          See all brands
        </PrimaryButton>
      }
    />
  );
};

export default BonusCreditShopPrompt;
