import React from "react";
import { useIntercom } from "react-use-intercom";

import { ErrorAlert } from "~common/components/alerts";
import { TextButtonSmall } from "~common/components/controls/buttons";

const EmailLimitExceededAlert: React.VFC = () => {
  const intercom = useIntercom();
  return (
    <ErrorAlert>
      Too many emails sent. Try checking spam, or{" "}
      <TextButtonSmall
        onClick={() => intercom.show}
        sx={{
          "&.MuiButton-sizeSmall": {
            p: 0,
            display: "inline",
            verticalAlign: "baseline",
          },
          "&.MuiButton-sizeSmall .label": {
            color: "inherit",
          },
        }}
      >
        contact us
      </TextButtonSmall>
      .
    </ErrorAlert>
  );
};

export default EmailLimitExceededAlert;
