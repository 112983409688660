import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Statsig, StatsigProvider } from "statsig-react";

import { useEnv } from "../../contexts/env-context";
import { useExperimentationInit } from "../../experimentation";
import { selectStatsigProviderUser } from "../../store/selectors";
import { setIsStatsigReady } from "../../store/slices/experimentation-slice";

type CustomStatsigProviderProps = {
  children: React.ReactNode;
  appName: string;
};

const CustomStatsigProvider: React.FC<CustomStatsigProviderProps> = ({
  children,
  appName,
}) => {
  const { STATSIG_KEY } = useEnv();
  const statsigProviderUser = useSelector(selectStatsigProviderUser);
  const dispatch = useDispatch();

  useExperimentationInit(appName);

  return (
    <StatsigProvider
      user={statsigProviderUser}
      sdkKey={STATSIG_KEY}
      waitForInitialization
      // Prevents children from unmounting/mounting when the user object changes
      mountKey="stableKey"
      options={{
        // Here we unblock execution anywhere that is waiting for isStatsigReady to be
        // true (namely pulling experiments).
        initCompletionCallback: (
          initDurationMs: number,
          success: boolean,
          message: string | null
        ) => {
          // If we successfully update the statsigProveiderUser before Statsig actually
          // initialized, we won't receive the completion callback, so set to ready
          if (statsigProviderUser.userID) {
            dispatch(setIsStatsigReady(true));
          }
          if (!success) {
            Statsig.logEvent("Initialization failure.", message);
          }
        },
        updateUserCompletionCallback: (
          durationMs: number,
          success: boolean,
          message: string | null
        ) => {
          if (success) {
            dispatch(setIsStatsigReady(true));
          }
          if (!success) {
            Statsig.logEvent("Update user failure.", message);
          }
        },
      }}
    >
      {children}
    </StatsigProvider>
  );
};

export default CustomStatsigProvider;
