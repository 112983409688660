import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";

type AccountInfoBoxProps = {
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  loading: boolean;
};

const AccountInfoBox: React.VFC<AccountInfoBoxProps> = ({
  icon,
  title,
  subtitle,
  loading,
}) => (
  <Stack
    sx={({ palette }) => ({
      background: palette.common.white,
      border: `1px solid ${palette.divider}`,
      borderRadius: 1,
      p: 6,
    })}
    alignItems="center"
    direction="row"
    gap={3}
  >
    <Box sx={{ width: 64, height: 64 }}>{icon}</Box>
    <Stack flexGrow={1}>
      <Typography variant="bodyRegular">{title}</Typography>

      <Typography variant="h2">
        {loading ? <WrappedSkeleton variant="text" width={80} /> : subtitle}
      </Typography>
    </Stack>
  </Stack>
);

export default AccountInfoBox;
