import React from "react";

import { Stack, Typography } from "@mui/material";

const HoldAuthorization: React.VFC = () => (
  <Stack
    spacing={5}
    sx={({ palette, shape }) => ({
      background: palette.grey[200],
      p: 2,
      borderRadius: `${shape.borderRadius * 2}px`,
      color: palette.grey[500],
    })}
  >
    <Typography variant="bodySmall">
      By continuing, you agree to have Catch place a temporary hold on your card
      for verification purposes. The hold will be released within minutes.
    </Typography>
  </Stack>
);

export default HoldAuthorization;
