import React from "react";

import { EnvProvider } from "~common/contexts/env-context";
import {
  APP_ENVIRONMENT,
  APP_STAGE,
  EXTERNAL_ASSETS_BASE_URL,
  STATSIG_KEY,
} from "~src/config";

const env = {
  APP_ENVIRONMENT,
  APP_STAGE,
  EXTERNAL_ASSETS_BASE_URL,
  STATSIG_KEY,
};

const ConfigEnvProvider: React.FC = ({ children }) => (
  <EnvProvider env={env}>{children}</EnvProvider>
);

export default ConfigEnvProvider;
