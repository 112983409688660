import React from "react";

import { Box, Stack } from "@mui/material";

type SummaryListItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
};

const SummaryListItem: React.FC<SummaryListItemProps> = ({
  icon,
  children,
}) => (
  <Stack direction="row" spacing={4}>
    <Box
      sx={({ palette, spacing }) => ({
        backgroundColor: palette.grey[200],
        borderRadius: "50%",
        width: spacing(6),
        height: spacing(6),
        display: "flex",
        flexShrink: 0,
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      {icon}
    </Box>
    <Stack spacing={2}>{children}</Stack>
  </Stack>
);

export default SummaryListItem;
