import React from "react";

import { SxProps, Theme } from "@mui/material";

import { useUniqueId } from "../../hooks/accessibility-hooks";
import { CloseReason, ModalOverlayColor } from "../../utils/modal-utils";
import DefaultDialog from "./DefaultDialog";
import DefaultDrawer from "./DefaultDrawer";

type ResponsiveDrawerDialogProps = {
  children: (props: {
    headingId: string;
    contentId: string;
  }) => React.ReactNode;
  fullBleed?: boolean;
  name?: string;
  onClose?: (event: unknown, reason?: CloseReason) => void;
  open: boolean;
  overlayColor?: ModalOverlayColor;
  sx?: SxProps<Theme>;
};

const ResponsiveDrawerDialog: React.VFC<ResponsiveDrawerDialogProps> = ({
  children,
  name,
  onClose,
  open,
  sx,
  ...props
}) => {
  const drawerIdPrefix = useUniqueId(`${name ? `${name}-` : ""}drawer`);
  const dialogIdPrefix = useUniqueId(`${name ? `${name}-` : ""}dialog`);

  return (
    <>
      <DefaultDrawer
        anchor="bottom"
        onClose={onClose ? (e) => onClose(e) : () => {}}
        onOpen={() => {}}
        open={open}
        ariaLabelledBy={`${drawerIdPrefix}-heading`}
        ariaDescribedBy={`${drawerIdPrefix}-content`}
        sx={{
          ...(sx || {}),
          display: {
            sm: "none",
          },
        }}
        {...props}
      >
        {children({
          headingId: `${drawerIdPrefix}-heading`,
          contentId: `${drawerIdPrefix}-content`,
        })}
      </DefaultDrawer>

      <DefaultDialog
        onClose={onClose}
        open={open}
        ariaLabelledBy={`${dialogIdPrefix}-heading`}
        ariaDescribedBy={`${dialogIdPrefix}-content`}
        sx={{
          ...(sx || {}),
          display: {
            zero: "none",
            sm: "block",
          },
        }}
        {...props}
      >
        {children({
          headingId: `${dialogIdPrefix}-heading`,
          contentId: `${dialogIdPrefix}-content`,
        })}
      </DefaultDialog>
    </>
  );
};

export default ResponsiveDrawerDialog;
