// GENERATED FILE. DO NOT EDIT.

import React from "react";

import SmallSVG from "../vector-source/RewardBoost.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const RewardBoostIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} {...props} />
);

export default RewardBoostIcon;
