import React, { useCallback } from "react";

import { Stack } from "@mui/material";

import RewardCard from "~common/components/cards/RewardCard";
import { PrimaryButton } from "~common/components/controls/buttons";
import LoadingGuard from "~common/components/loading/LoadingGuard";
import DefaultDialog from "~common/components/modals/DefaultDialog";
import DialogBody from "~common/components/modals/DialogBody";
import DialogHeader from "~common/components/modals/DialogHeader";
import { useUniqueId } from "~common/hooks/accessibility-hooks";
import {
  RewardPoolEntry,
  useClaimRewardPoolEntry,
  useGetGiftByHashedSecret,
} from "~common/services";
import useRefreshCreditExchange from "~src/hooks/useRefreshCreditExchange";

type ClaimRewardPoolGiftModalProps = {
  open: boolean;
  rewardPoolEntry: RewardPoolEntry;
  onClose: () => void;
};

const ClaimRewardPoolEntryModal: React.VFC<ClaimRewardPoolGiftModalProps> = ({
  open,
  onClose,
  rewardPoolEntry,
}) => {
  const {
    data: giftData,
    loading,
    error: loadGiftError,
  } = useGetGiftByHashedSecret({
    pathParams: { hashedGiftSecret: rewardPoolEntry.hashed_gift_secret },
  });

  const { mutate: claimRewardPoolEntry, loading: loadingClaim } =
    useClaimRewardPoolEntry();

  const refreshCreditExchange = useRefreshCreditExchange();

  const dialogId = useUniqueId(
    `claim-reward-pool-entry-${rewardPoolEntry.hashed_gift_secret}-modal`
  );
  const dialogDescriptionId = useUniqueId(
    `claim-reward-pool-entry-${rewardPoolEntry.hashed_gift_secret}-modal-description`
  );

  const handleClaimClick = useCallback(async () => {
    await claimRewardPoolEntry(
      { merchant_name: rewardPoolEntry.merchant_id },
      {
        pathParams: {
          hashedGiftSecret: rewardPoolEntry.hashed_gift_secret,
          rewardPoolId: rewardPoolEntry.pool_id,
        },
      }
    );
    onClose();
    refreshCreditExchange();
  }, [claimRewardPoolEntry, onClose, refreshCreditExchange, rewardPoolEntry]);

  return (
    <DefaultDialog
      fullBleed
      open={open}
      onClose={onClose}
      ariaLabelledBy={dialogId}
      ariaDescribedBy={dialogDescriptionId}
      sx={({ breakpoints, shape }) => ({
        "& .MuiPaper-root.MuiDialog-paper": {
          pt: 26,
          borderRadius: `${shape.borderRadius * 2}px`,
          [breakpoints.down("sm")]: {
            left: "auto",
          },
        },
      })}
    >
      <Stack
        alignItems="center"
        sx={{
          textAlign: "center",
          px: 6,
          py: 3,
          marginBottom: 6,
        }}
      >
        <LoadingGuard loading={loading} {...{ error: loadGiftError }}>
          <Stack alignItems="center" spacing={6} sx={{ px: 6, pb: 8 }}>
            {giftData && (
              <RewardCard reward={giftData} sx={{ width: 224, mb: 8 }} />
            )}

            <Stack spacing={4}>
              <DialogHeader id={dialogId} variant="h1">
                {rewardPoolEntry.sender_name} hooked it up!
              </DialogHeader>
              <DialogBody id={dialogDescriptionId}>
                Claim this credit by clicking the button below. It&apos;ll be
                added to your Catch account and ready to use right away.
              </DialogBody>
              <PrimaryButton loading={loadingClaim} onClick={handleClaimClick}>
                Claim now
              </PrimaryButton>
            </Stack>
          </Stack>
        </LoadingGuard>
      </Stack>
    </DefaultDialog>
  );
};

export default ClaimRewardPoolEntryModal;
