declare global {
  interface Window {
    marqeta: {
      bootstrap: (config: Record<string, unknown>) => void;
    };
  }
}

const MARQETA_ERROR_MESSAGE =
  "Sorry, we're having trouble getting your card details right now.";

const STYLES = {
  span: {
    color: "#fff",
    "font-family": "Circular, Helvetica, Arial, sans-serif",
    "line-height": "28px",
  },
};

const injectMarqeta = (isSandbox?: boolean, onLoad?: () => void) => {
  if (window.marqeta) {
    onLoad && onLoad();
    return;
  }

  const script = document.createElement("script");
  script.src = `https://widgets${
    isSandbox ? "-sandbox" : ""
  }.marqeta.com/marqetajs/2.0.0/marqeta.min.js`;
  script.type = "text/javascript";

  if (onLoad) {
    script.addEventListener("load", onLoad, { once: true });
  }

  document.body.appendChild(script);
};

const bootstrapMarqeta = (
  token: string,
  onSuccess: () => void,
  onFailure?: () => void,
  onCopy?: (name: string) => void
) => {
  window.marqeta &&
    window.marqeta.bootstrap({
      clientAccessToken: token,
      callbackEvents: {
        onSuccess,
        ...(onFailure && { onFailure }),
      },
      component: {
        showPan: {
          cardPan: {
            domId: "catch-mq-card-pan",
            format: "true",
            styles: STYLES,
          },
          copyCardPan: {
            domId: "catch-mq-card-pan-copy",
            mode: "transparent",
            onCopySuccess: () => handleCopySuccess("pan", onCopy),
          },
          cardExp: {
            domId: "catch-mq-card-exp",
            format: "true",
            styles: STYLES,
          },
          copyCardExp: {
            domId: "catch-mq-card-exp-copy",
            mode: "transparent",
            onCopySuccess: () => handleCopySuccess("exp", onCopy),
          },
          cardCvv: {
            domId: "catch-mq-card-cvv",
            styles: STYLES,
          },
          copyCardCvv: {
            domId: "catch-mq-card-cvv-copy",
            mode: "transparent",
            onCopySuccess: () => handleCopySuccess("cvv", onCopy),
          },
        },
      },
    });
};

const handleCopySuccess = (name: string, onCopy?: (name: string) => void) => {
  const input = document.getElementById(`catch-mq-card-${name}`);
  const button = document.getElementById(`catch-mq-card-${name}-copy`);
  const message = document.getElementById(`catch-mq-card-${name}-copied`);

  if (!input || !button || !message) {
    return;
  }

  onCopy && onCopy(name);
  input.style.display = "none";
  button.style.display = "none";
  message.style.display = "inline-flex";

  setTimeout(() => {
    input.style.display = "block";
    button.style.display = "block";
    message.style.display = "none";
  }, 3000);
};

export { bootstrapMarqeta, injectMarqeta, MARQETA_ERROR_MESSAGE };
