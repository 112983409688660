import React from "react";

import { Box, Divider, Stack } from "@mui/material";

import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";
import BorderedBox from "~common/components/primitives/BorderedBox";

const ActivityFeedSkelton: React.VFC = () => (
  <BorderedBox
    sx={({ palette }) => ({
      background: palette.common.white,
      p: 6,
    })}
  >
    {[...Array(3).keys()].map((i) => (
      <Box key={`skeleton${i}`}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Stack direction="row" gap={4} alignItems="center">
            <WrappedSkeleton variant="circular" width={24} height={24} />

            <Stack>
              <WrappedSkeleton variant="text" width={175} height={24} />
              <WrappedSkeleton variant="text" width={75} height={20} />
            </Stack>
          </Stack>

          <WrappedSkeleton variant="text" width={60} height={24} />
        </Stack>

        {i !== 2 && <Divider sx={{ my: 4 }} />}
      </Box>
    ))}
  </BorderedBox>
);

export default ActivityFeedSkelton;
