import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IllustrationLoadingScreen from "~common/components/loading/IllustrationLoadingScreen";
import { useInitiateAuth } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking, useTrackPageView } from "~common/tracking";
import {
  isValidUSPhoneNumber,
  removePhoneNumberFormatting,
} from "~common/utils/phone-number";
import { selectAuthPhone } from "~src/store";
import { setAuthPhone, setAuthSession } from "~src/store/slices/user-slice";

const SignIn: React.VFC = () => {
  useTrackPageView("Skip Phone Number");
  const { trackEvent, trackError, trackUser } = useTracking();
  const { mutate: initiateAuth } = useInitiateAuth();
  const dispatch = useDispatch();
  const authPhone = useSelector(selectAuthPhone) || null;

  useEffect(() => {
    if (!authPhone || !isValidUSPhoneNumber(authPhone)) {
      dispatch(setAuthPhone(null));
      return;
    }

    void (async () => {
      const scrubbedPhoneNumber = removePhoneNumberFormatting(authPhone);
      const phoneNumber = `+1${scrubbedPhoneNumber}`;

      trackEvent("Phone Number Submitted", {
        scrubbedPhoneNumber,
      });

      trackUser({
        phone: phoneNumber,
      });

      try {
        const { session } = await initiateAuth({
          phone_number: phoneNumber,
        });

        dispatch(
          setAuthSession({
            id: session,
            phoneNumber,
          })
        );
      } catch (err) {
        const error = convertRawServiceError(err);

        if (
          error.error_type === "BAD_REQUEST_BODY_FORMAT" &&
          error.data?.json?.phone_number
        ) {
          trackError("SkipSignIn", "Invalid phone number", { error });
        } else {
          trackError("SkipSignIn", "(Service error) Phone submission", {
            error,
          });
        }
      }
    })();
  }, [dispatch, authPhone, initiateAuth, trackEvent, trackUser, trackError]);

  return <IllustrationLoadingScreen />;
};

export default SignIn;
