import { useGetAvailableRewardPoolEntries } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectRewardPoolEntries } from "~src/store";
import { rewardPoolEntriesActions } from "~src/store/slices/services/rewardPoolEntries-slice";

const useGet = () => useGetAvailableRewardPoolEntries({ lazy: true });

const useFetchRewardPoolEntries = () => {
  useLazyFetch(
    "RewardPoolEntries",
    rewardPoolEntriesActions,
    selectRewardPoolEntries,
    useGet,
    (data) => data
  );
};

export default useFetchRewardPoolEntries;
