import React from "react";
import ReactDOM from "react-dom";

import { configureLogging } from "~common/logging";
import { initializeSentry } from "~common/tracking";
import App from "~src/App";
import {
  BankLinkingProvider,
  BasePathRouter,
  CatchThemeProvider,
  ConfigEnvProvider,
  DateLocalizationProvider,
  IntercomProvider,
  ServiceProvider,
  StoreProvider,
} from "~src/components/main";
import { APP_ENVIRONMENT, LOGGING_MODE, SENTRY_DSN } from "~src/config";

configureLogging({
  mode: LOGGING_MODE,
});

if (SENTRY_DSN) {
  initializeSentry(SENTRY_DSN, APP_ENVIRONMENT);
}

// Keep `main.tsx` simple by setting config in ~src/components/main.

ReactDOM.render(
  <React.StrictMode>
    <CatchThemeProvider>
      <ConfigEnvProvider>
        <IntercomProvider>
          <StoreProvider>
            <ServiceProvider>
              <BankLinkingProvider>
                <DateLocalizationProvider>
                  <BasePathRouter>
                    <App />
                  </BasePathRouter>
                </DateLocalizationProvider>
              </BankLinkingProvider>
            </ServiceProvider>
          </StoreProvider>
        </IntercomProvider>
      </ConfigEnvProvider>
    </CatchThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
