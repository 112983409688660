import React, { useState } from "react";

import { Box } from "@mui/material";

import LabeledSetting from "~src/components/account/LabeledSetting";
import useCurrentUser from "~src/hooks/services/useCurrentUser";

import AccountSettingsEditForm from "./AccountSettingsEditForm";

const AccountSettingsAddressRow: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  return (
    <Box>
      {isEditMode ? (
        <AccountSettingsEditForm
          label="Mailing Address"
          fields={[
            {
              label: "Mailing Address",
              name: "mailing_address",
            },
          ]}
          onClose={() => setIsEditMode(false)}
          onValidate={() => null}
        />
      ) : (
        <LabeledSetting
          label="Mailing Address"
          value={currentUser?.mailing_address}
          loading={!currentUser}
          onEditClick={() => setIsEditMode(true)}
        />
      )}
    </Box>
  );
};

export default AccountSettingsAddressRow;
