const getDevice = (): "" | "iphone" | "android" => {
  const isIphone = navigator.userAgent.match(/iPhone/i);
  const isAndroid = navigator.userAgent.match(/Android/i);

  if (!isIphone && !isAndroid) {
    return "";
  }

  return isIphone ? "iphone" : "android";
};

export { getDevice };
