import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  AccountBalance as BankIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import InformationalButton from "~common/components/controls/buttons/InformationalButton";
import { CreditCardIcon } from "~common/components/icons/vector";
import {
  setBankLinkingStep,
  setHideAdyenModal,
  setIsCatchPassFlow,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import MobileAppEventBus from "~common/utils/mobile-app-messaging";
import WrappedBankLinkManager from "~src/components/bank/WrappedBankLinkManager";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { selectIsCatchPassFlow, selectPaymentInstrument } from "~src/store";

import LinkCatchPassMobile from "./LinkCatchPassMobile";

const LinkPaymentMethodMobile: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const paymentInstrument = useSelector(selectPaymentInstrument);
  const isCatchPassFlow = useSelector(selectIsCatchPassFlow);
  const navigate = useNavigate();
  const eventBus = new MobileAppEventBus({ overrideTargetToWindow: true });

  // This component is currently used inside a webview in iOS, to inform
  // the mobile client to dismiss the webview, send an event the app listens for.
  useEffect(() => {
    if (paymentInstrument && !isCatchPassFlow) {
      eventBus.sendEventToMobile("CATCH_DISMISS_PAYMENT_LINKING", {
        paymentInstrumentId: paymentInstrument.payment_instrument_id,
      });
      // PD-256 Remove old method of sending events
      window.dispatchEvent(
        new CustomEvent("CATCH_DISMISS_PAYMENT_LINKING", {
          detail: {
            paymentInstrumentId: paymentInstrument.payment_instrument_id,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, paymentInstrument]);

  const handleLinkDebit = () => {
    trackEvent("User opened link debit from signup page");
    dispatch(setHideAdyenModal(false));
    dispatch(setBankLinkingStep("AdyenConnect"));
  };

  const handleLinkBank = () => {
    trackEvent("User opened link bank from signup page");
    dispatch(setBankLinkingStep("BankPicker"));
  };

  const handleSkipButtonClick = () => {
    trackEvent("Bank Linking Skip Button Clicked");
    eventBus.sendEventToMobile("CATCH_DISMISS_PAYMENT_LINKING");
    dispatch(setIsCatchPassFlow(false));
    // PD-256 Remove old method of sending events
    window.dispatchEvent(new CustomEvent("CATCH_DISMISS_PAYMENT_LINKING"));
    navigate("/");
  };

  return (
    <>
      {isCatchPassFlow ? (
        <LinkCatchPassMobile />
      ) : (
        <WrappedBankLinkManager>
          <Box
            sx={({ palette }) => ({
              height: 60,
              borderBottom: `1px solid ${palette.grey[300]}`,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            })}
          >
            <Typography
              variant="bodySmall"
              color="grey.500"
              onClick={handleSkipButtonClick}
              sx={{ mr: 4 }}
            >
              Skip
            </Typography>
          </Box>
          <SmallPagePanel title="Link a payment method">
            <Stack sx={{ gap: 8 }}>
              <Stack sx={{ gap: 4 }}>
                <Typography variant="bodyRegular" color="grey.600">
                  Speed through checkout on every purchase by linking a payment
                  method now.
                </Typography>
              </Stack>

              <Stack>
                <InformationalButton
                  onClick={handleLinkDebit}
                  labelIcon={<CreditCardIcon size="icon" />}
                  label={<>Link debit card</>}
                  informationalText="Provide the information listed on your debit card to effortlessly link it to Catch."
                  actionIcon={<AddIcon />}
                />
                <InformationalButton
                  onClick={handleLinkBank}
                  labelIcon={<BankIcon />}
                  label={<>Link bank</>}
                  informationalText="Sign in to your bank to instantly link your account. Just like Venmo or Cash App."
                  actionIcon={<AddIcon />}
                  sx={{
                    "&.MuiButton-sizeLarge": {
                      mt: -1,
                    },
                    "&.MuiButton-outlined.MuiButton-colorInherit": {
                      borderTop: "none",
                    },
                  }}
                />
              </Stack>
            </Stack>
          </SmallPagePanel>
        </WrappedBankLinkManager>
      )}
    </>
  );
};

export default LinkPaymentMethodMobile;
