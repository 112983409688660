import React from "react";
import { useSelector } from "react-redux";

import { selectDisableAch } from "../../../../../store/selectors";
import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const DebitHeaderUnsupportedCardBrand: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => {
  const disableAch = useSelector(selectDisableAch);

  return (
    <ConnectPaymentInstrumentAlertHeader
      headingId={headingId}
      title={<>Sorry, Catch doesn&apos;t support that card type</>}
      subtitle={
        <>
          The brand of card you tried to link isn&apos;t compatible with Catch
          at the moment. To continue, please connect a different debit card
          {!disableAch && " or link your bank account directly"}.
        </>
      }
    />
  );
};

export default DebitHeaderUnsupportedCardBrand;
