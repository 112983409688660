import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.3885 33.093C37.8405 29.9558 37.5117 27.3246 37.4021 26.2114C37.1829 23.2767 38.7174 22.8719 39.4846 22.9731C40.2519 23.0743 41.2384 23.5803 42.0056 27.527C42.7729 31.4738 43.869 37.0397 43.869 37.0397"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.7502 64.5656C68.7502 64.5656 58.1182 64.1608 54.282 64.3632C50.5553 64.5656 42.9923 64.0596 39.2656 57.6841"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.1439 36.4324C47.8151 34.712 46.6094 24.5921 46.0613 22.467C45.5133 20.3418 44.4172 19.3298 43.1019 19.5322C41.5674 19.8358 41.3482 21.961 42.1154 27.7293"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.4181 36.2295C51.9797 33.6996 50.774 23.6809 50.1163 21.8593C49.4587 20.0377 48.6914 20.0377 47.9242 20.1389C46.7185 20.2401 46.0608 21.5557 46.2801 22.6689"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.3223 28.4372C51.3223 25.6037 54.0625 25.806 54.7201 27.5264C55.2682 28.7408 56.2546 31.2708 57.1315 35.2175C58.0084 38.8607 60.3101 51.1058 60.3101 51.1058"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.7634 47.2605L59.6523 47.0581"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3711 9.91895L27.7554 15.5861"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9902 7.49023L35.3191 13.461"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.2799 8.80615L42.7725 14.4733"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 51.3085L15.3708 49.9929C15.3708 49.9929 15.1516 37.6466 15.3708 34.6106C15.4804 31.6759 16.1381 29.0447 17.6726 28.7411C19.2071 28.4375 19.9743 29.0447 20.1936 31.8783C20.4128 34.7118 20.7416 39.1646 20.7416 39.1646"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2358 38.1529C25.0166 36.2301 23.9205 24.0863 23.5917 22.8719C23.2628 21.6575 21.6187 21.1515 20.6322 21.8599C19.6458 22.4671 19.5361 25.0983 19.9746 30.057"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5106 37.647C29.0722 35.5218 28.5241 25.6043 28.3049 23.1755C28.0857 20.7468 26.88 20.0384 25.4551 20.342C23.9206 20.6456 23.4821 23.1755 23.7014 25.1995"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.7435 28.4385C28.6339 24.9977 29.0723 23.6821 30.3876 23.1761C31.7029 22.7714 33.1278 23.9857 33.347 25.8073C33.6758 27.6289 34.6623 40.1776 35.1008 42.5051C35.1008 42.5051 35.2104 35.2188 37.1833 33.802C38.8274 32.5876 40.2523 33.5996 40.362 34.7128C40.4716 35.5224 41.458 45.6423 41.2388 48.9819C41.0196 52.2202 39.8139 62.1377 32.0317 64.2629C28.4147 65.2749 23.5919 66.3881 19.646 67.4C18.7691 67.6024 17.8923 67.8048 17.125 68.0072"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
