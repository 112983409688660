import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { useCatchFeatureGate } from "../../experimentation/experimentation-hooks";
import { setDisableDebit } from "../../store/slices/bankLinking-slice";
import AdyenCardConnect from "./AdyenCardConnect";
import BankLinkConnector from "./BankLinkConnector";
import ConnectBankModal from "./connect/ConnectBankModal";
import BankPicker from "./picker/BankPicker";

type BankLinkingProps = {
  isModal: boolean;
};

const BankLinking: React.VFC<BankLinkingProps> = ({ isModal }) => {
  const dispatch = useDispatch();
  const disableDebit = useCatchFeatureGate("disable-debit");

  useEffect(() => {
    if (disableDebit != null) {
      dispatch(setDisableDebit(disableDebit));
    }

    // Due to a bug if the user is using incognito Chrome, disable debit.
    let privateBrowser = false;
    try {
      localStorage.setItem("catch-local-storage-test", "success");
    } catch (e) {
      privateBrowser = true;
    }

    if (!privateBrowser) {
      localStorage.removeItem("catch-local-storage-test");
    }
  }, [dispatch, disableDebit]);

  return (
    <Box>
      {isModal && <ConnectBankModal />}
      <BankLinkConnector />
      {!disableDebit && <AdyenCardConnect />}
      <BankPicker />
    </Box>
  );
};

export default BankLinking;
export type { BankLinkingProps };
