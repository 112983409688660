import React, { useEffect, useState } from "react";

import { ClickAwayListener, TextField } from "@mui/material";

import { formatUSPhoneNumber } from "../../../utils/phone-number";
import { WrappedTooltip } from "../../tooltips";
import { DropToggleButton } from "../buttons";

const CountrySupportButton: React.VFC = () => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(true);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <WrappedTooltip
          title={
            <>
              We can only support U.S. customers right now — but we&apos;re
              working on it.
            </>
          }
          open={tooltipOpen}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <DropToggleButton onClick={handleTooltipOpen}>🇺🇸</DropToggleButton>
        </WrappedTooltip>
      </div>
    </ClickAwayListener>
  );
};

type PhoneInputProps = {
  value: string;
  updateValue: (value: string) => void;
  helperText?: React.ReactNode;
  error?: boolean;
  errorHelperText?: React.ReactNode;
};

const PhoneInput: React.VFC<PhoneInputProps> = ({
  value,
  updateValue,
  helperText,
  error,
  errorHelperText,
}) => {
  // The label shrink needs to be manually controlled because the
  // start adornment on the input disrupts MUI's default shrinking
  // behavior.
  const [shrink, setShrink] = useState<boolean>(false);

  useEffect(() => {
    value.length && setShrink(true);
  }, [value]);

  return (
    <TextField
      value={value}
      onFocus={() => setShrink(true)}
      onBlur={() => !value.length && setShrink(false)}
      onChange={(e) => updateValue(formatUSPhoneNumber(e.target.value))}
      label="Phone number"
      helperText={error ? errorHelperText || helperText : helperText}
      error={error}
      name="phone"
      type="tel"
      autoComplete="tel"
      autoFocus
      InputLabelProps={{
        shrink,
      }}
      InputProps={{
        startAdornment: <CountrySupportButton />,
      }}
      sx={{
        ".MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
          pl: 11,
          lineHeight: 1.2,
        },
        ".MuiInputBase-input": {
          pl: 1,
        },
      }}
    />
  );
};

PhoneInput.defaultProps = {
  helperText: "Just for login, never spam.",
  error: false,
  errorHelperText: "Please enter a valid U.S. phone number.",
};

export default PhoneInput;
