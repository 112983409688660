import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  GetConsumerActivitiesListResponse,
  useGetConsumerActivities,
} from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { ErrorResponseData } from "~common/services/types/error-handling-types";
import { useTracking } from "~common/tracking";
import { selectUserId } from "~src/store";
import { consumerActivitiesActions } from "~src/store/slices/services/consumerActivities-slice";

const useConsumerActivities = (limit?: number, lastEvaluatedKey?: string) => {
  const { captureException } = useTracking();
  const [consumerActivities, setConsumerActivities] =
    useState<GetConsumerActivitiesListResponse | null>(null);
  const [error, setError] = useState<ErrorResponseData | null>(null);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  const {
    refetch,
    loading,
    error: serviceError,
  } = useGetConsumerActivities({
    pathParams: { userId: userId ?? "" },
    queryParams: { limit, last_evaluated_key: lastEvaluatedKey },
    lazy: true,
  });

  useEffect(() => {
    const refresh = async (): Promise<void> => {
      try {
        const responseData = await refetch();

        if (responseData) {
          setConsumerActivities(responseData);
          dispatch(consumerActivitiesActions.manualSet(responseData));
        }
      } catch (_error) {
        // Restful-react can't properly handle catching
        // errors on lazy GET requests yet. See the useEffect
        // below for handling.
      }
    };

    if (consumerActivities === null) {
      void refresh();
    }
  }, [consumerActivities, refetch, dispatch]);

  // Handle service errors and update error state.
  useEffect(() => {
    let newError;

    if (serviceError !== null) {
      newError = convertRawServiceError(serviceError);
      captureException({
        component: `useGift`,
        exceptionMessage: `Error fetching Gift`,
        rawError: serviceError,
      });
    } else {
      newError = null;
    }

    setError(newError);
  }, [serviceError, captureException]);

  return { consumerActivities, loading, error };
};

export default useConsumerActivities;
