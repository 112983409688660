import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@adyen/adyen-web/dist/types/components/Card";
import { CbObjOnBrand } from "@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types";
import { useMediaQuery, useTheme } from "@mui/material";

import { useBankLinkingOptions } from "../../contexts/BankLinkingContext";
import { useUniqueId } from "../../hooks/accessibility-hooks";
import useInitiateAdyenCardComponent from "../../hooks/bank-linking/useInitiateAdyenCardComponent";
import {
  selectAdyenReloadNeeded,
  selectDisableAch,
} from "../../store/selectors";
import {
  setAdyenReloadNeeded,
  setCardBrand,
  setCardFieldValidity,
  setHideAdyenModal,
  setIsZipCodeValid,
  setShowZipCodeError,
} from "../../store/slices/bankLinking-slice";
import AdyenCardInput from "./AdyenCardInput";
import AdyenCardInputModal from "./AdyenCardInputModal";

type AdyenCardConnectProps = {
  // Inline renders the card input fields only (i.e. not the title,
  // or save button) and assumes the component should fill the parent
  // component's width. Otherwise this component is rendered as it's
  // traditional style within a drawer/dialog.
  inline?: boolean;
};

const AdyenCardConnect: React.VFC<AdyenCardConnectProps> = ({
  inline = false,
}) => {
  const theme = useTheme();
  const matchesDialog = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const { bus } = useBankLinkingOptions();
  const { ready, adyenCheckout, inputsValid } = useInitiateAdyenCardComponent();
  const [isMounting, setIsMounting] = useState<boolean>(false);
  const needsReload = useSelector(selectAdyenReloadNeeded);
  const disableAch = useSelector(selectDisableAch);

  const [adyenCardComponents, setAdyenCardComponents] = useState<Array<Card>>(
    []
  );
  const [isCardComponentMounted, setIsCardComponentMounted] =
    useState<boolean>(false);

  const inlineMountingId = useUniqueId("inline-connect-debit-card");

  useEffect(() => {
    if (needsReload) {
      setIsCardComponentMounted(false);
      dispatch(setCardFieldValidity(null));
    }
  }, [dispatch, needsReload]);

  // The only way we can update the placeholder within the zip code field is to
  // inject the placeholder after the fields have rendered. Additionally, we add
  // custom validation error messaging to the field.
  useEffect(() => {
    const addZipErrorMessage = (zipCodeInput: HTMLInputElement) => {
      dispatch(setShowZipCodeError(true));
      zipCodeInput.classList.add("zipcode-error");
      const zipCodeLabel = zipCodeInput.parentElement?.parentElement
        ?.firstChild as HTMLLabelElement;
      if (zipCodeLabel) {
        zipCodeLabel.classList.add("zipcode-error");
      }
    };

    const updatePlaceholder = (zipCodeInput: HTMLInputElement) => {
      zipCodeInput.placeholder = "Zip code";
      zipCodeInput.required = true;
      zipCodeInput.pattern = "[0-9]{5}";
      // Note we use "tel" here to force the numeric keypad on mobile devices.
      // Using "number" overrode the validation pattern.
      zipCodeInput.type = "tel";
    };

    const onZipCodeBlurEventHandler = (event: FocusEvent) => {
      const zipElements = document.querySelectorAll(
        "input[id^=adyen-checkout-postalCode]"
      );
      for (let i = 0; i < zipElements.length; i += 1) {
        const elem = zipElements[i] as HTMLInputElement;
        if (elem.value.length > 0) {
          if (!elem.validity.valid) {
            addZipErrorMessage(elem);
          } else {
            dispatch(setShowZipCodeError(false));
          }
        }
      }
    };

    const validateZipField = (zipCodeInput: HTMLInputElement) => {
      zipCodeInput.addEventListener("blur", onZipCodeBlurEventHandler);
      zipCodeInput.addEventListener("keyup", (event) => {
        // We determine the zip validity based on the following rules
        // 1. while focused, with only digits from 0-5 in length → valid
        // 2. while focused, any non digits → invalid
        // 3. while focused, any digits longer than 5 → invalid
        // 4. while not focused, anything not a valid zip → invalid
        const zipElements = document.querySelectorAll(
          "input[id^=adyen-checkout-postalCode]"
        );
        let validZipFields = 0;
        for (let i = 0; i < zipElements.length; i += 1) {
          const elem = zipElements[i] as HTMLInputElement;
          if (elem.validity.valid) {
            validZipFields += 1;
          }
          // Store whether to show the zip input error message, but
          // only set the error if it is the correct zipcode element
          if (elem.value.length !== 0) {
            if (
              (!/^\d+$/.test(elem.value) && elem.value.length > 0) ||
              (!elem.validity.valid && elem.value.length >= 5)
            ) {
              addZipErrorMessage(elem);
            } else {
              // If we set the error message via the blur event, don't remove it unless the
              // zip code is currently focused
              const focusedElem = document.activeElement as HTMLInputElement;
              if (focusedElem.name === "postalCode") {
                dispatch(setShowZipCodeError(false));
                elem.classList.remove("zipcode-error");
                const zipCodeLabel = elem.parentElement?.parentElement
                  ?.firstChild as HTMLLabelElement;
                if (zipCodeLabel) {
                  zipCodeLabel.classList.remove("zipcode-error");
                }
              }
            }
          }
        }
        // Store zip field validity
        if (validZipFields > 0) {
          dispatch(setIsZipCodeValid(true));
          dispatch(setShowZipCodeError(false));
        } else {
          dispatch(setIsZipCodeValid(false));
        }
      });
    };

    if (isCardComponentMounted) {
      let numUpdated = 0;
      const zipObserver = new MutationObserver((mutations) => {
        const zipElements = document.querySelectorAll(
          "[id^=adyen-checkout-postalCode]"
        );
        if (zipElements) {
          for (let i = 0; i < zipElements.length; i += 1) {
            updatePlaceholder(zipElements[i] as HTMLInputElement);
            validateZipField(zipElements[i] as HTMLInputElement);
            numUpdated += 1;
          }
          // If we have updated both, disconnect.
          // If we have an inline modal, we expect to update 3.
          let requiredNum = 2;
          if (disableAch) {
            requiredNum = 3;
          }
          if (numUpdated >= requiredNum) {
            zipObserver.disconnect();
          }
        }
      });

      zipObserver.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  }, [disableAch, dispatch, isCardComponentMounted, needsReload]);

  useEffect(() => {
    if (ready && !isCardComponentMounted && !isMounting) {
      // Since we have both a modal and a drawer component, we need to initalize the
      // Adyen component into each. This approach was taken because there is no simple
      // way to utilize a single component, as unmounting and remounting the Adyen checkout
      // when switching from the dialog to the drawer results in existing data being removed
      // First unmount any existing components
      if (needsReload) {
        for (let i = 0; i < adyenCardComponents?.length; i += 1) {
          adyenCardComponents[i].unmount();
          setIsMounting(false);
        }
      }

      // Mount new components, for modal mount one to dialog and one to drawer
      // for inline, mount only to the inline component.
      setIsMounting(true);
      const debitHeaders = document.querySelectorAll(
        `[id^=${inline ? "inline-" : ""}connect-debit-card]`
      );
      const newComponents = [];
      for (let i = 0; i < debitHeaders?.length; i += 1) {
        const mountingElement = debitHeaders[i].nextSibling as HTMLElement;
        const checkout = adyenCheckout
          ?.create("card", {
            onBrand: (data: CbObjOnBrand) => {
              dispatch(setCardBrand(data.brand));
            },
            onLoad: () => {
              setIsMounting(false);
              setIsCardComponentMounted(true);
            },
          })
          .mount(mountingElement);
        if (checkout) {
          newComponents.push(checkout);
        }
      }
      setAdyenCardComponents(newComponents);

      setIsCardComponentMounted(true);
      dispatch(setAdyenReloadNeeded(false));
    }

    const handleUserIdle = () => {
      dispatch(setAdyenReloadNeeded(true));
      dispatch(setHideAdyenModal(true));
      setIsCardComponentMounted(false);
    };

    bus?.on("USER_IDLE", handleUserIdle);
    return () => {
      bus?.off("USER_IDLE", handleUserIdle);
    };

    // We don't want the hook to run when the Adyen components are unmounted.
    // However, we do want it to run when we switch between the modal and the dialog
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adyenCheckout,
    ready,
    matchesDialog,
    needsReload,
    isCardComponentMounted,
  ]);

  return (
    <>
      {inline ? (
        <AdyenCardInput
          headingId={inlineMountingId}
          ready={isCardComponentMounted}
          inputsValid={inputsValid}
          // NB: This field enables removing the button for inline form when needed
          displayButton
          inline
        />
      ) : (
        <AdyenCardInputModal
          ready={isCardComponentMounted}
          inputsValid={inputsValid}
        />
      )}
    </>
  );
};

export default AdyenCardConnect;
