import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";

import { useCatchFeatureGate } from "../../../experimentation/experimentation-hooks";
import {
  selectBankModalOpen,
  selectConnectBankVariant,
  selectDisableAch,
  selectDisableDebit,
  selectIsCatchPassFlow,
  selectPaymentInstrument,
} from "../../../store/selectors";
import {
  closeBankModal,
  ConnectBankVariant,
  openBankModal,
  setBankLinkingStep,
  setConnectBankVariant,
  setHideAdyenModal,
  setIsReconnectingPaymentInstrument,
  setPlaidCustomLinkName,
} from "../../../store/slices/bankLinking-slice";
import { useTracking } from "../../../tracking";
import { BankButton, DefaultButton } from "../../controls/buttons";
import AdyenCardConnect from "../AdyenCardConnect";
import ConnectBankFAQ from "./ConnectBankFAQ";
import ConnectBankTroubleFooter from "./ConnectBankTroubleFooter";
import ConnectPaymentInstrumentButtons from "./ConnectPaymentInstrumentButtons";
import {
  BankHeaderChangeMethod,
  BankHeaderDefault,
  PaymentInstrumentHeaderChangeMethod,
  PaymentInstrumentHeaderDefault,
} from "./headers";
import PaymentInstrumentErrorHeaders from "./headers/payment-instrument/PaymentInstrumentErrorHeaders";

type ConnectBankProps = {
  headingId?: string;
  isModal?: boolean;
  forcedVariant?: ConnectBankVariant;
};

const ConnectBank: React.VFC<ConnectBankProps> = ({
  headingId,
  isModal = false,
  forcedVariant,
}) => {
  const { trackEvent, captureException } = useTracking();
  const paymentInstrument = useSelector(selectPaymentInstrument);
  const dynamicVariant = useSelector(selectConnectBankVariant);
  const modalOpen = useSelector(selectBankModalOpen);
  const disableDebit = useSelector(selectDisableDebit);
  const disableAch = useSelector(selectDisableAch);
  const isCatchPassFlow = useSelector(selectIsCatchPassFlow);
  const turnTellerOff = useCatchFeatureGate("turn-teller-off");
  const variant = forcedVariant ?? dynamicVariant;

  const shouldConnectBankBeVisible = !isModal || modalOpen;

  const dispatch = useDispatch();

  const handleContinueButtonClick = () => () => {
    dispatch(closeBankModal());

    if (disableAch && !paymentInstrument) {
      handleReturnToInlineFlowContinueClick("DisableAch");
    } else if (disableAch && paymentInstrument) {
      handleCardClick();
    }
  };

  const handleReturnToInlineFlowContinueClick = (component: string) => () => {
    trackEvent("Continue clicked", {
      component: `${component} ConnectBank Continue Button`,
      variant,
    });
  };

  const handleBankClick = (eventName: string, noReconnect?: boolean) => () => {
    if (variant === "Reconnect" && !noReconnect) {
      // If Teller is turned off we can't reconnect a Teller bank
      // so open the try again modal instead.
      if (
        turnTellerOff &&
        paymentInstrument?.source === "teller_instant_auth"
      ) {
        dispatch(setConnectBankVariant("Retry"));
        dispatch(openBankModal());
      } else {
        dispatch(setIsReconnectingPaymentInstrument(true));
        dispatch(
          setBankLinkingStep(
            paymentInstrument?.source === "teller_instant_auth"
              ? "TellerLogin"
              : "PlaidLogin"
          )
        );
      }
    } else if (turnTellerOff) {
      // If Teller is off, open Plaid in search mode.
      dispatch(setPlaidCustomLinkName("default"));
      dispatch(setBankLinkingStep("PlaidLogin"));
    } else {
      dispatch(setBankLinkingStep("BankPicker"));
    }

    trackEvent(`${eventName} clicked`, {
      component: "ConnectBank Button",
      variant,
    });
  };

  const handleCardClick = () => {
    dispatch(setHideAdyenModal(false));
    dispatch(setBankLinkingStep("AdyenConnect"));
  };

  const handleOpenChangeMethod = () => {
    dispatch(setConnectBankVariant("ChangeMethod"));
    dispatch(openBankModal());
  };

  // Track whenever the user is seeing a new or updated variant
  // of ConnectBank, e.g:
  //  - When they open the bank modal or get sent to the connect bank step.
  //  - When they load a page with an in-line connect bank prompt.
  //  - When a visible connect bank prompt changes variant.
  useEffect(() => {
    if (shouldConnectBankBeVisible) {
      if (variant === "Retry") {
        captureException({
          component: "ConnectBank",
          exceptionMessage: "Bank Link Try Again shown",
          rawError: "N/A",
        });
      }
      trackEvent("ConnectBank variant shown", {
        variant,
      });
    }
  }, [shouldConnectBankBeVisible, trackEvent, captureException, variant]);

  let bankButtonCopy = "Log in securely";
  let cardButtonCopy = "Link a debit card";
  // Backwards compatible for our existing graphs etc:
  let bankButtonEventName = "Log in to my bank";

  if (variant === "ChangeMethod") {
    bankButtonCopy = "Connect a new bank";
    bankButtonEventName = bankButtonCopy;
    cardButtonCopy = "Link a debit card";
  } else if (
    variant === "ErrorInsufficientFunds" ||
    variant === "ErrorExistingAccount"
  ) {
    bankButtonCopy = "Connect a different account";
    bankButtonEventName = bankButtonCopy;
  } else if (variant === "Retry") {
    bankButtonCopy = "Try logging in again";
    bankButtonEventName = bankButtonCopy;
    cardButtonCopy = "Try linking again";
  } else if (
    variant === "ErrorCardCannotConnect" ||
    variant === "ErrorUnsupportedCardBrand" ||
    variant === "ErrorExpiredLinkedCard"
  ) {
    cardButtonCopy = "Link a new debit card";
  } else if (variant === "ErrorCardVerificationFailed") {
    cardButtonCopy = "Try linking again";
  }

  let secondaryBankButtonCopy = "Connect a different account";
  if (!disableDebit) {
    secondaryBankButtonCopy = "Change payment method";
  }

  return (
    <Stack
      sx={[
        ({ breakpoints }) => ({
          gap: 8,
          position: "relative",
          textAlign: "left",
          ".BankLinking-header": {
            textAlign: "inherit",
            alignItems: "flex-start",
            "& > h1": {
              typography: "h1",
            },
          },
          ".BankLinking-iconHeader": {
            mt: -12,
            [breakpoints.down("md")]: {
              mt: 0,
            },
          },
        }),
        isModal && {
          gap: 6,
          ".BankLinking-header": {
            textAlign: "center",
            alignItems: "center",
            "& > h1": {
              typography: "h2",
            },
          },
          ".BankLinking-connectBankFAQ": {
            display: "none",
          },
          ".BankLinking-iconHeader": {
            mt: 0,
          },
        },
      ]}
    >
      <Stack spacing={4}>
        {variant === "Default" && disableDebit && (
          <BankHeaderDefault headingId={headingId} />
        )}

        {variant === "Default" && !disableDebit && (
          <PaymentInstrumentHeaderDefault
            headingId={headingId}
            isModal={isModal}
          />
        )}

        {variant === "ChangeMethod" && disableDebit && (
          <BankHeaderChangeMethod headingId={headingId} />
        )}

        {variant === "ChangeMethod" && !disableDebit && (
          <PaymentInstrumentHeaderChangeMethod headingId={headingId} />
        )}

        {variant !== "Default" && variant !== "ChangeMethod" && (
          <PaymentInstrumentErrorHeaders
            variant={variant}
            headingId={headingId}
          />
        )}
      </Stack>

      <Stack spacing={4}>
        {/* In the case of ACH being turned off, the modal prior to the "Complete purchase" page
            should have a continue button that returns to the inline experience. */}
        {disableAch && isModal && variant !== "ErrorPushToDebit" ? (
          <BankButton onClick={handleContinueButtonClick()}>
            {isCatchPassFlow ? "Try again" : "Continue"}
          </BankButton>
        ) : (
          <>
            {!disableDebit && variant !== "Reconnect" ? (
              <>
                {disableAch ? (
                  <>
                    {!paymentInstrument ? (
                      // Debit only experience (inline)
                      <AdyenCardConnect inline />
                    ) : (
                      // Debit only experience (not inline)
                      <BankButton onClick={handleCardClick}>
                        {cardButtonCopy}
                      </BankButton>
                    )}
                  </>
                ) : (
                  // Traditional flow with bank and debit
                  <ConnectPaymentInstrumentButtons
                    isModal={isModal}
                    variant={variant}
                    cardButtonCopy={cardButtonCopy}
                    bankButtonEventName={bankButtonEventName}
                    handleBankClick={handleBankClick}
                    handleCardClick={handleCardClick}
                  />
                )}
              </>
            ) : (
              <>
                {/* Bank experience only */}
                <BankButton
                  onClick={handleBankClick(bankButtonEventName)}
                  startIcon={<LockIcon />}
                >
                  {bankButtonCopy}
                </BankButton>

                {variant === "Reconnect" && (
                  <DefaultButton
                    onClick={
                      disableDebit
                        ? handleBankClick(secondaryBankButtonCopy, true)
                        : handleOpenChangeMethod
                    }
                    startIcon={!disableDebit && <LockIcon />}
                  >
                    {secondaryBankButtonCopy}
                  </DefaultButton>
                )}
              </>
            )}
          </>
        )}
      </Stack>

      {disableDebit && (
        <>
          <ConnectBankFAQ /> <ConnectBankTroubleFooter />
        </>
      )}
    </Stack>
  );
};

export default ConnectBank;
