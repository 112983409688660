import { Components, Theme } from "@mui/material";

const buildLinearProgress = (theme: Theme): Components["MuiLinearProgress"] => {
  const { palette, shape } = theme;

  return {
    styleOverrides: {
      root: {
        backgroundColor: palette.grey[200],
        "&:not([aria-valuenow='100']) .MuiLinearProgress-bar1Determinate": {
          borderRadius: `0 ${shape.borderRadius / 2}px ${
            shape.borderRadius / 2
          }px 0`,
        },
      },
    },
  };
};

export default buildLinearProgress;
