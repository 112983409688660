import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";

import AnimatedPiggyBankFallback from "./AnimatedPiggyBankFallback";

const AnimatedPiggyBankLottie = lazy(() => import("./AnimatedPiggyBankLottie"));

const AnimatedPiggyBank: React.VFC = () => (
  <Suspense
    fallback={
      <Box style={{ width: 164, height: 164, margin: -22 }}>
        <AnimatedPiggyBankFallback />
      </Box>
    }
  >
    <AnimatedPiggyBankLottie />
  </Suspense>
);

export default AnimatedPiggyBank;
