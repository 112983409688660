// Inputs for Date
type DateValue = string | number | Date;

// Helper to convert datetime string to a string representation of how many months from now it will expire
const getExpMonths = (date: DateValue): string => {
  const timeDiff = getTimeDiff(date);
  // Expiration windows are multiples of 30 days. Convert expiration from milliseconds to multiples of 30 days.
  const monthDiff = Math.round(timeDiff / 1000 / 60 / 60 / 24 / 30);

  if (monthDiff <= 1) {
    return "1 month";
  }

  return `${monthDiff} months`;
};

// Helper to convert datetime string to a string representation of how many days from now it will expire
const getExpDays = (date: DateValue): string => {
  const timeDiff = getTimeDiff(date);
  const days = Math.round(timeDiff / (1000 * 3600 * 24));

  if (days === 0) {
    return "Ends today";
  }

  if (days === 1) {
    return "Ends tomorrow";
  }

  return `Ends in ${days} days`;
};

type FormatDateOptions = {
  year: "2-digit" | "numeric";
  month: "2-digit" | "long" | "narrow" | "short" | "numeric";
  day: "2-digit" | "numeric";
};

const formatUSDate = (
  date: DateValue,
  formatDateOptions: FormatDateOptions = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  }
): string => new Date(date).toLocaleDateString("en-US", formatDateOptions);

const offsetDate = (date: DateValue, offsetDays: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + offsetDays);

  return newDate;
};

// Shared logic for formatting expiration dates - always shift -1 days.
const formatExpirationDate = (
  date: DateValue,
  formatDateOptions?: FormatDateOptions
): string => formatUSDate(offsetDate(date, -1), formatDateOptions);

const getTodaysUSDate = (): string =>
  formatUSDate(new Date(Date.now()).toDateString(), {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

const getTimeDiff = (date: DateValue): number => {
  const expDate = new Date(date);
  const today = new Date();

  return expDate.getTime() - today.getTime();
};

const formatNumDays = (days: number): string => {
  // This function will convert 2 or fewer days to the correct number of hours.
  // If the number of days is divisible by 7, it will convert it to the correct number of weeks instead.
  if (days <= 2) {
    return `${days * 24} hours`;
  }

  if (days % 7 === 0) {
    const weeks = days / 7;
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  }

  return `${days} day${days > 1 ? "s" : ""}`;
};

export {
  formatExpirationDate,
  formatNumDays,
  formatUSDate,
  getExpDays,
  getExpMonths,
  getTodaysUSDate,
  offsetDate,
};
