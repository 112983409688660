import React, { useState } from "react";
import { useSelector } from "react-redux";

import { GenericServiceErrorAlert } from "~common/components/alerts";
import ResendEmailVerification from "~common/components/auth/ResendEmailVerification";
import WrongEmailHelp from "~common/components/auth/WrongEmailHelp";
import { EmailIcon } from "~common/components/icons/vector";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking, useTrackPageView } from "~common/tracking";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import useClaimRewards from "~src/hooks/useClaimRewards";
import useSendEmailCode from "~src/hooks/useSendEmailCode";
import { selectAuthUserInfo } from "~src/store";

import EmailLimitExceededAlert from "../../components/auth/EmailLimitExceededAlert";

const VerifyEmail: React.VFC = () => {
  useTrackPageView("Verify Email");

  const { trackEvent } = useTracking();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const sendCode = useSendEmailCode();

  const [hasServiceError, setHasServiceError] = useState<boolean>(false);
  const [emailLimitExceeded, setEmailLimitExceeded] = useState<boolean>(false);
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);

  // Claim any new rewards the user has claimed through links.
  useClaimRewards();

  const handleResendVerification = async (): Promise<void> => {
    trackEvent("Resend Email Clicked", {
      component: "VerifyEmail",
    });
    setIsCodeResent(true);
    try {
      await sendCode({ cause: "Resend requested" });
    } catch (rawError) {
      const error = convertRawServiceError(rawError);

      if (!error.status || error.status >= 500 || error.status === 400) {
        if (error.error_type === "LIMIT_EXCEEDED") {
          // Do not alert Sentry here, but do track the event and show an alert
          // to the user
          trackEvent("Resend Verify Email Limit Exceeded", {
            component: "VerifyEmail",
          });
          setEmailLimitExceeded(true);
          return;
        }

        setHasServiceError(true);
        setIsCodeResent(false);
      }
    }
  };

  return (
    <SmallPagePanel
      icon={<EmailIcon size="small" />}
      title="Let's verify your email, just to be safe"
      subtitle={`Click the link sent to ${
        authUserInfo?.email || "your email"
      }.`}
      footer={<WrongEmailHelp />}
      centered
    >
      {hasServiceError && <GenericServiceErrorAlert />}
      {emailLimitExceeded && <EmailLimitExceededAlert />}

      <ResendEmailVerification
        handleResendVerification={handleResendVerification}
        isCodeResent={isCodeResent}
        centered
      />
    </SmallPagePanel>
  );
};

export default VerifyEmail;
