import { createSelector } from "@reduxjs/toolkit";

import { Reward } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectUserRewards = makeFetchSelectors<Reward[], RootState>(
  "userRewards"
);

const selectBoostedRewards = createSelector(
  (state: RootState) => state.userRewards.data,
  (userRewards) =>
    userRewards.filter((reward) => !!reward.associated_reward_boost)
);

const selectRewardBoosts = createSelector(
  selectBoostedRewards,
  (boostedRewards) =>
    boostedRewards.reduce((obj, boostedReward) => {
      const { associated_reward_boost: rewardBoost } = boostedReward;
      if (rewardBoost) {
        const { id: rewardBoostId } = rewardBoost;
        return { ...obj, [rewardBoostId]: rewardBoost };
      }

      return { ...obj };
    }, {})
);

export { selectBoostedRewards, selectRewardBoosts, selectUserRewards };
