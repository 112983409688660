import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { rewardPoolEntriesActions } from "~src/store/slices/services/rewardPoolEntries-slice";
import { rewardPoolEventsActions } from "~src/store/slices/services/rewardPoolEvents-slice";
import { rewardPoolsActions } from "~src/store/slices/services/rewardPools-slice";
import { userRewardsActions } from "~src/store/slices/services/userRewards-slice";

/**
 * A hook that returns a function for refreshing all of
 * the data relevant to the credit exchange section.
 */
const useRefreshCreditExchange = () => {
  const dispatch = useDispatch();

  const refreshCreditExchange = useCallback(() => {
    dispatch(userRewardsActions.setStatus("requested"));
    dispatch(rewardPoolsActions.setStatus("requested"));
    dispatch(rewardPoolEntriesActions.setStatus("requested"));
    dispatch(rewardPoolEventsActions.setStatus("requested"));
  }, [dispatch]);

  return refreshCreditExchange;
};

export default useRefreshCreditExchange;
