import React from "react";

import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import ExternalTextLink from "../../../../links/ExternalTextLink";
import SummaryListItem from "./SummaryListItem";

const YourInfoIsEncrypted: React.VFC = () => (
  <SummaryListItem icon={<VisibilityOffIcon />}>
    <Typography variant="h3">Your info is never visible to us.</Typography>
    <Typography paragraph variant="bodySmall" color="grey.600">
      {"Encrypted by "}
      <ExternalTextLink
        color="grey.600"
        size="small"
        href="https://plaid.com/how-it-works-for-consumers/"
        eventName="Plaid Outgoing Link Clicked"
      >
        Plaid
      </ExternalTextLink>
      {" or "}
      <ExternalTextLink
        color="grey.600"
        size="small"
        href="https://teller.io/legal#end-user-terms-of-service"
        eventName="Teller Outgoing Link Clicked"
      >
        Teller
      </ExternalTextLink>
      , just like Venmo or Cash App.
    </Typography>
  </SummaryListItem>
);

export default YourInfoIsEncrypted;
