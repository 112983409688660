import { capitalize } from "lodash-es";

// The last 7 chars of the merchant ID are a hyphen and 6 random chars
const NUM_CHARACTERS_MERCHANT_ID_SUFFIX = 7;

/**
 * A function that takes in a merchant ID and extracts its name from it.
 * Since the structure of the merchant IDs is their name all lowercase
 * with hyphens for spaces followed by a 6 digit random string, we can
 * extract the actual name from though the casing and special characters
 * may be a tiny bit off.
 */
const merchantNameFromId = (merchantId: string): string =>
  merchantId
    .slice(0, merchantId.length - NUM_CHARACTERS_MERCHANT_ID_SUFFIX)
    .split("-")
    .map((str) => capitalize(str))
    .join(" ");

export { merchantNameFromId };
