// GENERATED FILE. DO NOT EDIT.

import React from "react";

import MediumSVG from "../vector-source/MeditatingCoin.medium.svg";
import SmallSVG from "../vector-source/MeditatingCoin.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const MeditatingCoinIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} mediumSvg={MediumSVG} {...props} />
);

export default MeditatingCoinIcon;
