import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  ClaimGiftPathParams,
  ClaimGiftResponseData,
  CreateGiftSecretRequestData,
  CreateGiftSecretResponseData,
  GetGiftByHashedSecretPathParams,
  GetGiftPathParams,
  GiftResponseData,
} from "./types/gifts-types";

const useCreateGiftSecret = () =>
  useMutate<
    CreateGiftSecretResponseData,
    ErrorResponseData,
    undefined,
    CreateGiftSecretRequestData,
    unknown
  >({
    verb: "POST",
    path: `/transactions-svc/gifts`,
  });

const useGetGift = (
  options: { pathParams?: GetGiftPathParams; lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<GiftResponseData, ErrorResponseData, undefined, GetGiftPathParams>({
    path: ({ giftSecret }) => `/transactions-svc/gifts/${giftSecret}`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

const useGetGiftByHashedSecret = (
  options: { pathParams?: GetGiftByHashedSecretPathParams; lazy?: boolean } = {
    lazy: false,
  }
) =>
  useGet<
    GiftResponseData,
    ErrorResponseData,
    undefined,
    GetGiftByHashedSecretPathParams
  >({
    path: ({ hashedGiftSecret }) =>
      `transactions-svc/gifts/hashed_secret/${hashedGiftSecret}`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

const useClaimGift = () =>
  useMutate<
    ClaimGiftResponseData,
    ErrorResponseData,
    undefined,
    undefined,
    ClaimGiftPathParams
  >({
    verb: "PUT",
    path: ({ giftSecret }) => `transactions-svc/gifts/${giftSecret}`,
  });

export {
  useClaimGift,
  useCreateGiftSecret,
  useGetGift,
  useGetGiftByHashedSecret,
};
