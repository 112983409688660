import React from "react";

import { Divider, Stack } from "@mui/material";

import LoadingGuard from "~common/components/loading/LoadingGuard";
import { formatUSPhoneNumber } from "~common/utils/phone-number";
import LabeledSetting from "~src/components/account/LabeledSetting";
import LabeledSection from "~src/components/layout/LabeledSection";
import useCurrentUser from "~src/hooks/services/useCurrentUser";

import AccountSettingsAddressRow from "./AccountSettingsAddressRow";
import AccountSettingsBirthdayRow from "./AccountSettingsBirthdayRow";
import AccountSettingsNameRow from "./AccountSettingsNameRow";

const AccountSettingsSection: React.VFC = () => {
  const { currentUser, error } = useCurrentUser();

  const phoneNumber = formatUSPhoneNumber(currentUser?.phone_number ?? "");
  const email = currentUser?.email;

  return (
    <LabeledSection heading="Settings">
      <LoadingGuard {...{ error }}>
        <Stack>
          <AccountSettingsNameRow />
          <Divider />
          <LabeledSetting
            label="Phone number"
            value={phoneNumber}
            loading={!currentUser}
          />
          <Divider />
          <LabeledSetting label="E-mail" value={email} loading={!currentUser} />
          <Divider />

          {currentUser?.is_catch_superfan && (
            <>
              <AccountSettingsAddressRow />
              <Divider />
              <AccountSettingsBirthdayRow />
              <Divider />
            </>
          )}
        </Stack>
      </LoadingGuard>
    </LabeledSection>
  );
};

export default AccountSettingsSection;
