import bankOfAmerica from "../assets/icons/banks/bank_of_america.png";
import bankOfAmericaNaturalAspect from "../assets/icons/banks/bank_of_america_natural.png";
import bbva from "../assets/icons/banks/bbva.png";
import brex from "../assets/icons/banks/brex.png";
import capitalOne from "../assets/icons/banks/capital_one.png";
import capitalOneNaturalAspect from "../assets/icons/banks/capital_one_natural.png";
import chase from "../assets/icons/banks/chase.png";
import chaseNaturalAspect from "../assets/icons/banks/chase_natural.png";
import citiBank from "../assets/icons/banks/citibank.png";
import citiBankNaturalAspect from "../assets/icons/banks/citibank_natural.png";
import pnc from "../assets/icons/banks/pnc.png";
import pncNaturalAspect from "../assets/icons/banks/pnc_natural.png";
import tdBank from "../assets/icons/banks/td_bank.png";
import tdBankNaturalAspect from "../assets/icons/banks/td_bank_natural.png";
import usBank from "../assets/icons/banks/us_bank.png";
import usBankNaturalAspect from "../assets/icons/banks/us_bank_natural.png";
import usaa from "../assets/icons/banks/usaa.png";
import wellsFargo from "../assets/icons/banks/wells_fargo.png";
import wellsFargoNaturalAspect from "../assets/icons/banks/wells_fargo_natural.png";

type LinkingSource = "plaid" | "teller";

type Bank = {
  name: string;
  bank_connector: LinkingSource;
  // Only on Plaid items, a custom string defined in the Plaid customization dashboard that allows
  // us to only show a specific institution (as opposed to full search)
  plaid_custom_link_name?: string;
  // Only on Teller items, needed to open TellerConnect directly to a specific institution
  teller_institution_id?: string;
  position_number: number;
  logo: string;
  // We use natural aspect ratio for the square bank picker buttons as needed
  logo_natural_aspect: string;
};

const pickerBanks: Array<Bank> = [
  {
    name: "Chase",
    bank_connector: "teller",
    teller_institution_id: "chase",
    position_number: 1,
    logo: String(chase),
    logo_natural_aspect: String(chaseNaturalAspect),
  },
  {
    name: "Bank of America",
    bank_connector: "teller",
    teller_institution_id: "bank_of_america",
    position_number: 2,
    logo: String(bankOfAmerica),
    logo_natural_aspect: String(bankOfAmericaNaturalAspect),
  },
  {
    name: "CapitalOne",
    bank_connector: "teller",
    teller_institution_id: "capital_one",
    position_number: 3,
    logo: String(capitalOne),
    logo_natural_aspect: String(capitalOneNaturalAspect),
  },
  {
    name: "Wells Fargo",
    bank_connector: "teller",
    teller_institution_id: "wells_fargo",
    position_number: 4,
    logo: String(wellsFargo),
    logo_natural_aspect: String(wellsFargoNaturalAspect),
  },
  {
    name: "PNC",
    bank_connector: "teller",
    teller_institution_id: "pnc",
    position_number: 5,
    logo: String(pnc),
    logo_natural_aspect: String(pncNaturalAspect),
  },
  {
    name: "U.S. Bank",
    bank_connector: "teller",
    teller_institution_id: "us_bank",
    position_number: 6,
    logo: String(usBank),
    logo_natural_aspect: String(usBankNaturalAspect),
  },
  {
    name: "TD Bank",
    bank_connector: "teller",
    // Note: 0 removes it from the modal
    teller_institution_id: "td_bank",
    position_number: 0,
    logo: String(tdBank),
    logo_natural_aspect: String(tdBankNaturalAspect),
  },
  {
    name: "Citibank",
    bank_connector: "teller",
    teller_institution_id: "citibank",
    position_number: 8,
    logo: String(citiBank),
    logo_natural_aspect: String(citiBankNaturalAspect),
  },
  {
    name: "USAA",
    bank_connector: "teller",
    teller_institution_id: "usaa",
    position_number: 7,
    logo: String(usaa),
    logo_natural_aspect: String(usaa),
  },
  {
    // Use the following bank to test the FORBIDDEN_BANK_ACCOUNT_IN_USE service error
    // with Teller in dev
    name: "BBVA",
    bank_connector: "teller",
    teller_institution_id: "bbva",
    position_number: 0,
    logo: String(bbva),
    logo_natural_aspect: String(bbva),
  },
  {
    // Use the following bank to test the FORBIDDEN_INVALID_BANK_ACCOUNT service error
    // with Teller in dev
    name: "Brex",
    bank_connector: "teller",
    teller_institution_id: "brex",
    position_number: 0,
    logo: String(brex),
    logo_natural_aspect: String(brex),
  },
];

export default pickerBanks;
export type { Bank, LinkingSource };
