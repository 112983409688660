import React from "react";

import { Divider, Stack } from "@mui/material";

import { TextButton } from "../controls/buttons";
import CompletionTextMarker from "../markers/CompletionTextMarker";

// TODO: This component is currently duplicated within
//       checkout-v2/src/components/steps/answer-otp/AnswerOTP.tsx
//       and should be used in Checkout as well to share common code.

type PinInputStatusBarProps = {
  isProcessing: boolean;
  isVerified: boolean;
  isCodeResent: boolean;
  handleResendCodeClick: () => void;
  handleUseDifferentNumberClick: () => void;
};

const PinInputStatusBarContents: React.VFC<PinInputStatusBarProps> = ({
  isProcessing,
  isVerified,
  isCodeResent,
  handleResendCodeClick,
  handleUseDifferentNumberClick,
}) => {
  if (isProcessing) {
    return (
      <CompletionTextMarker loading>Verifying&hellip;</CompletionTextMarker>
    );
  }
  if (isVerified) {
    return <CompletionTextMarker primary>Success</CompletionTextMarker>;
  }
  return (
    <>
      {isCodeResent ? (
        <CompletionTextMarker>Sent</CompletionTextMarker>
      ) : (
        <TextButton onClick={handleResendCodeClick} sx={{ ml: -1 }}>
          Resend code
        </TextButton>
      )}
      <Divider orientation="vertical" flexItem />
      <TextButton onClick={handleUseDifferentNumberClick}>
        Use a different number
      </TextButton>
    </>
  );
};

// Note: Putting the processing and verified states in the same
//       Stack helps with vertical alignment and height as state changes.

const PinInputStatusBar: React.VFC<PinInputStatusBarProps> = (props) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      minHeight: 24,
    }}
  >
    <PinInputStatusBarContents {...props} />
  </Stack>
);

export default PinInputStatusBar;
