import React from "react";

import { Lock as LockIcon } from "@mui/icons-material";

import CatchCard from "~common/components/cards/CatchCard";
import { DefaultButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";

import InfoCard from "../shared/InfoCard";

type CatchCardPinCardProps = {
  onClick: () => void;
};

const CatchCardPinCard: React.VFC<CatchCardPinCardProps> = ({ onClick }) => {
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent("Catch Card Set your PIN clicked");
    onClick();
  };

  return (
    <InfoCard
      heading="Finish setting up your Catch card"
      cta={
        <DefaultButton startIcon={<LockIcon />} onClick={handleClick}>
          Set your PIN
        </DefaultButton>
      }
    >
      <CatchCard height={150} width={238} />
    </InfoCard>
  );
};

export default CatchCardPinCard;
