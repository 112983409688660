import React from "react";

const SVG: React.VFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    aria-label="Catch Cash"
  >
    <path
      d="M6.665 13.665a6.665 6.665 0 1 0 0-13.33 6.665 6.665 0 0 0 0 13.33ZM4.93 3.235a.9.9 0 0 1 .686-1.071l.15-.033a.9.9 0 0 1 1.073.686l.01.05c1.8-.011 2.934.984 3.433 2.096a.635.635 0 0 1-.347.844l-.72.298c-.36.15-.725-.049-.88-.335-.256-.478-.768-.913-1.72-.83-.48.042-.955.258-1.29.637-.333.375-.55.931-.482 1.696.062.712.356 1.217.746 1.535.393.32.903.466 1.423.42.934-.081 1.395-.634 1.586-1.167.107-.298.424-.541.793-.464l.758.159c.334.07.591.403.494.776a3.665 3.665 0 0 1-2.065 2.426l.018.08a.9.9 0 0 1-.687 1.071l-.15.033a.9.9 0 0 1-1.072-.686l-.022-.097a.938.938 0 0 1-.008-.04c-2.03-.062-3.766-1.498-3.973-3.857-.17-1.943.826-3.458 2.26-4.161l-.014-.065Zm.51.21c-1.504.56-2.598 2.036-2.427 3.988.19 2.18 1.78 3.497 3.649 3.556-1.87-.059-3.458-1.376-3.65-3.556-.17-1.952.924-3.427 2.428-3.987Zm3.469 4.728ZM6.586 4.612c1.095-.096 1.726.418 2.04 1.002.086.16.271.251.44.195-.169.056-.354-.035-.44-.195-.314-.584-.945-1.098-2.04-1.002Z"
      fill="currentColor"
    />
  </svg>
);

export default SVG;
