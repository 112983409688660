import { useGetPaymentInstruments } from "~common/services/banking";
import { paymentInstrumentsActions } from "~common/store/slices/paymentInstruments-slice";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectPaymentInstruments } from "~src/store";

const useGet = () => useGetPaymentInstruments({ lazy: true });

const useFetchPaymentInstruments = () => {
  useLazyFetch(
    "PaymentInstruments",
    paymentInstrumentsActions,
    selectPaymentInstruments,
    useGet,
    (data) => data.payment_instruments
  );
};

export default useFetchPaymentInstruments;
