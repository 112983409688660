import React, { useState } from "react";

import { Box } from "@mui/material";

import {
  EMPTY_FIRST_NAME_MESSAGE,
  EMPTY_LAST_NAME_MESSAGE,
} from "~common/utils/names";
import LabeledSetting from "~src/components/account/LabeledSetting";
import useCurrentUser from "~src/hooks/services/useCurrentUser";

import AccountSettingsEditForm from "./AccountSettingsEditForm";

const label = "Name";

const AccountSettingsNameRow: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleValidate = (values: {
    first_name?: string;
    last_name?: string;
  }) => {
    if (!values.first_name || !values.last_name) {
      return {
        ...(!values.first_name && {
          first_name: EMPTY_FIRST_NAME_MESSAGE,
        }),
        ...(!values.last_name && {
          last_name: EMPTY_LAST_NAME_MESSAGE,
        }),
      };
    }

    return null;
  };

  return (
    <Box sx={{ mt: isEditMode ? -2 : -1 }}>
      {isEditMode ? (
        <>
          <AccountSettingsEditForm
            label={label}
            fields={[
              {
                label: "First name",
                name: "first_name",
              },
              {
                label: "Last name",
                name: "last_name",
              },
            ]}
            onValidate={handleValidate}
            onClose={() => setIsEditMode(false)}
          />
        </>
      ) : (
        <LabeledSetting
          label={label}
          value={currentUser?.legal_name}
          loading={!currentUser}
          onEditClick={() => setIsEditMode(true)}
        />
      )}
    </Box>
  );
};

export default AccountSettingsNameRow;
