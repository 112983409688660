import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { FormHelperText, Stack, TextField } from "@mui/material";

import DefaultButton from "~common/components/controls/buttons/DefaultButton";
import PrimaryButton from "~common/components/controls/buttons/PrimaryButton";
import type { UserResponseData } from "~common/services";
import { useUpdateCurrentUser } from "~common/services/users";
import { useTracking } from "~common/tracking";
import { getValidName } from "~common/utils/names";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { setAuthState } from "~src/store/slices/user-slice";

type FieldName = "first_name" | "last_name" | "mailing_address";
type Fields = { [key in FieldName]?: string };

type AccountSettingsEditFormProps = {
  label: string;
  fields: {
    label: string;
    name: FieldName;
  }[];
  onValidate: (values: Fields) => Fields | null;
  onClose: () => void;
};

const AccountSettingsEditForm: React.VFC<AccountSettingsEditFormProps> = ({
  label,
  fields,
  onValidate,
  onClose,
}) => {
  const { trackEvent, trackError } = useTracking();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { mutate: updateCurrentUser } = useUpdateCurrentUser();
  const [values, setValues] = useState<Fields>({});
  const [errors, setErrors] = useState<Fields | null>(null);
  const [hasGenericError, setHasGenericError] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setValues(
        fields.reduce((obj, field) => {
          obj[field.name] = currentUser?.[field.name];
          return obj;
        }, {} as Fields)
      );
    }
  }, [currentUser, fields]);

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!currentUser?.email) {
      return;
    }

    const trimmedValues = Object.keys(values).reduce((obj, key) => {
      obj[key as FieldName] = getValidName(values[key as FieldName] || "");
      return obj;
    }, {} as Fields);

    const validationErrors = onValidate(trimmedValues);

    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    trackEvent(`Save New ${label}`);

    try {
      await updateCurrentUser({
        email: currentUser?.email,
        ...trimmedValues,
      }).then((userResponseData: UserResponseData) => {
        dispatch(currentUserActions.manualSet(userResponseData));

        // Updates relevant app state to ensure correct data present
        // in API calls e.g. for claiming.
        if (userResponseData.refreshed_auth_info) {
          dispatch(setAuthState(userResponseData.refreshed_auth_info));
        }
      });

      handleClose();
    } catch (error) {
      trackError(`Edit ${label}`, "Updating current user", { error });
      setValues(trimmedValues);
      setHasGenericError(true);
    }
  };

  const handleChange = (name: FieldName, value: string) => {
    setValues({
      ...values,
      [name]: value,
    });

    setErrors(null);
    setHasGenericError(false);
  };

  const handleClose = () => {
    setValues({});
    setErrors(null);
    setHasGenericError(false);
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Escape") {
      handleClose();
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSave}
      onKeyDown={handleKeyDown}
      gap={4}
      sx={{ py: 4, width: "100%" }}
    >
      {fields.map((field, i) => (
        <TextField
          key={field.name}
          label={field.label}
          value={values?.[field.name] || ""}
          error={!!errors?.[field.name]}
          helperText={errors?.[field.name]}
          onChange={(e) => handleChange(field.name, e.target.value)}
          fullWidth
          autoFocus={i === 0}
        />
      ))}

      {hasGenericError && (
        <FormHelperText error sx={{ mt: 0 }}>
          Something didn&apos;t work as expected. Please try again.
        </FormHelperText>
      )}

      <Stack direction="row" gap={4}>
        <PrimaryButton
          size="small"
          type="submit"
          sx={{
            minWidth: 100,
          }}
        >
          Save
        </PrimaryButton>

        <DefaultButton
          size="small"
          onClick={handleClose}
          sx={{
            minWidth: 100,
          }}
        >
          Cancel
        </DefaultButton>
      </Stack>
    </Stack>
  );
};

export default AccountSettingsEditForm;
