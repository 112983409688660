import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useHideIntercom from "../../../hooks/useHideIntercom";
import useMatchesMedia from "../../../hooks/useMatchesMedia";
import { selectBankLinkingStep } from "../../../store/selectors";
import {
  closeBankModal,
  setBankLinkingStep,
} from "../../../store/slices/bankLinking-slice";
import { useTracking } from "../../../tracking";
import { ResponsiveDrawerDialog } from "../../modals";
import BankPickerPanel from "./BankPickerPanel";

const BankPicker: React.VFC = () => {
  const { trackEvent } = useTracking();
  const isMobile = useMatchesMedia();

  const dispatch = useDispatch();

  const step = useSelector(selectBankLinkingStep);

  const isBankPickerOpen = step === "BankPicker";

  useHideIntercom(isBankPickerOpen && isMobile);

  const handleClose = () => {
    trackEvent("Bank Picker Closed");
    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(closeBankModal());
  };

  useEffect(() => {
    if (isBankPickerOpen) {
      trackEvent("Opened Bank Picker");
      dispatch(setBankLinkingStep("BankPicker"));
      dispatch(closeBankModal());
    }
  }, [isBankPickerOpen, dispatch, trackEvent]);

  return (
    <>
      <ResponsiveDrawerDialog
        name="bank-picker"
        onClose={handleClose}
        open={isBankPickerOpen}
        sx={{
          ".container": {
            maxWidth: "375px",
          },
          ".MuiDialog-paper": {
            width: "375px",
            pb: 6,
          },
        }}
      >
        {({ headingId }) => <BankPickerPanel headingId={headingId} />}
      </ResponsiveDrawerDialog>
    </>
  );
};

export default BankPicker;
