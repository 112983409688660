import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery, useTheme } from "@mui/material";

import {
  selectConnectBankVariant,
  selectDisableAch,
  selectHideAdyenModal,
  selectIsZipCodeValid,
} from "../../store/selectors";
import {
  closeBankModal,
  setAdyenReloadNeeded,
  setBankLinkingStep,
  setHideAdyenModal,
  setIsZipCodeValid,
  setShowZipCodeError,
} from "../../store/slices/bankLinking-slice";
import { useTracking } from "../../tracking";
import { ResponsiveDrawerDialog } from "../modals";
import AdyenCardInput from "./AdyenCardInput";

type AdyenCardInputModalProps = {
  ready: boolean;
  inputsValid: boolean;
};

const AdyenCardInputModal: React.VFC<AdyenCardInputModalProps> = ({
  ready,
  inputsValid,
}) => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const hideAdyenModal = useSelector(selectHideAdyenModal);
  const connectBankVariant = useSelector(selectConnectBankVariant);
  const disableAch = useSelector(selectDisableAch);
  const isZipCodeValid = useSelector(selectIsZipCodeValid);
  const [open, setOpen] = useState<boolean>(true);

  const theme = useTheme();
  const matchesDialog = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    trackEvent("Link Card Closed");
    dispatch(setBankLinkingStep("ConnectBank"));
    dispatch(setAdyenReloadNeeded(true));
    dispatch(setHideAdyenModal(true));
    setOpen(false);

    // Reset the zip code validation message
    const zipElements = document.querySelectorAll(
      "[id^=adyen-checkout-postalCode]"
    );
    for (let i = 0; i < zipElements.length; i += 1) {
      const elem = zipElements[i] as HTMLInputElement;
      dispatch(setShowZipCodeError(false));
      elem.classList.remove("zipcode-error");
      const zipCodeLabel = elem.parentElement?.parentElement
        ?.firstChild as HTMLLabelElement;
      if (zipCodeLabel) {
        zipCodeLabel.classList.remove("zipcode-error");
      }
    }
  };

  // Due to an issue where the drawer does not fully resurface on mobile
  // when it is closed via swiping, upon closing, we force it to close
  // and then open back up to "reset" its height
  useEffect(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  // Force reload the components when switching between dialog and drawer
  useEffect(() => {
    dispatch(setAdyenReloadNeeded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesDialog]);

  useEffect(() => {
    if (!hideAdyenModal) {
      trackEvent("Opened Link Card");
      dispatch(closeBankModal());
      dispatch(setBankLinkingStep("AdyenConnect"));
    }
  }, [hideAdyenModal, dispatch, trackEvent]);

  useEffect(() => {
    // We force the state of the zip to be valid initially on load if
    // the user just came from the "ErrorCardVerificationFailed" modal.
    // This is because the state has reset but the input field is still
    // initially filled out and valid.
    if (
      connectBankVariant === "ErrorCardVerificationFailed" &&
      (!hideAdyenModal || disableAch)
    ) {
      dispatch(setIsZipCodeValid(true));
    }
  }, [
    connectBankVariant,
    disableAch,
    dispatch,
    hideAdyenModal,
    isZipCodeValid,
  ]);

  return (
    <>
      <ResponsiveDrawerDialog
        name="connect-debit-card"
        data-testid="connect-debit-card-drawer-dialog"
        onClose={handleClose}
        open={open}
        sx={{
          ...(hideAdyenModal && {
            visibility: "hidden",
          }),
          // On small screen sizes, reduce the external padding
          // on top of the drawer to ensure the CTA doesn't fall
          // beneath the keyboard.
          ...(!matchesDialog && {
            ".MuiPaper-root": {
              maxHeight: `calc(100vh - 8px)`,
            },
          }),
          // Ensure the NavBar (zIndex.drawer + 1) stays behind
          // the modal.
          zIndex: theme.zIndex.drawer + 2,
        }}
      >
        {({ headingId }) => (
          <AdyenCardInput
            headingId={headingId}
            ready={ready}
            inputsValid={inputsValid}
            displayButton
          />
        )}
      </ResponsiveDrawerDialog>
    </>
  );
};

export default AdyenCardInputModal;
