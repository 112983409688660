import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useBankLinkingOptions } from "../../contexts/BankLinkingContext";
import useInitiateTellerConnect from "../../hooks/bank-linking/useInitiateTellerConnect";
import { selectTellerInstitutionId } from "../../store/selectors";
import { setBankLinkingStep } from "../../store/slices/bankLinking-slice";
import { useTracking } from "../../tracking";

const TellerConnect: React.VFC = () => {
  const tellerConnect = useInitiateTellerConnect();
  const { bus } = useBankLinkingOptions();
  const tellerInstitutionId = useSelector(selectTellerInstitutionId);

  const [tellerConnectOpened, setTellerConnectOpened] =
    useState<boolean>(false);
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      setTellerConnectOpened(false);
    },
    []
  );

  useEffect(() => {
    if (!tellerConnectOpened) {
      if (tellerConnect && tellerInstitutionId) {
        tellerConnect.open({ institution: tellerInstitutionId });
        dispatch(setBankLinkingStep("TellerLogin"));
        setTellerConnectOpened(true);
      } else if (tellerConnect) {
        tellerConnect.open({});
        dispatch(setBankLinkingStep("TellerLogin"));
        setTellerConnectOpened(true);
      }
      trackEvent("Teller Connect Opened", { tellerInstitutionId });
    }
  }, [
    dispatch,
    tellerConnect,
    tellerInstitutionId,
    trackEvent,
    tellerConnectOpened,
  ]);

  useEffect(() => {
    // NB: handleUserIdle is currently a no op, until TellerConnect instances
    // support be closed (per TODO below). This is left here for now as a skeleton
    // implementation.
    const handleUserIdle = () => {
      if (tellerConnectOpened && tellerConnect) {
        // TODO FIXME closing the TellerConnect instance is not currently supported.
        // tellerConnect.close();
      }
    };

    bus?.on("USER_IDLE", handleUserIdle);
    return () => {
      bus?.off("USER_IDLE", handleUserIdle);
    };
  }, [tellerConnect, tellerConnectOpened, bus]);

  return null;
};

export default TellerConnect;
