import { createSelector } from "@reduxjs/toolkit";

import { PaymentInstrument } from "../../services/types/payment-instrument-types";
import { FetchState, makeFetchSelectors } from "../store-utils";

type RootState = {
  paymentInstruments: FetchState<PaymentInstrument[]>;
};

const selectPaymentInstruments = makeFetchSelectors<
  PaymentInstrument[],
  RootState
>("paymentInstruments");

const selectPaymentInstrument = createSelector(
  (state: RootState) => state.paymentInstruments.data,
  (data) =>
    data.find(
      (paymentInstrument) =>
        paymentInstrument && paymentInstrument.precedence === "default"
    ) || null
);

export { selectPaymentInstrument, selectPaymentInstruments };
