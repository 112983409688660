import React from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import RewardCard from "~common/components/cards/RewardCard";
import {
  PrimaryButton,
  TextButtonSmall,
} from "~common/components/controls/buttons";
import Nowrap from "~common/components/primitives/Nowrap";
import { Reward } from "~common/services";
import { useTracking } from "~common/tracking";
import { formatNumDays } from "~common/utils/dates";
import { formatMoney } from "~common/utils/money";
import { isExpiredRevivalBoost } from "~common/utils/reward-boosts";

interface RewardBoostDetailsProps {
  boostedReward: Reward;
  handleClose: () => void;
  displayCatchButton: boolean;
}

const RewardBoostDetails: React.FC<RewardBoostDetailsProps> = ({
  boostedReward,
  handleClose,
  displayCatchButton,
}) => {
  const { trackEvent } = useTracking();

  const { associated_reward_boost: rewardBoost } = boostedReward;

  if (!rewardBoost) {
    return null;
  }

  let header = <></>;
  if (!isExpiredRevivalBoost(rewardBoost)) {
    header = <>Your credit has grown!</>;
  } else {
    header = <>You&apos;ve got a credit extension&nbsp;🎉</>;
  }

  let subheader;
  if (!isExpiredRevivalBoost(rewardBoost)) {
    subheader = (
      <>
        (For {formatNumDays(rewardBoost.granted_reward_expiration_in_days)}{" "}
        only⌛)
      </>
    );
  }

  let body;
  const formattedTimePeriod = formatNumDays(
    rewardBoost.granted_reward_expiration_in_days
  );

  if (isExpiredRevivalBoost(rewardBoost)) {
    // If the string is "1 week", we want this to just say "week" so that the body will read
    // "an extra week" instead of "an extra 1 week"
    const formattedTimePeriodForBody = formattedTimePeriod.includes("1 ")
      ? formattedTimePeriod.replace("1 ", "")
      : formattedTimePeriod;
    body = (
      <>
        {boostedReward.merchant_name || "Catch"} has extended your expired
        credit. You now have{" "}
        <strong>
          an extra {formattedTimePeriodForBody} to redeem your&nbsp;credit.
        </strong>
      </>
    );
  } else {
    body = (
      <>
        Yes, you heard that right.{" "}
        <strong>
          Your credit has increased by{" "}
          {!!rewardBoost.reward_amount_multiplier && (
            <>{rewardBoost.reward_amount_multiplier}x</>
          )}
          {!!rewardBoost.reward_drop_amount && (
            <>
              {formatMoney(
                rewardBoost.reward_drop_amount ||
                  boostedReward.amount -
                    (boostedReward.original_reward_amount || 0)
              )}
            </>
          )}{" "}
          for the next {formattedTimePeriod}.
        </strong>{" "}
        It&apos;ll <Nowrap>auto-apply</Nowrap> on your next purchase.
      </>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={6}
      sx={{
        px: 6,
      }}
    >
      <RewardCard reward={boostedReward} sx={{ width: 224, px: 4, pt: 4 }} />

      <Stack direction="column" spacing={2} textAlign="center">
        <Typography variant="h1" color="grey.700">
          {header}
          {subheader && (
            <>
              <br />
              <Typography variant="bodyRegular">{subheader}</Typography>
            </>
          )}
        </Typography>
        <Typography variant="bodyRegular" color="grey.600" sx={{ px: 1 }}>
          {body}
        </Typography>
      </Stack>

      <Stack
        direction="column"
        spacing={4}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <PrimaryButton
          fullWidth
          endIcon={<OpenInNewIcon />}
          href={`https://${boostedReward.merchant_url || ""}`}
          onClick={() => {
            trackEvent("Reward boost modal shop at merchant clicked");
          }}
          target="_blank"
        >
          Shop {boostedReward.merchant_name}
        </PrimaryButton>
        {displayCatchButton && (
          <TextButtonSmall
            onClick={() => {
              trackEvent(
                "Reward boost modal 'Go to your Catch account' clicked"
              );
              handleClose();
            }}
          >
            Go to your Catch account
          </TextButtonSmall>
        )}
      </Stack>
    </Stack>
  );
};

export default RewardBoostDetails;
