const EMPTY_FIRST_NAME_MESSAGE = "Please enter a first name";
const EMPTY_LAST_NAME_MESSAGE = "Please enter a last name";

// Trim whitespace and conver to single spacing
const trimName = (name: string) => name.replace(/\s+/g, " ").trim();

const getValidName = (name: string) => {
  if (name.includes("@")) {
    return "";
  }

  return name.replace(/\s+/g, " ").trim();
};

const splitPrefillName = (name: string) => {
  const nameArray = trimName(name).split(" ") || [];

  return {
    userFirstName: nameArray[0] || null,
    userLastName: nameArray.slice(1).join(" ") || null,
  };
};

export {
  EMPTY_FIRST_NAME_MESSAGE,
  EMPTY_LAST_NAME_MESSAGE,
  getValidName,
  splitPrefillName,
  trimName,
};
