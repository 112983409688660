import Account from "./Account";
import PublicShop from "./external/PublicShop";
import SignUp from "./external/SignUp";
import Home from "./Home";
import QuickStart from "./internal/QuickStart";
import LandingCampaign from "./LandingCampaign";
import LandingGift from "./LandingGift";
import LandingReferral from "./LandingReferral";
import LinkPaymentMethodMobile from "./mobile-embedded/LinkPaymentMethodMobile";
import NotFound from "./NotFound";
import Shop from "./Shop";
import ActivateCard from "./sign-in/activate-card/ActivateCard";
import AnswerOTP from "./sign-in/AnswerOTP";
import CompleteAccount from "./sign-in/CompleteAccount";
import SignIn from "./sign-in/SignIn";
import SkipSignIn from "./sign-in/SkipSignIn";
import VerifyEmail from "./sign-in/VerifyEmail";
import CheckEmailVerification from "./verify/CheckEmailVerification";

const Auth = {
  SignIn,
  SkipSignIn,
  AnswerOTP,
  CompleteAccount,
  ActivateCard,
  VerifyEmail,
};

const External = {
  PublicShop,
  SignUp,
};

const Internal = {
  QuickStart,
};

const Verify = {
  Email: CheckEmailVerification,
};

export {
  Account,
  Auth,
  External,
  Home,
  Internal,
  LandingCampaign,
  LandingGift,
  LandingReferral,
  LinkPaymentMethodMobile,
  NotFound,
  Shop,
  Verify,
};
