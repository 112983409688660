import { MerchantPartnerType, MerchantSearchResult } from "~common/services";
import {
  getExpiresAt,
  getLocalStorageWithFallback,
  isExpired,
  setLocalStorageWithFallback,
} from "~common/utils/browser-storage";
import { APP_ENVIRONMENT } from "~src/config";

const RECENT_RESULTS_KEY = "catch_recent_searches";
const RECENT_RESULTS_MAX = 5;
const RECENT_RESULTS_EXPIRATION =
  APP_ENVIRONMENT === "production" ? 5184000 : 600;

type RecentResult = MerchantSearchResult & { expiresAt: number };

const getRecentResults = () => {
  const storedResults = getLocalStorageWithFallback(RECENT_RESULTS_KEY);

  if (storedResults) {
    return (JSON.parse(storedResults) as RecentResult[])
      .filter((recentResult) => !isExpired(recentResult.expiresAt))
      .sort((a, b) => b.expiresAt - a.expiresAt)
      .slice(0, RECENT_RESULTS_MAX);
  }

  return [];
};

const updateRecentResults = (
  result: MerchantSearchResult,
  recentResults: RecentResult[]
) => {
  const updatedRecentResults = [
    ...[
      {
        ...result,
        expiresAt: getExpiresAt(RECENT_RESULTS_EXPIRATION),
      },
    ],
    ...recentResults.filter((recentResult) => recentResult.id !== result.id),
  ].slice(0, RECENT_RESULTS_MAX);

  setLocalStorageWithFallback(
    RECENT_RESULTS_KEY,
    JSON.stringify(updatedRecentResults)
  );

  return updatedRecentResults;
};

const filterResults = (
  type: MerchantPartnerType | "",
  results: MerchantSearchResult[],
  excludedResults?: MerchantSearchResult[]
) => {
  let filteredResults = results;

  if (type) {
    filteredResults = results.filter(
      (result) => type === result.merchant_partner_type
    );
  }

  if (excludedResults) {
    const excludedIds = excludedResults.map((result) => result.id);
    filteredResults = filteredResults.filter(
      (result) => !excludedIds.includes(result.id)
    );
  }

  return filteredResults;
};

export { filterResults, getRecentResults, updateRecentResults };
