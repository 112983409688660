import React from "react";

import ExternalTextLink from "../../links/ExternalTextLink";

const ConnectBankTroubleFooter: React.VFC = () => (
  <ExternalTextLink
    size="regular"
    textAlign="center"
    href="https://help.getcatch.com/en/articles/5118553-i-m-having-trouble-linking-my-bank-account-to-catch-why-won-t-it-link"
    eventName="Trouble Connecting Clicked"
    componentName="BankStepFooter"
  >
    Trouble connecting?
  </ExternalTextLink>
);

export default ConnectBankTroubleFooter;
