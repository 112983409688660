import React from "react";
import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { selectCardVerificationError } from "../../../../../store/selectors";
import { BankErrorIcon } from "../../../../icons/vector";

type DebitHeaderCardVerificationFailedProps = {
  headingId?: string;
};

const DebitHeaderCardVerificationFailed: React.VFC<
  DebitHeaderCardVerificationFailedProps
> = ({ headingId }) => {
  const cardError = useSelector(selectCardVerificationError);

  return (
    <Stack spacing={4} className="BankLinking-header">
      <BankErrorIcon size="small" />
      <Stack spacing={2}>
        <Typography variant="h2" id={headingId}>
          Card verification failed
        </Typography>
        <Typography variant="bodyRegular" color="grey.600">
          Please review the following information and try again:
        </Typography>
        <Typography variant="bodyRegular" color="grey.600">
          {cardError === "Cvc" || cardError === "ExpirationDate"
            ? "• Invalid CVC or expiration date"
            : "• Invalid Zip/Postal code"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DebitHeaderCardVerificationFailed;
