import React from "react";

import { ButtonProps, Stack, StackProps, Typography } from "@mui/material";

import brandsImage from "~common/assets/illustrations/catch_card_brands.png";
import { PrimaryButton } from "~common/components/controls/buttons";

type BannerProps = StackProps & {
  heading: string;
  body: string;
  button: ButtonProps;
  hideBackground?: boolean;
};

const Banner: React.VFC<BannerProps> = ({
  heading,
  body,
  button,
  hideBackground,
}) => {
  const { children: buttonChildren, ...buttonProps } = button;

  return (
    <Stack
      spacing={8}
      alignItems="flex-start"
      sx={({ palette, shape }) => ({
        px: {
          zero: 6,
          sm: 8,
        },
        py: {
          zero: 12,
          sm: 8,
        },
        border: `1px solid ${palette.grey[300]}`,
        borderRadius: `${shape.borderRadius * 4}px`,
        ...(!hideBackground && {
          background: `url(${brandsImage}) ${
            palette.tertiary.light || ""
          } no-repeat`,
          backgroundSize: {
            zero: "160px 128px",
            sm: "193px 155px",
          },
          backgroundPosition: {
            zero: "right bottom",
            sm: "calc(100% - 32px) center",
          },
        }),
      })}
    >
      <Stack
        spacing={2}
        sx={{
          ...(!hideBackground && {
            maxWidth: {
              sm: "calc(100% - 225px)",
            },
          }),
        }}
      >
        <Typography variant="h1">{heading}</Typography>

        <Typography variant="bodyLarge" color="grey.600">
          {body}
        </Typography>
      </Stack>

      <PrimaryButton
        sx={{
          width: {
            sm: 200,
          },
        }}
        {...buttonProps}
      >
        {buttonChildren}
      </PrimaryButton>
    </Stack>
  );
};

export default Banner;
