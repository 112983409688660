const formatRelativeLink = (path: string) =>
  `${window.location.origin}/u/${path}`;

const extractPrefillSearchParam = (rawParam: string | null) =>
  rawParam ? decodeURIComponent(rawParam) : null;

const normalizeUrl = (url: string) =>
  `https://${url.replace("https://", "").replace("http://", "")}`;

export { extractPrefillSearchParam, formatRelativeLink, normalizeUrl };
