// GENERATED FILE. DO NOT EDIT.

import React from "react";

import MediumSVG from "../vector-source/HighFive.medium.svg";
import SmallSVG from "../vector-source/HighFive.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const HighFiveIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} mediumSvg={MediumSVG} {...props} />
);

export default HighFiveIcon;
