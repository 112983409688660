import "@adyen/adyen-web/dist/adyen.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdyenCheckout from "@adyen/adyen-web";
import Core from "@adyen/adyen-web/dist/types/core";
import { useTheme } from "@mui/material";

import { AdyenCheckoutCallbackData } from "../../banking/adyen";
import { useBankLinkingOptions } from "../../contexts/BankLinkingContext";
import { useEnv } from "../../contexts/env-context";
import { useCreateAdyenCardPaymentSession } from "../../services";
import {
  selectAdyenSession,
  selectcardFieldValidity,
  selectIsDebitSecureInputValid,
} from "../../store/selectors";
import {
  setAdyenReloadNeeded,
  setAdyenSession,
  setCardFieldValidity,
  setCurrentAdyenCardDetails,
  setIsDebitSecureInputValid,
} from "../../store/slices/bankLinking-slice";
import { useTracking } from "../../tracking";
import { MINUTE_MS } from "../../utils/delays";
import useBankLinkCallbacks from "./useBankLinkCallbacks";

type UseInitiateAdyenCardComponent = () => {
  ready: boolean;
  adyenCheckout: Core | null;
  // Used to control the disable state on our "Save card" button as this is not
  // part of the card component UI
  inputsValid: boolean;
};

// Adyen sessions last 60 minutes, so set an interval
// of 55 minutes to be safe
const ADYEN_LINK_INTERVAL_MS = 55 * MINUTE_MS;

const useInitiateAdyenCardComponent: UseInitiateAdyenCardComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mountedRef = useRef(true);
  const { APP_ENVIRONMENT, APP_STAGE } = useEnv();

  const { trackEvent, trackError } = useTracking();

  const { adyenClientKey } = useBankLinkingOptions();
  const { mutate: createAdyenSession } = useCreateAdyenCardPaymentSession();
  const { handleCardLinkingError } = useBankLinkCallbacks({
    connector: "adyen_card",
  });
  const inputsValid = useSelector(selectIsDebitSecureInputValid);
  const adyenSession = useSelector(selectAdyenSession);
  const cardFieldValidity = useSelector(selectcardFieldValidity);

  const [checkout, setCheckout] = useState<Core | null>(null);
  const [ready, setReady] = useState<boolean>(false);

  const [trackedValidCardNumber, setTrackedValidCardNumber] =
    useState<boolean>(false);
  const [trackedValidExpirationDate, setTrackedValidExpirationDate] =
    useState<boolean>(false);
  const [trackedValidCvv, setTrackedValidCvv] = useState<boolean>(false);
  const [trackedAllInputsValid, setTrackedAllInputsValid] =
    useState<boolean>(false);

  useEffect(() => {
    if (cardFieldValidity?.encryptedCardNumber && !trackedValidCardNumber) {
      trackEvent("Card number input valid");
      setTrackedValidCardNumber(true);
    }
    if (
      cardFieldValidity?.encryptedExpiryMonth &&
      cardFieldValidity?.encryptedExpiryYear &&
      !trackedValidExpirationDate
    ) {
      trackEvent("Card expiration input valid");
      setTrackedValidExpirationDate(true);
    }
    if (cardFieldValidity?.encryptedSecurityCode && !trackedValidCvv) {
      trackEvent("Card cvv input valid");
      setTrackedValidCvv(true);
    }
  }, [
    cardFieldValidity,
    trackEvent,
    trackedValidCardNumber,
    trackedValidCvv,
    trackedValidExpirationDate,
  ]);

  useEffect(() => {
    if (inputsValid && !trackedAllInputsValid) {
      trackEvent("Debit secure inputs valid");
      setTrackedAllInputsValid(true);
    }
  }, [inputsValid, trackEvent, trackedAllInputsValid]);

  /* 
  1. Get an Adyen payment session from our API. This is required to initialize
     the Adyen web component
     Docs: https://docs.adyen.com/payment-methods/cards/web-component
  */
  const getAdyenSession = useCallback(async () => {
    try {
      const session = await createAdyenSession({
        return_url: window.location.href,
      });
      trackEvent("Adyen Session Generated", { sessionId: session?.id });
      dispatch(setAdyenSession(session));

      if (!mountedRef.current) return null;
    } catch (rawError) {
      // Don't error on failed to fetch when user navigates
      const potentialError = rawError as { message: string };
      if (
        potentialError?.message &&
        potentialError?.message.toLowerCase().includes("failed to fetch")
      ) {
        if (!mountedRef.current) return null;
      }
      handleCardLinkingError(rawError);
    }
    return null;
  }, [createAdyenSession, dispatch, handleCardLinkingError, trackEvent]);

  useEffect(() => {
    if (!adyenSession) {
      void getAdyenSession();
    }

    const adyenSessionInterval = setInterval(() => {
      void getAdyenSession();
    }, ADYEN_LINK_INTERVAL_MS);

    return () => {
      mountedRef.current = false; // Clean up function
      clearInterval(adyenSessionInterval);
    };
  }, [adyenSession, getAdyenSession]);

  /*
   2. Create the AdyenCheckout which represents the card component
      we will mount
  */
  useEffect(() => {
    if (!adyenSession) {
      // We haven't finished generating the session yet
      return;
    }

    const createCheckout = async () => {
      const adyenCheckout = await AdyenCheckout({
        environment:
          APP_ENVIRONMENT === "production" && APP_STAGE === "live"
            ? "live"
            : "test",
        locale: "en-US",
        clientKey: adyenClientKey,
        analytics: {
          enabled: true,
        },
        session: {
          id: adyenSession.id,
          sessionData: adyenSession.session_data,
        },
        onChange: (state: AdyenCheckoutCallbackData) => {
          // We use the onChange callback instead of the onSubmit callback so
          // that we can exclude Adyen's payment button and instead use our own.
          // Thus, once all the fields are valid we store the necessary information
          // to create the payment instrument in state and await the user's click of
          // the "Save card" button.
          // NB: The zip code validation isn't considered by the state here
          if (state?.isValid) {
            dispatch(setIsDebitSecureInputValid(true));
            dispatch(setCurrentAdyenCardDetails(state));
          } else {
            dispatch(setIsDebitSecureInputValid(false));
          }

          // Save the validity of the different inputs for
          // tracking purposes
          dispatch(setCardFieldValidity(state.valid));
        },
        onError: (rawError: unknown) => {
          trackError(
            "useInitiateAdyenCardComponent",
            "Error from Adyen card web component",
            {
              originalError: rawError,
            }
          );
          // Force reload of the adyen component
          dispatch(setAdyenReloadNeeded(true));
        },
        paymentMethodsConfiguration: {
          card: {
            billingAddressMode: "partial", // ZIP Code only
            billingAddressAllowedCountries: ["US"],
            billingAddressRequired: true,
            showBrandsUnderCardNumber: false,
            showBrandIcon: false,
            autoFocus: true,
            // These styles are used for the formatting inside the secured fields iframes
            styles: {
              base: {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.grey[700],
                ...theme.typography.bodyLarge,
              },
              error: {
                color: theme.palette.grey[700],
              },
              placeholder: {
                color: theme.palette.grey[500],
                // NB: This value seems to only actually
                // work at specific values
                fontWeight: 100,
              },
            },
          },
        },
        // Custom placeholders for the card fields
        translations: {
          "en-US": {
            "creditCard.numberField.placeholder": "Card Number",
            "creditCard.cvcField.placeholder.3digits": "CVC",
            "creditCard.expiryDateField.placeholder": "Exp. (MM/YY)",
            "error.va.sf-cc-dat.01": "Year has expired",
            "error.va.gen.01": "Wrong value",
            "error.va.sf-cc-num.01": "Please enter a valid debit card number",
          },
        },
      });

      // Store the checkout to pass back
      if (adyenCheckout) {
        setReady(true);
        setCheckout(adyenCheckout);
      }
    };

    void createCheckout().catch((error) => {
      const err = error as Error;
      if (!err.message.includes("Access is denied")) {
        trackError("useInitiateAdyenCardComponent", "Create Adyen checkout", {
          originalError: error,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adyenClientKey, adyenSession, trackError, dispatch]);

  /*
   3. Return Adyen checkout if we have generated it, otherwise
      return null
  */
  if (checkout) {
    return { ready, adyenCheckout: checkout, inputsValid };
  }

  return { ready: false, adyenCheckout: null, inputsValid };
};

export default useInitiateAdyenCardComponent;
