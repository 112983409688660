import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import { useTracking } from "~common/tracking";

import LinkDebit from "../shared/onboarding/LinkDebit";

type CatchCardLinkDebitProps = {
  onNext: () => void;
  onEdit: (isActive: boolean, hideCancel?: boolean) => void;
  isEditing: boolean;
};

const CatchCardLinkDebit: React.VFC<CatchCardLinkDebitProps> = ({
  onNext,
  onEdit,
  isEditing,
}) => {
  const { trackEvent } = useTracking();
  const [hideIntro, setHideIntro] = useState(false);

  const handleConfirm = () => {
    trackEvent("Catch Card Link Debit Confirm Clicked");
    onNext();
  };

  return (
    <Stack spacing={6}>
      {!hideIntro && (
        <Typography>
          Your Catch card is a free virtual debit card that&apos;s linked to
          your normal debit card, so we can grant and redeem your rewards.
        </Typography>
      )}

      <LinkDebit
        onEdit={onEdit}
        isEditing={isEditing}
        onError={setHideIntro}
        onSuccess={handleConfirm}
      />
    </Stack>
  );
};

export default CatchCardLinkDebit;
