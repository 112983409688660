type MessagePayload = {
  action: string;
  data?: Record<string, unknown>;
};

type MessageHandlers = {
  [key: string]: (payload: MessagePayload) => void;
};

type EmbeddedMessengerHandle = {
  dispatch: (action: string, data?: Record<string, unknown>) => void;
  destroy: () => void;
};

const embeddedMessenger = (
  parentOrigin: string,
  handlers: MessageHandlers = {}
): EmbeddedMessengerHandle => {
  const handleReceivedMessage = ({
    origin,
    data,
  }: MessageEvent<string | MessagePayload>): void => {
    if (origin !== parentOrigin) {
      return;
    }
    let parsedData: MessagePayload;

    try {
      if (typeof data === "string") {
        parsedData = JSON.parse(data) as MessagePayload;
      } else {
        parsedData = data;
      }
    } catch {
      // We likely recieved a bad JSON payload, return
      return;
    }

    const { action } = parsedData;
    if (action in handlers) {
      handlers[action](parsedData);
    }
  };

  window.addEventListener("message", handleReceivedMessage);

  return {
    dispatch(action: string, data?: Record<string, unknown>) {
      // Bail if Tofu was embedded on a page where window.origin
      // returned a bogus value.
      if (!parentOrigin || parentOrigin === "null") {
        return;
      }
      window.parent.postMessage({ action, data }, parentOrigin);
    },
    destroy() {
      window.removeEventListener("message", handleReceivedMessage);
    },
  };
};

export { embeddedMessenger };
export type { EmbeddedMessengerHandle };
