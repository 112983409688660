import { ExplorePublicMerchantCategoriesResponseData } from "~common/services";
import { makeFetchSlice } from "~common/store/store-utils";

const slice = makeFetchSlice<ExplorePublicMerchantCategoriesResponseData>(
  "exploreMerchants",
  { categories: [] }
);

const { reducer, actions: exploreMerchantsActions } = slice;

export { exploreMerchantsActions };
export default reducer;
