import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import DefaultButton from "~common/components/controls/buttons/DefaultButton";
import PrimaryButton from "~common/components/controls/buttons/PrimaryButton";
import { UserResponseData } from "~common/services";
import { useUpdateCurrentUser } from "~common/services/users";
import { useTracking } from "~common/tracking";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import { currentUserActions } from "~src/store/slices/services/currentUser-slice";
import { setAuthState } from "~src/store/slices/user-slice";

type AccountSettingsBirthdayEditFormProps = {
  onClose: () => void;
};

const AccountSettingsBirthdayEditForm: React.VFC<
  AccountSettingsBirthdayEditFormProps
> = ({ onClose }) => {
  const { trackEvent, trackError } = useTracking();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { mutate: updateCurrentUser } = useUpdateCurrentUser();
  const [month, setMonth] = useState<number>(1);
  const [day, setDay] = useState<number>(1);
  const [daysInMonth, setDaysInMonth] = useState<number>(31);

  const handleChangeMonth = (e: SelectChangeEvent) => {
    const parsedMonth = parseInt(e.target.value, 10);
    const updatedDaysInMonth = getDaysInMonth(parsedMonth);

    setMonth(parsedMonth);
    setDaysInMonth(updatedDaysInMonth);
    setDay(Math.min(updatedDaysInMonth, day));
  };

  const handleChangeDay = (e: SelectChangeEvent) => {
    setDay(parseInt(e.target.value, 10));
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!currentUser?.email) {
      return;
    }

    trackEvent("Save New Birthday");

    try {
      await updateCurrentUser({
        email: currentUser?.email,
        birthday_month: month,
        birthday_day: day,
      }).then((userResponseData: UserResponseData) => {
        dispatch(currentUserActions.manualSet(userResponseData));

        // Updates relevant app state to ensure correct data present
        // in API calls e.g. for claiming.
        if (userResponseData.refreshed_auth_info) {
          dispatch(setAuthState(userResponseData.refreshed_auth_info));
        }
      });

      onClose();
    } catch (error) {
      trackError("Edit Birthday", "Updating current user", { error });
    }
  };

  const handleCancel = () => {
    onClose();
    setMonth(1);
    setDay(1);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Escape") {
      handleCancel();
    }
  };

  const getDaysInMonth = (updatedMonth: number) => {
    if (updatedMonth === 2) {
      return 29;
    }

    if ([4, 6, 9, 11].includes(updatedMonth)) {
      return 30;
    }

    return 31;
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSave}
      onKeyDown={handleKeyDown}
      gap={4}
      sx={{ py: 4, width: "100%", maxWidth: 400 }}
    >
      <Typography variant="bodySmall" color="grey.500">
        Birthday
      </Typography>

      <Stack spacing={4} direction="row">
        <FormControl sx={{ width: "60%" }}>
          <InputLabel id="birthdayMonth">Month</InputLabel>

          <Select
            labelId="birthdayMonth"
            value={month.toString()}
            label="Month"
            onChange={handleChangeMonth}
          >
            {[...Array(12).keys()].map((i) => (
              <MenuItem key={`month${i}`} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "40%" }}>
          <InputLabel id="birthdayDay">Day</InputLabel>

          <Select
            labelId="birthdayDay"
            value={day.toString()}
            label="Day"
            onChange={handleChangeDay}
          >
            {[...Array(daysInMonth).keys()].map((i) => (
              <MenuItem key={`day${i}`} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="row" gap={4}>
        <PrimaryButton size="small" type="submit" sx={{
          minWidth: 100
        }}>
          Save
        </PrimaryButton>

        <DefaultButton
          size="small"
          onClick={handleCancel}
          sx={{
            minWidth: 100
          }}
        >
          Cancel
        </DefaultButton>
      </Stack>
    </Stack>
  );
};

export default AccountSettingsBirthdayEditForm;
