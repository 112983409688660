import { MerchantSearchResult } from "../services";

const getMerchantLogoUrl = (baseUrl: string, merchant: MerchantSearchResult) =>
  `${baseUrl}/merchant-assets/${
    merchant.merchant_partner_type === "affiliate" ? "affiliate/" : ""
  }${merchant.id}/${
    merchant.merchant_partner_type === "affiliate"
      ? "logo.png"
      : "shop_badge.png"
  }`;

export { getMerchantLogoUrl };
