import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.332 26.1313H50.6514"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5723 21.6357H46.1561"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.6816 31.5967H53.9129"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0928 37.1504H55.324"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.5332 42.6152H56.0289"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0049 48.0811H55.3243"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.3291 53.6348H53.7366"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.0684 59.1001H50.2114"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.6328 64.5649H44.1285"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.7627 66.7694L32.4043 66.9457C43.7758 68.3561 54.2657 58.7477 55.8525 45.5251C57.4392 32.3025 49.5937 20.4021 38.2223 19.0799L30.5532 18.9917"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.3619 45.5305C52.9413 32.2844 45.012 20.4482 33.6513 19.0936C22.2905 17.739 11.8005 27.379 10.2211 40.6251C8.64168 53.8713 16.571 65.7075 27.9318 67.0621C39.2925 68.4167 49.7825 58.7767 51.3619 45.5305Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.5983 45.0824C48.8895 34.2534 42.4117 24.5776 33.1297 23.4709C23.8478 22.3642 15.2765 30.2456 13.9854 41.0746C12.6942 51.9035 19.172 61.5793 28.4539 62.686C37.7359 63.7928 46.3071 55.9114 47.5983 45.0824Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3887 38.4736C18.3887 38.4736 22.091 44.4679 27.7327 40.1485"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5205 40.1475C36.8124 42.1749 39.8977 43.8498 43.6 41.5579"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0605 45.4365C25.2643 49.8441 34.432 51.0782 38.7514 46.2299"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.7344 18.5506L61.0538 10"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.2295 25.3384L71.1024 21.3716"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.8164 32.7432L72.2485 34.9469"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
