import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCreateAdyenCardPaymentInstrument } from "../../services";
import { PaymentInstrumentPrecedence } from "../../services/types/payment-instrument-types";
import {
  selectCardBrand,
  selectCurrentAdyenCardDetails,
  selectIsCatchPassFlow,
  selectPaymentInstrument,
} from "../../store/selectors";
import {
  openBankModal,
  resetConnectionState,
  setAdyenReloadNeeded,
  setBankLinkingStep,
  setConnectBankVariant,
  setHideAdyenModal,
  setIsProcessingLinkUpdate,
} from "../../store/slices/bankLinking-slice";
import { paymentInstrumentsActions } from "../../store/slices/paymentInstruments-slice";
import { useTracking } from "../../tracking";
import MobileAppEventBus from "../../utils/mobile-app-messaging";
import useBankLinkCallbacks from "./useBankLinkCallbacks";

type UseHandleSaveCardOptions = {
  // Prevents the hook from initiating the loading screen while it
  // processes saving the card (as controlled by isProcessingLinkUpdate)
  disableLoadingScreen: boolean;
};

type UseHandleSaveCardMethods = {
  handleSaveCardClick: () => Promise<void>;
};

const useHandleSaveCard = ({
  disableLoadingScreen,
}: UseHandleSaveCardOptions): UseHandleSaveCardMethods => {
  const [isProcessingSave, setIsProcessingSave] = useState<boolean>(false);
  const validCardData = useSelector(selectCurrentAdyenCardDetails);
  const cardBrand = useSelector(selectCardBrand);
  const isCatchPassFlow = useSelector(selectIsCatchPassFlow);
  const currentPaymentInstrument = useSelector(selectPaymentInstrument);
  const eventBus = new MobileAppEventBus({ overrideTargetToWindow: true });

  const { trackEvent, trackError } = useTracking();
  const dispatch = useDispatch();
  const { mutate: createPaymentInstrument } =
    useCreateAdyenCardPaymentInstrument();
  const { handleCardLinkingError, linkBank } = useBankLinkCallbacks({
    connector: "adyen_card",
  });

  const handleSaveCardClick = async () => {
    const createInstrument = async (): Promise<boolean> => {
      try {
        if (!validCardData) {
          trackError(
            "AdyenCardInput",
            "Save button clicked but no valid card data"
          );

          return false;
        }

        // During the CatchPass flow, if a user already has a payment
        // instrument connected (could be bank or debit), and is connecting
        // a new instrument, pass in "secondary" to indicate this is not their
        // default Catch payment method
        let precedence: PaymentInstrumentPrecedence = "default";
        if (currentPaymentInstrument && isCatchPassFlow) {
          precedence = "secondary";
        }

        const paymentInstrumentData = await createPaymentInstrument({
          return_url: window.location.href,
          adyen_payment_method_details: validCardData.data.paymentMethod,
          adyen_billing_address_details: validCardData.data.billingAddress,
          precedence,
        });

        dispatch(paymentInstrumentsActions.manualSet([paymentInstrumentData]));

        trackEvent("Link Card Success", {
          brand: validCardData.data.paymentMethod.brand,
          precedence,
        });
        trackEvent("Payment method connected successfully");

        dispatch(setAdyenReloadNeeded(true));
        eventBus.sendEventToMobile("CATCH_DISMISS_PAYMENT_LINKING", {
          paymentInstrumentId: paymentInstrumentData.payment_instrument_id,
        });
        // PD-256 Remove old method of sending events
        window.dispatchEvent(
          new CustomEvent("CATCH_DISMISS_PAYMENT_LINKING", {
            detail: {
              paymentInstrumentId: paymentInstrumentData?.payment_instrument_id,
            },
          })
        );

        if (isCatchPassFlow) {
          dispatch(setConnectBankVariant("Success"));
        }

        return true;
      } catch (rawError) {
        handleCardLinkingError(rawError);
        return false;
      }
    };

    // Set the loading screen and create a new payment instrument
    if (!disableLoadingScreen) {
      dispatch(setIsProcessingLinkUpdate(true));
    }

    // Check to see if the brand is supported, if not set the error screen
    // before attempting to link
    if (cardBrand && !["mc", "visa", "discover"].includes(cardBrand)) {
      dispatch(setConnectBankVariant("ErrorUnsupportedCardBrand"));
      trackError("CardConnect", "Save Adyen Card: Unsupported card type", {
        connector: "adyen_card",
      });
      dispatch(openBankModal());
      dispatch(setBankLinkingStep("ConnectBank"));
      dispatch(resetConnectionState());
      dispatch(setHideAdyenModal(true));
      dispatch(setAdyenReloadNeeded(true));
      return;
    }
    if (!isProcessingSave) {
      setIsProcessingSave(true);
      await linkBank(false, createInstrument);
      setIsProcessingSave(false);
    }

    // Hide the Adyen input UI
    dispatch(setHideAdyenModal(true));
  };

  return { handleSaveCardClick };
};

export default useHandleSaveCard;
