import { UserBillingAddress } from "~common/services";

const getAddressForCatchCardApplication = (
  billingAddress?: UserBillingAddress | null
) => {
  if (!billingAddress) {
    return null;
  }

  return {
    address_1: billingAddress.address_1,
    address_2: billingAddress.address_2,
    city: billingAddress.city,
    zone_code: billingAddress.zone_code,
    postal_code: billingAddress.postal_code,
    country_code: billingAddress.country_code,
  };
};

export { getAddressForCatchCardApplication };
