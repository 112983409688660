import { useEffect, useState } from "react";

import {
  RewardCampaignResponseData,
  useGetRewardCampaign,
} from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { ErrorResponseData } from "~common/services/types/error-handling-types";
import { useTracking } from "~common/tracking";

const useRewardCampaign = (rewardCampaignId: string) => {
  const { captureException } = useTracking();
  const [rewardCampaign, setRewardCampaign] =
    useState<RewardCampaignResponseData | null>(null);
  const [error, setError] = useState<ErrorResponseData | null>(null);

  const {
    refetch,
    loading,
    error: serviceError,
  } = useGetRewardCampaign({
    pathParams: {
      rewardCampaignId,
    },
    lazy: true,
  });

  useEffect(() => {
    if (!rewardCampaignId) {
      return;
    }
    const refresh = async (): Promise<void> => {
      try {
        const responseData = await refetch();

        if (responseData) {
          setRewardCampaign(responseData);
        }
      } catch (_error) {
        // Restful-react can't properly handle catching
        // errors on lazy GET requests yet. See the useEffect
        // below for handling.
      }
    };

    if (rewardCampaign === null) {
      void refresh();
    }
  }, [rewardCampaign, refetch, rewardCampaignId]);

  // Handle service errors and update error state.
  useEffect(() => {
    let newError;

    if (serviceError !== null) {
      newError = convertRawServiceError(serviceError);
      captureException({
        component: `useRewardCampaign`,
        exceptionMessage: `Error fetching Reward Campaign`,
        rawError: serviceError,
      });
    } else {
      newError = null;
    }

    setError(newError);
  }, [serviceError, captureException]);

  return { rewardCampaign, loading, error };
};

export default useRewardCampaign;
