import React from "react";

import { Stack } from "@mui/material";

import { SparkleIcon } from "~common/components/icons/vector";

const LandingPageRewardCard: React.FC = ({ children }) => (
  <Stack direction="row" color="primary.main" position="relative" sx={{
    "& > .MuiBox-root svg": {
      width: "auto"
    }
  }}>
    <SparkleIcon size="original" sx={{
      alignSelf: "flex-end",
      pb: 1,
    }} />
    {children}
    <SparkleIcon size="original" sx={{
      alignSelf: "flex-start",
      mt: -5,
    }} />
  </Stack>
);

export default LandingPageRewardCard;
