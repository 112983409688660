import { useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import { TransactionalSmsRequest } from "./types/sms-types";

const useTransactionalSms = () =>
  useMutate<
    undefined,
    ErrorResponseData,
    undefined,
    TransactionalSmsRequest,
    unknown
  >({
    verb: "POST",
    path: "users-svc/sms/send_transactional/public",
  });

export { useTransactionalSms };
