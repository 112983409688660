import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Send as SendIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import {
  DefaultButton,
  PrimaryButton,
} from "~common/components/controls/buttons";
import { AlertIcon } from "~common/components/icons/vector";
import ExternalTextLink from "~common/components/links/ExternalTextLink";
import { useInitiateAuth } from "~common/services";
import { formatUSPhoneNumber } from "~common/utils/phone-number";
import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import { selectAuthSession } from "~src/store";
import { clearAuthSession, setAuthSession } from "~src/store/slices/user-slice";

type AnswerOTPServiceError =
  | "incorrect-code"
  | "max-attempts"
  | "expired"
  | "generic";

const getServiceErrorTitleCopy = (
  serviceError: AnswerOTPServiceError
): React.ReactNode => {
  switch (serviceError) {
    case "max-attempts":
      return "Oops! Too many incorrect attempts.";
    case "expired":
      return "Oops! That code has expired.";
    default:
      return "Oops! Something went wrong.";
  }
};

type AnswerOTPErrorPanelProps = {
  serviceError: AnswerOTPServiceError;
  onCodeResent: () => void;
};

const AnswerOTPErrorPanel: React.VFC<AnswerOTPErrorPanelProps> = ({
  serviceError,
  onCodeResent,
}) => {
  const authSession = useSelector(selectAuthSession);
  const { mutate: initiateAuth } = useInitiateAuth();
  const dispatch = useDispatch();

  if (!authSession) return null;

  const handleResendCodeClick = async (): Promise<void> => {
    const { phoneNumber } = authSession;
    const { session } = await initiateAuth({
      phone_number: phoneNumber,
    });
    dispatch(
      setAuthSession({
        id: session,
        phoneNumber,
      })
    );
    onCodeResent();
  };

  const handleUseDifferentNumberClick = (): void => {
    dispatch(clearAuthSession());
  };

  return (
    <SmallPagePanel
      icon={<AlertIcon size="small" />}
      title={getServiceErrorTitleCopy(serviceError)}
      subtitle={`Let’s send a new code to ${formatUSPhoneNumber(
        authSession.phoneNumber
      )} or enter a different phone number.`}
    >
      <Stack spacing={4}>
        <PrimaryButton
          startIcon={<SendIcon />}
          onClick={handleResendCodeClick}
          fullWidth
        >
          Send a new code
        </PrimaryButton>
        <DefaultButton onClick={handleUseDifferentNumberClick} fullWidth>
          Use a different phone number
        </DefaultButton>
      </Stack>
      <Typography
        variant="bodySmall"
        color="grey.500"
        sx={{
          a: {
            typography: "linkSmall",
          },
        }}
      >
        Trouble logging in?{" "}
        <ExternalTextLink
          size="small"
          href="https://help.getcatch.com/"
          color="grey.500"
          sx={{ "&.MuiTypography-root": { fontWeight: "400" } }}
          eventName="Contact Support Clicked"
        >
          Contact Support
        </ExternalTextLink>
      </Typography>
    </SmallPagePanel>
  );
};

export default AnswerOTPErrorPanel;
export type { AnswerOTPServiceError };
