import React from "react";

import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";

type EditCardProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const EditCard: React.VFC<EditCardProps> = ({ children, onClick }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    spacing={4}
    p={4}
    sx={({ shape }) => ({
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      borderRadius: `${shape.borderRadius * 2}px`,
    })}
  >
    <Typography color="grey.700" component="div">
      {children}
    </Typography>

    {onClick && (
      <DefaultButton onClick={onClick} size="small">
        Edit
      </DefaultButton>
    )}
  </Stack>
);

export default EditCard;
