import React, { useState } from "react";

import { alpha } from "@mui/material";

import RewardCard from "~common/components/cards/RewardCard";
import { Reward } from "~common/services";
import { useTracking } from "~common/tracking";
import { formatNumDays, formatUSDate } from "~common/utils/dates";
import { formatMoney } from "~common/utils/money";
import { isExpiredRevivalBoost } from "~common/utils/reward-boosts";
import { formatRelativeLink, normalizeUrl } from "~common/utils/urls";
import { getUtmParams } from "~src/utils/getUtmsParams";

import MerchantCardMenu from "./MerchantCardMenu";
import MyCreditsCatchCashTooltip from "./MyCreditsCatchCashTooltip";
import ShareAsGiftModal from "./ShareAsGiftModal";

type MyCreditsCardProps = {
  reward: Reward;
  readOnly?: boolean;
};

const MyCreditsCard: React.VFC<MyCreditsCardProps> = ({
  reward,
  readOnly = false,
}) => {
  const { trackEvent } = useTracking();
  const [showModal, setShowModal] = useState<boolean>(false);
  const amount = reward.amount - (reward.total_pending_hold || 0);

  const rewardEventData = {
    merchant: reward.merchant_name,
    credit_amount: amount,
  };

  const handleShopNowClick = () => {
    trackEvent("Credit ShopNow clicked", rewardEventData);
  };

  const handleShareAsGiftClick = () => {
    trackEvent("Credit ShareAsGift clicked", rewardEventData);
    setShowModal(true);
  };

  const getTooltipText = () => {
    if (!reward.associated_reward_boost) {
      return "";
    }

    if (isExpiredRevivalBoost(reward.associated_reward_boost)) {
      return `${
        reward.merchant_name || "Catch"
      } has extended your credit expiration date by ${formatNumDays(
        reward.associated_reward_boost.granted_reward_expiration_in_days
      )}`;
    }

    return `Your credit has increased by ${
      reward.associated_reward_boost.reward_amount_multiplier
        ? `${reward.associated_reward_boost.reward_amount_multiplier || 0}x`
        : formatMoney(reward.associated_reward_boost.reward_drop_amount || 0)
    } for the next ${formatNumDays(
      reward.associated_reward_boost.granted_reward_expiration_in_days
    )} only. (Ends ${formatUSDate(reward.expiration || "")})`;
  };

  const cardMenuProps = {
    isGiftable: reward.is_giftable,
    color: alpha(reward.merchant_card_font_color || "#000", 0.96),
    handleShopNowClick,
    ...(reward.merchant_id === "FLEX_MERCHANT_ID"
      ? {
          merchantUrl: formatRelativeLink("shop"),
          sx: {
            "& > .MuiButtonBase-root": {
              width: 40,
            },
          },
        }
      : {
          merchantUrl: `${normalizeUrl(
            reward.merchant_url || ""
          )}/?${getUtmParams("web_app")}`,
          handleShareAsGiftClick,
        }),
  };

  const rewardCardProps = {
    reward: {
      ...reward,
      amount,
    },
    topRight: readOnly ? null : <MerchantCardMenu {...cardMenuProps} />,
    ...(reward.merchant_id === "FLEX_MERCHANT_ID"
      ? {
          merchantTooltip: <MyCreditsCatchCashTooltip />,
        }
      : {
          amountTooltip: reward.original_reward_amount
            ? getTooltipText()
            : undefined,
          expirationTooltip: reward.original_reward_expiration
            ? getTooltipText()
            : undefined,
        }),
  };

  return (
    <>
      <RewardCard {...rewardCardProps} />

      {reward.is_giftable && !readOnly && (
        <ShareAsGiftModal
          open={showModal}
          onClose={() => setShowModal(false)}
          creditCard={
            <RewardCard
              {...rewardCardProps}
              sx={{ width: 224 }}
              topRight={null}
            />
          }
          merchantName={reward.merchant_name || ""}
          rewardIds={reward.reward_ids}
          fromRewardCampaign={reward.reference_reward_campaign_ids.length > 0}
          rewardEventData={rewardEventData}
        />
      )}
    </>
  );
};

export default MyCreditsCard;
