import { useCallback } from "react";

import { useUniqueId } from "./accessibility-hooks";

type ResolveId = (base: string) => string;

const useIdResolver = (): ResolveId => {
  const uniqueId = useUniqueId();
  return useCallback((base) => `${base}${uniqueId}`, [uniqueId]);
};

export default useIdResolver;
