import { useCallback } from "react";
import { useSelector } from "react-redux";

import { useSendEmailVerificationCode } from "~common/services";
import { convertRawServiceError } from "~common/services/error-handling";
import { useTracking } from "~common/tracking";
import { selectAuthData, selectAuthUserInfo } from "~src/store";

const useSendEmailCode = () => {
  const { trackEvent, trackError } = useTracking();
  const authUserInfo = useSelector(selectAuthUserInfo);
  const authData = useSelector(selectAuthData);
  const { mutate: sendEmailVerificationCode } = useSendEmailVerificationCode();

  const sendCode = useCallback(
    async ({ cause }: { cause: string }): Promise<void> => {
      if (!authData || !authUserInfo || authUserInfo.emailVerified) {
        return;
      }

      try {
        await sendEmailVerificationCode({
          access_token: authData.access_token,
          attribute_name: "email",
        });
        trackEvent("Sent email verification code", { cause });
      } catch (rawError) {
        const error = convertRawServiceError(rawError);

        if (!error.status || error.status >= 500 || error.status === 400) {
          // These errors we will handle in code instead of alerting Sentry
          throw rawError;
        }
        trackError("useSendEmailCode", "Sending email verification code", {
          error,
          cause,
        });
      }
    },
    [authData, authUserInfo, sendEmailVerificationCode, trackEvent, trackError]
  );

  return sendCode;
};

export default useSendEmailCode;
