import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

const useHideIntercom = (shouldHide: boolean) => {
  const { update } = useIntercom();

  useEffect(() => {
    if (shouldHide) {
      update({ hideDefaultLauncher: true });
      return;
    }

    update({ hideDefaultLauncher: false });
  }, [update, shouldHide]);
};

export default useHideIntercom;
