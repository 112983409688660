import React from "react";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { Palette } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { useTracking } from "~common/tracking";

type CategoryTabButtonProps = {
  categoryName: string;
};

type CategoryTabArrowButtonProps = {
  direction: "left" | "right";
  onClick: () => void;
};

const sharedCategoryTabButtonStyles = ({ palette }: { palette: Palette }) => ({
  "&, &.MuiButton-sizeMedium": {
    background: "none",
    borderRadius: "20px",
    height: "40px",
    border: `1px solid ${palette.grey[500]}`,
    fontSize: "16px",
    color: palette.grey[700],
    fontWeight: 500,
  },
});

const CategoryTabButton: React.VFC<CategoryTabButtonProps> = ({
  categoryName,
}) => {
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent("Shop CategoryTabButton clicked", { categoryName });
  };

  return (
    <DefaultButton
      href={`#${categoryName}`}
      onClick={handleClick}
      sx={[
        sharedCategoryTabButtonStyles,
        {
          "&, &.MuiButton-sizeMedium": {
            px: 4,
            py: 2,
          },
        },
      ]}
    >
      {categoryName}
    </DefaultButton>
  );
};

const CategoryTabArrowButton: React.VFC<CategoryTabArrowButtonProps> = ({
  direction = "right",
  onClick,
}) => (
  <DefaultButton
    onClick={onClick}
    sx={[
      sharedCategoryTabButtonStyles,
      {
        "&, &.MuiButton-sizeMedium": {
          height: 40,
          minHeight: 40,
          width: 40,
          minWidth: 40,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& > .label": {
            width: 16,
            height: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > .MuiSvgIcon-root": {
              width: 16,
              height: 16,
            },
          },
        },
      },
    ]}
  >
    {direction === "right" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
  </DefaultButton>
);

export default CategoryTabButton;
export { CategoryTabArrowButton };
