import React from "react";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";

import { useUniqueId } from "../../../hooks/accessibility-hooks";
import WrappedAccordion from "../../accordions/WrappedAccordion";
import ExternalTextLink from "../../links/ExternalTextLink";

const ConnectBankFAQ: React.VFC = () => {
  const headerId = useUniqueId("panel-header");
  const contentId = useUniqueId("panel-content");
  const { isCatch } = useTheme();

  return (
    <WrappedAccordion
      square
      className="BankLinking-connectBankFAQ"
      sx={({ spacing, palette }) => ({
        "&.MuiPaper-root": {
          borderBottom: `1px solid ${palette.grey[300]}`,
          "&.Mui-expanded": {
            marginTop: spacing(8),
          },
          ".MuiAccordionSummary-root": {
            alignItems: "flex-start",
          },
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={contentId}
        id={headerId}
        sx={{ pb: 6 }}
      >
        <Typography variant="bodyRegular" color="grey.600">
          Why connect my bank?{isCatch ? <> 🤔</> : ""}
        </Typography>
      </AccordionSummary>
      <AccordionDetails id={contentId} sx={{ pb: 6 }}>
        <Typography color="grey.600" variant="bodyRegular">
          When you pay by bank, brands save on credit card fees. Catch makes
          bank payments{" "}
          <ExternalTextLink
            size="regular"
            href="https://help.getcatch.com/en/articles/5010849-how-does-catch-process-my-payment"
            eventName="Safe and Easy Clicked"
          >
            safe and easy
          </ExternalTextLink>
          , so brands can pass their savings to you!
        </Typography>
      </AccordionDetails>
    </WrappedAccordion>
  );
};

export default ConnectBankFAQ;
