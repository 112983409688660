import React, { useEffect, useState } from "react";

import { css, Global } from "@emotion/react";
import { CssBaseline, useTheme } from "@mui/material";

const NAVBAR_HEIGHT_PX = 80;

const GlobalStyles: React.VFC = () => {
  const { spacing } = useTheme();

  // Compute and store the scrollbar width in a CSS variable.
  // This is used to offset the width of the page when the scrollbar is visible.
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
  useEffect(() => {
    // We create a temporary element to measure the scrollbar width, and keep it
    // invisible to the user before quickly removing it.
    const tempEl = document.createElement("div");
    tempEl.style.cssText =
      "overflow:scroll; visibility:hidden; position:absolute;";
    document.body.appendChild(tempEl);
    // Compute and save the scrollbar width.
    setScrollbarWidth(tempEl.offsetWidth - tempEl.clientWidth);
    // Clean up the temporary element.
    tempEl.remove();
  }, []);

  return (
    <>
      <CssBaseline />
      <Global
        styles={css`
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          :root {
            --navbar-height: ${NAVBAR_HEIGHT_PX}px;
            --navbar-content-max-width: 1140px;
            --small-page-panel-max-width: 464px;
            --medium-page-panel-max-width: 772px;
            --large-page-panel-max-width: 1140px;
            --scrollbar-width: ${scrollbarWidth}px;
          }

          html,
          body,
          #root {
            height: 100%;
            overscroll-behavior-x: none;
          }

          #root {
            overflow-x: hidden;
            overflow-y: scroll;
            scroll-behavior: smooth;
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          /*
          Override positioning of the Intercom launcher. We use "body"
          in this selector to ensure a higher specificity.
          */
          body .intercom-launcher {
            bottom: ${spacing(6)};
            right: ${spacing(6)};
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
export { NAVBAR_HEIGHT_PX };
