import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { Box } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { selectCatchCard } from "~src/store";

import CatchCardModal from "../catch-card/CatchCardModal";
import InfoCard from "../shared/InfoCard";
import CatchCardError from "../shared/onboarding/CatchCardError";
import CatchCardDetails from "./CatchCardDetails";
import CatchCardPinCard from "./CatchCardPinCard";

const CatchCard: React.VFC = () => {
  const intercom = useIntercom();
  const [openModal, setOpenModal] = useState(false);
  const catchCard = useSelector(selectCatchCard);
  const hasError =
    catchCard?.user_flow_status === "denied" ||
    catchCard?.card_status === "closed" ||
    catchCard?.card_status === "suspended";

  if (catchCard?.user_flow_status === "pending_manual_review") {
    return (
      <InfoCard
        heading="Your Catch card application is being reviewed."
        cta={
          <DefaultButton onClick={() => intercom.show()}>
            Contact us
          </DefaultButton>
        }
      >
        <Box>
          It may take up to 48 hours for your application to be approved.
        </Box>
      </InfoCard>
    );
  }

  if (hasError) {
    return (
      <InfoCard>
        <CatchCardError height="100%" justifyContent="space-between" />
      </InfoCard>
    );
  }

  return (
    <>
      <>
        {catchCard?.is_pin_set ? (
          <CatchCardDetails />
        ) : (
          <CatchCardPinCard onClick={() => setOpenModal(true)} />
        )}
      </>

      <CatchCardModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        initialScreen="pin"
      />
    </>
  );
};

export default CatchCard;
