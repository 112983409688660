import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatsigUser } from "statsig-react";

type ExperimentationState = {
  // This is the user passed into the StatsigProvider, it is not
  // necessarily equivalent to the normally referenced "statsigUser"
  // in the shared experimentation code, although it may be set to this value
  statsigProviderUser: StatsigUser;
  // Used to determine when we can pull experiments effectively, namely when
  // the StatsigProvider user has been updated with the hashed IP
  isStatsigReady: boolean;
  // Statsig's initCompletionCallback can return a failure if the timeout of
  // 3000ms is hit
  isStatsigErrored: boolean;
};

const initialState: ExperimentationState = {
  statsigProviderUser: {},
  isStatsigReady: false,
  isStatsigErrored: false,
};

const slice = createSlice({
  name: "experimentation",
  initialState,
  reducers: {
    setStatsigProviderUser: (state, action: PayloadAction<StatsigUser>) => {
      state.statsigProviderUser = action.payload;
    },
    setIsStatsigReady: (state, action: PayloadAction<boolean>) => {
      state.isStatsigReady = action.payload;
    },
    setIsStatsigErrored: (state, action: PayloadAction<boolean>) => {
      state.isStatsigReady = action.payload;
    },
  },
});

export const {
  setIsStatsigReady,
  setStatsigProviderUser,
  setIsStatsigErrored,
} = slice.actions;
export default slice.reducer;
export type { ExperimentationState };
