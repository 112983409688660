import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Stack, Typography } from "@mui/material";

import {
  PrimaryButton,
} from "~common/components/controls/buttons";
import { HighFiveIcon, MeditatingCoinIcon, ProtectionIcon } from "~common/components/icons/vector";
import WavySection from "~common/components/layout/WavySection";
import { useTracking } from "~common/tracking";
import { selectClaims } from "~src/store";

import MerchantMarquee from "../home/MerchantMarquee";
import LearnMoreButton from "../shared/LearnMoreButton";
import BaseValueCard from "./BaseValueCard";

type LandingPagePanelProps = {
  ctaCopy: string;
};

const LandingPagePanel: React.FC<LandingPagePanelProps> = ({
  ctaCopy,
  children,
}) => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const claims = useSelector(selectClaims);

  const handleContinueClick = () => {
    trackEvent("Continue with Catch", {
      giftSecret: claims.giftSecret,
      rewardCampaignId: claims.rewardCampaignId,
      referralCode: claims.referralCode,
    });
    navigate("/home");
  };

  return (
    <Stack>
      <Stack
        alignItems="center"
        sx={({ breakpoints }) => ({
          py: 12,
          px: 6,
          margin: "0 auto",
          width: "100%",
          textAlign: "center",
          [breakpoints.up("sm")]: {
            maxWidth: 396,
          },
        })}
      >
        {children}

        <PrimaryButton sx={{ mt: 6 }} onClick={handleContinueClick} fullWidth>
          {ctaCopy}
        </PrimaryButton>

        <Typography variant="bodyRegular" sx={{ mt: 4 }}>
          No debt · No fees · Easy sign up
        </Typography>
      </Stack>

      <WavySection>
        <Box maxWidth={848} mx="auto">
          <Grid
            container
            pt={{
              zero: 4,
              sm: 8,
            }}
            pb={{
              zero: 12,
              sm: 16,
            }}
            px={{ sm: 6 }}
            mt={0}
            rowSpacing={8}
            columnSpacing={{ sm: 16 }}
          >
            <Grid item maxWidth="100%" sm={6}>
              <Stack
                spacing={4}
                sx={{
                  textAlign: {
                    zero: "center",
                    sm: "left"
                  }
                }}
              >
                <Stack spacing={4} px={{ zero: 6, sm: 0 }}>
                  <Typography
                    variant="h1"
                    component="h3"
                    color="grey.700"
                  >
                    A win-win for you and your favorite brands
                  </Typography>

                  <Typography
                    variant="bodyLarge"
                    color="grey.600"
                  >
                    Brands save on credit card fees and pass those savings to you.
                    Everyone wins &ndash; except the credit card companies.
                  </Typography>
                </Stack>

                <MerchantMarquee sx={{
                  background: "transparent",
                  height: 76
                }} />

                <Stack spacing={4} pt={4} sx={{
                  display: {
                    zero: "none",
                    sm: "block",
                  }
                }}>
                  <PrimaryButton onClick={handleContinueClick} fullWidth>
                    {ctaCopy}
                  </PrimaryButton>
                  <LearnMoreButton eventComponent="LandingPagePanel" fullWidth />
                </Stack>
              </Stack>
            </Grid>

            <Grid item sm={6} px={{ zero: 6, sm: 0 }}>
              <Stack spacing={4}>
                <BaseValueCard
                  title="Equitable rewards"
                  description="5-10% store credit every time, no matter who you are or what your credit score is."
                  icon={<HighFiveIcon size="extrasmall" />}
                />
                <BaseValueCard
                  title="Savings, never debt"
                  description="It&apos;s free. No debt. No fees. Our business model aligns our incentive with yours."
                  icon={<MeditatingCoinIcon size="extrasmall" />}
                />
                <BaseValueCard
                  title="Safe & secure"
                  description="We link your payment method through providers trusted by Google Pay, Venmo, Cash App."
                  icon={<ProtectionIcon size="extrasmall" />}
                />

                <Stack spacing={4} pt={4} sx={{
                  display: {
                    sm: "none",
                  }
                }}>
                  <PrimaryButton onClick={handleContinueClick} fullWidth>
                    {ctaCopy}
                  </PrimaryButton>
                  <LearnMoreButton eventComponent="LandingPagePanel" fullWidth />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </WavySection>
    </Stack>
  );
};

export default LandingPagePanel;
