import React from "react";

import { Box, CircularProgress } from "@mui/material";

type GenericLoadingScreenProps = {
  isFullscreen?: boolean;
};

// NB: The `GenericLoadingScreen` is intended for use ahead of loading a
// custom merchant theme. Therefore, we do not use any values that may
// vary across themes (such as color palette values) to style this component.

const GenericLoadingScreen: React.VFC<GenericLoadingScreenProps> = ({
  isFullscreen = false,
}) => (
  <Box
    sx={[
      {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        color: "#666666",
      },
      isFullscreen && {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
      },
    ]}
  >
    <CircularProgress color="inherit" size={24} />
  </Box>
);

export default GenericLoadingScreen;
