import { RewardPoolEntriesResponseData } from "~common/services";
import { makeFetchSlice } from "~common/store/store-utils";

const slice = makeFetchSlice<RewardPoolEntriesResponseData | null>(
  "rewardPoolEntries",
  null
);

const { reducer, actions: rewardPoolEntriesActions } = slice;

export { rewardPoolEntriesActions };
export default reducer;
