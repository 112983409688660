import { Components, Theme } from "@mui/material";

const buildAccordion = (theme: Theme): Components["MuiAccordion"] => {
  const { palette, spacing, shape } = theme;
  return {
    defaultProps: {
      disableGutters: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        overflow: "hidden",
        boxShadow: "none",
        ".MuiAccordionSummary-root": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        ".MuiAccordionSummary-content": {
          margin: 0,
        },
        ".MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          color: palette.grey[600],
          ".MuiSvgIcon-fontSizeMedium": {
            fontSize: 24,
          },
        },
        ".MuiAccordionDetails-root": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        ...(ownerState.variant === "card" && {
          borderRadius: `${shape.borderRadius * 2}px`,
          border: `1px solid ${palette.grey[300]}`,
          "&.Mui-expanded": {
            backgroundColor: palette.grey[200],
            border: "none",
          },
          ".MuiAccordionSummary-root": {
            padding: spacing(4),
          },
          ".MuiAccordionSummary-root.Mui-expanded": {
            pointerEvents: "none",
          },
          ".MuiAccordionDetails-root": {
            padding: spacing(2, 4, 4, 4),
          },
        }),
      }),
    },
  };
};

export default buildAccordion;
