import { selectRewardPoolEntries } from "~src/store";
import { rewardPoolEntriesActions } from "~src/store/slices/services/rewardPoolEntries-slice";

import useLazyService from "./useLazyService";

const useRewardPoolEntries = () => {
  const { data, loading, error } = useLazyService(
    selectRewardPoolEntries,
    rewardPoolEntriesActions
  );

  return {
    rewardPoolEntries: data?.reward_pool_entries || null,
    loading,
    error,
  };
};

export default useRewardPoolEntries;
