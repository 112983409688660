import React from "react";

import { Box } from "@mui/material";

const Nowrap: React.FC = ({ children }) => (
  <Box component="span" sx={{ whiteSpace: "nowrap" }}>
    {children}
  </Box>
);

export default Nowrap;
