import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";

type FetchState<T> = {
  data: T;
  loading: boolean;
  error: unknown;
  // Status for coordinating requests for data.
  status: "unfetched" | "requested" | "fetched";
  // The timestamp of the last API fetch.
  fetched: number;
};

const emptyFetchState = <T>(data: T): FetchState<T> => ({
  data,
  loading: false,
  error: null,
  status: "unfetched",
  fetched: 0,
});

const makeFetchSlice = <T>(name: string, initialData: T) => {
  const initialState = emptyFetchState<T>(initialData);

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      setData: (state, action: PayloadAction<T>) => {
        state.data = action.payload as Draft<T>;
      },
      setLoading: (state, action: PayloadAction<FetchState<T>["loading"]>) => {
        state.loading = action.payload;
      },
      setError: (state, action: PayloadAction<FetchState<T>["error"]>) => {
        state.error = action.payload;
      },
      setStatus: (state, action: PayloadAction<FetchState<T>["status"]>) => {
        state.status = action.payload;
      },
      setFetched: (state, action: PayloadAction<FetchState<T>["fetched"]>) => {
        state.fetched = action.payload;
      },
      resetState: (state) => {
        state.data = initialState.data as Draft<T>;
        state.loading = initialState.loading;
        state.error = initialState.error;
        state.status = initialState.status;
        state.fetched = initialState.fetched;
      },
      manualSet: (state, action: PayloadAction<T>) => {
        state.data = action.payload as Draft<T>;
        state.loading = initialState.loading;
        state.error = initialState.error;
        state.status = "fetched";
        state.fetched = Date.now();
      },
    },
  });

  const { reducer, actions } = slice;

  return { reducer, actions };
};

const makeFetchSelectors = <T, TRootState>(slice: keyof TRootState) => {
  const selectors = {
    data: createSelector(
      (state: TRootState) => (state[slice] as unknown as FetchState<T>).data,
      (data) => data
    ),
    loading: createSelector(
      (state: TRootState) => (state[slice] as unknown as FetchState<T>).loading,
      (loading) => loading
    ),
    error: createSelector(
      (state: TRootState) => (state[slice] as unknown as FetchState<T>).error,
      (error) => error
    ),
    status: createSelector(
      (state: TRootState) => (state[slice] as unknown as FetchState<T>).status,
      (status) => status
    ),
    fetched: createSelector(
      (state: TRootState) => (state[slice] as unknown as FetchState<T>).fetched,
      (fetched) => fetched
    ),
  };

  return selectors;
};

export { makeFetchSelectors, makeFetchSlice };
export type { FetchState };
