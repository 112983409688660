import { RewardPoolEventsResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectRewardPoolEvents = makeFetchSelectors<
  RewardPoolEventsResponseData | null,
  RootState
>("rewardPoolEvents");

export { selectRewardPoolEvents };
