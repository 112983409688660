import { ExplorePublicMerchantCategoriesResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectExploreMerchants = makeFetchSelectors<
  ExplorePublicMerchantCategoriesResponseData,
  RootState
>("exploreMerchants");

export { selectExploreMerchants };
