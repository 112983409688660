import React from "react";

import { Typography } from "@mui/material";

import { AlertIcon } from "../../../../icons/vector";
import BankHeader from "../BankHeader";

type ConnectPaymentInstrumentAlertHeaderProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  headingId?: string;
};

type ConnectPaymentInstrumentAlertHeaderInstanceProps = Omit<
  ConnectPaymentInstrumentAlertHeaderProps,
  "title" | "subtitle"
>;

const ConnectPaymentInstrumentAlertHeader: React.VFC<
  ConnectPaymentInstrumentAlertHeaderProps
> = ({ title, subtitle, headingId }) => (
  <BankHeader icon={<AlertIcon size="small" />}>
    <Typography variant="h1" id={headingId}>
      {title}
    </Typography>
    <Typography variant="bodyRegular" color="grey.600">
      {subtitle}
    </Typography>
  </BankHeader>
);

export default ConnectPaymentInstrumentAlertHeader;
export type { ConnectPaymentInstrumentAlertHeaderInstanceProps };
