import {
  MobileToWebEventType,
  WEB_TO_MOBILE_EVENT,
  WebToMobileEventType,
} from "../experimentation/shared/mobile-app-messaging-types";
import EventBus from "./EventBus";

class MobileAppEventBus extends EventBus<
  typeof WEB_TO_MOBILE_EVENT | MobileToWebEventType
> {
  sendEventToMobile = (
    type: WebToMobileEventType,
    detail?: Record<string, unknown>
  ) => {
    const data = {
      ...detail,
      type,
    };

    this.emit(WEB_TO_MOBILE_EVENT, { data });
  };

  listenToEventFromMobile = (
    type: MobileToWebEventType,
    handler: (e: CustomEvent) => void
  ) => {
    // NB: When using this function, we intentionally don't utilize the `off` function
    // of the EventBus as we can't accurately determine when our web apps are embedded
    // within our mobile apps and when to expect events from those apps.
    this.on(type, handler);
  };
}

export default MobileAppEventBus;
