import React from "react";

import {
  CardGiftcard as GiftIcon,
  HourglassBottom as ExpiredIcon,
  LocalAtm as BonusIcon,
  Replay as ReturnIcon,
  ShoppingCartOutlined as CartIcon,
} from "@mui/icons-material";

import {
  DonateIcon,
  ReferralIcon,
  RewardBoostIcon,
} from "~common/components/icons/vector";
import { ConsumerActivity } from "~common/services";

type ActivityFeedIconProps = {
  type: ConsumerActivity["activity_type"];
};

const ActivityFeedIcon: React.VFC<ActivityFeedIconProps> = ({ type }) => {
  const sx = {
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
  };

  if (type === "admin_granted_reward" || type === "new_user_bonus_earned") {
    return <BonusIcon sx={sx} />;
  }

  if (type === "donation") {
    return <DonateIcon size="icon" sx={sx} />;
  }

  if (
    type === "gift_received" ||
    type === "gift_sent" ||
    type === "reward_campaign_claimed"
  ) {
    return <GiftIcon sx={sx} />;
  }

  if (type === "purchase" || type === "reward_applied") {
    return <CartIcon sx={sx} />;
  }

  if (type === "referral") {
    return <ReferralIcon size="icon" sx={sx} />;
  }

  if (type === "refund") {
    return <ReturnIcon sx={sx} />;
  }

  if (type === "reward_boost") {
    return <RewardBoostIcon size="icon" sx={sx} />;
  }

  if (type === "reward_expired") {
    return <ExpiredIcon sx={sx} />;
  }

  return null;
};

export default ActivityFeedIcon;
