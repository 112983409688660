import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.6595 8.62012C39.6595 8.62012 47.8859 16.8017 65.1276 21.8451V43.0275C65.1276 57.4854 52.3935 63.0892 39.3214 71.3828C26.2493 63.2012 13.6279 57.4854 13.6279 43.0275V21.8451C30.8696 16.8017 39.096 8.62012 39.096 8.62012H39.6595Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.547 15.4565C39.547 15.4565 45.9704 21.8449 59.4933 25.7676V42.3548C59.4933 53.6745 49.5765 58.0455 39.3216 64.4339C29.0668 58.1576 19.2627 53.6745 19.2627 42.3548V25.7676C32.6729 21.8449 39.209 15.4565 39.209 15.4565H39.547Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.7733 36.5273H30.9824V47.9591H47.7733V36.5273Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9268 36.3031V32.8287C34.9268 30.4751 36.8425 28.4578 39.3217 28.4578C41.6882 28.4578 43.7166 30.3631 43.7166 32.8287V36.3031"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3212 42.6914C40.2548 42.6914 41.0116 41.9387 41.0116 41.0102C41.0116 40.0818 40.2548 39.3291 39.3212 39.3291C38.3877 39.3291 37.6309 40.0818 37.6309 41.0102C37.6309 41.9387 38.3877 42.6914 39.3212 42.6914Z"
      fill="currentColor"
    />
    <path
      d="M39.3213 40.562V43.9243"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
