import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useEnv } from "../contexts/env-context";
import { useTracking } from "../tracking";
import {
  getLocalStorageWithFallback,
  removeLocalStorageWithFallback,
  setLocalStorageWithFallback,
} from "../utils/browser-storage";
import { getLocalDeviceTokenKey } from "../utils/device-token";
import EventBus from "../utils/EventBus";
import { embeddedMessenger } from "../utils/iframe-messaging";

const localDeviceTokenBus = new EventBus({ overrideTargetToWindow: false });
const SET_VALUE_EVENT = "SET_VALUE";

const useLocalDeviceToken = (): [
  string | null,
  (deviceToken: string | null) => void
] => {
  const { APP_STAGE, APP_ENVIRONMENT } = useEnv();
  const { trackError } = useTracking();
  const key = getLocalDeviceTokenKey(APP_ENVIRONMENT, APP_STAGE);

  const handleLocalStorageError = useCallback(
    (error: Error) => {
      // Don't track "Access is denied" errors in Segment. We expect these
      // to throw whenever reading from localStorage in an incognito window
      // if the user has third-party cookies disabled, so we ignore this.
      if (!error.message.includes("Access is denied")) {
        trackError(
          "useLocalDeviceToken",
          "Accessing localStorage (unexpected)",
          {
            originalError: error,
          }
        );
      }
    },
    [trackError]
  );

  const [value, setValue] = useState<string | null>(
    () => getLocalStorageWithFallback(key, handleLocalStorageError) || null
  );

  useEffect(() => {
    const handleSetValue = (e: CustomEvent) => {
      const detail = e.detail as { deviceToken: string | null };
      setValue(detail.deviceToken);
    };

    localDeviceTokenBus.on(SET_VALUE_EVENT, handleSetValue);

    return () => {
      localDeviceTokenBus.off(SET_VALUE_EVENT, handleSetValue);
    };
  }, []);

  return [
    value,
    (deviceToken: string | null): void => {
      const handleLocalStorageSuccess = () => {
        localDeviceTokenBus.emit(SET_VALUE_EVENT, { deviceToken });
      };

      if (deviceToken === null) {
        removeLocalStorageWithFallback(
          key,
          handleLocalStorageSuccess,
          handleLocalStorageError
        );
      } else {
        setLocalStorageWithFallback(
          key,
          deviceToken,
          handleLocalStorageSuccess,
          handleLocalStorageError
        );
      }
    },
  ];
};

const useNotifyRefererDeviceTokenUpdates = () => {
  const [searchParams] = useSearchParams();
  const [deviceToken] = useLocalDeviceToken();

  useEffect(() => {
    const referer = searchParams.get("referer");
    if (!referer) {
      return;
    }
    const messenger = embeddedMessenger(referer);

    messenger.dispatch("CATCH_DEVICE_TOKEN", { deviceToken });

    // eslint-disable-next-line consistent-return
    return () => {
      messenger.destroy();
    };
  }, [deviceToken, searchParams]);
};

export {
  getLocalDeviceTokenKey,
  useLocalDeviceToken,
  useNotifyRefererDeviceTokenUpdates,
};
