import { useEffect, useState } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";
import { useSelector } from "react-redux";

import {
  useAnywhereIDVSession,
  useLatestConsumerKYCCheck,
} from "~common/services/risk-decisions";
import { useTracking } from "~common/tracking";
import { selectCurrentUser } from "~src/store";
import { getApplicationData } from "~src/utils/catch-card";

import usePaymentInstruments from "./services/usePaymentInstruments";

/*
  Plaid link tokens last 30 minutes, so set an
  interval for 25 minutes just in case.
*/
const MINUTE_MS = 6e4;
const PLAID_LINK_INTERVAL_MS = 25 * MINUTE_MS;

const useInitiatePlaidIDVLink = () => {
  const { trackEvent, trackError } = useTracking();
  const currentUser = useSelector(selectCurrentUser.data);
  const { mutate: fetchAnywhereIDVSession } = useAnywhereIDVSession();
  const { refetch: fetchLatestConsumerKYCCheck } = useLatestConsumerKYCCheck({
    lazy: true,
  });
  const { paymentInstruments } = usePaymentInstruments();
  const paymentInstrument = paymentInstruments[0] || null;
  const [plaidLinkToken, setPlaidLinkToken] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [pending, setPending] = useState(false);
  const [denied, setDenied] = useState(false);
  const [debitAuthFailed, setDebitAuthFailed] = useState(false);
  const [error, setError] = useState(false);

  /*
    1. Get the link token
  */
  useEffect(() => {
    const getLinkToken = async () => {
      const applicationData = getApplicationData(
        currentUser,
        paymentInstrument
      );

      if (applicationData) {
        try {
          const response = await fetchAnywhereIDVSession(applicationData);

          if (response.decision === "deny") {
            if (response.error_type === "DEBIT_AUTH_FAILURE") {
              trackEvent("Plaid IDV Link Token Debit Auth Failure");
              setDebitAuthFailed(true);
            } else {
              trackEvent("Plaid IDV Link Token Denied");
              setDenied(true);
            }

            return;
          }

          if (response.decision === "pending_manual_review") {
            trackEvent("Plaid IDV Link Token Pending Manual Review");
            setPending(true);
            return;
          }

          trackEvent("Plaid IDV Link Token Success");

          if (response.link_token) {
            setPlaidLinkToken(response.link_token);
          } else {
            setVerifying(true);
          }
        } catch (err) {
          trackError("useInitiatePlaidIDVLink", "Link token loading error", {
            error: err,
          });
          setError(true);
        }
      }
    };

    void getLinkToken();

    const plaidLinkInterval = setInterval(() => {
      void getLinkToken();
    }, PLAID_LINK_INTERVAL_MS);

    return () => {
      clearInterval(plaidLinkInterval);
    };
  }, [
    currentUser,
    paymentInstrument,
    fetchAnywhereIDVSession,
    trackEvent,
    trackError,
  ]);

  /*
   2. Define the success, failure, & event callbacks
  */
  const handleOnPlaidSuccess = (
    _plaidToken: string,
    plaidData: PlaidLinkOnSuccessMetadata
  ) => {
    setVerifying(true);
    trackEvent("Plaid IDV Link Success", { plaidData });
  };

  const handleOnPlaidExit = (
    _error: null | PlaidLinkError,
    plaidData: PlaidLinkOnExitMetadata
  ) => {
    if (plaidData.link_session_id) {
      trackEvent("Plaid IDV Link Exited", { plaidData });
    }
  };

  const handleOnPlaidEvent = (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    trackEvent(`Plaid IDV Link Event ${eventName}`, { metadata });
  };

  /*
   3. Initiate Plaid
  */
  const plaidConfig: PlaidLinkOptions = {
    token: plaidLinkToken,
    onSuccess: handleOnPlaidSuccess,
    onEvent: handleOnPlaidEvent,
    onExit: handleOnPlaidExit,
  };

  const { open, ready, error: plaidError, exit } = usePlaidLink(plaidConfig);

  useEffect(() => {
    if (plaidError) {
      trackError("useInitiatePlaidIDVLink", "Plaid loading error", {
        plaidError,
      });

      setError(true);
    }
  }, [plaidError, trackError]);

  /*
   4. Verify user
  */
  useEffect(() => {
    const getKYCCheck = async () => {
      try {
        let response = await fetchLatestConsumerKYCCheck();

        while (response?.decision === "active") {
          /* eslint-disable no-await-in-loop */
          await new Promise((resolve) => setTimeout(resolve, 5000));
          response = await fetchLatestConsumerKYCCheck();
          /* eslint-enable no-await-in-loop */
        }

        setVerifying(false);

        if (response) {
          trackEvent(
            `Plaid IDV KYC Check ${
              response.decision.charAt(0).toUpperCase() +
              response.decision.slice(1)
            }`
          );
        }

        if (response?.decision === "success") {
          setVerified(true);
          return;
        }

        if (response?.decision === "failed") {
          setDenied(true);
          return;
        }

        setError(true);
      } catch (err) {
        trackError("useInitiatePlaidIDVLink", "Plaid IDV KYC Check", {
          error: err,
        });
        setError(true);
      }
    };

    if (verifying) {
      void getKYCCheck();
    }
  }, [verifying, fetchLatestConsumerKYCCheck, trackEvent, trackError]);

  /*
    5. Return functions and status
  */
  return {
    ready,
    open,
    exit,
    verifying,
    verified,
    pending,
    denied,
    debitAuthFailed,
    error,
  };
};

export default useInitiatePlaidIDVLink;
