import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";

import React from "react";

import { Box } from "@mui/material";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const width = "calc(min(300px, max(224px, 85vw - 48px)))";

// See Swiper React docs: https://swiperjs.com/react

const DraggableWallet: React.FC = ({ children }) => (
  <Box
    sx={({ palette }) => ({
      pb: 6,
      position: "relative",
      ".swiper": {
        overflow: "visible",
        width,
        margin: 0,
      },
      ".swiper-wrapper": {
        height: `calc(${width}/1.618 + 48px)`,
      },
      ".swiper-pagination": {
        width: "calc(100vw - 48px)",
      },
      ".swiper-pagination-bullet": {
        background: palette.grey[300],
        opacity: 1,
        width: 8,
        height: 8,
        "&.swiper-pagination-bullet-active": {
          background: palette.grey[600],
          opacity: 1,
        },
      },
    })}
  >
    <Swiper
      effect="cards"
      spaceBetween={18}
      grabCursor
      modules={[Pagination]}
      pagination={{
        bulletElement: "button",
        clickable: true,
      }}
    >
      {(children as React.ReactNode[]).map((child, j) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={j}>{child}</SwiperSlide>
      ))}
    </Swiper>
  </Box>
);

export default DraggableWallet;
