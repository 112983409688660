import React from "react";

import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { Chip, Typography } from "@mui/material";

import { WrappedTooltip } from "~common/components/tooltips";

const MyCreditsPending: React.VFC = () => (
  <WrappedTooltip
    title={
      <Typography variant="h4" component="p">
        Credits may take 24-48 hours to appear
      </Typography>
    }
    placement="bottom"
  >
    <Chip
      size="small"
      component="button"
      label="Pending credits"
      icon={<InfoOutlinedIcon sx={{ height: 14, width: 14 }} />}
      sx={({ palette, typography }) => ({
        background: palette.grey[200],
        cursor: "pointer",
        ...typography.h4,
      })}
    />
  </WrappedTooltip>
);

export default MyCreditsPending;
