import React, { useEffect, useRef } from "react";

import { Box, Stack } from "@mui/material";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import useExploreMerchants from "~src/hooks/services/useExplorablePublicMerchants";

import { NAVBAR_HEIGHT_PX } from "../layout/GlobalStyles";
import CategoryTabButton from "./CategoryTabButton";
import CategoryTabButtonSkeleton from "./CategoryTabButtonSkeleton";

const STACK_HEIGHT_PX = 80;

type CategoryTabsProps = {
  // Disable the sticky/abosolute positioning of the tabs.
  // This is used in the public variant of the page due to the
  // limitations of the `iframe` implementation.
  noSticky?: boolean;
};

// See Swiper React docs: https://swiperjs.com/react

const CategoryTabs: React.VFC<CategoryTabsProps> = ({ noSticky = false }) => {
  const { loading, exploreMerchants } = useExploreMerchants();

  const topOffset = NAVBAR_HEIGHT_PX + 1;

  const categoryNames = exploreMerchants.categories.map(
    (category) => category.name
  );

  // Position the tabs sticky to the top of the screen, but below the top app bar.
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const stackEl = stackRef.current;
    const rootEl = document.getElementById("root");

    // If the page is scrolled above NAVBAR_HEIGHT_PX, then the tabs should be
    // positioned at the current scroll position minus NAVBAR_HEIGHT_PX.
    // Otherwise, the tabs should be positioned at the top of the screen.

    const handleScroll = () => {
      if (stackEl && rootEl) {
        if (rootEl.scrollTop < topOffset) {
          stackEl.style.borderBottomWidth = "0px";
          stackEl.style.top = `${topOffset - rootEl.scrollTop}px`;
        } else {
          stackEl.style.borderBottomWidth = "1px";
          stackEl.style.top = `${0}px`;
        }
      }
    };

    if (rootEl && !noSticky) {
      rootEl.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (rootEl) {
        rootEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, [topOffset, noSticky]);

  const slides = loading
    ? // This width list is used to give the skeletons a less
      // repetitive and unnatural look, and better match the
      // real content.
      [90, 110, 86, 92, 120, 98, 144]
        // eslint-disable-next-line react/no-array-index-key
        .map((width, j) => ({
          key: j,
          node: <CategoryTabButtonSkeleton width={width} />,
        }))
    : categoryNames.map((categoryName) => ({
        key: categoryName,
        node: <CategoryTabButton categoryName={categoryName} />,
      }));

  return (
    <>
      {!noSticky && <Stack sx={{ height: STACK_HEIGHT_PX }} />}
      <Stack
        ref={stackRef}
        style={{
          ...(!noSticky && {
            top: topOffset,
            marginTop: 0,
          }),
        }}
        sx={({ palette }) => ({
          height: STACK_HEIGHT_PX,
          background: palette.grey[100],
          borderBottom: `0px solid ${palette.divider}`,
          ...(!noSticky && {
            // We offset the width by --scrollbar-width to account for the
            // scrollbar that can appear underneath when the bar is sticky.
            width: "calc(100vw - var(--scrollbar-width))",
            left: 0,
            zIndex: 3,
            position: "absolute",
          }),
        })}
      >
        <Stack
          ref={stackRef}
          sx={({ palette, breakpoints }) => ({
            height: STACK_HEIGHT_PX,
            width: "100%",
            ...(!noSticky && {
              maxWidth: "var(--large-page-panel-max-width)",
              margin: "0 auto",
              px: 4,
              [breakpoints.up("md")]: {
                px: 8,
              },
            }),
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            ".swiper": {
              width: "calc(100% + 32px)",
              mx: -4,
              px: 4,
              [breakpoints.up("md")]: {
                width: "calc(100% + 64px)",
                mx: -8,
                px: 8,
              },
              overflow: "hidden",
              ".swiper-slide": {
                width: "auto",
              },
              // Shared styles for edge blur effect.
              ".edge-blur": {
                width: "50vw",
                height: "calc(100% + 32px)",
                position: "absolute",
                background: palette.grey[100],
                top: -16,
                filter: "blur(4px)",
                zIndex: 2,
              },
              // 1140px is where the auto-x-margin on the desktop starts.
              [breakpoints.down(1140)]: {
                ".edge-blur": {
                  display: "none",
                },
              },
            },
          })}
        >
          <Swiper
            slidesPerView="auto"
            spaceBetween={8}
            freeMode
            modules={[FreeMode]}
          >
            <Box
              slot="container-start"
              className="edge-blur"
              sx={{ right: "calc(100% - 24px)" }}
            />
            {slides.map((slide) => (
              <SwiperSlide key={slide.key}>{slide.node}</SwiperSlide>
            ))}
            <Box
              slot="container-end"
              className="edge-blur"
              sx={{ left: "calc(100% - 24px)" }}
            />
          </Swiper>
        </Stack>
      </Stack>
    </>
  );
};

export default CategoryTabs;
