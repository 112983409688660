import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Typography } from "@mui/material";

import { selectCatchCard } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";

import PinForm from "../shared/onboarding/PinForm";

const CatchCardPinSetup: React.VFC = () => {
  const dispatch = useDispatch();
  const catchCard = useSelector(selectCatchCard);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      dispatch(
        setCatchCard({
          ...catchCard,
          is_pin_set: true,
        })
      );
    }
  }, [catchCard, success, dispatch]);

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <Stack spacing={6}>
      <Typography>
        Stores might ask for a 4-digit PIN if you use your Catch card with Apple
        Pay. Make sure you can memorize it or write it down.
      </Typography>

      <PinForm onSuccess={handleSuccess} />
    </Stack>
  );
};

export default CatchCardPinSetup;
