import React from "react";

import {
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";

type MarqetaInputProps = {
  name: "pan" | "exp" | "cvv";
  isRendered: boolean;
};

const MarqetaInput: React.FC<MarqetaInputProps> = ({
  children,
  name,
  isRendered,
}) => (
  <Box position="relative">
    <Skeleton
      animation="pulse"
      variant="rectangular"
      height={52}
      sx={({ palette, shape }) => ({
        bgcolor: `${palette.common.white}1a`,
        width: "100%",
        borderRadius: `${shape.borderRadius}px`,
        position: "absolute",
        top: 0,
        left: 0,
        visibility: isRendered ? "hidden" : "visible",
      })}
    />

    <Box
      sx={({ palette, shape }) => ({
        height: 52,
        border: `1px solid ${palette.common.white}`,
        borderRadius: `${shape.borderRadius}px`,
        px: 4,
        py: 2.75,
        position: "relative",
        visibility: isRendered ? "visible" : "hidden",
      })}
    >
      <Box
        sx={({ palette, typography }) => ({
          background: palette.tertiary.main,
          px: 1,
          position: "absolute",
          top: -12,
          left: 12,
          ...typography.bodySmall,
        })}
      >
        {children}
      </Box>

      <Box id={`catch-mq-card-${name}`} overflow="hidden" />

      <Stack
        id={`catch-mq-card-${name}-copied`}
        spacing={1}
        direction="row"
        display="none"
        alignItems="center"
        px={2}
        py={1}
        color="tertiary.main"
        sx={({ palette, shape }) => ({
          background: palette.common.white,
          borderRadius: `${shape.borderRadius * 3.5}px`,
        })}
      >
        <Typography variant="h4">Copied</Typography>
        <CheckIcon fontSize="small" />
      </Stack>

      <IconButton
        id={`catch-mq-card-${name}-copy`}
        sx={({ palette }) => ({
          position: "absolute",
          color: palette.common.white,
          "&.MuiButtonBase-root": {
            m: 0,
            top: 0,
            right: 0,
          },
        })}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  </Box>
);

export default MarqetaInput;
