import { RewardPoolsResponseData } from "~common/services";
import { makeFetchSlice } from "~common/store/store-utils";

const slice = makeFetchSlice<RewardPoolsResponseData | null>(
  "rewardPools",
  null
);

const { reducer, actions: rewardPoolsActions } = slice;

export { rewardPoolsActions };
export default reducer;
