import React from "react";
import { useDispatch } from "react-redux";

import {
  setPlaidCustomLinkName,
  setPlaidOpenAllowed,
} from "../../../store/slices/bankLinking-slice";
import { useTracking } from "../../../tracking";
import { DefaultButton } from "../../controls/buttons";

const SearchOtherBanks: React.VFC = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();

  // Setting the Plaid custom link name to "default" opens
  // the Plaid modal in search mode.
  const handleSearchOtherBanksClick = () => {
    trackEvent("Bank Search Clicked");
    dispatch(setPlaidCustomLinkName("default"));
    dispatch(setPlaidOpenAllowed(true));
  };

  return (
    <DefaultButton fullWidth onClick={handleSearchOtherBanksClick}>
      Search other banks
    </DefaultButton>
  );
};

export default SearchOtherBanks;
