import React from "react";

import { ErrorOutlined as ErrorOutlinedIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import ExternalTextLink from "~common/components/links/ExternalTextLink";

const PaymentMethodErrorPrompt: React.VFC = () => (
  <Stack direction="row" alignItems="center" gap={1}>
    <ErrorOutlinedIcon color="error" />
    <Typography color="error" variant="bodySmall">
      Trouble connecting.
    </Typography>
    <ExternalTextLink
      color="error"
      size="small"
      variant="linkSmall"
      eventName="Why Relink Bank Account Link Clicked"
      href="https://help.getcatch.com/en/articles/5010842-why-am-i-being-asked-to-relink-my-bank-account-if-i-never-unlinked-it"
    >
      Why did this happen?
    </ExternalTextLink>
  </Stack>
);

export default PaymentMethodErrorPrompt;
