import { useGet } from "restful-react";

import {
  GetConsumerActivitiesListQueryParams,
  GetConsumerActivitiesListResponse,
  GetConsumerActivitiesPathParams,
} from "./types/consumer-activities-types";
import { ErrorResponseData } from "./types/error-handling-types";

const useGetConsumerActivities = (options: {
  pathParams: GetConsumerActivitiesPathParams;
  queryParams?: GetConsumerActivitiesListQueryParams;
  lazy?: boolean;
}) =>
  useGet<
    GetConsumerActivitiesListResponse,
    ErrorResponseData,
    GetConsumerActivitiesListQueryParams,
    GetConsumerActivitiesPathParams
  >({
    path: ({ userId }) => `users-svc/users/${userId}/activities`,
    pathParams: options.pathParams,
    queryParams: options.queryParams,
    lazy: options.lazy,
  });

export { useGetConsumerActivities };
