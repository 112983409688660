import React from "react";

import { Link as LinkIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { RainingMoneyIcon } from "~common/components/icons/vector";
import ExternalTextLink from "~common/components/links/ExternalTextLink";
import { CopyLinkButtonLabel } from "~common/components/markers";
import { formatMoney } from "~common/utils/money";
import useCurrentUser from "~src/hooks/services/useCurrentUser";
import useShareReferralLink from "~src/hooks/services/useShareReferralLink";

import WhatNextPrompt from "./WhatNextPrompt";

const ReferralPrompt: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const { copyShareLink, linkCopied } = useShareReferralLink(
    "WhatNext/ReferralPrompt"
  );
  const referralBonus = formatMoney(
    currentUser?.eligible_referral_bonus_amount || 2000,
    {
      dropCentsIfRound: true,
    }
  );

  return (
    <WhatNextPrompt
      body={
        <Stack spacing={1}>
          <RainingMoneyIcon size="small" sx={{ mb: 3 }} />

          <Typography variant="h2">
            Earn{" "}
            <Typography variant="h2" component="span" color="primary.main">
              {referralBonus} Catch Cash
            </Typography>{" "}
            by inviting your friends
          </Typography>

          <Typography variant="bodyRegular" color="grey.600">
            Plus they&apos;ll earn an extra $5 on top of the usual first order
            boost.
          </Typography>

          <Typography
            variant="bodySmall"
            component="p"
            color="grey.600"
            sx={{ marginTop: 2 }}
          >
            Applies to order over $20.{" "}
            <ExternalTextLink
              size="regular"
              variant="linkSmall"
              eventName="Whats Next Learn How Referrals Work Link Clicked"
              href="https://help.getcatch.com/en/collections/2753571-referrals"
            >
              Learn more
            </ExternalTextLink>
          </Typography>
        </Stack>
      }
      footer={
        <DefaultButton
          onClick={copyShareLink}
          startIcon={
            linkCopied === null || linkCopied === "succeeded" ? (
              <LinkIcon />
            ) : null
          }
          disabled={linkCopied !== null}
          fullWidth
        >
          <CopyLinkButtonLabel linkCopied={linkCopied} />
        </DefaultButton>
      }
    />
  );
};

export default ReferralPrompt;
