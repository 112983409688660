import React, { forwardRef, useState } from "react";

import { Accordion, AccordionProps } from "@mui/material";

const WrappedAccordion: React.FC<AccordionProps> = forwardRef(
  ({ children, ...props }, ref) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const toggleAccordion = () => {
      setIsExpanded((prev) => !prev);
    };

    return (
      <Accordion
        square
        expanded={isExpanded}
        onChange={toggleAccordion}
        {...props}
        ref={ref}
      >
        {children}
      </Accordion>
    );
  }
);

WrappedAccordion.displayName = "WrappedAccordion";

export default WrappedAccordion;
