import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lock as LockIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";

import AdyenCardConnect from "~common/components/bank-linking/AdyenCardConnect";
import PaymentInstrumentErrorHeaders from "~common/components/bank-linking/connect/headers/payment-instrument/PaymentInstrumentErrorHeaders";
import HoldAuthorization from "~common/components/bank-linking/HoldAuthorization";
import { PrimaryButton } from "~common/components/controls/buttons";
import {
  setConnectBankVariant,
  setIsCatchPassFlow,
} from "~common/store/slices/bankLinking-slice";
import { useTracking } from "~common/tracking";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectConnectBankVariant } from "~src/store";

import EditCard from "../EditCard";

type LinkDebitProps = {
  onEdit: (isActive: boolean, hideCancel?: boolean) => void;
  isEditing: boolean;
  onSuccess: () => void;
  onError: (active: boolean) => void;
};

const LinkDebit: React.VFC<LinkDebitProps> = ({
  onEdit,
  isEditing,
  onSuccess,
  onError,
}) => {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const { paymentInstruments, loading } = usePaymentInstruments();
  const connectBankVariant = useSelector(selectConnectBankVariant);
  const paymentInstrument = paymentInstruments[0] || null;

  useEffect(() => {
    dispatch(setIsCatchPassFlow(true));

    return () => {
      dispatch(setIsCatchPassFlow(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !paymentInstrument) {
      onEdit(true, true);
    }
  }, [paymentInstrument, loading, onEdit]);

  useEffect(() => {
    if (connectBankVariant === "Success" && paymentInstrument) {
      onSuccess();

      dispatch(
        setConnectBankVariant(paymentInstrument ? "ChangeMethod" : "Default")
      );
    }
  }, [dispatch, paymentInstrument, connectBankVariant, onSuccess]);

  useEffect(() => {
    if (
      connectBankVariant !== "Default" &&
      connectBankVariant !== "ChangeMethod"
    ) {
      onError(true);
    } else {
      onError(false);
    }
  }, [connectBankVariant, onError]);

  const handleRetry = () => {
    trackEvent("Link Debit Try again Clicked", {
      component: "LinkDebit",
    });

    dispatch(
      setConnectBankVariant(paymentInstrument ? "ChangeMethod" : "Default")
    );
  };

  const handleConfirm = () => {
    trackEvent("Link Debit Continue Clicked");
    onSuccess();
  };

  if (
    connectBankVariant !== "Default" &&
    connectBankVariant !== "ChangeMethod"
  ) {
    return (
      <Stack
        spacing={6}
        sx={{
          ".BankLinking-header": {
            textAlign: "center",
            alignItems: "center",
          },
        }}
      >
        <PaymentInstrumentErrorHeaders variant={connectBankVariant} />
        <PrimaryButton onClick={handleRetry}>Try again</PrimaryButton>
      </Stack>
    );
  }

  return (
    <>
      {isEditing ? (
        <AdyenCardConnect inline />
      ) : (
        <>
          {paymentInstrument && (
            <>
              <EditCard onClick={() => onEdit(true)}>
                {paymentInstrument?.display_name}
              </EditCard>

              <HoldAuthorization />

              <PrimaryButton startIcon={<LockIcon />} onClick={handleConfirm}>
                Continue
              </PrimaryButton>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LinkDebit;
