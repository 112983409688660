import { useGetUserData } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectUserData } from "~src/store";
import { userDataActions } from "~src/store/slices/services/userData-slice";

const useGet = () => useGetUserData({ lazy: true });

const useFetchUserData = () => {
  useLazyFetch(
    "UserData",
    userDataActions,
    selectUserData,
    useGet,
    (data) => data
  );
};

export default useFetchUserData;
