import React from "react";

import ErrorAlert from "./ErrorAlert";

const GenericServiceErrorAlert: React.VFC = () => (
  <ErrorAlert>
    Something didn&apos;t work as expected. Please try again.
  </ErrorAlert>
);

export default GenericServiceErrorAlert;
