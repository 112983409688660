import { useGetRewardPoolEvents } from "~common/services";
import useLazyFetch from "~src/hooks/fetching/useLazyFetch";
import { selectRewardPoolEvents } from "~src/store";
import { rewardPoolEventsActions } from "~src/store/slices/services/rewardPoolEvents-slice";

const useGet = () => useGetRewardPoolEvents({ lazy: true });

const useFetchRewardPoolEvents = () => {
  useLazyFetch(
    "RewardPoolEvents",
    rewardPoolEventsActions,
    selectRewardPoolEvents,
    useGet,
    (data) => data
  );
};

export default useFetchRewardPoolEvents;
