import React from "react";

import AdaptiveBasenameRouter from "~common/components/routing/AdaptiveBasenameRouter";

const BasePathRouter: React.FC = ({ children }) => (
  <AdaptiveBasenameRouter defaultBasename="/u" alternatives={[]}>
    {children}
  </AdaptiveBasenameRouter>
);

export default BasePathRouter;
