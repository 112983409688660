const MINUTE_MS = 6e4;

// Set a delay in certain flows where we want the UI to linger
// in a certain state before advancing the user. For example,
// sometimes after some processing (such as OTP verification),
// we want to show the user a "success" state briefly before
// advancing them to the next view.
const delay = async (seconds: number): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const answerOTPCompletionDelay = (): Promise<void> => delay(1);

export { answerOTPCompletionDelay, delay, MINUTE_MS };
