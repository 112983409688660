import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";

import AnimatedMeditatingCoinFallback from "./AnimatedMeditatingCoinFallback";

const AnimatedMeditatingCoinLottie = lazy(
  () => import("./AnimatedMeditatingCoinLottie")
);

const AnimatedMeditatingCoin: React.VFC = () => (
  <Suspense
    fallback={
      <Box style={{ width: 142, height: 142, margin: -11 }}>
        <AnimatedMeditatingCoinFallback />
      </Box>
    }
  >
    <AnimatedMeditatingCoinLottie />
  </Suspense>
);

export default AnimatedMeditatingCoin;
