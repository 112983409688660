import React from "react";
import { useSelector } from "react-redux";

import { Divider, Stack } from "@mui/material";

import ButtonRow from "~src/components/account/ButtonRow";
import DeleteAccountLinkRow from "~src/components/account/DeleteAccountLinkRow";
import LabeledSection from "~src/components/layout/LabeledSection";
import useAppLogout from "~src/hooks/services/useAppLogout";
import { selectIsMobileApp } from "~src/store";

const LogoutSection: React.VFC = () => {
  const isMobileApp = useSelector(selectIsMobileApp);
  const logout = useAppLogout();

  const handleLogoutClick = () => {
    void logout({ cause: "Clicked button in LogoutSection" });
  };

  return (
    <LabeledSection heading="Log out">
      <Stack>
        <ButtonRow title="Log out of Catch" onClick={handleLogoutClick} />
        {isMobileApp && (
          <>
            <Divider />
            <DeleteAccountLinkRow
              href="https://ead5e680ees.typeform.com/to/BqwpIu7Y"
              title="Delete Catch account"
            />
            <Divider />
          </>
        )}
      </Stack>
    </LabeledSection>
  );
};

export default LogoutSection;
