import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.0191 65.6176L28.1793 63.918C26.5027 63.7291 25.1987 62.2183 25.2918 60.5187L28.8313 17.9332C29.1107 16.1392 30.601 14.9116 32.3708 15.0061L52.2106 16.7057C53.8872 16.8946 55.1912 18.4054 55.0981 20.105L51.5586 62.6904C51.2791 64.4845 49.7888 65.8064 48.0191 65.6176Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.0437 41.8228L31.4394 40.312C30.6942 40.2176 30.2285 39.651 30.2285 38.8956L30.8805 31.1528C30.9737 30.3974 31.5325 29.9253 32.2777 29.9253L49.882 31.4361C50.6272 31.5305 51.0929 32.0971 51.0929 32.8525L50.4409 40.5953C50.3477 41.3507 49.7889 41.8228 49.0437 41.8228Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3271 35.3076L46.9948 36.4407"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7676 60.0464L43.2683 60.8962"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.8421 15.667C36.6558 17.7443 38.2392 19.6328 40.2884 19.8217L43.5485 20.1049C45.5976 20.2938 47.4605 18.6886 47.6468 16.6112V16.4224"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1193 33.1357C18.1193 33.1357 14.673 39.651 16.9085 48.9991"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9721 27.6587C11.9721 27.6587 8.71207 34.4573 10.575 40.7837"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.4132 29.8306C64.4132 29.8306 66.9281 33.6075 64.1338 41.5392"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.5107 18.311C68.5107 18.311 72.4228 25.2984 69.7216 33.8911"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
