import { useTracking } from "../tracking";
import { formatRelativeLink } from "../utils/urls";

type LinkCopyState = null | "succeeded" | "permission-denied" | "errored";

type CopyLinkOptions = {
  setLinkCopied: (linkCopied: LinkCopyState) => void;
  component: string;
};

const useCopyLink = () => {
  const { trackError } = useTracking();

  const copyLink = async (path: string, options: CopyLinkOptions) => {
    const { setLinkCopied, component } = options;

    const flashCopyState = (copyState: LinkCopyState) => {
      setLinkCopied(copyState);
      setTimeout(() => {
        setLinkCopied(null);
      }, 3000);
    };

    const value = formatRelativeLink(path);
    try {
      await navigator.clipboard.writeText(value);
      flashCopyState("succeeded");
    } catch (rawError) {
      if ((rawError as Error).name === "NotAllowedError") {
        trackError(component, "Permission denied copying to clipboard");
        flashCopyState("permission-denied");
      } else {
        // If writeText fails, attempt to fallback to using a tempory <textarea>.
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = value;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);

        flashCopyState("succeeded");
      }
    }
  };

  return copyLink;
};

export default useCopyLink;
export type { LinkCopyState };
