import React, { forwardRef } from "react";

import { CompactButton } from "~common/components/controls/buttons";
import { WrappedButtonProps } from "~common/components/controls/buttons/WrappedButton";

const MerchantCardButton: React.FC<WrappedButtonProps> = forwardRef(
  ({ sx = [], ...props }, ref) => (
    <CompactButton
      variant="contained"
      ref={ref}
      sx={[
        ({ palette }) => ({
          "&.MuiButton-sizeSmall": {
            color: palette.grey[700],
            background: palette.common.white,
            height: 36,
            padding: "6px 12px",
            boxShadow: "none",
            ".label": {
              fontSize: "14px",
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
);

export default MerchantCardButton;
