import React, { forwardRef } from "react";

import { Box, Stack, Typography } from "@mui/material";

import { DefaultButton } from ".";
import { WrappedButtonProps } from "./WrappedButton";

type InformationalButtonProps = WrappedButtonProps & {
  labelIcon: React.ReactNode;
  label: React.ReactNode;
  informationalText: string;
  actionIcon: React.ReactNode;
};

const InformationalButton: React.VFC<InformationalButtonProps> = forwardRef(
  (
    { labelIcon, label, informationalText, actionIcon, sx = [], ...props },
    ref
  ) => (
    <DefaultButton
      {...props}
      ref={ref}
      size="large"
      fullWidth
      sx={[
        (theme) => {
          const { spacing, palette } = theme;
          return {
            "&.MuiButton-sizeLarge": {
              p: spacing(4, 0),
            },
            "&.MuiButton-outlined.MuiButton-colorInherit": {
              border: `solid ${palette.grey[300]}`,
              borderWidth: "1px 0px",
              borderRadius: 0,
            },
            ".label": {
              width: "100%",
            },
          };
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={8}
        textAlign="left"
      >
        <Stack spacing={1}>
          <Typography color="info.main" variant="h3" component="span">
            <Stack direction="row" gap={1} alignItems="center">
              <Box
                sx={({ spacing }) => ({
                  height: spacing(6),
                  width: spacing(6),
                  // Align icon flush with left side of button
                  marginLeft: spacing(-1.5),
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                })}
              >
                {labelIcon}
              </Box>

              {label}
            </Stack>
          </Typography>
          <Typography color="grey.600" variant="bodySmall" component="span">
            {informationalText}
          </Typography>
        </Stack>
        <Box
          sx={({ palette }) => ({
            color: palette.info.main,
            ".MuiSvgIcon-root": {
              height: "24px",
              width: "24px",
            },
          })}
        >
          {actionIcon}
        </Box>
      </Stack>
    </DefaultButton>
  )
);

InformationalButton.displayName = "InformationalButton";

export default InformationalButton;
