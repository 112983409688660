import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUpdateUser } from "~common/experimentation/experimentation-hooks";
import { StatsigUser } from "~common/experimentation/shared";
import { useRefreshToken } from "~common/services";
import { useTracking } from "~common/tracking";
import {
  selectAuthData,
  selectAuthUserInfo,
  selectCurrentUser,
} from "~src/store";
import { stopLoading } from "~src/store/slices/core-slice";
import { clearAuthState, setAuthState } from "~src/store/slices/user-slice";

const useAuthEffects = (): void => {
  const {
    trackEvent,
    trackError,
    trackUser,
    clearUserTraits,
    patchTrackingContext,
  } = useTracking();
  const authData = useSelector(selectAuthData);
  const authUserInfo = useSelector(selectAuthUserInfo);
  const currentUser = useSelector(selectCurrentUser.data);
  const { mutate: refreshToken } = useRefreshToken();
  const dispatch = useDispatch();

  const hasBecomeAuthed = useRef<boolean>(false);
  const [userID, setUserId] = useState<string | undefined>(undefined);
  const statsigUser: StatsigUser = {
    customIDs: { catchUserId: userID || "" },
    custom: { app: "user-portal" },
  };
  useUpdateUser({
    user: statsigUser,
    useHashedIp: true,
  });

  // Update user tracking info when UserInfo changes.
  useEffect(() => {
    if (authUserInfo) {
      trackUser({
        id: authUserInfo.userId,
        email: authUserInfo.email,
        emailVerified: authUserInfo.emailVerified,
        userHash: authUserInfo.intercomUserHash,
      });
    }
  }, [trackUser, authUserInfo]);

  // Update user tracking info when CurrentUser changes.
  useEffect(() => {
    if (currentUser) {
      trackUser({
        id: currentUser.id,
        email: currentUser.email,
        emailVerified: currentUser.email_verified,
        name: currentUser.legal_name,
        deactivated: currentUser.deactivated,
      });
    }
  }, [trackUser, currentUser]);

  // Survive page refresh: authenticate if possible upon load.
  useEffect(() => {
    const authAfterRefresh = () => {
      refreshToken(undefined).then(
        (data) => {
          dispatch(setAuthState(data));
          dispatch(stopLoading());
          trackEvent("Completed initial load for logged in user");
        },
        () => {
          dispatch(clearAuthState());
          dispatch(stopLoading());
          trackEvent("Completed initial load for anonymous user");
          trackError("useAuthEffects", "Failed surviving refresh");
        }
      );
    };

    authAfterRefresh();
  }, [trackEvent, trackError, dispatch, refreshToken]);

  // Refresh auth state.
  useEffect(() => {
    let refreshTimeout: ReturnType<typeof setTimeout> | null = null;

    if (authData && authUserInfo) {
      refreshTimeout = setTimeout(() => {
        refreshToken(undefined).then(
          (data) => dispatch(setAuthState(data)),
          () => {
            dispatch(clearAuthState());
            trackError("useAuthEffects", "Failed timeout refresh");
          }
        );
      }, (authData.expires_in - 10) * 1000);

      if (!hasBecomeAuthed.current) {
        hasBecomeAuthed.current = true;
        setUserId(authUserInfo.userId);
      }
    } else if (hasBecomeAuthed.current) {
      hasBecomeAuthed.current = false;
      clearUserTraits();
    }

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [
    trackUser,
    clearUserTraits,
    trackError,
    authData,
    authUserInfo,
    dispatch,
    refreshToken,
  ]);

  useEffect(() => {
    if (currentUser) {
      patchTrackingContext({
        isEmployee: currentUser?.is_employee,
      });
    }
  }, [currentUser, patchTrackingContext]);
};

export default useAuthEffects;
