import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Check as CheckIcon, Lock as LockIcon } from "@mui/icons-material";
import { FormHelperText, Stack } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { PinInput } from "~common/components/controls/text-fields";
import { useUpdateAnywhereCardPin } from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import { selectCatchCard } from "~src/store";

type PinFormProps = {
  onSuccess: () => void;
};

const PinForm: React.VFC<PinFormProps> = ({ onSuccess }) => {
  const { trackEvent, trackError } = useTracking();
  const catchCard = useSelector(selectCatchCard);
  const { mutate: updateAnywhereCardPin } = useUpdateAnywhereCardPin();
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [success, setSuccess] = useState(catchCard?.is_pin_set || false);

  const handleChange = (value: string) => {
    setPin(value);
    setErrors({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (pin.length !== 4) {
      setErrors({
        pin: "Please enter a four digit PIN",
      });

      return;
    }

    if (!catchCard?.card_id) {
      trackError("PinForm", "PIN", { error: "Missing card_id" });

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });

      return;
    }

    trackEvent("Catch Card PIN Submitted");
    setLoading(true);

    try {
      await updateAnywhereCardPin(
        { pin },
        {
          pathParams: { issuedCardId: catchCard?.card_id },
        }
      );

      trackEvent("Catch Card PIN Saved");
      setLoading(false);
      setSuccess(true);
      onSuccess();
    } catch (err) {
      trackError("PinForm", "PIN", { error: err });
      setLoading(false);

      setErrors({
        generic: "Something didn't work as expected. Please try again.",
      });
    }
  };

  return (
    <Stack spacing={6} component="form" onSubmit={handleSubmit}>
      <PinInput
        numInputs={4}
        value={pin}
        updateValue={handleChange}
        error={!!errors.pin}
        errorMessage={errors.pin}
        disabled={loading || success}
      />

      {errors.generic && (
        <FormHelperText error>{errors.generic}</FormHelperText>
      )}

      <PrimaryButton
        type="submit"
        startIcon={success ? <CheckIcon /> : <LockIcon />}
        loading={loading}
        disabled={success}
      >
        {success ? "Your PIN is set" : "Continue"}
      </PrimaryButton>
    </Stack>
  );
};

export default PinForm;
