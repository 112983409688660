import { UserDataResponseData } from "~common/services";
import { makeFetchSelectors } from "~common/store/store-utils";
import { RootState } from "~src/store";

const selectUserData = makeFetchSelectors<
  UserDataResponseData | null,
  RootState
>("userData");

export { selectUserData };
