import React from "react";

import { useTrackPageView } from "~common/tracking";
import LargePagePanel from "~src/components/layout/LargePagePanel";
import CategoryTabs from "~src/components/shop/CategoryTabs";
import ShopCategories from "~src/components/shop/ShopCategories";

const Shop: React.VFC = () => {
  useTrackPageView("Shop");

  return (
    <LargePagePanel
      sx={({ palette }) => ({
        overflowX: "hidden",
      })}
      shopPage
    >
      <CategoryTabs />
      <ShopCategories utmCampaign="web_app" />
    </LargePagePanel>
  );
};

export default Shop;
