import React from "react";

import { Stack, Typography } from "@mui/material";

import WrappedSkeleton from "~common/components/loading/WrappedSkeleton";

const MerchantCardSkeleton: React.VFC = () => (
  <Stack
    sx={{
      gap: 2,
    }}
  >
    <WrappedSkeleton
      variant="rectangular"
      sx={{
        borderRadius: 2,
        height: "240px",
      }}
    />
    <Stack>
      <Typography variant="h3">
        <WrappedSkeleton variant="text" width={80} />
      </Typography>
      <Typography variant="bodyRegular">
        <WrappedSkeleton variant="text" width={120} />
      </Typography>
    </Stack>
  </Stack>
);

export default MerchantCardSkeleton;
