import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import getStartedRedeemImage from "~common/assets/illustrations/get_started_redeem.png";
import { PrimaryButton } from "~common/components/controls/buttons";
import { ResponsiveDrawerDialog } from "~common/components/modals";
import useRecentMerchantVisit from "~common/hooks/useRecentMerchantVisit";
import { useTracking } from "~common/tracking";
import useUserData from "~src/hooks/services/useUserData";
import { selectClaims, selectHasDismissedReturnToMerchant } from "~src/store";
import { markhasDismissedReturnToMerchant } from "~src/store/slices/core-slice";

const ReturnToMerchantModal: React.VFC = () => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const hasBeenDismissed = useSelector(selectHasDismissedReturnToMerchant);
  const { userData } = useUserData();
  const claims = useSelector(selectClaims);

  // Derive "has never earned credits" by looking for a successful user data
  // with $0.00 lifetime rewards earned.
  const hasNeverEarnedCredits =
    userData?.lifetime_rewards_earned !== undefined &&
    userData.lifetime_rewards_earned === 0;

  const { recentMerchantVisit, clearRecentMerchantVisit } =
    useRecentMerchantVisit();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      !hasBeenDismissed &&
      recentMerchantVisit &&
      !open &&
      hasNeverEarnedCredits &&
      !claims.rewardCampaignId
    ) {
      setOpen(true);
      trackEvent("Return to merchant modal open", { recentMerchantVisit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    hasBeenDismissed,
    hasNeverEarnedCredits,
    recentMerchantVisit,
    trackEvent,
  ]);

  if (!recentMerchantVisit) {
    return null;
  }

  const { pageUrl, merchantName } = recentMerchantVisit;

  const handleButtonClick = () => {
    trackEvent("Return to merchant modal button click", {
      recentMerchantVisit,
    });
    clearRecentMerchantVisit();
  };

  const handleClose = () => {
    trackEvent("Return to merchant modal close", { recentMerchantVisit });
    setOpen(false);
    dispatch(markhasDismissedReturnToMerchant());
  };

  return (
    <ResponsiveDrawerDialog
      name="returnToMerchant"
      onClose={handleClose}
      open={open}
    >
      {({ contentId, headingId }) => (
        <Stack sx={{ gap: 6 }}>
          <Typography variant="h2" color="primary.main">
            Your account is all set up 🎉
          </Typography>

          <Box sx={{
            background: `center top / cover url(${getStartedRedeemImage}) no-repeat`,
            height: 194,
          }} />

          <Stack sx={{ gap: 2 }}>
            <Typography id={headingId} variant="h2">
              Ready to start earning?
            </Typography>
            <Typography id={contentId} variant="bodyRegular" color="grey.600">
              <strong>Just select Catch as your payment method</strong> when
              shopping at your favorite brands and earn up to{" "}
              <strong>10% back on every purchase.</strong>
            </Typography>
          </Stack>
          <PrimaryButton
            href={pageUrl}
            target="_blank"
            onClick={handleButtonClick}
            endIcon={<OpenInNewIcon />}
          >
            Go back to {merchantName}
          </PrimaryButton>
        </Stack>
      )}
    </ResponsiveDrawerDialog>
  );
};

export default ReturnToMerchantModal;
