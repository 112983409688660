import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import {
  ClaimRewardPoolEntryPathParams,
  ClaimRewardPoolEntryRequestData,
  ClaimRewardPoolEntryResponseData,
  CreateRewardPoolEntryRequestData,
  CreateRewardPoolEntryResponseData,
  GetRewardPoolEntriesForPoolPathParams,
  RemoveRewardPoolEntryPathParams,
  RemoveRewardPoolEntryResponseData,
  RewardPoolEntriesResponseData,
  RewardPoolEventsResponseData,
  RewardPoolsResponseData,
} from "./types/reward-pools-types";

const useGetRewardPools = (options: { lazy?: boolean } = { lazy: false }) =>
  useGet<RewardPoolsResponseData, ErrorResponseData, undefined, undefined>({
    path: `promotions-svc/reward_pools`,
    lazy: options.lazy,
  });

const useGetRewardPoolEntriesForPool = (
  options: {
    pathParams?: GetRewardPoolEntriesForPoolPathParams;
    lazy?: boolean;
  } = {
    lazy: false,
  }
) =>
  useGet<
    RewardPoolEntriesResponseData,
    ErrorResponseData,
    undefined,
    GetRewardPoolEntriesForPoolPathParams
  >({
    path: ({ rewardPoolId }) =>
      `promotions-svc/reward_pools/${rewardPoolId}/entries`,
    pathParams: options.pathParams,
    lazy: options.lazy,
  });

const useGetRewardPoolEvents = (
  options: { lazy?: boolean } = { lazy: false }
) =>
  useGet<RewardPoolEventsResponseData, ErrorResponseData, undefined, undefined>(
    {
      path: `promotions-svc/reward_pool_events`,
      lazy: options.lazy,
    }
  );

const useClaimRewardPoolEntry = () =>
  useMutate<
    ClaimRewardPoolEntryResponseData,
    ErrorResponseData,
    undefined,
    ClaimRewardPoolEntryRequestData,
    ClaimRewardPoolEntryPathParams
  >({
    verb: "POST",
    path: ({ rewardPoolId, hashedGiftSecret }) =>
      `promotions-svc/reward_pools/${rewardPoolId}/entries/hashed_secret/${hashedGiftSecret}/claim`,
  });

const useCreateRewardPoolEntry = () =>
  useMutate<
    CreateRewardPoolEntryResponseData,
    ErrorResponseData,
    undefined,
    CreateRewardPoolEntryRequestData,
    unknown
  >({
    verb: "POST",
    path: `promotions-svc/reward_pool_entries`,
  });

const useGetAvailableRewardPoolEntries = (
  options: { lazy?: boolean } = { lazy: false }
) =>
  useGet<
    RewardPoolEntriesResponseData,
    ErrorResponseData,
    undefined,
    undefined
  >({
    path: `promotions-svc/reward_pool_entries`,
    lazy: options.lazy,
  });

const useRemoveRewardPoolEntry = () =>
  useMutate<
    RemoveRewardPoolEntryResponseData,
    ErrorResponseData,
    undefined,
    undefined,
    RemoveRewardPoolEntryPathParams
  >({
    verb: "DELETE",
    path: ({ rewardPoolId, hashedGiftSecret }) =>
      `promotions-svc/reward_pools/${rewardPoolId}/entries/hashed_gift_secret/${hashedGiftSecret}`,
  });

export {
  useClaimRewardPoolEntry,
  useCreateRewardPoolEntry,
  useGetAvailableRewardPoolEntries,
  useGetRewardPoolEntriesForPool,
  useGetRewardPoolEvents,
  useGetRewardPools,
  useRemoveRewardPoolEntry,
};
