import React from "react";
import { useSelector } from "react-redux";

import { selectIsAuthedVerified } from "~src/store";

import NavAuthed from "./NavAuthed";
import NavBarContainer from "./NavBarContainer";
import NavItemsUnauthed from "./NavItemsUnauthed";
import NavLogo from "./NavLogo";

const NavBar: React.VFC = () => {
  const isAuthedVerified = useSelector(selectIsAuthedVerified);

  return (
    <NavBarContainer>
      <NavLogo />
      {isAuthedVerified ? <NavAuthed /> : <NavItemsUnauthed />}
    </NavBarContainer>
  );
};

export default NavBar;
