import React from "react";

import { HelpOutline as HelpIcon } from "@mui/icons-material";
import { Box, TooltipProps, Typography } from "@mui/material";

import { CompactIconButton } from "../controls/buttons";
import { WrappedTooltip } from "../tooltips";

const RewardCardTooltip: React.VFC<Omit<TooltipProps, "children">> = ({
  title,
  ...props
}) => (
  <WrappedTooltip
    title={<Typography variant="bodyRegular">{title}</Typography>}
    placement="bottom"
    {...props}
  >
    <Box mr={-0.5} component="span">
      <CompactIconButton
        sx={{
          color: "inherit",
          "&.MuiIconButton-sizeSmall .MuiSvgIcon-root": {
            fontSize: 12,
          },
        }}
      >
        <HelpIcon />
      </CompactIconButton>
    </Box>
  </WrappedTooltip>
);

export default RewardCardTooltip;
