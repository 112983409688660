import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import AlertModal from "~src/components/layout/AlertModal";
import GlobalStyles from "~src/components/layout/GlobalStyles";
import Skipnav from "~src/components/layout/Skipnav";
import NavBar from "~src/components/nav/NavBar";
import { selectIsMobileApp } from "~src/store";

const Layout: React.VFC = () => {
  const isMobileApp = useSelector(selectIsMobileApp);

  return (
    <>
      <GlobalStyles />
      <Skipnav targetId="main" />

      {!isMobileApp && <NavBar />}

      <main id="main">
        <Outlet />
      </main>

      <AlertModal />
    </>
  );
};

export default Layout;
