import React from "react";
import { useSelector } from "react-redux";

import { selectDisableAch } from "../../../../../store/selectors";
import ConnectPaymentInstrumentAlertHeader, {
  ConnectPaymentInstrumentAlertHeaderInstanceProps,
} from "../payment-instrument/ConnectPaymentInstrumentAlertHeader";

const DebitHeaderExpiredLinkedCard: React.VFC<
  ConnectPaymentInstrumentAlertHeaderInstanceProps
> = ({ headingId }) => {
  const disableAch = useSelector(selectDisableAch);

  return (
    <ConnectPaymentInstrumentAlertHeader
      headingId={headingId}
      title={<>The debit card linked to your account has expired</>}
      subtitle={
        <>
          Please connect a new debit card
          {!disableAch && " or link your bank account directly"}.
        </>
      }
    />
  );
};

export default DebitHeaderExpiredLinkedCard;
