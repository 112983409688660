import React from "react";

import FAQCardGroup from "~common/components/faq/FAQCardGroup";
import { useTrackPageView } from "~common/tracking";
import ExpandableLabeledSection from "~src/components/layout/ExpandableLabeledSection";
import { forTheCuriousCards } from "~src/data/forTheCuriousCards";

const ForTheCuriousSection: React.VFC = () => {
  useTrackPageView("For the Curious Section");

  return (
    <ExpandableLabeledSection heading="For the curious" name="ForTheCurious">
      <FAQCardGroup faqCards={forTheCuriousCards} />
    </ExpandableLabeledSection>
  );
};

export default ForTheCuriousSection;
